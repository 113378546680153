function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var Text = function(_React$Component) {
    _inherits(Text, _React$Component);
    function Text() {
        _classCallCheck(this, Text);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    Text.prototype.render = function render() {
        var _props = this.props, numberOfLines = _props.numberOfLines, style = _props.style;
        var className = css.apply(void 0, [ styles.initial ].concat(Array.isArray(style) ? style : [ style ], [ 1 === numberOfLines && styles.singleLineStyle ]));
        return React.createElement("span", {
            className: className,
            style: this.props.dynamicStyle
        }, this.props.children);
    };
    return Text;
}(React.Component);

// https://github.com/necolas/react-native-web/blob/master/src/components/Text/index.js
Text.propTypes = {
    children: React.PropTypes.oneOfType([ React.PropTypes.arrayOf(React.PropTypes.node), React.PropTypes.node ]),
    // The `dynamicStyle` prop is provided for animating dynamic
    // properties, as creating Aphrodite StyleSheets in animation loops is
    // expensive. `dynamicStyle` should be a raw style object, rather than
    // a StyleSheet.
    dynamicStyle: React.PropTypes.any,
    numberOfLines: React.PropTypes.number,
    style: React.PropTypes.any
};

var styles = StyleSheet.create({
    initial: {
        color: "inherit",
        display: "inline",
        font: "inherit",
        margin: 0,
        padding: 0,
        textDecorationLine: "none",
        wordWrap: "break-word"
    },
    singleLineStyle: {
        maxWidth: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    }
});

module.exports = Text;