/**
 * An autogenerated component that renders the DISMISS iconograpy in SVG.
 *
 * Generated with: https://gist.github.com/crm416/3c7abc88e520eaed72347af240b32590.
 */
var React = require("react");

var Dismiss = function Dismiss() {
    return React.createElement("svg", {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48"
    }, React.createElement("g", {
        fill: "none",
        fillRule: "evenodd"
    }, React.createElement("path", {
        fill: "none",
        d: "M0 0h48v48H0z"
    }), React.createElement("path", {
        d: "M18 21l6 6 6-6",
        strokeLinecap: "round",
        strokeWidth: "2",
        stroke: "#71B307",
        strokeLinejoin: "round"
    })));
};

module.exports = Dismiss;