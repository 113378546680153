var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable comma-dangle, no-var, react/sort-comp */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
/* globals $_ */
var React = require("react");

var _ = require("underscore");

var Changeable = require("../mixins/changeable.jsx");

var PerseusMarkdown = require("../perseus-markdown.jsx");

var WidgetJsonifyDeprecated = require("../mixins/widget-jsonify-deprecated.jsx");

var EN_DASH = "–";

var PassageRef = React.createClass({
    displayName: "PassageRef",
    propTypes: _extends({}, Changeable.propTypes, {
        passageNumber: React.PropTypes.number,
        referenceNumber: React.PropTypes.number,
        summaryText: React.PropTypes.string
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            passageNumber: 1,
            referenceNumber: 1,
            summaryText: ""
        };
    },
    getInitialState: function getInitialState() {
        return {
            lineRange: null,
            content: null
        };
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState);
    },
    getUserInput: function getUserInput() {
        return WidgetJsonifyDeprecated.getUserInput.call(this);
    },
    render: function render() {
        var lineRange = this.state.lineRange;
        var lineRangeOutput;
        lineRangeOutput = lineRange ? lineRange[0] === lineRange[1] ? $_({
            lineNumber: lineRange[0]
        }, "line { lineNumber }") : $_({
            lineRange: lineRange[0] + EN_DASH + lineRange[1]
        }, "lines { lineRange }") : $_({
            lineRange: "?" + EN_DASH + "?"
        }, "lines { lineRange }");
        var summaryOutput;
        if (this.props.summaryText) {
            var summaryTree = PerseusMarkdown.parseInline(this.props.summaryText);
            summaryOutput = React.createElement("span", {
                "aria-hidden": true
            }, " ", "(“", PerseusMarkdown.basicOutput(summaryTree), "”)");
        } else summaryOutput = null;
        return React.createElement("span", null, lineRangeOutput, summaryOutput, lineRange && React.createElement("div", {
            className: "perseus-sr-only"
        }, this.state.content));
    },
    change: function change() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return Changeable.change.apply(this, args);
    },
    componentDidMount: function componentDidMount() {
        this._deferredUpdateRange();
        this._throttledUpdateRange = _.throttle(this._deferredUpdateRange, 500);
        window.addEventListener("resize", this._throttledUpdateRange);
    },
    componentDidUpdate: function componentDidUpdate() {
        this._deferredUpdateRange();
    },
    componentWillUnmount: function componentWillUnmount() {
        window.removeEventListener("resize", this._throttledUpdateRange);
    },
    _deferredUpdateRange: function _deferredUpdateRange() {
        _.defer(this._updateRange);
    },
    _updateRange: function _updateRange() {
        var passage = this.props.findWidgets("passage " + this.props.passageNumber)[0];
        var refInfo = null;
        passage && (refInfo = passage.getReference(this.props.referenceNumber));
        this.isMounted() && (refInfo ? this.setState({
            lineRange: [ refInfo.startLine, refInfo.endLine ],
            content: refInfo.content
        }) : this.setState({
            lineRange: null,
            content: null
        }));
    },
    simpleValidate: function simpleValidate(rubric) {
        return PassageRef.validate(this.getUserInput(), rubric);
    }
});

_.extend(PassageRef, {
    validate: function validate(state, rubric) {
        return {
            type: "points",
            earned: 0,
            total: 0,
            message: null
        };
    }
});

module.exports = {
    name: "passage-ref",
    displayName: "PassageRef (SAT only)",
    defaultAlignment: "inline",
    widget: PassageRef,
    transform: function transform(editorProps) {
        return _.pick(editorProps, "passageNumber", "referenceNumber", "summaryText");
    },
    version: {
        major: 0,
        minor: 1
    }
};