var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* global i18n */
var React = require("react");

var _ = require("underscore");

var Renderer = require("../../renderer.jsx");

var PassageRef = require("../passage-ref.jsx");

var Util = require("../../util.js");

var BaseRadio = require("./base-radio.jsx");

var _require = require("../../gorgon/proptypes.js"), linterContextProps = _require.linterContextProps, linterContextDefault = _require.linterContextDefault;

var Radio = React.createClass({
    displayName: "Radio",
    propTypes: {
        apiOptions: BaseRadio.propTypes.apiOptions,
        choices: React.PropTypes.arrayOf(React.PropTypes.shape({
            content: React.PropTypes.string.isRequired,
            // Clues are called "rationales" in most other places but are
            // left as "clue"s here to preserve legacy widget data.
            clue: React.PropTypes.string,
            correct: React.PropTypes.bool,
            isNoneOfTheAbove: React.PropTypes.bool,
            originalIndex: React.PropTypes.number.isRequired
        }).isRequired).isRequired,
        deselectEnabled: React.PropTypes.bool,
        displayCount: React.PropTypes.any,
        findWidgets: React.PropTypes.func,
        multipleSelect: React.PropTypes.bool,
        countChoices: React.PropTypes.bool,
        numCorrect: React.PropTypes.number,
        onChange: React.PropTypes.func.isRequired,
        questionCompleted: React.PropTypes.bool,
        reviewModeRubric: BaseRadio.propTypes.reviewModeRubric,
        trackInteraction: React.PropTypes.func.isRequired,
        // values is the legacy choiceState data format
        values: React.PropTypes.arrayOf(React.PropTypes.bool),
        choiceStates: React.PropTypes.arrayOf(React.PropTypes.shape({
            // Indicates whether this choice is selected. (Inside
            // BaseRadio, this is called `checked`.)
            selected: React.PropTypes.bool,
            // Indicates whether the user has "crossed out" this choice,
            // meaning that they don't think it's correct. This value does
            // not affect scoring or other behavior; it's just a note for
            // the user's reference.
            crossedOut: React.PropTypes.bool,
            highlighted: React.PropTypes.bool,
            rationaleShown: React.PropTypes.bool,
            correctnessShown: React.PropTypes.bool,
            readOnly: React.PropTypes.bool
        }).isRequired),
        linterContext: linterContextProps,
        static: React.PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            choices: [ {} ],
            displayCount: null,
            multipleSelect: false,
            countChoices: false,
            deselectEnabled: false,
            linterContext: linterContextDefault
        };
    },
    _renderRenderer: function _renderRenderer(content) {
        content = content || "";
        var nextPassageRefId = 1;
        var widgets = {};
        var modContent = content.replace(/\{\{passage-ref (\d+) (\d+)(?: "([^"]*)")?\}\}/g, function(match, passageNum, refNum, summaryText) {
            var widgetId = "passage-ref " + nextPassageRefId;
            nextPassageRefId++;
            widgets[widgetId] = {
                type: "passage-ref",
                graded: false,
                options: {
                    passageNumber: parseInt(passageNum),
                    referenceNumber: parseInt(refNum),
                    summaryText: summaryText
                },
                version: PassageRef.version
            };
            return "[[" + Util.snowman + " " + widgetId + "]]";
        });
        // alwaysUpdate={true} so that passage-refs findWidgets
        // get called when the outer passage updates the renderer
        // TODO(aria): This is really hacky
        // We pass in a key here so that we avoid a semi-spurious
        // react warning when the ChoiceNoneAbove renders a
        // different renderer in the same place. Note this destroys
        // state, but since all we're doing is outputting
        // "None of the above", that is okay.
        // TODO(mdr): Widgets inside this Renderer are not discoverable through
        //     the parent Renderer's `findWidgets` function.
        return React.createElement(Renderer, {
            key: "choiceContentRenderer",
            content: modContent,
            widgets: widgets,
            findExternalWidgets: this.props.findWidgets,
            alwaysUpdate: true,
            linterContext: this.props.linterContext
        });
    },
    focus: function focus(i) {
        return this.refs.baseRadio.focus(i);
    },
    // When `BaseRadio`'s `onChange` handler is called, indicating a change in
    // our choices' state, we need to call our `onChange` handler in order to
    // persist those changes in the item's Perseus state.
    //
    // So, given the new values for each choice, construct the new
    // `choiceStates` objects, and pass them to `this.props.onChange`.
    //
    // `newValueLists` is an object with two keys: `checked` and `crossedOut`.
    // Each contains an array of boolean values, specifying the new checked and
    // crossed-out value of each choice.
    //
    // NOTE(mdr): This method expects to be auto-bound. If this component is
    //     converted to an ES6 class, take care to auto-bind this method!
    updateChoices: function updateChoices(newValueLists) {
        var _props = this.props, choiceStates = _props.choiceStates, choices = _props.choices;
        // Construct the baseline `choiceStates` objects. If this is the user's
        // first interaction with the widget, we'll need to initialize them to
        // new objects with all fields set to the default values. Otherwise, we
        // should clone the old `choiceStates` objects, in preparation to
        // mutate them.
        var newChoiceStates = void 0;
        newChoiceStates = choiceStates ? choiceStates.map(function(state) {
            return _extends({}, state);
        }) : choices.map(function() {
            return {
                selected: false,
                crossedOut: false,
                highlighted: false,
                rationaleShown: false,
                correctnessShown: false,
                readOnly: false
            };
        });
        // Mutate the new `choiceState` objects, according to the new `checked`
        // and `crossedOut` values provided in `newValueLists`.
        newChoiceStates.forEach(function(choiceState, i) {
            choiceState.selected = newValueLists.checked[i];
            choiceState.crossedOut = newValueLists.crossedOut[i];
        });
        this.props.onChange({
            choiceStates: newChoiceStates
        });
        this.props.trackInteraction();
    },
    getUserInput: function getUserInput() {
        // Return checked inputs in the form {choicesSelected: [bool]}. (Dear
        // future timeline implementers: this used to be {value: i} before
        // multiple select was added)
        if (this.props.choiceStates) {
            var noneOfTheAboveIndex = null;
            var noneOfTheAboveSelected = false;
            var choiceStates = this.props.choiceStates;
            var choicesSelected = choiceStates.map(function() {
                return false;
            });
            var countChoices = this.props.countChoices;
            var numCorrect = this.props.numCorrect;
            for (var i = 0; i < choicesSelected.length; i++) {
                var index = this.props.choices[i].originalIndex;
                choicesSelected[index] = choiceStates[i].selected;
                if (this.props.choices[i].isNoneOfTheAbove) {
                    noneOfTheAboveIndex = index;
                    choicesSelected[i] && (noneOfTheAboveSelected = true);
                }
            }
            return {
                countChoices: countChoices,
                choicesSelected: choicesSelected,
                numCorrect: numCorrect,
                noneOfTheAboveIndex: noneOfTheAboveIndex,
                noneOfTheAboveSelected: noneOfTheAboveSelected
            };
        }
        if (this.props.values) {
            var _noneOfTheAboveIndex = null;
            var _noneOfTheAboveSelected = false;
            var values = this.props.values.slice();
            var _countChoices = this.props.countChoices;
            var _numCorrect = this.props.numCorrect;
            for (var _i = 0; _i < this.props.values.length; _i++) {
                var _index = this.props.choices[_i].originalIndex;
                values[_index] = this.props.values[_i];
                if (this.props.choices[_i].isNoneOfTheAbove) {
                    _noneOfTheAboveIndex = _index;
                    values[_i] && (_noneOfTheAboveSelected = true);
                }
            }
            return {
                choicesSelected: values,
                noneOfTheAboveIndex: _noneOfTheAboveIndex,
                noneOfTheAboveSelected: _noneOfTheAboveSelected,
                countChoices: _countChoices,
                numCorrect: _numCorrect
            };
        }
        // Nothing checked
        return {
            choicesSelected: _.map(this.props.choices, function() {
                return false;
            })
        };
    },
    simpleValidate: function simpleValidate(rubric) {
        return Radio.validate(this.getUserInput(), rubric);
    },
    enforceOrdering: function enforceOrdering(choices) {
        var content = _.pluck(choices, "content");
        if (_.isEqual(content, [ i18n._("False"), i18n._("True") ]) || _.isEqual(content, [ i18n._("No"), i18n._("Yes") ])) return [ choices[1] ].concat([ choices[0] ]);
        return choices;
    },
    /**
     * Turn on rationale display for the currently selected choices. Note that
     * this leaves rationales on for choices that are already showing
     * rationales.
     */
    showRationalesForCurrentlySelectedChoices: function showRationalesForCurrentlySelectedChoices(rubric) {
        if (this.props.choiceStates) {
            var score = this.simpleValidate(rubric);
            var widgetCorrect = "points" === score.type && score.total === score.earned;
            var newStates = this.props.choiceStates.map(function(state) {
                return _extends({}, state, {
                    highlighted: state.selected,
                    // If the choice is selected, show the rationale now
                    rationaleShown: state.selected || // If the choice already had a rationale, keep it shown
                    state.rationaleShown || // If the widget is correctly answered, show the rationale
                    // for all the choices
                    widgetCorrect,
                    // We use the same behavior for the readOnly flag as for
                    // rationaleShown, but we keep it separate in case other
                    // behaviors want to disable choices without showing rationales.
                    readOnly: state.selected || state.readOnly || widgetCorrect,
                    correctnessShown: state.selected || state.correctnessShown
                });
            });
            this.props.onChange({
                choiceStates: newStates
            }, null, // cb
            true);
        }
    },
    /**
     * Deselects any currently-selected choices that are not correct choices.
     */
    deselectIncorrectSelectedChoices: function deselectIncorrectSelectedChoices() {
        var _this = this;
        if (this.props.choiceStates) {
            var newStates = this.props.choiceStates.map(function(state, i) {
                return _extends({}, state, {
                    selected: state.selected && !!_this.props.choices[i].correct,
                    highlighted: false
                });
            });
            this.props.onChange({
                choiceStates: newStates
            }, null, // cb
            false);
        }
    },
    render: function render() {
        var _this2 = this;
        var choices = this.props.choices;
        var choiceStates = void 0;
        choiceStates = this.props.static ? _.map(choices, function(val) {
            return {
                selected: val.correct,
                crossedOut: val.crossedOut,
                readOnly: true,
                highlighted: false,
                rationaleShown: true,
                correctnessShown: true
            };
        }) : this.props.choiceStates ? this.props.choiceStates : this.props.values ? _.map(this.props.values, function(val) {
            return {
                selected: val,
                crossedOut: false,
                readOnly: false,
                highlighted: false,
                rationaleShown: false,
                correctnessShown: false
            };
        }) : _.map(choices, function() {
            return {
                selected: false,
                crossedOut: false,
                readOnly: false,
                highlighted: false,
                rationaleShown: false,
                correctnessShown: false
            };
        });
        choices = _.map(choices, function(choice, i) {
            var content = choice.isNoneOfTheAbove && !choice.content ? // we use i18n._ instead of $_ here because the content
            // sent to a renderer needs to be a string, not a react
            // node (/renderable/fragment).
            i18n._("None of the above") : choice.content;
            var _choiceStates$i = choiceStates[i], selected = _choiceStates$i.selected, crossedOut = _choiceStates$i.crossedOut, rationaleShown = _choiceStates$i.rationaleShown, correctnessShown = _choiceStates$i.correctnessShown, readOnly = _choiceStates$i.readOnly, highlighted = _choiceStates$i.highlighted;
            var reviewChoice = _this2.props.reviewModeRubric && _this2.props.reviewModeRubric.choices[i];
            return {
                content: _this2._renderRenderer(content),
                checked: selected,
                // Current versions of the radio widget always pass in the
                // "correct" value through the choices. Old serialized state
                // for radio widgets doesn't have this though, so we have to
                // pull the correctness out of the review mode rubric. This
                // only works because all of the places we use
                // `restoreSerializedState()` also turn on reviewMode, but is
                // fine for now.
                // TODO(emily): Come up with a more comprehensive way to solve
                // this sort of "serialized state breaks when internal
                // structure changes" problem.
                correct: "undefined" === typeof choice.correct ? !!reviewChoice && reviewChoice.correct : choice.correct,
                disabled: readOnly,
                hasRationale: !!choice.clue,
                rationale: _this2._renderRenderer(choice.clue),
                showRationale: rationaleShown,
                showCorrectness: correctnessShown,
                isNoneOfTheAbove: choice.isNoneOfTheAbove,
                revealNoneOfTheAbove: _this2.props.questionCompleted && selected,
                crossedOut: crossedOut,
                highlighted: highlighted
            };
        });
        choices = this.enforceOrdering(choices);
        return React.createElement(BaseRadio, {
            ref: "baseRadio",
            labelWrap: true,
            multipleSelect: this.props.multipleSelect,
            countChoices: this.props.countChoices,
            numCorrect: this.props.numCorrect,
            choices: choices,
            onChange: this.updateChoices,
            reviewModeRubric: this.props.reviewModeRubric,
            deselectEnabled: this.props.deselectEnabled,
            apiOptions: this.props.apiOptions
        });
    }
});

_.extend(Radio, {
    validate: function validate(state, rubric) {
        var numSelected = _.reduce(state.choicesSelected, function(sum, selected) {
            return sum + (selected ? 1 : 0);
        }, 0);
        if (0 === numSelected) return {
            type: "invalid",
            message: null
        };
        if (state.countChoices && numSelected !== state.numCorrect) return {
            type: "invalid",
            message: i18n._("Please choose the correct number of answers.")
        };
        if (state.noneOfTheAboveSelected && numSelected > 1) return {
            type: "invalid",
            message: i18n._("'None of the above' may not be selected when other answers are selected.")
        };
        /* jshint +W018 */
        return {
            type: "points",
            earned: _.all(state.choicesSelected, function(selected, i) {
                var isCorrect = void 0;
                isCorrect = state.noneOfTheAboveIndex === i ? _.all(rubric.choices, function(choice, j) {
                    return i === j || !choice.correct;
                }) : !!rubric.choices[i].correct;
                return isCorrect === selected;
            }) ? 1 : 0,
            total: 1,
            message: null
        };
    }
});

module.exports = Radio;