function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * Renders a circular selection ring around the child.
 */
var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var styleConstants = require("../../styles/constants.js");

var FocusRing = function(_React$Component) {
    _inherits(FocusRing, _React$Component);
    function FocusRing() {
        _classCallCheck(this, FocusRing);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    FocusRing.prototype.render = function render() {
        var borderColor = this.props.visible ? this.props.color : "transparent";
        var style = {
            borderColor: borderColor
        };
        return React.createElement("span", {
            className: css(styles.ring),
            style: style
        }, this.props.children);
    };
    return FocusRing;
}(React.Component);

FocusRing.defaultProps = {
    visible: true,
    color: styleConstants.kaGreen
};

FocusRing.propTypes = {
    children: require("react").PropTypes.any,
    visible: require("react").PropTypes.bool.isRequired,
    color: require("react").PropTypes.string.isRequired
};

var styles = StyleSheet.create({
    ring: {
        margin: "auto",
        display: "inline-block",
        borderRadius: "50%",
        borderWidth: 2,
        padding: 2,
        borderStyle: "solid"
    }
});

module.exports = FocusRing;