var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "unescaped-dollar",
    severity: _rule2.default.Severity.ERROR,
    selector: "unescapedDollar",
    message: "Unescaped dollar sign:\nDollar signs must appear in pairs or be escaped as \\$"
});