var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable no-var, object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
/* globals KA */
var _ = require("underscore");

var SimpleMarkdown = require("simple-markdown");

var TeX = require("react-components/tex.jsx");

var Util = require("./util.js");

var Lint = require("./components/lint.jsx");

/**
 * This match function matches math in `$`s, such as:
 *
 * $y = x + 1$
 *
 * It functions roughly like the following regex:
 * /\$([^\$]*)\$/
 *
 * Unfortunately, math may have other `$`s inside it, as
 * long as they are inside `{` braces `}`, mostly for
 * `\text{ $math$ }`.
 *
 * To parse this, we can't use a regex, since we
 * should support arbitrary nesting (even though
 * MathJax actually only supports two levels of nesting
 * here, which we *could* parse with a regex).
 *
 * Non-regex matchers like this are now a first-class
 * concept in simple-markdown. Yay!
 *
 * This can also match block-math, which is math alone in a paragraph.
 */
var mathMatcher = function mathMatcher(source, state, isBlock) {
    var length = source.length;
    var index = 0;
    // When looking for blocks, skip over leading spaces
    if (isBlock) {
        if (state.inline) return null;
        while (index < length && " " === source[index]) index++;
    }
    // Our source must start with a "$"
    if (!(index < length && "$" === source[index])) return null;
    index++;
    var startIndex = index;
    var braceLevel = 0;
    // Loop through the source, looking for a closing '$'
    // closing '$'s only count if they are not escaped with
    // a `\`, and we are not in nested `{}` braces.
    while (index < length) {
        var character = source[index];
        if ("\\" === character) // Consume both the `\` and the escaped char as a single
        // token.
        // This is so that the second `$` in `$\\$` closes
        // the math expression, since the first `\` is escaping
        // the second `\`, but the second `\` is not escaping
        // the second `$`.
        // This also handles the case of escaping `$`s or
        // braces `\{`
        index++; else {
            if (braceLevel <= 0 && "$" === character) {
                var endIndex = index + 1;
                if (isBlock) {
                    // Look for two trailing newlines after the closing `$`
                    var match = /^(?: *\n){2,}/.exec(source.slice(endIndex));
                    endIndex = match ? endIndex + match[0].length : null;
                }
                // Return an array that looks like the results of a
                // regex's .exec function:
                // capture[0] is the whole string
                // capture[1] is the first "paren" match, which is the
                //   content of the math here, as if we wrote the regex
                //   /\$([^\$]*)\$/
                if (endIndex) return [ source.substring(0, endIndex), source.substring(startIndex, index) ];
                return null;
            }
            if ("{" === character) braceLevel++; else if ("}" === character) braceLevel--; else if ("\n" === character && "\n" === source[index - 1]) // This is a weird case we supported in the old
            // math implementation--double newlines break
            // math. I'm preserving it for now because content
            // creators might have questions with single '$'s
            // in paragraphs...
            return null;
        }
        index++;
    }
    // we didn't find a closing `$`
    return null;
};

var mathMatch = function mathMatch(source, state) {
    return mathMatcher(source, state, false);
};

var blockMathMatch = function blockMathMatch(source, state) {
    return mathMatcher(source, state, true);
};

var TITLED_TABLE_REGEX = new RegExp("^\\|\\| +(.*) +\\|\\| *\\n(" + // The simple-markdown nptable regex, without
// the leading `^`
SimpleMarkdown.defaultRules.nptable.match.regex.source.substring(1) + ")");

var crowdinJiptMatcher = SimpleMarkdown.blockRegex(/^(crwdns.*)\n\s*\n/);

var rules = _.extend({}, SimpleMarkdown.defaultRules, {
    // NOTE: basically ignored by JIPT. wraps everything at the outer layer
    columns: {
        order: -2,
        match: SimpleMarkdown.blockRegex(/^([\s\S]*\n\n)={5,}\n\n([\s\S]*)/),
        parse: function parse(capture, _parse, state) {
            return {
                col1: _parse(capture[1], state),
                col2: _parse(capture[2], state)
            };
        },
        react: function react(node, output, state) {
            return React.createElement("div", {
                className: "perseus-two-columns",
                key: state.key
            }, React.createElement("div", {
                className: "perseus-column"
            }, React.createElement("div", {
                className: "perseus-column-content"
            }, output(node.col1, state))), React.createElement("div", {
                className: "perseus-column"
            }, React.createElement("div", {
                className: "sat-header-grafting-area"
            }), React.createElement("div", {
                className: "perseus-column-content"
            }, React.createElement("div", {
                className: "sat-skill-subscore-grafting-area"
            }), output(node.col2, state), React.createElement("div", {
                className: "sat-grafting-area"
            }))));
        }
    },
    // Match paragraphs consisting solely of crowdin IDs
    // (they look roughly like crwdns9238932:0), which means that
    // crowdin is going to take the DOM node that ID is rendered into
    // and count it as the top-level translation node. They mutate this
    // node, so we need to make sure it is an outer node, not an inner
    // span. So here we parse this separately and just output the
    // raw string, which becomes the body of the <QuestionParagraph>
    // created by the Renderer.
    // This currently (2015-09-01) affects only articles, since
    // for exercises the renderer just renders the crowdin id to the
    // renderer div.
    crowdinId: {
        order: -1,
        match: function match(source, state, prevCapture) {
            // Only match on the just-in-place translation site
            // Only match on the just-in-place translation site
            return state.isJipt ? crowdinJiptMatcher(source, state, prevCapture) : null;
        },
        parse: function parse(capture, _parse2, state) {
            return {
                id: capture[1]
            };
        },
        react: function react(node, output, state) {
            return node.id;
        }
    },
    // This is pretty much horrible, but we have a regex here to capture an
    // entire table + a title. capture[1] is the title. capture[2] of the
    // regex is a copy of the simple-markdown nptable regex. Then we turn
    // our capture[2] into tableCapture[0], and any further captures in
    // our table regex into tableCapture[1..], and we pass tableCapture to
    // our nptable regex
    titledTable: {
        // process immediately before nptables
        order: SimpleMarkdown.defaultRules.nptable.order - .5,
        match: SimpleMarkdown.blockRegex(TITLED_TABLE_REGEX),
        parse: function parse(capture, _parse3, state) {
            var title = SimpleMarkdown.parseInline(_parse3, capture[1], state);
            // Remove our [0] and [1] captures, and pass the rest to
            // the nptable parser
            var tableCapture = _.rest(capture, 2);
            return {
                title: title,
                table: SimpleMarkdown.defaultRules.nptable.parse(tableCapture, _parse3, state)
            };
        },
        react: function react(node, output, state) {
            var contents = void 0;
            if (node.table) {
                var tableOutput = SimpleMarkdown.defaultRules.table.react(node.table, output, state);
                var caption = React.createElement("caption", {
                    key: "caption",
                    className: "perseus-table-title"
                }, output(node.title, state));
                // Splice the caption into the table's children with the
                // caption as the first child.
                contents = React.cloneElement(tableOutput, null, [ caption ].concat(tableOutput.props.children));
            } else contents = "//invalid table//";
            // Note: if the DOM structure changes, edit the Zoomable wrapper
            // in src/renderer.jsx.
            return React.createElement("div", {
                className: "perseus-titled-table",
                key: state.key
            }, contents);
        }
    },
    widget: {
        order: SimpleMarkdown.defaultRules.link.order - .75,
        match: SimpleMarkdown.inlineRegex(Util.rWidgetRule),
        parse: function parse(capture, _parse4, state) {
            return {
                id: capture[1],
                widgetType: capture[2]
            };
        },
        react: function react(node, output, state) {
            // The actual output is handled in the renderer, where
            // we know the current widget props/state. This is
            // just a stub for testing.
            return React.createElement("em", {
                key: state.key
            }, "[Widget: ", node.id, "]");
        }
    },
    blockMath: {
        order: SimpleMarkdown.defaultRules.codeBlock.order + .5,
        match: blockMathMatch,
        parse: function parse(capture, _parse5, state) {
            return {
                content: capture[1]
            };
        },
        react: function react(node, output, state) {
            // The actual output is handled in the renderer, because
            // it needs to pass in an `onRender` callback prop. This
            // is just a stub for testing.
            return React.createElement(TeX, {
                key: state.key
            }, node.content);
        }
    },
    math: {
        order: SimpleMarkdown.defaultRules.link.order - .25,
        match: mathMatch,
        parse: function parse(capture, _parse6, state) {
            return {
                content: capture[1]
            };
        },
        react: function react(node, output, state) {
            // The actual output is handled in the renderer, because
            // it needs to pass in an `onRender` callback prop. This
            // is just a stub for testing.
            return React.createElement(TeX, {
                key: state.key
            }, node.content);
        }
    },
    unescapedDollar: {
        order: SimpleMarkdown.defaultRules.link.order - .24,
        match: SimpleMarkdown.inlineRegex(/^(?!\\)\$/),
        parse: function parse(capture, _parse7, state) {
            return {};
        },
        react: function react(node, output, state) {
            // Unescaped dollar signs render correctly, but result in
            // untranslatable text after the i18n python linter flags it
            return "$";
        }
    },
    fence: _.extend({}, SimpleMarkdown.defaultRules.fence, {
        parse: function parse(capture, _parse8, state) {
            var node = SimpleMarkdown.defaultRules.fence.parse(capture, _parse8, state);
            // support screenreader-only text with ```alt
            // support screenreader-only text with ```alt
            return "alt" === node.lang ? {
                type: "codeBlock",
                lang: "alt",
                // default codeBlock parsing doesn't parse the contents.
                // We need to parse the contents for things like table
                // support :).
                // The \n\n is because the inside of the codeblock might
                // not end in double newlines for block rules, because
                // ordinarily we don't parse this :).
                content: _parse8(node.content + "\n\n", state)
            } : node;
        }
    }),
    // Extend the SimpleMarkdown link parser to make the link
    // zero-rating-friendly if necessary. No changes will be made for
    // non-zero-rated requests, but zero-rated requests will be re-pointed at
    // either the zero-rated version of khanacademy.org or the external link
    // warning interstitial. We also replace the default <a /> tag with a custom
    // element, if necessary.
    link: _.extend({}, SimpleMarkdown.defaultRules.link, {
        react: function react(node, output, state) {
            var link = SimpleMarkdown.defaultRules.link.react(node, output, state);
            var href = link.props.href;
            // TODO(charlie): Move this logic out of Perseus and into webapp via
            // the <Link /> component that is now injected as a dependency.
            "undefined" !== typeof KA && KA.isZeroRated && (href = href.match(/https?:\/\/[^\/]*khanacademy.org/) ? href.replace("khanacademy.org", "zero.khanacademy.org") : "/zero/external-link?url=" + encodeURIComponent(href));
            var newProps = _extends({}, link.props, {
                href: href
            });
            return state.baseElements && state.baseElements.Link ? state.baseElements.Link(newProps) : React.cloneElement(link, newProps);
        }
    }),
    codeBlock: _.extend({}, SimpleMarkdown.defaultRules.codeBlock, {
        react: function react(node, output, state) {
            // ideally this should be a different rule, with only an
            // output function, but right now that breaks the parser.
            // ideally this should be a different rule, with only an
            // output function, but right now that breaks the parser.
            return "alt" === node.lang ? React.createElement("div", {
                key: state.key,
                className: "perseus-markdown-alt perseus-sr-only"
            }, output(node.content, state)) : SimpleMarkdown.defaultRules.codeBlock.react(node, output, state);
        }
    }),
    list: _.extend({}, SimpleMarkdown.defaultRules.list, {
        match: function match(source, state, prevCapture) {
            // Since lists can contain double newlines and we have special
            // handling of double newlines while parsing jipt content, just
            // disable the list parser.
            // Since lists can contain double newlines and we have special
            // handling of double newlines while parsing jipt content, just
            // disable the list parser.
            return state.isJipt ? null : SimpleMarkdown.defaultRules.list.match(source, state, prevCapture);
        }
    }),
    // The lint rule never actually matches anything.
    // We check for lint after parsing, and, if we find any, we
    // transform the tree to add lint nodes. This rule is here
    // just for the react() function
    lint: {
        order: 1e3,
        match: function match(s) {
            return null;
        },
        parse: function parse(capture, _parse9, state) {
            return {};
        },
        react: function react(node, output, state) {
            return React.createElement(Lint, {
                message: node.message,
                ruleName: node.ruleName,
                inline: isInline(node.content),
                insideTable: node.insideTable,
                severity: node.severity
            }, output(node.content, state));
        }
    }
});

// Return true if the specified parse tree node represents inline content
// and false otherwise. We need this so that lint nodes can figure out whether
// they should behave as an inline wrapper or a block wrapper
function isInline(node) {
    return !!(node && node.type && inlineNodeTypes.hasOwnProperty(node.type));
}

var inlineNodeTypes = {
    text: true,
    math: true,
    unescapedDollar: true,
    link: true,
    img: true,
    strong: true,
    u: true,
    em: true,
    del: true,
    code: true
};

var builtParser = SimpleMarkdown.parserFor(rules);

var parse = function parse(source, state) {
    return builtParser(source + "\n\n", _.extend({
        inline: false
    }, state));
};

var inlineParser = function inlineParser(source, state) {
    return builtParser(source, _.extend({
        inline: true
    }, state));
};

/**
 * Traverse all of the nodes in the Perseus Markdown AST. The callback is
 * called for each node in the AST.
 */
var traverseContent = function traverseContent(ast, cb) {
    if (_.isArray(ast)) _.each(ast, function(node) {
        return traverseContent(node, cb);
    }); else if (_.isObject(ast)) {
        cb(ast);
        if ("table" === ast.type) {
            traverseContent(ast.header, cb);
            traverseContent(ast.cells, cb);
        } else if ("list" === ast.type) traverseContent(ast.items, cb); else if ("titledTable" === ast.type) traverseContent(ast.table, cb); else if ("columns" === ast.type) {
            traverseContent(ast.col1, cb);
            traverseContent(ast.col2, cb);
        } else _.isArray(ast.content) && traverseContent(ast.content, cb);
    }
};

/**
 * Pull out text content from a Perseus Markdown AST.
 * Returns an array of strings.
 */
var getContent = function getContent(ast) {
    // Simplify logic by dealing with a single AST node at a time
    if (_.isArray(ast)) return _.flatten(_.map(ast, getContent));
    // Base case: This is where we actually extract text content
    if (ast.content && _.isString(ast.content)) // Collapse whitespace within content unless it is code
    // Collapse whitespace within content unless it is code
    return -1 !== ast.type.toLowerCase().indexOf("code") ? [ "", ast.content, "" ] : [ ast.content.replace(/\s+/g, " ") ];
    // Recurse through child AST nodes
    // Assumptions made:
    // 1) Child AST nodes are either direct properties or inside
    //    arbitrarily nested lists that are direct properties.
    // 2) Only AST nodes have a 'type' property.
    var children = _.chain(ast).values().flatten().filter(function(object) {
        return null != object && _.has(object, "type");
    }).value();
    if (children.length) {
        var nestedContent = getContent(children);
        if ("paragraph" === ast.type && nestedContent.length) {
            // Trim whitespace before or after a paragraph
            nestedContent[0] = nestedContent[0].replace(/^\s+/, "");
            var last = nestedContent.length - 1;
            nestedContent[last] = nestedContent[last].replace(/\s+$/, "");
        }
        return nestedContent;
    }
    return [];
};

/**
 * Count the number of characters in Perseus Markdown source.
 * Markdown markup and widget references are ignored.
 */
var characterCount = function characterCount(source) {
    var ast = parse(source);
    return getContent(ast).join("").length;
};

module.exports = {
    characterCount: characterCount,
    traverseContent: traverseContent,
    parse: parse,
    parseInline: inlineParser,
    reactFor: SimpleMarkdown.reactFor,
    ruleOutput: SimpleMarkdown.ruleOutput(rules, "react"),
    basicOutput: SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, "react")),
    sanitizeUrl: SimpleMarkdown.sanitizeUrl
};