/* eslint-disable object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var _require = require("../styles/constants.js"), interactiveSizes = _require.interactiveSizes;

// Note: these size cutoffs represent content-width cutoffs as
// specified in http://zpl.io/1mVmvU
// TODO(benkomalo): these values aren't used in JS outside of this file, but
// are coupled to the values in
// stylesheets/exercise-content-package/articles.less - DRY it up at some point
var React = require("react");

var smMax = 512;

var mdMax = 688;

var containerSizeClass = {
    SMALL: "small",
    MEDIUM: "medium",
    LARGE: "large",
    XLARGE: "xlarge"
};

module.exports = {
    containerSizeClass: containerSizeClass,
    containerSizeClassPropType: React.PropTypes.oneOf(Object.values(containerSizeClass)),
    getClassFromWidth: function getClassFromWidth(width) {
        if (!width) return containerSizeClass.MEDIUM;
        return width <= smMax ? containerSizeClass.SMALL : width <= mdMax ? containerSizeClass.MEDIUM : containerSizeClass.LARGE;
    },
    getInteractiveBoxFromSizeClass: function getInteractiveBoxFromSizeClass(sizeClass) {
        return sizeClass === containerSizeClass.SMALL ? [ interactiveSizes.defaultBoxSizeSmall, interactiveSizes.defaultBoxSizeSmall ] : [ interactiveSizes.defaultBoxSize, interactiveSizes.defaultBoxSize ];
    }
};