var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/* Component for rendering a letter icon in radio choice */
/* global i18n */
var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var styleConstants = require("../../styles/constants.js");

var _require2 = require("../../icon-paths.js"), iconCheck = _require2.iconCheck, iconMinus = _require2.iconMinus;

var InlineIcon = require("../../components/inline-icon.jsx");

var FocusRing = require("./focus-ring.jsx");

var SATChoiceIcon = function(_React$Component) {
    _inherits(SATChoiceIcon, _React$Component);
    function SATChoiceIcon() {
        _classCallCheck(this, SATChoiceIcon);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    // TODO(amy): figure out a better scheme for specifying these
    // styles that isn't such a pain to grok. See some neat ideas
    // from MDR in https://phabricator.khanacademy.org/D35249.
    SATChoiceIcon.prototype.constructStyles = function constructStyles(reviewMode, correct, checked) {
        var backgroundColor = void 0;
        var borderColor = styleConstants.satBlue;
        var color = styleConstants.satBlue;
        if (reviewMode) {
            if (correct) {
                borderColor = styleConstants.satCorrectColor;
                color = checked ? styleConstants.white : styleConstants.satCorrectColor;
                backgroundColor = checked ? styleConstants.satCorrectColor : styleConstants.white;
            } else if (checked) {
                borderColor = styleConstants.satIncorrectColor;
                color = styleConstants.white;
                backgroundColor = styleConstants.satIncorrectColor;
            }
        } else if (checked) {
            color = styleConstants.white;
            backgroundColor = styleConstants.satBlue;
        }
        return {
            color: color,
            backgroundColor: backgroundColor,
            borderColor: borderColor
        };
    };
    SATChoiceIcon.prototype.render = function render() {
        var _props = this.props, letter = _props.letter, a11yText = _props.a11yText, reviewMode = _props.reviewMode, checked = _props.checked, correct = _props.correct;
        var _constructStyles = this.constructStyles(reviewMode, correct, checked), color = _constructStyles.color, backgroundColor = _constructStyles.backgroundColor, borderColor = _constructStyles.borderColor;
        return React.createElement("div", null, React.createElement("div", {
            className: css(styles.satCircle),
            style: {
                backgroundColor: backgroundColor,
                borderColor: borderColor
            }
        }), React.createElement("div", {
            style: {
                color: color
            },
            className: css(styles.letter)
        }, React.createElement("span", {
            className: "perseus-sr-only"
        }, a11yText), React.createElement("span", {
            "aria-hidden": "true"
        }, letter)));
    };
    return SATChoiceIcon;
}(React.Component);

SATChoiceIcon.propTypes = {
    letter: require("react").PropTypes.string.isRequired,
    a11yText: require("react").PropTypes.string.isRequired,
    checked: require("react").PropTypes.bool.isRequired,
    correct: require("react").PropTypes.bool.isRequired,
    reviewMode: require("react").PropTypes.bool.isRequired
};

var LibraryChoiceIcon = function(_React$Component2) {
    _inherits(LibraryChoiceIcon, _React$Component2);
    function LibraryChoiceIcon() {
        _classCallCheck(this, LibraryChoiceIcon);
        return _possibleConstructorReturn(this, _React$Component2.apply(this, arguments));
    }
    LibraryChoiceIcon.prototype.getChoiceInner = function getChoiceInner() {
        var _props2 = this.props, letter = _props2.letter, showCorrectness = _props2.showCorrectness, correct = _props2.correct;
        return showCorrectness ? correct ? React.createElement(InlineIcon, _extends({}, iconCheck, {
            style: {
                position: "relative",
                top: -1
            }
        })) : React.createElement(InlineIcon, iconMinus) : letter;
    };
    // Handle dynamic styling of the multiple choice icon. Most
    // MC icon styles are constant, but we do allow the caller
    // to specify the selected color, and thus must control styles
    // related to the selected state dynamically.
    LibraryChoiceIcon.prototype.getDynamicStyles = function getDynamicStyles() {
        var _props3 = this.props, checked = _props3.checked, showCorrectness = _props3.showCorrectness, pressed = _props3.pressed, primaryProductColor = _props3.primaryProductColor, correct = _props3.correct;
        if (!showCorrectness && pressed) return {
            borderColor: primaryProductColor,
            color: primaryProductColor,
            backgroundColor: styleConstants.white
        };
        if (checked) {
            // Note: kaGreen is not only the default product color,
            // but also the "correctness" color
            var bg = showCorrectness && correct ? styleConstants.kaGreen : primaryProductColor;
            return {
                color: styleConstants.white,
                backgroundColor: bg,
                borderColor: bg
            };
        }
        return {
            borderColor: styleConstants.gray68,
            color: styleConstants.gray68
        };
    };
    LibraryChoiceIcon.prototype.render = function render() {
        var _props4 = this.props, checked = _props4.checked, showCorrectness = _props4.showCorrectness, correct = _props4.correct, focused = _props4.focused, primaryProductColor = _props4.primaryProductColor;
        return React.createElement(FocusRing, {
            color: primaryProductColor,
            visible: focused
        }, React.createElement("div", {
            style: this.getDynamicStyles(),
            className: css(styles.libraryCircle, showCorrectness && correct && styles.libraryCircleCorrect, showCorrectness && !correct && styles.libraryCircleIncorrect, showCorrectness && !correct && checked && styles.libraryCircleIncorrectSelected),
            "data-is-radio-icon": true
        }, this.getChoiceInner()));
    };
    return LibraryChoiceIcon;
}(React.Component);

LibraryChoiceIcon.propTypes = {
    letter: require("react").PropTypes.string.isRequired,
    a11yText: require("react").PropTypes.string.isRequired,
    checked: require("react").PropTypes.bool.isRequired,
    pressed: require("react").PropTypes.bool.isRequired,
    focused: require("react").PropTypes.bool.isRequired,
    correct: require("react").PropTypes.bool,
    reviewMode: require("react").PropTypes.bool.isRequired,
    showCorrectness: require("react").PropTypes.bool.isRequired,
    primaryProductColor: require("react").PropTypes.string.isRequired
};

var ChoiceIcon = function(_React$Component3) {
    _inherits(ChoiceIcon, _React$Component3);
    function ChoiceIcon() {
        _classCallCheck(this, ChoiceIcon);
        return _possibleConstructorReturn(this, _React$Component3.apply(this, arguments));
    }
    ChoiceIcon.prototype.a11yText = function a11yText(letter) {
        // If the option was checked we need to reveal more context about
        // what the result was (correct/incorrect)
        if (this.props.checked) {
            if (this.props.showCorrectness) return this.props.correct ? i18n._("(Choice { letter }, Checked, Correct)", {
                letter: letter
            }) : i18n._("(Choice { letter }, Checked, Incorrect)", {
                letter: letter
            });
            return i18n._("(Choice { letter }, Checked)", {
                letter: letter
            });
        }
        if (this.props.showCorrectness && this.props.correct) return i18n._("(Choice { letter }, Correct Answer)", {
            letter: letter
        });
        return i18n._("(Choice { letter })", {
            letter: letter
        });
    };
    ChoiceIcon.prototype.getLetter = function getLetter() {
        /* I18N: This is a list of single-character labels that will appear in
         * front of multiple-choice options. For instance, a multiple-choice
         * question with three options would display
         *  (A) first option
         *  (B) second option
         *  (C) third option
         * There must be spaces between each of the different characters. The
         * characters will show up next to options in the order that they are
         * listed here. Most multiple choice questions have 5 or fewer options.
         */
        var lettersString = i18n._("A B C D E F G H I J K L M N O P Q R S T U V W X Y Z");
        var letters = lettersString.split(" ");
        var pos = this.props.pos;
        return pos < letters.length ? letters[pos] : " ";
    };
    ChoiceIcon.prototype.render = function render() {
        var _props5 = this.props, reviewMode = _props5.reviewMode, checked = _props5.checked, correct = _props5.correct, product = _props5.product, showCorrectness = _props5.showCorrectness, pressed = _props5.pressed, focused = _props5.focused, primaryProductColor = _props5.primaryProductColor;
        var letter = this.getLetter();
        return "sat" === product ? React.createElement(SATChoiceIcon, {
            letter: letter,
            a11yText: this.a11yText(letter),
            reviewMode: reviewMode,
            checked: checked,
            correct: correct
        }) : React.createElement(LibraryChoiceIcon, {
            letter: letter,
            a11yText: this.a11yText(letter),
            reviewMode: reviewMode,
            checked: checked,
            pressed: pressed,
            focused: focused,
            correct: correct,
            showCorrectness: showCorrectness,
            primaryProductColor: primaryProductColor
        });
    };
    return ChoiceIcon;
}(React.Component);

ChoiceIcon.defaultProps = {
    primaryProductColor: styleConstants.kaGreen
};

ChoiceIcon.propTypes = {
    pos: require("react").PropTypes.number.isRequired,
    checked: require("react").PropTypes.bool.isRequired,
    pressed: require("react").PropTypes.bool.isRequired,
    focused: require("react").PropTypes.bool.isRequired,
    correct: require("react").PropTypes.bool.isRequired,
    showCorrectness: require("react").PropTypes.bool.isRequired,
    product: require("react").PropTypes.oneOf([ "sat", "library" ]).isRequired,
    primaryProductColor: require("react").PropTypes.string.isRequired,
    reviewMode: require("react").PropTypes.bool.isRequired
};

var styles = StyleSheet.create({
    satCircle: {
        display: "block",
        borderRadius: 25,
        borderStyle: "solid",
        borderWidth: 2,
        content: "''",
        height: 25,
        width: 25,
        position: "absolute",
        top: 1,
        left: 1
    },
    libraryCircle: {
        // Make the circle
        width: 24,
        height: 24,
        boxSizing: "border-box",
        borderRadius: 24,
        borderStyle: "solid",
        borderWidth: 2,
        // The default icons have letters in them. Style those letters.
        fontFamily: styleConstants.baseFontFamily,
        // NOTE(emily): We explicitly set the font weight instead of using the
        // "bold font family" so that characters which fall back to the default
        // font get bolded too.
        fontWeight: "bold",
        fontSize: 12,
        // Center the contents of the icon.
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        // HACK(emily): I don't know why adding this line height makes the text
        // appear centered better than any other value, but it does. In
        // particular, at large zoom levels this line height does almost
        // nothing, but at the default size this shifts the letter down one
        // pixel so it is much better centered.
        lineHeight: "1px"
    },
    libraryCircleCorrect: {
        fontSize: 24
    },
    libraryCircleIncorrect: {
        fontSize: 24,
        borderColor: styleConstants.warning1,
        color: styleConstants.warning1
    },
    libraryCircleIncorrectSelected: {
        backgroundColor: styleConstants.warning1,
        color: styleConstants.white
    },
    letter: {
        // These properties make sure that this element has the exact
        // same size as `circle` so that we can center things
        // inside of it.
        border: "2px solid transparent",
        width: 25,
        height: 25,
        position: "absolute",
        left: 1,
        top: 1,
        // Center contained items.
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: styleConstants.boldFontFamily,
        fontSize: 13
    }
});

module.exports = ChoiceIcon;