var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* globals KA */
var classNames = require("classnames");

var React = require("react");

var $ = require("jquery");

var _ = require("underscore");

var FixedToResponsive = require("../components/fixed-to-responsive.jsx");

var Graphie = require("../components/graphie.jsx");

var ImageLoader = require("../components/image-loader.jsx");

var _require = require("../jipt-hack.jsx"), maybeUnescape = _require.maybeUnescape;

var Util = require("../util.js");

var Zoom = require("../zoom.js");

// Minimum image width to make an image appear as zoomable.
var ZOOMABLE_THRESHOLD = 700;

// The global cache of label data. Its format is:
// {
//   hash (e.g. "c21435944d2cf0c8f39d9059cb35836aa701d04a"): {
//     loaded: a boolean of whether the data has been loaded or not
//     dataCallbacks: a list of callbacks to call with the data when the data
//                    is loaded
//     data: the other data for this hash
//   },
//   ...
// }
var labelDataCache = {};

// Write our own JSONP handler because all the other ones don't do things we
// need.
var doJSONP = function doJSONP(url, options) {
    options = _extends({
        callbackName: "callback",
        success: $.noop,
        error: $.noop
    }, options);
    // Create the script
    var script = document.createElement("script");
    script.setAttribute("async", "");
    script.setAttribute("src", url);
    // A cleanup function to run when we're done.
    function cleanup() {
        document.head.removeChild(script);
        delete window[options.callbackName];
    }
    // Add the global callback.
    window[options.callbackName] = function() {
        cleanup();
        options.success.apply(null, arguments);
    };
    // Add the error handler.
    script.addEventListener("error", function() {
        cleanup();
        options.error.apply(null, arguments);
    });
    // Insert the script to start the download.
    document.head.appendChild(script);
};

var svgLabelsRegex = /^web\+graphie\:/;

var hashRegex = /\/([^\/]+)$/;

function isLabeledSVG(url) {
    return svgLabelsRegex.test(url);
}

function isImageProbablyPhotograph(imageUrl) {
    // TODO(david): Do an inventory to refine this heuristic. For example, what
    //     % of .png images are illustrations?
    return /\.(jpg|jpeg)$/i.test(imageUrl);
}

// For each svg+labels, there are two urls we need to download from. This gets
// the base url without the suffix, and `getSvgUrl` and `getDataUrl` apply
// appropriate suffixes to get the image and other data
function getBaseUrl(url) {
    // Force HTTPS connection unless we're on HTTP, so that IE works.
    var protocol = "http:" === window.location.protocol ? "http:" : "https:";
    return url.replace(svgLabelsRegex, protocol);
}

function getSvgUrl(url) {
    if (Khan.imageUrls) return Khan.imageUrls[url.replace(svgLabelsRegex, "") + ".svg"];
    return getBaseUrl(url) + ".svg";
}

function getDataUrl(url) {
    if (Khan.imageUrls) return Khan.imageUrls[url.replace(svgLabelsRegex, "") + "-data.json"];
    return getBaseUrl(url) + "-data.json";
}

function shouldUseLocalizedData() {
    // TODO(emily): Remove this depenency on `KA` and pass it down with
    // Perseus' initialization. (Also used in renderer.jsx)
    return "undefined" !== typeof KA && "en" !== KA.language;
}

function shouldRenderJipt() {
    return "undefined" !== typeof KA && "en-pt" === KA.language;
}

var jiptLabels = [];

if (shouldRenderJipt()) {
    KA.jipt_dom_insert_checks || (KA.jipt_dom_insert_checks = []);
    KA.jipt_dom_insert_checks.push(function(text, node, attribute) {
        var index = $(node).data("jipt-label-index");
        if (node && "undefined" !== typeof index) {
            var _jiptLabels$index = jiptLabels[index], label = _jiptLabels$index.label, useMath = _jiptLabels$index.useMath;
            label.text("");
            text = maybeUnescape(text);
            if (useMath) {
                var mathRegex = /^\$(.*)\$$/;
                var match = text.match(mathRegex);
                var mathText = match ? match[1] : "\\color{red}{\\text{Invalid Math}}";
                label.processMath(mathText, true);
            } else label.processText(text);
            return false;
        }
        return text;
    });
}

// Get the hash from the url, which is just the filename
function getUrlHash(url) {
    var match = url.match(hashRegex);
    return match && match[1];
}

function defaultPreloader() {
    return React.DOM.span({
        style: {
            background: "url(/static/images/spinner.gif) no-repeat",
            backgroundPosition: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            minWidth: "20px"
        }
    });
}

var SvgImage = React.createClass({
    displayName: "SvgImage",
    propTypes: {
        allowFullBleed: React.PropTypes.bool,
        alt: React.PropTypes.string,
        constrainHeight: React.PropTypes.bool,
        extraGraphie: React.PropTypes.shape({
            box: React.PropTypes.array.isRequired,
            range: React.PropTypes.array.isRequired,
            labels: React.PropTypes.array.isRequired
        }),
        height: React.PropTypes.number,
        // When the DOM updates to replace the preloader with the image, or
        // vice-versa, we trigger this callback.
        onUpdate: React.PropTypes.func,
        preloader: React.PropTypes.func,
        // By default, this component attempts to be responsive whenever
        // possible (specifically, when width and height are passed in).
        // You can expliclty force unresponsive behavior by *either*
        // not passing in width/height *or* setting this prop to false.
        // The difference is that forcing via this prop will result in
        // explicit width and height styles being set on the rendered
        // component.
        responsive: React.PropTypes.bool,
        scale: React.PropTypes.number,
        src: React.PropTypes.string.isRequired,
        title: React.PropTypes.string,
        trackInteraction: React.PropTypes.func,
        width: React.PropTypes.number,
        // Whether clicking this image will allow it to be fully zoomed in to
        // its original size on click, and allow the user to scroll in that
        // state. This also does some hacky viewport meta tag changing to
        // ensure this works on mobile devices, so I (david@) don't recommend
        // enabling this on desktop yet.
        zoomToFullSizeOnMobile: React.PropTypes.bool
    },
    statics: {
        // Sometimes other components want to download the actual image e.g. to
        // determine its size. Here, we transform an .svg-labels url into the
        // correct image url, and leave normal image urls alone
        getRealImageUrl: function getRealImageUrl(url) {
            return isLabeledSVG(url) ? getSvgUrl(url) : url;
        }
    },
    getDefaultProps: function getDefaultProps() {
        return {
            constrainHeight: false,
            onUpdate: function onUpdate() {},
            responsive: true,
            src: "",
            scale: 1,
            zoomToFullSizeOnMobile: false
        };
    },
    getInitialState: function getInitialState() {
        return {
            imageLoaded: false,
            imageDimensions: null,
            dataLoaded: false,
            labelDataIsLocalized: false,
            labels: [],
            range: [ [ 0, 0 ], [ 0, 0 ] ]
        };
    },
    componentDidMount: function componentDidMount() {
        isLabeledSVG(this.props.src) && this.loadResources();
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        this.props.src !== nextProps.src && this.setState({
            imageLoaded: false,
            dataLoaded: false
        });
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
        // If the props changed, we definitely need to update
        if (!_.isEqual(this.props, nextProps)) return true;
        if (!isLabeledSVG(nextProps.src)) return false;
        return this.isLoadedInState(this.state) !== this.isLoadedInState(nextState);
    },
    componentDidUpdate: function componentDidUpdate() {
        isLabeledSVG(this.props.src) && !this.isLoadedInState(this.state) && this.loadResources();
    },
    // Check if all of the resources are loaded in a given state
    isLoadedInState: function isLoadedInState(state) {
        return state.imageLoaded && state.dataLoaded;
    },
    loadResources: function loadResources() {
        var _this = this;
        var hash = getUrlHash(this.props.src);
        // We can't make multiple jsonp calls to the same file because their
        // callbacks will collide with each other. Instead, we cache the data
        // and only make the jsonp calls once.
        if (labelDataCache[hash]) if (labelDataCache[hash].loaded) {
            var _labelDataCache$hash = labelDataCache[hash], data = _labelDataCache$hash.data, localized = _labelDataCache$hash.localized;
            this.onDataLoaded(data, localized);
        } else labelDataCache[hash].dataCallbacks.push(this.onDataLoaded); else {
            var cacheData = {
                loaded: false,
                dataCallbacks: [ this.onDataLoaded ],
                data: null,
                localized: false
            };
            labelDataCache[hash] = cacheData;
            (function retrieveData(url, errorCallback) {
                doJSONP(url, {
                    callbackName: "svgData" + hash,
                    success: function success(data) {
                        cacheData.data = data;
                        cacheData.loaded = true;
                        _.each(cacheData.dataCallbacks, function(callback) {
                            callback(cacheData.data, cacheData.localized);
                        });
                    },
                    error: errorCallback
                });
            })(getDataUrl(this.props.src), function(x, status, error) {
                // eslint-disable-next-line no-console
                console.error("Data load failed:", getDataUrl(_this.props.src), error);
            });
        }
    },
    onDataLoaded: function onDataLoaded(data, localized) {
        this.isMounted() && data.labels && data.range && this.setState({
            dataLoaded: true,
            labelDataIsLocalized: localized,
            labels: data.labels,
            range: data.range
        });
    },
    sizeProvided: function sizeProvided() {
        return null != this.props.width && null != this.props.height;
    },
    onImageLoad: function onImageLoad() {
        var _this2 = this;
        // Only need to do this if rendering a Graphie
        this.sizeProvided() ? // If width and height are provided, we don't need to calculate the
        // size ourselves
        this.setState({
            imageLoaded: true
        }) : Util.getImageSize(this.props.src, function(width, height) {
            _this2.isMounted() && _this2.setState({
                imageLoaded: true,
                imageDimensions: [ width, height ]
            });
        });
    },
    setupGraphie: function setupGraphie(graphie, options) {
        var _this3 = this;
        _.map(options.labels, function(labelData) {
            if (shouldRenderJipt() && _this3.state.labelDataIsLocalized) {
                // If we're using JIPT translation and we got proper JIPT tags,
                // render the labels as plain text (so JIPT can find them) and
                // add some extra properties to the element so we can properly
                // re-render the label once it is replaced with translated
                // text.
                var elem = graphie.label(labelData.coordinates, labelData.content, labelData.alignment, false);
                $(elem).data("jipt-label-index", jiptLabels.length);
                jiptLabels.push({
                    label: elem,
                    useMath: labelData.typesetAsMath
                });
            } else if (labelData.coordinates) {
                // Create labels from the data
                // TODO(charlie): Some erroneous labels are being sent down
                // without coordinates. They don't seem to have any content, so
                // it seems fine to just ignore them (rather than error), but
                // we should figure out why this is happening.
                var label = graphie.label(labelData.coordinates, labelData.content, labelData.alignment, labelData.typesetAsMath, {
                    "font-size": 100 * _this3.props.scale + "%"
                });
                // Convert absolute positioning css from pixels to percentages
                // TODO(alex): Dynamically resize font-size as well. This
                // almost certainly means listening to throttled window.resize
                // events.
                var labelStyle = label[0].style;
                var labelTop = _this3._tryGetPixels(labelStyle.top);
                var labelLeft = _this3._tryGetPixels(labelStyle.left);
                if (null === labelTop || null === labelLeft) {
                    // Graphie labels are supposed to have an explicit position,
                    // but to be on the safe side, let's fall back to using
                    // jQuery's position(). The reason we're not always using
                    // this is that in the presence of CSS transforms, it will
                    // give the rendered position, which may be scaled and
                    // not equal to the explicitly specified one.
                    var labelPosition = label.position();
                    labelTop = labelPosition.top;
                    labelLeft = labelPosition.left;
                }
                var svgHeight = _this3.props.height * _this3.props.scale;
                var svgWidth = _this3.props.width * _this3.props.scale;
                label.css({
                    top: labelTop / svgHeight * 100 + "%",
                    left: labelLeft / svgWidth * 100 + "%"
                });
                // Add back the styles to each of the labels
                _.each(labelData.style, function(styleValue, styleName) {
                    label.css(styleName, styleValue);
                });
            }
        });
    },
    // Try to parse a CSS value as pixels. Returns null if the parameter string
    // does not contain a number followed by "px".
    _tryGetPixels: function _tryGetPixels(value) {
        value = value || "";
        // While this doesn't check that there are no other alphabetical
        // characters prior to "px", that should be taken care of by the DOM,
        // which won't accept invalid units.
        if (!value.endsWith("px")) return null;
        // parseFloat() ignores trailing non-numerical characters.
        return parseFloat(value) || null;
    },
    _handleZoomClick: function _handleZoomClick(e) {
        // It's possible that the image is already displayed at its
        // full size, but we don't really know that until we get a chance
        // to measure it (just now, after the user clicks). We only zoom
        // if there's more image to be shown.
        //
        // TODO(kevindangoor) If the window is narrow and the image is
        // already displayed as wide as possible, we may want to do
        // nothing in that case as well. Figuring this out correctly
        // likely required accounting for the image alignment and margins.
        ($(e.target).width() < this.props.width || this.props.zoomToFullSizeOnMobile) && Zoom.ZoomService.handleZoomClick(e, this.props.zoomToFullSizeOnMobile);
        this.props.trackInteraction && this.props.trackInteraction();
    },
    render: function render() {
        var imageSrc = this.props.src;
        // Props to send to all images
        var imageProps = {
            alt: this.props.alt,
            title: this.props.title
        };
        var width = this.props.width && this.props.width * this.props.scale;
        var height = this.props.height && this.props.height * this.props.scale;
        var dimensions = {
            width: width,
            height: height
        };
        // To make an image responsive, we need to know what its width and
        // height are in advance (before inserting it into the DOM) so that we
        // can ensure it doesn't grow past those limits. We don't always have
        // this information, especially in places where <Renderer /> is used
        // to render inline Markdown images within a widget. See Radio, Sorter,
        // Matcher, etc.
        // TODO(alex): Make all of those image rendering locations aware of
        // width+height so that they too can render responsively.
        var responsive = this.props.responsive && !!(width && height);
        // An additional <Graphie /> may be inserted after the image/graphie
        // pair. Only used by the image widget, for its legacy labels support.
        // Note that since the image widget always provides width and height
        // data, extraGraphie can be ignored for unresponsive images.
        // TODO(alex): Convert all existing uses of that to web+graphie. This
        // is tricky because web+graphie doesn't support labels on non-graphie
        // images.
        var extraGraphie = void 0;
        this.props.extraGraphie && this.props.extraGraphie.labels.length && (extraGraphie = React.createElement(Graphie, {
            box: this.props.extraGraphie.box,
            range: this.props.extraGraphie.range,
            options: {
                labels: this.props.extraGraphie.labels
            },
            responsive: true,
            addMouseLayer: false,
            setup: this.setupGraphie
        }));
        // If preloader is undefined, we use the default. If it's
        // null, there will be no preloader in use.
        var preloaderBaseFunc = void 0 === this.props.preloader ? defaultPreloader : this.props.preloader;
        var preloader = preloaderBaseFunc ? function() {
            return preloaderBaseFunc(dimensions);
        } : null;
        // Just use a normal image if a normal image is provided
        if (!isLabeledSVG(imageSrc)) {
            if (responsive) {
                var wrapperClasses = classNames({
                    zoomable: width > ZOOMABLE_THRESHOLD,
                    "svg-image": true
                });
                imageProps.onClick = this._handleZoomClick;
                return React.createElement(FixedToResponsive, {
                    className: wrapperClasses,
                    width: width,
                    height: height,
                    constrainHeight: this.props.constrainHeight,
                    allowFullBleed: this.props.allowFullBleed && isImageProbablyPhotograph(imageSrc)
                }, React.createElement(ImageLoader, {
                    src: imageSrc,
                    imgProps: imageProps,
                    preloader: preloader,
                    onUpdate: this.props.onUpdate
                }), extraGraphie);
            }
            imageProps.style = dimensions;
            return React.createElement(ImageLoader, {
                src: imageSrc,
                preloader: preloader,
                imgProps: imageProps,
                onUpdate: this.props.onUpdate
            });
        }
        var imageUrl = getSvgUrl(imageSrc);
        var graphie = void 0;
        // Since we only want to do the graphie setup once, we only render the
        // graphie once everything is loaded
        if (this.isLoadedInState(this.state)) {
            // Use the provided width and height to size the graphie if
            // possible, otherwise use our own calculated size
            var box = void 0;
            box = this.sizeProvided() ? [ width, height ] : [ this.state.imageDimensions[0] * this.props.scale, this.state.imageDimensions[1] * this.props.scale ];
            var scale = [ 40 * this.props.scale, 40 * this.props.scale ];
            graphie = React.createElement(Graphie, {
                ref: "graphie",
                box: box,
                scale: scale,
                range: this.state.range,
                options: _.pick(this.state, "labels"),
                responsive: responsive,
                addMouseLayer: false,
                setup: this.setupGraphie
            });
        }
        if (responsive) return React.createElement(FixedToResponsive, {
            className: "svg-image",
            width: width,
            height: height,
            constrainHeight: this.props.constrainHeight
        }, React.createElement(ImageLoader, {
            src: imageUrl,
            onLoad: this.onImageLoad,
            onUpdate: this.props.onUpdate,
            preloader: preloader,
            imgProps: imageProps
        }), graphie, extraGraphie);
        imageProps.style = dimensions;
        return React.createElement("div", {
            className: "unresponsive-svg-image",
            style: dimensions
        }, React.createElement(ImageLoader, {
            src: imageUrl,
            onLoad: this.onImageLoad,
            onUpdate: this.props.onUpdate,
            preloader: preloader,
            imgProps: imageProps
        }), graphie);
    }
});

module.exports = SvgImage;