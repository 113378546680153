var _responsiveLabel;

/* eslint-disable object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var _require = require("aphrodite"), StyleSheet = _require.StyleSheet;

var mediaQueries = require("./media-queries.js");

var _require2 = require("./constants.js"), zIndexAboveScratchpad = _require2.zIndexAboveScratchpad, zIndexInteractiveComponent = _require2.zIndexInteractiveComponent, radioBorderColor = _require2.radioBorderColor, checkedColor = _require2.checkedColor, circleSize = _require2.circleSize, radioMarginWidth = _require2.radioMarginWidth;

module.exports = StyleSheet.create({
    perseusInteractive: {
        zIndex: zIndexInteractiveComponent,
        position: "relative"
    },
    aboveScratchpad: {
        position: "relative",
        zIndex: zIndexAboveScratchpad
    },
    blankBackground: {
        // TODO(emily): Use KhanUtil._BACKGROUND?
        backgroundColor: "#FDFDFD"
    },
    perseusSrOnly: {
        border: 0,
        clip: "rect(0,0,0,0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        width: 1
    },
    responsiveLabel: (_responsiveLabel = {}, _responsiveLabel[mediaQueries.smOrSmaller] = {
        fontSize: 14,
        lineHeight: 1.3
    }, _responsiveLabel[mediaQueries.md] = {
        fontSize: 17,
        lineHeight: 1.4
    }, _responsiveLabel[mediaQueries.lgOrLarger] = {
        fontSize: 20,
        lineHeight: 1.4
    }, _responsiveLabel),
    responsiveInput: {
        display: "inline-block",
        WebkitAppearance: "none",
        appearance: "none",
        "::-ms-check": {
            display: "none"
        },
        backgroundColor: "#fff",
        border: "2px solid #fff",
        boxShadow: "0 0px 0px 1px " + radioBorderColor,
        outline: "none",
        boxSizing: "border-box",
        flexShrink: 0,
        marginBottom: 1,
        marginLeft: 1,
        marginRight: 1,
        marginTop: 1,
        height: circleSize - 2,
        width: circleSize - 2
    },
    responsiveRadioInput: {
        borderRadius: "50%",
        ":checked": {
            backgroundColor: checkedColor,
            border: "none",
            borderRadius: "50%",
            boxShadow: "inset 0px 0px 0px 2px white, 0 0px 0px 2px " + checkedColor,
            marginTop: radioMarginWidth,
            marginBottom: radioMarginWidth,
            marginLeft: radioMarginWidth,
            marginRight: radioMarginWidth,
            height: circleSize - 2 * radioMarginWidth,
            width: circleSize - 2 * radioMarginWidth
        }
    },
    responsiveRadioInputActive: {
        backgroundColor: "#fff",
        border: "2px solid #fff",
        borderRadius: "50%",
        boxShadow: "0 0px 0px 2px " + checkedColor,
        marginTop: radioMarginWidth,
        marginBottom: radioMarginWidth,
        marginLeft: radioMarginWidth,
        marginRight: radioMarginWidth,
        height: circleSize - 2 * radioMarginWidth,
        width: circleSize - 2 * radioMarginWidth,
        ":checked": {
            backgroundColor: "#fff"
        }
    },
    disableTextSelection: {
        userSelect: "none"
    }
});