var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/**
 * A mixin that accepts the `apiOptions` prop, and populates any missing values
 * with defaults.
 */
var React = require("react");

var ApiOptions = require("../perseus-api.jsx").Options;

var ApiOptionsProps = {
    propTypes: {
        // TODO(mdr): Should this actually be objectOf(any)?
        apiOptions: React.PropTypes.any
    },
    getDefaultProps: function getDefaultProps() {
        return {
            apiOptions: {}
        };
    },
    getApiOptions: function getApiOptions() {
        return _extends({}, ApiOptions.defaults, this.props.apiOptions);
    }
};

module.exports = ApiOptionsProps;