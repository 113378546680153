var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "heading-level-skip",
    severity: _rule2.default.Severity.WARNING,
    selector: "heading ~ heading",
    lint: function lint(state, content, nodes, match) {
        var currentHeading = nodes[1];
        var previousHeading = nodes[0];
        // A heading can have a level less than, the same as
        // or one more than the previous heading. But going up
        // by 2 or more levels is not right
        if (currentHeading.level > previousHeading.level + 1) return "Skipped heading level:\nthis heading is level " + currentHeading.level + " but\nthe previous heading was level " + previousHeading.level;
    }
});