function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var View = function(_React$Component) {
    _inherits(View, _React$Component);
    function View() {
        _classCallCheck(this, View);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    View.prototype.render = function render() {
        var className = css.apply(void 0, [ View.styles.initial ].concat(Array.isArray(this.props.style) ? this.props.style : [ this.props.style ])) + (this.props.extraClassName ? " " + this.props.extraClassName : "");
        return React.createElement("div", {
            className: className,
            style: this.props.dynamicStyle,
            onClick: this.props.onClick,
            onTouchCancel: this.props.onTouchCancel,
            onTouchEnd: this.props.onTouchEnd,
            onTouchMove: this.props.onTouchMove,
            onTouchStart: this.props.onTouchStart,
            "aria-label": this.props.ariaLabel,
            role: this.props.role
        }, this.props.children);
    };
    return View;
}(React.Component);

View.propTypes = {
    ariaLabel: React.PropTypes.string,
    children: React.PropTypes.oneOfType([ React.PropTypes.arrayOf(React.PropTypes.node), React.PropTypes.node ]),
    // The `dynamicStyle` prop is provided for animating dynamic
    // properties, as creating Aphrodite StyleSheets in animation loops is
    // expensive. `dynamicStyle` should be a raw style object, rather than
    // a StyleSheet.
    dynamicStyle: React.PropTypes.any,
    // The `extraClassName` prop should almost never be used. It gives the
    // client a way to provide an additional CSS class name, to augment
    // the class name generated by Aphrodite. (Right now, it's only used to
    // disable some externally-applied CSS that would otherwise be far too
    // difficult to override with inline styles.)
    extraClassName: React.PropTypes.string,
    numberOfLines: React.PropTypes.number,
    onClick: React.PropTypes.func,
    onTouchCancel: React.PropTypes.func,
    onTouchEnd: React.PropTypes.func,
    onTouchMove: React.PropTypes.func,
    onTouchStart: React.PropTypes.func,
    role: React.PropTypes.string,
    style: React.PropTypes.any
};

View.styles = StyleSheet.create({
    // From: https://github.com/necolas/react-native-web/blob/master/src/components/View/index.js
    initial: {
        alignItems: "stretch",
        borderWidth: 0,
        borderStyle: "solid",
        boxSizing: "border-box",
        display: "flex",
        flexBasis: "auto",
        flexDirection: "column",
        margin: 0,
        padding: 0,
        position: "relative",
        // button and anchor reset
        backgroundColor: "transparent",
        color: "inherit",
        font: "inherit",
        textAlign: "inherit",
        textDecorationLine: "none",
        // list reset
        listStyle: "none",
        // fix flexbox bugs
        maxWidth: "100%",
        minHeight: 0,
        minWidth: 0
    }
});

module.exports = View;