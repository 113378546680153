function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A keypad with two pages of keys.
 */
var React = require("react");

var _require = require("react-redux"), connect = _require.connect;

var _require2 = require("aphrodite"), StyleSheet = _require2.StyleSheet;

var Keypad = require("./keypad");

var ViewPager = require("./view-pager");

var PagerIndicator = require("./pager-indicator");

var _require3 = require("../fake-react-native-web"), View = _require3.View;

var _require4 = require("./styles"), column = _require4.column, row = _require4.row, fullWidth = _require4.fullWidth;

var _require5 = require("./common-style"), innerBorderColor = _require5.innerBorderColor, innerBorderStyle = _require5.innerBorderStyle, innerBorderWidthPx = _require5.innerBorderWidthPx, gray85 = _require5.gray85;

var TwoPageKeypad = function(_React$Component) {
    _inherits(TwoPageKeypad, _React$Component);
    function TwoPageKeypad() {
        _classCallCheck(this, TwoPageKeypad);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    TwoPageKeypad.prototype.render = function render() {
        var _props = this.props, currentPage = _props.currentPage, leftPage = _props.leftPage, paginationEnabled = _props.paginationEnabled, rightPage = _props.rightPage;
        return paginationEnabled ? React.createElement(Keypad, {
            style: [ column, styles.keypad ]
        }, React.createElement(PagerIndicator, {
            numPages: 2,
            currentPage: currentPage
        }), React.createElement(View, {
            style: styles.borderTop
        }, React.createElement(ViewPager, null, leftPage, rightPage))) : React.createElement(Keypad, {
            style: styles.keypad
        }, React.createElement(View, {
            style: row
        }, React.createElement(View, {
            style: fullWidth
        }, leftPage), React.createElement(View, {
            style: [ styles.borderLeft, fullWidth ]
        }, rightPage)));
    };
    return TwoPageKeypad;
}(React.Component);

TwoPageKeypad.propTypes = {
    currentPage: React.PropTypes.oneOf([ 0, 1 ]).isRequired,
    leftPage: React.PropTypes.node.isRequired,
    paginationEnabled: React.PropTypes.bool.isRequired,
    rightPage: React.PropTypes.node.isRequired
};

var styles = StyleSheet.create({
    keypad: {
        // Set the background to light grey, so that when the user drags the
        // keypad pages past the edges, there's a grey backdrop.
        backgroundColor: gray85
    },
    borderTop: {
        borderTop: innerBorderWidthPx + "px " + innerBorderStyle + " " + innerBorderColor
    },
    borderLeft: {
        borderLeft: innerBorderWidthPx + "px " + innerBorderStyle + " " + innerBorderColor,
        boxSizing: "content-box"
    }
});

var mapStateToProps = function mapStateToProps(state) {
    return {
        paginationEnabled: state.layout.paginationEnabled
    };
};

module.exports = connect(mapStateToProps)(TwoPageKeypad);