var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "profanity",
    // This list could obviously be expanded a lot, but I figured we
    // could start with https://en.wikipedia.org/wiki/Seven_dirty_words
    pattern: /\b(shit|piss|fuck|cunt|cocksucker|motherfucker|tits)\b/i,
    message: "Avoid profanity"
});