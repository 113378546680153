/**
 * A library of options to pass to add/draw/remove/constraints
 */
var _ = require("underscore");

var WrappedLine = require("./wrapped-line.js");

var WrappedPath = require("./wrapped-path.js");

var kvector = require("kmath").vector;

var kpoint = require("kmath").point;

var KhanMath = require("../util/math.js");

/**
 * Helper functions
 */
var getScaledAngle = function getScaledAngle(line) {
    var scaledA = line.graphie.scalePoint(line.coord(0));
    var scaledZ = line.graphie.scalePoint(line.coord(1));
    return kvector.polarDegFromCart(kvector.subtract(scaledZ, scaledA))[1];
};

// Given `coord` and `angle`, find the point where a line extended
// from `coord` in the direction of `angle` would be clipped by the
// edge of the graphie canvas. Then draw an arrowhead at that point
// pointing in the direction of `angle`.
var getClipPoint = function getClipPoint(graph, coord, angle) {
    // Actually put the arrowheads 4px from the edge so they have
    // a bit of room
    var xExtent = graph.range[0][1] - graph.range[0][0];
    var yExtent = graph.range[1][1] - graph.range[1][0];
    // shoot a point off into the distance ...
    var distance = xExtent + yExtent;
    // we need to scale the point according to the scale of the axes
    var angleVec = graph.unscaleVector(kvector.cartFromPolarDeg([ 1, angle ]));
    var distVec = kvector.scale(kvector.normalize(angleVec), distance);
    var farCoord = kvector.add(coord, distVec);
    var scaledAngle = kvector.polarDegFromCart(angleVec)[1];
    return graph.constrainToBoundsOnAngle(farCoord, 4, scaledAngle * Math.PI / 180);
};

// Given `coord` and `angle`, find the point where a line extended
// from `coord` in the direction of `angle` would be clipped by the
// edge of the graphie canvas. Then draw an arrowhead at that point
// pointing in the direction of `angle`.
var createArrow = function createArrow(graph, style) {
    // Points that define the arrowhead
    var center = [ .75, 0 ];
    var points = [ [ -3, 4 ], [ -2.75, 2.5 ], [ 0, .25 ], center, [ 0, -.25 ], [ -2.75, -2.5 ], [ -3, -4 ] ];
    // Scale points by 1.4 around (0.75, 0)
    var scale = 1.4;
    points = _.map(points, function(point) {
        var pv = kvector.subtract(point, center);
        var pvScaled = kvector.scale(pv, 1.4);
        return kvector.add(center, pvScaled);
    });
    // We can't just pass in a path to `graph.fixedPath` as we need to modify
    // the points in some way, so instead we provide a function for creating
    // the path once the points have been transformed
    var createCubicPath = function createCubicPath(points) {
        var path = "M" + points[0][0] + " " + points[0][1];
        for (var i = 1; i < points.length; i += 3) path += "C" + points[i][0] + " " + points[i][1] + " " + points[i + 1][0] + " " + points[i + 1][1] + " " + points[i + 2][0] + " " + points[i + 2][1];
        return path;
    };
    // Create arrowhead
    var unscaledPoints = _.map(points, graph.unscalePoint);
    var options = {
        center: graph.unscalePoint(center),
        createPath: createCubicPath
    };
    var arrowHead = new WrappedPath(graph, unscaledPoints, options);
    arrowHead.attr(_.extend({
        "stroke-linejoin": "round",
        "stroke-linecap": "round",
        "stroke-dasharray": ""
    }, style));
    // Add custom function for transforming arrowheads that accounts for
    // center, scaling, etc.
    arrowHead.toCoordAtAngle = function(coord, angle) {
        var clipPoint = graph.scalePoint(getClipPoint(graph, coord, angle));
        arrowHead.transform("translateX(" + (clipPoint[0] + 1.4 * center[0]) + "px) translateY(" + (clipPoint[1] + 1.4 * center[1]) + "px) translateZ(0) rotate(" + (360 - KhanMath.bound(angle)) + "deg)");
    };
    return arrowHead;
};

/**
 * MovableLine option functions
 */
var add = {
    // We do this in add as well as in standard so that we can call
    // pointsToFront after the first draw (which adds `this.visibleShape`)
    draw: function draw() {
        this.draw();
    },
    pointsToFront: function pointsToFront(state) {
        _.invoke(state.points, "toFront");
    }
};

add.standard = [ add.draw, add.pointsToFront ];

var modify = {
    draw: function draw() {
        this.draw();
    }
};

modify.standard = [ modify.draw ];

var draw = {
    basic: function basic(state) {
        var graphie = this.graphie;
        var start = this.coord(0);
        var end = this.coord(1);
        if (!this.state.visibleShape) {
            var options = {
                thickness: 10
            };
            this.state.visibleShape = new WrappedLine(graphie, start, end, options);
            this.state.visibleShape.attr(this.normalStyle());
            this.state.visibleShape.toFront();
            this.mouseTarget() && this.mouseTarget().toFront();
        }
        // Compute angle
        var angle = getScaledAngle(this);
        // Extend start, end if necessary (i.e., if not a line segment)
        if (state.extendLine) {
            start = getClipPoint(graphie, start, 360 - angle);
            end = getClipPoint(graphie, end, (540 - angle) % 360);
        } else state.extendRay && (end = getClipPoint(graphie, end, 360 - angle));
        // Move elements
        var elements = [ this.state.visibleShape ];
        this.mouseTarget() && elements.push(this.mouseTarget());
        _.each(elements, function(element) {
            element.moveTo(start, end);
        });
    },
    arrows: function arrows(state) {
        // Create arrows, if not yet created
        if (null == this._arrows) {
            this._arrows = [];
            if (state.extendLine) {
                this._arrows.push(createArrow(this.graphie, this.normalStyle()));
                this._arrows.push(createArrow(this.graphie, this.normalStyle()));
            } else state.extendRay && this._arrows.push(createArrow(this.graphie, this.normalStyle()));
        }
        // Transform arrows
        var angle = getScaledAngle(this);
        var angleForArrow = [ 360 - angle, (540 - angle) % 360 ];
        _.each(this._arrows, function(arrow, i) {
            arrow.toCoordAtAngle(this.coord(i), angleForArrow[i]);
        }, this);
    },
    highlight: function highlight(state, prevState) {
        // TODO(jack): Figure out a way to highlight the points attached to
        // the line. Maybe this means an additional isHovering: []
        // function to state of movable/movablepoint to define [additional?]
        // times it should be highlighted
        state.isHovering && !prevState.isHovering ? state.visibleShape.animate(state.highlightStyle, 50) : !state.isHovering && prevState.isHovering && state.visibleShape.animate(state.normalStyle, 50);
    }
};

draw.standard = [ draw.basic, draw.arrows, draw.highlight ];

var remove = {
    basic: function basic() {
        this.state.visibleShape && this.state.visibleShape.remove();
    },
    arrows: function arrows() {
        null != this._arrows && _.invoke(this._arrows, "remove");
        this._arrows = null;
    }
};

remove.standard = [ remove.basic, remove.arrows ];

var constraints = {
    fixed: function fixed() {
        return function() {
            return false;
        };
    },
    snap: function snap(_snap) {
        return function(coord, prevCoord) {
            if (null === _snap) return true;
            var delta = kvector.subtract(coord, prevCoord);
            _snap = _snap || this.graphie.snap;
            delta = kpoint.roundTo(delta, _snap);
            return kvector.add(prevCoord, delta);
        };
    },
    bound: function bound(range, snap, paddingPx) {
        void 0 === paddingPx && (paddingPx = void 0 === range ? 10 : 0);
        return function(coord, prevCoord) {
            var graphie = this.graphie;
            var delta = kvector.subtract(coord, prevCoord);
            var range = range || graphie.range;
            // A null snap means no snap; an undefined snap means
            // default to graphie's
            void 0 === snap && (snap = graphie.snap);
            // Calculate the bounds for both points
            var absoluteLower = graphie.unscalePoint([ paddingPx, graphie.ypixels - paddingPx ]);
            var absoluteUpper = graphie.unscalePoint([ graphie.xpixels - paddingPx, paddingPx ]);
            if (snap) {
                absoluteLower = kpoint.ceilTo(absoluteLower, snap);
                absoluteUpper = kpoint.floorTo(absoluteUpper, snap);
            }
            // Calculate the bounds for the delta.
            var deltaBounds = _.map(this.coords(), function(coord, i) {
                var max = kvector.subtract(absoluteUpper, coord);
                return [ kvector.subtract(absoluteLower, coord), max ];
            });
            // bound the delta by the calculated bounds
            var boundedDelta = _.reduce(deltaBounds, function(delta, bound) {
                var lower = bound[0];
                var upper = bound[1];
                return [ Math.max(lower[0], Math.min(upper[0], delta[0])), Math.max(lower[1], Math.min(upper[1], delta[1])) ];
            }, delta);
            return kvector.add(prevCoord, boundedDelta);
        };
    }
};

constraints.standard = null;

var onMove = {
    updatePoints: function updatePoints(coord, prevCoord) {
        var actualDelta = kvector.subtract(coord, prevCoord);
        _.each(this.state.points, function(point) {
            point.setCoord(kvector.add(point.coord(), actualDelta));
        });
    }
};

onMove.standard = null;

module.exports = {
    add: add,
    modify: modify,
    draw: draw,
    remove: remove,
    onMoveStart: {
        standard: null
    },
    constraints: constraints,
    onMove: onMove,
    onMoveEnd: {
        standard: null
    }
};