/**
 * Utility functions to build React PropTypes for multi-items and shapes.
 *
 * If you're writing new components, though, consider using the Item and Shape
 * Flow types instead.
 */
var React = require("react");

/**
 * A recursive PropType that accepts Shape objects, and rejects other objects.
 *
 * Usage: `propTypes: {shape: shapePropType}`.
 */
var babelPluginFlowReactPropTypes_proptype_Shape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_Shape || require("react").PropTypes.any;

function shapePropType() {
    return React.PropTypes.oneOfType([ React.PropTypes.shape({
        type: React.PropTypes.oneOf([ "content" ]).isRequired
    }).isRequired, React.PropTypes.shape({
        type: React.PropTypes.oneOf([ "hint" ]).isRequired
    }).isRequired, React.PropTypes.shape({
        type: React.PropTypes.oneOf([ "tags" ]).isRequired
    }).isRequired, React.PropTypes.shape({
        type: React.PropTypes.oneOf([ "object" ]).isRequired,
        shape: React.PropTypes.objectOf(shapePropType)
    }).isRequired, React.PropTypes.shape({
        type: React.PropTypes.oneOf([ "array" ]).isRequired,
        elementShape: shapePropType
    }).isRequired ]).apply(void 0, arguments);
}

/**
 * Return a PropType that accepts Items of the given shape, and rejects other
 * objects.
 *
 * Usage: `propTypes: {item: buildPropTypeForShape(myShape)}`
 */
function buildPropTypeForShape(shape) {
    return React.PropTypes.oneOfType([ React.PropTypes.shape({
        _multi: buildTreePropTypeForShape(shape)
    }), React.PropTypes.oneOf([ null, void 0 ]) ]);
}

/**
 * Return a PropType that accepts ItemTrees of the given shape, and rejects
 * other objects.
 */
function buildTreePropTypeForShape(shape) {
    if ("content" === shape.type) return React.PropTypes.shape({
        // TODO(mdr): Remove #LegacyContentNode support.
        __type: React.PropTypes.oneOf([ "content", "item" ]).isRequired,
        content: React.PropTypes.string,
        images: React.PropTypes.objectOf(React.PropTypes.any),
        widgets: React.PropTypes.objectOf(React.PropTypes.any)
    });
    if ("hint" === shape.type) return React.PropTypes.shape({
        __type: React.PropTypes.oneOf([ "hint" ]).isRequired,
        content: React.PropTypes.string,
        images: React.PropTypes.objectOf(React.PropTypes.any),
        widgets: React.PropTypes.objectOf(React.PropTypes.any),
        replace: React.PropTypes.bool
    });
    if ("tags" === shape.type) return React.PropTypes.arrayOf(React.PropTypes.string.isRequired);
    if ("array" === shape.type) {
        var elementPropType = buildTreePropTypeForShape(shape.elementShape);
        return React.PropTypes.arrayOf(elementPropType.isRequired);
    }
    if ("object" === shape.type) {
        var valueShapes = shape.shape;
        var propTypeShape = {};
        Object.keys(valueShapes).forEach(function(key) {
            propTypeShape[key] = buildTreePropTypeForShape(valueShapes[key]).isRequired;
        });
        return React.PropTypes.shape(propTypeShape);
    }
    throw new Error("unexpected shape type " + shape.type);
}

module.exports = {
    shapePropType: shapePropType,
    buildPropTypeForShape: buildPropTypeForShape
};