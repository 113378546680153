var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

/**
 * A version of the `math-input` subrepo's KeypadInput component that adheres to
 * the same API as Perseus's  MathOuput and NumberInput, allowing it to be
 * dropped in as a replacement for those components without any modifications.
 *
 * TODO(charlie): Once the keypad API has stabilized, move this into the
 * `math-input` subrepo and use it everywhere as a simpler, keypad-coupled
 * interface to `math-input`'s MathInput component.
 */
var React = require("react");

var KeypadInput = require("../../math-input").components.KeypadInput;

var KeypadTypes = require("../../math-input").consts.KeypadTypes;

var keypadElementPropType = require("../../math-input").propTypes.keypadElementPropType;

var SimpleKeypadInput = React.createClass({
    displayName: "SimpleKeypadInput",
    propTypes: {
        keypadElement: keypadElementPropType,
        onFocus: React.PropTypes.func,
        value: React.PropTypes.oneOfType([ React.PropTypes.string, React.PropTypes.number ])
    },
    focus: function focus() {
        this.refs.input.focus();
    },
    blur: function blur() {
        this.refs.input.blur();
    },
    getValue: function getValue() {
        return this.props.value;
    },
    render: function render() {
        var _this = this;
        // Intercept the `onFocus` prop, as we need to configure the keypad
        // before continuing with the default focus logic for Perseus inputs.
        // Intercept the `value` prop so as to map `null` to the empty string,
        // as the `KeypadInput` does not support `null` values.
        var _props = this.props, keypadElement = _props.keypadElement, _onFocus = _props.onFocus, value = _props.value, rest = _objectWithoutProperties(_props, [ "keypadElement", "onFocus", "value" ]);
        return React.createElement(KeypadInput, _extends({
            ref: "input",
            keypadElement: keypadElement,
            onFocus: function onFocus() {
                keypadElement ? keypadElement.configure({
                    keypadType: KeypadTypes.FRACTION
                }, function() {
                    _this.isMounted() && _onFocus && _onFocus();
                }) : _onFocus && _onFocus();
            },
            value: null == value ? "" : "" + value
        }, rest));
    }
});

module.exports = SimpleKeypadInput;