function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * An autogenerated component that renders the TIMES iconograpy in SVG.
 *
 * Generated with: https://gist.github.com/crm416/3c7abc88e520eaed72347af240b32590.
 */
var React = require("react");

var Times = function(_React$Component) {
    _inherits(Times, _React$Component);
    function Times() {
        _classCallCheck(this, Times);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    Times.prototype.render = function render() {
        return React.createElement("svg", {
            width: "48",
            height: "48",
            viewBox: "0 0 48 48"
        }, React.createElement("g", {
            fill: "none",
            fillRule: "evenodd"
        }, React.createElement("path", {
            fill: "none",
            d: "M0 0h48v48H0z"
        }), React.createElement("path", {
            fill: "none",
            d: "M12 12h24v24H12z"
        }), React.createElement("path", {
            d: "M20 20l8 8M28 20l-8 8",
            stroke: this.props.color,
            strokeWidth: "2",
            strokeLinecap: "round",
            strokeLinejoin: "round"
        })));
    };
    return Times;
}(React.Component);

Times.propTypes = {
    color: React.PropTypes.string.isRequired
};

module.exports = Times;