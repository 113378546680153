/* eslint-disable no-var, object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var _2 = require("underscore");

var shuffle = require("../util.js").shuffle;

var Radio = require("./radio/widget.jsx");

var _choiceTransform = function _choiceTransform(editorProps, problemNum) {
    var _maybeRandomize = function _maybeRandomize(array) {
        return editorProps.randomize ? shuffle(array, problemNum) : array;
    };
    var _addNoneOfAbove = function _addNoneOfAbove(choices) {
        var noneOfTheAbove = null;
        var newChoices = _2.reject(choices, function(choice, index) {
            if (choice.isNoneOfTheAbove) {
                noneOfTheAbove = choice;
                return true;
            }
        });
        // Place the "None of the above" options last
        noneOfTheAbove && newChoices.push(noneOfTheAbove);
        return newChoices;
    };
    // Add meta-information to choices
    var choices = editorProps.choices.slice();
    choices = _2.map(choices, function(choice, i) {
        return _2.extend({}, choice, {
            originalIndex: i,
            correct: Boolean(choice.correct)
        });
    });
    // Randomize and add 'None of the above'
    return _addNoneOfAbove(_maybeRandomize(choices));
};

var transform = function transform(editorProps, problemNum) {
    var choices = _choiceTransform(editorProps, problemNum);
    return {
        numCorrect: _2.reduce(editorProps.choices, function(memo, choice) {
            return choice.correct ? memo + 1 : memo;
        }, 0),
        hasNoneOfTheAbove: editorProps.hasNoneOfTheAbove,
        multipleSelect: editorProps.multipleSelect,
        countChoices: editorProps.countChoices,
        correctAnswer: editorProps.correctAnswer,
        deselectEnabled: editorProps.deselectEnabled,
        choices: choices,
        selectedChoices: _2.pluck(choices, "correct")
    };
};

var propUpgrades = {
    1: function _(v0props) {
        var choices;
        var hasNoneOfTheAbove;
        if (v0props.noneOfTheAbove) {
            choices = _2.clone(v0props.choices);
            var noneOfTheAboveIndex = _2.random(0, v0props.choices.length - 1);
            var noneChoice = _2.extend({}, v0props.choices[noneOfTheAboveIndex], {
                isNoneOfTheAbove: true
            });
            choices.splice(noneOfTheAboveIndex, 1);
            choices.push(noneChoice);
            hasNoneOfTheAbove = true;
        } else {
            choices = v0props.choices;
            hasNoneOfTheAbove = false;
        }
        return _2.extend(_2.omit(v0props, "noneOfTheAbove"), {
            choices: choices,
            hasNoneOfTheAbove: hasNoneOfTheAbove
        });
    }
};

module.exports = {
    name: "radio",
    displayName: "Multiple choice",
    accessible: true,
    widget: Radio,
    transform: transform,
    staticTransform: transform,
    version: {
        major: 1,
        minor: 0
    },
    propUpgrades: propUpgrades,
    isLintable: true
};