var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "math-adjacent",
    severity: _rule2.default.Severity.WARNING,
    selector: "blockMath+blockMath",
    message: "Adjacent math blocks:\ncombine the blocks between \\begin{align} and \\end{align}"
});