var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "extra-content-spacing",
    selector: "paragraph",
    pattern: /\s+$/,
    applies: function applies(context) {
        return "article" === context.contentType;
    },
    message: "No extra whitespace at the end of content blocks."
});