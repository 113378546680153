function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

/**
 * Simple version of jQuery's Deferred.
 */
var Deferred = function() {
    function Deferred() {
        var _this = this;
        _classCallCheck(this, Deferred);
        this.promise = new Promise(function(resolve, reject) {
            _this.resolve = resolve;
            _this.reject = reject;
        });
    }
    Deferred.prototype.then = function then(callback) {
        return this.promise.then(callback);
    };
    return Deferred;
}();

module.exports = Deferred;