var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var _typeof = "function" === typeof Symbol && "symbol" === typeof Symbol.iterator ? function(obj) {
    return typeof obj;
} : function(obj) {
    return obj && "function" === typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

/* eslint-disable brace-style, max-lines, object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var kpoint = require("kmath").point;

var kvector = require("kmath").vector;

var _ = require("underscore");

// Minify Raphael ourselves because IE8 has a problem with the 1.5.2 minified
// release
// http://groups.google.com/group/raphaeljs/browse_thread/thread/c34c75ad8d431544
/* globals Raphael:false */
require("../../lib/raphael.js");

var KhanMath = require("./math.js");

var processMath = require("./tex.js").processMath;

var KhanColors = require("./colors");

/* Convert cartesian coordinates [x, y] to polar coordinates [r,
 * theta], with theta in degrees, or in radians if angleInRadians is
 * specified.
 */
function cartToPolar(coord, angleInRadians) {
    var r = Math.sqrt(Math.pow(coord[0], 2) + Math.pow(coord[1], 2));
    var theta = Math.atan2(coord[1], coord[0]);
    // convert angle range from [-pi, pi] to [0, 2pi]
    theta < 0 && (theta += 2 * Math.PI);
    angleInRadians || (theta = 180 * theta / Math.PI);
    return [ r, theta ];
}

function polar(r, th) {
    "number" === typeof r && (r = [ r, r ]);
    th = th * Math.PI / 180;
    return [ r[0] * Math.cos(th), r[1] * Math.sin(th) ];
}

var GraphUtils = {
    unscaledSvgPath: function unscaledSvgPath(points) {
        // If this is an empty closed path, return "" instead of "z", which
        // would give an error
        if (true === points[0]) return "";
        return $.map(points, function(point, i) {
            if (true === point) return "z";
            return (0 === i ? "M" : "L") + point[0] + " " + point[1];
        }).join("");
    },
    getDistance: function getDistance(point1, point2) {
        return kpoint.distanceToPoint(point1, point2);
    },
    /**
    * Return the difference between two sets of coordinates
    */
    coordDiff: function coordDiff(startCoord, endCoord) {
        return _.map(endCoord, function(val, i) {
            return endCoord[i] - startCoord[i];
        });
    },
    /**
    * Round the given coordinates to a given snap value
    * (e.g., nearest 0.2 increment)
    */
    snapCoord: function snapCoord(coord, snap) {
        return _.map(coord, function(val, i) {
            return KhanMath.roundToNearest(snap[i], val);
        });
    },
    // Find the angle in degrees between two or three points
    findAngle: function findAngle(point1, point2, vertex) {
        if (void 0 === vertex) {
            var x = point1[0] - point2[0];
            var y = point1[1] - point2[1];
            if (!x && !y) return 0;
            return (180 + 180 * Math.atan2(-y, -x) / Math.PI + 360) % 360;
        }
        return GraphUtils.findAngle(point1, vertex) - GraphUtils.findAngle(point2, vertex);
    },
    graphs: {}
};

var Graphie = GraphUtils.Graphie = function() {};

_.extend(Graphie.prototype, {
    cartToPolar: cartToPolar,
    polar: polar
});

var labelDirections = {
    center: [ -.5, -.5 ],
    above: [ -.5, -1 ],
    "above right": [ 0, -1 ],
    right: [ 0, -.5 ],
    "below right": [ 0, 0 ],
    below: [ -.5, 0 ],
    "below left": [ -1, 0 ],
    left: [ -1, -.5 ],
    "above left": [ -1, -1 ]
};

/**
 * Safari applies some SVG-specific styles to things that are not SVGs, so we
 * need to exclude those styles from things that are not SVGs.
 *
 * To see this behavior in action, open https://codepen.io/anon/pen/zENEoa in
 * Safari.
 *
 * Usage `$.extend({}, someStyles, SVG_SPECIFIC_STYLE_MASK)`
 */
var SVG_SPECIFIC_STYLE_MASK = {
    "stroke-width": null
};

GraphUtils.createGraphie = function(el) {
    var xScale = 40;
    var yScale = 40;
    var xRange = void 0;
    var yRange = void 0;
    $(el).css("position", "relative");
    var raphael = Raphael(el);
    // For a sometimes-reproducible IE8 bug; doesn't affect SVG browsers at all
    $(el).children("div").css("position", "absolute");
    // Set up some reasonable defaults
    var currentStyle = {
        "stroke-width": 2,
        fill: "none"
    };
    var scaleVector = function scaleVector(point) {
        if ("number" === typeof point) return scaleVector([ point, point ]);
        var x = point[0];
        var y = point[1];
        return [ x * xScale, y * yScale ];
    };
    var scalePoint = function scalePoint(point) {
        if ("number" === typeof point) return scalePoint([ point, point ]);
        var x = point[0];
        var y = point[1];
        return [ (x - xRange[0]) * xScale, (yRange[1] - y) * yScale ];
    };
    var unscalePoint = function unscalePoint(point) {
        if ("number" === typeof point) return unscalePoint([ point, point ]);
        var x = point[0];
        var y = point[1];
        return [ x / xScale + xRange[0], yRange[1] - y / yScale ];
    };
    var unscaleVector = function unscaleVector(point) {
        if ("number" === typeof point) return unscaleVector([ point, point ]);
        return [ point[0] / xScale, point[1] / yScale ];
    };
    var setLabelMargins = function setLabelMargins(span, size) {
        var $span = $(span);
        var direction = $span.data("labelDirection");
        $span.css("visibility", "");
        if ("number" === typeof direction) {
            var x = Math.cos(direction);
            var y = Math.sin(direction);
            var scale = Math.min(size[0] / 2 / Math.abs(x), size[1] / 2 / Math.abs(y));
            $span.css({
                marginLeft: -size[0] / 2 + x * scale,
                marginTop: -size[1] / 2 - y * scale
            });
        } else {
            var multipliers = labelDirections[direction || "center"];
            $span.css({
                marginLeft: Math.round(size[0] * multipliers[0]),
                marginTop: Math.round(size[1] * multipliers[1])
            });
        }
    };
    var svgPath = function svgPath(points, alreadyScaled) {
        return $.map(points, function(point, i) {
            if (true === point) return "z";
            var scaled = alreadyScaled ? point : scalePoint(point);
            return (0 === i ? "M" : "L") + KhanMath.bound(scaled[0]) + " " + KhanMath.bound(scaled[1]);
        }).join("");
    };
    var svgParabolaPath = function svgParabolaPath(a, b, c) {
        var computeParabola = function computeParabola(x) {
            return (a * x + b) * x + c;
        };
        // If points are collinear, plot a line instead
        if (0 === a) {
            var _points = _.map(xRange, function(x) {
                return [ x, computeParabola(x) ];
            });
            return svgPath(_points);
        }
        // Calculate x coordinates of points on parabola
        var xVertex = -b / (2 * a);
        var distToEdge = Math.max(Math.abs(xVertex - xRange[0]), Math.abs(xVertex - xRange[1]));
        // To guarantee that drawn parabola to spans the viewport, use a point
        // on the edge of the graph furtherest from the vertex
        var xPoint = xVertex + distToEdge;
        // Compute parabola and other point on the curve
        var vertex = [ xVertex, computeParabola(xVertex) ];
        var point = [ xPoint, computeParabola(xPoint) ];
        // Calculate SVG 'control' point, defined by spec
        var control = [ vertex[0], vertex[1] - (point[1] - vertex[1]) ];
        // Calculate mirror points across parabola's axis of symmetry
        var dx = Math.abs(vertex[0] - point[0]);
        var left = [ vertex[0] - dx, point[1] ];
        var right = [ vertex[0] + dx, point[1] ];
        // Scale and bound
        var points = _.map([ left, control, right ], scalePoint);
        var values = _.map(_.flatten(points), KhanMath.bound);
        return "M" + values[0] + "," + values[1] + " Q" + values[2] + "," + values[3] + " " + values[4] + "," + values[5];
    };
    var svgSinusoidPath = function svgSinusoidPath(a, b, c, d) {
        // Plot a sinusoid of the form: f(x) = a * sin(b * x - c) + d
        var quarterPeriod = Math.abs(Math.PI / (2 * b));
        var computeSine = function computeSine(x) {
            return a * Math.sin(b * x - c) + d;
        };
        var computeDerivative = function computeDerivative(x) {
            return a * b * Math.cos(c - b * x);
        };
        var coordsForOffset = function coordsForOffset(initial, i) {
            // Return the cubic coordinates (including the two anchor and two
            // control points) for the ith portion of the sinusoid.
            var x0 = initial + quarterPeriod * i;
            var x1 = x0 + quarterPeriod;
            // Interpolate using derivative technique
            // See: http://stackoverflow.com/questions/13932704/how-to-draw-sine-waves-with-svg-js
            var xCoords = [ x0, 2 * x0 / 3 + 1 * x1 / 3, 1 * x0 / 3 + 2 * x1 / 3, x1 ];
            var yCoords = [ computeSine(x0), computeSine(x0) + computeDerivative(x0) * (x1 - x0) / 3, computeSine(x1) - computeDerivative(x1) * (x1 - x0) / 3, computeSine(x1) ];
            // Zip and scale
            return _.map(_.zip(xCoords, yCoords), scalePoint);
        };
        // How many quarter-periods do we need to span the graph?
        var extent = xRange[1] - xRange[0];
        var numQuarterPeriods = Math.ceil(extent / quarterPeriod) + 1;
        // Find starting coordinate: first anchor point curve left of xRange[0]
        var initial = c / b;
        var distToEdge = initial - xRange[0];
        initial -= quarterPeriod * Math.ceil(distToEdge / quarterPeriod);
        // First portion of path is special-case, requiring move-to ('M')
        var coords = coordsForOffset(initial, 0);
        var path = "M" + coords[0][0] + "," + coords[0][1] + " C" + coords[1][0] + "," + coords[1][1] + " " + coords[2][0] + "," + coords[2][1] + " " + coords[3][0] + "," + coords[3][1];
        for (var i = 1; i < numQuarterPeriods; i++) {
            coords = coordsForOffset(initial, i);
            path += " C" + coords[1][0] + "," + coords[1][1] + " " + coords[2][0] + "," + coords[2][1] + " " + coords[3][0] + "," + coords[3][1];
        }
        return path;
    };
    // `svgPath` is independent of graphie range, so we export it independently
    GraphUtils.svgPath = svgPath;
    var processAttributes = function processAttributes(attrs) {
        var transformers = {
            scale: function scale(_scale) {
                "number" === typeof _scale && (_scale = [ _scale, _scale ]);
                xScale = _scale[0];
                yScale = _scale[1];
                // Update the canvas size
                raphael.setSize((xRange[1] - xRange[0]) * xScale, (yRange[1] - yRange[0]) * yScale);
            },
            clipRect: function clipRect(pair) {
                var point = pair[0];
                var size = pair[1];
                point[1] += size[1];
                // because our coordinates are flipped
                return {
                    "clip-rect": scalePoint(point).concat(scaleVector(size)).join(" ")
                };
            },
            strokeWidth: function strokeWidth(val) {
                return {
                    "stroke-width": parseFloat(val)
                };
            },
            rx: function rx(val) {
                return {
                    rx: scaleVector([ val, 0 ])[0]
                };
            },
            ry: function ry(val) {
                return {
                    ry: scaleVector([ 0, val ])[1]
                };
            },
            r: function r(val) {
                var scaled = scaleVector([ val, val ]);
                return {
                    rx: scaled[0],
                    ry: scaled[1]
                };
            }
        };
        var processed = {};
        $.each(attrs || {}, function(key, value) {
            var transformer = transformers[key];
            if ("function" === typeof transformer) $.extend(processed, transformer(value)); else {
                var dasherized = key.replace(/([A-Z]+)([A-Z][a-z])/g, "$1-$2").replace(/([a-z\d])([A-Z])/g, "$1-$2").toLowerCase();
                processed[dasherized] = value;
            }
        });
        return processed;
    };
    var addArrowheads = function arrows(path) {
        var type = path.constructor.prototype;
        if (type === Raphael.el) {
            if ("path" === path.type && "undefined" === typeof path.arrowheadsDrawn) {
                var w = path.attr("stroke-width");
                var s = .6 + .4 * w;
                var l = path.getTotalLength();
                var set = raphael.set();
                var head = raphael.path(graphie.isMobile ? "M-4,4 C-4,4 -0.25,0 -0.25,0 C-0.25,0 -4,-4 -4,-4" : "M-3 4 C-2.75 2.5 0 0.25 0.75 0C0 -0.25 -2.75 -2.5 -3 -4");
                var end = path.getPointAtLength(l - .4);
                var almostTheEnd = path.getPointAtLength(l - .75 * s);
                var angle = 180 * Math.atan2(end.y - almostTheEnd.y, end.x - almostTheEnd.x) / Math.PI;
                var attrs = path.attr();
                delete attrs.path;
                var subpath = path.getSubpath(0, l - .75 * s);
                subpath = raphael.path(subpath).attr(attrs);
                subpath.arrowheadsDrawn = true;
                path.remove();
                // For some unknown reason 0 doesn't work for the rotation
                // origin so we use a tiny number.
                head.rotate(angle, graphie.isMobile ? 1e-5 : .75, 0).scale(s, s, .75, 0).translate(almostTheEnd.x, almostTheEnd.y).attr(attrs).attr({
                    "stroke-linejoin": "round",
                    "stroke-linecap": "round"
                });
                head.arrowheadsDrawn = true;
                set.push(subpath);
                set.push(head);
                return set;
            }
        } else if (type === Raphael.st) for (var i = 0, _l = path.items.length; i < _l; i++) arrows(path.items[i]);
        return path;
    };
    var drawingTools = {
        circle: function circle(center, radius) {
            return raphael.ellipse.apply(raphael, scalePoint(center).concat(scaleVector([ radius, radius ])));
        },
        // (x, y) is coordinate of bottom left corner
        rect: function rect(x, y, width, height) {
            // Raphael needs (x, y) to be coordinate of upper left corner
            var corner = scalePoint([ x, y + height ]);
            var dims = scaleVector([ width, height ]);
            var elem = raphael.rect.apply(raphael, corner.concat(dims));
            graphie.isMobile && (elem.node.style.shapeRendering = "crispEdges");
            return elem;
        },
        ellipse: function ellipse(center, radii) {
            return raphael.ellipse.apply(raphael, scalePoint(center).concat(scaleVector(radii)));
        },
        fixedEllipse: function fixedEllipse(center, radii, maxScale, padding) {
            // Scale point and radius
            var scaledPoint = scalePoint(center);
            var scaledRadii = scaleVector(radii);
            var width = 2 * scaledRadii[0] * maxScale + padding;
            var height = 2 * scaledRadii[1] * maxScale + padding;
            // Calculate absolute left, top
            var left = scaledPoint[0] - width / 2;
            var top = scaledPoint[1] - height / 2;
            // Wrap in <div>
            var wrapper = document.createElement("div");
            $(wrapper).css({
                position: "absolute",
                width: width + "px",
                height: height + "px",
                left: left + "px",
                top: top + "px"
            });
            return {
                wrapper: wrapper,
                visibleShape: Raphael(wrapper, width, height).ellipse(width / 2, height / 2, scaledRadii[0], scaledRadii[1])
            };
        },
        arc: function arc(center, radius, startAngle, endAngle, sector) {
            startAngle = (startAngle % 360 + 360) % 360;
            endAngle = (endAngle % 360 + 360) % 360;
            var cent = scalePoint(center);
            var radii = scaleVector(radius);
            var startVector = polar(radius, startAngle);
            var endVector = polar(radius, endAngle);
            var startPoint = scalePoint([ center[0] + startVector[0], center[1] + startVector[1] ]);
            var endPoint = scalePoint([ center[0] + endVector[0], center[1] + endVector[1] ]);
            var largeAngle = ((endAngle - startAngle) % 360 + 360) % 360 > 180;
            // ellipse rotation
            // sweep flag
            return raphael.path("M" + startPoint.join(" ") + "A" + radii.join(" ") + " 0 " + (largeAngle ? 1 : 0) + " 0 " + endPoint.join(" ") + (sector ? "L" + cent.join(" ") + "z" : ""));
        },
        path: function path(points) {
            var p = raphael.path(svgPath(points));
            p.graphiePath = points;
            return p;
        },
        fixedPath: function fixedPath(points, center, createPath) {
            points = _.map(points, scalePoint);
            center = center ? scalePoint(center) : null;
            createPath = createPath || svgPath;
            var pathLeft = _.min(_.pluck(points, 0));
            var pathRight = _.max(_.pluck(points, 0));
            var pathTop = _.min(_.pluck(points, 1));
            var pathBottom = _.max(_.pluck(points, 1));
            // Apply padding to line
            var padding = [ 4, 4 ];
            // Calculate and apply additional offset
            var extraOffset = [ pathLeft, pathTop ];
            // Apply padding and offset to points
            points = _.map(points, function(point) {
                return kvector.add(kvector.subtract(point, extraOffset), kvector.scale(padding, .5));
            });
            // Calculate <div> dimensions
            var width = pathRight - pathLeft + padding[0];
            var height = pathBottom - pathTop + padding[1];
            var left = extraOffset[0] - padding[0] / 2;
            var top = extraOffset[1] - padding[1] / 2;
            // Create <div>
            var wrapper = document.createElement("div");
            $(wrapper).css({
                position: "absolute",
                width: width + "px",
                height: height + "px",
                left: left + "px",
                top: top + "px",
                // If user specified a center, set it
                transformOrigin: center ? width / 2 + center[0] + "px " + (height / 2 + center[1]) + "px" : null
            });
            return {
                wrapper: wrapper,
                visibleShape: Raphael(wrapper, width, height).path(createPath(points))
            };
        },
        scaledPath: function scaledPath(points) {
            var p = raphael.path(svgPath(points, /* alreadyScaled */ true));
            p.graphiePath = points;
            return p;
        },
        line: function line(start, end) {
            var l = this.path([ start, end ]);
            graphie.isMobile && (l.node.style.shapeRendering = "crispEdges");
            return l;
        },
        parabola: function parabola(a, b, c) {
            // Plot a parabola of the form: f(x) = (a * x + b) * x + c
            return raphael.path(svgParabolaPath(a, b, c));
        },
        fixedLine: function fixedLine(start, end, thickness) {
            // Apply padding to line
            var padding = [ thickness, thickness ];
            // Scale points to get values in pixels
            start = scalePoint(start);
            end = scalePoint(end);
            // Calculate and apply additional offset
            var extraOffset = [ Math.min(start[0], end[0]), Math.min(start[1], end[1]) ];
            // Apply padding and offset to start, end points
            start = kvector.add(kvector.subtract(start, extraOffset), kvector.scale(padding, .5));
            end = kvector.add(kvector.subtract(end, extraOffset), kvector.scale(padding, .5));
            // Calculate <div> dimensions
            var left = extraOffset[0] - padding[0] / 2;
            var top = extraOffset[1] - padding[1] / 2;
            var width = Math.abs(start[0] - end[0]) + padding[0];
            var height = Math.abs(start[1] - end[1]) + padding[1];
            // Create <div>
            var wrapper = document.createElement("div");
            $(wrapper).css({
                position: "absolute",
                width: width + "px",
                height: height + "px",
                left: left + "px",
                top: top + "px",
                // Outsiders should feel like the line's 'origin' (i.e., for
                // rotation) is the starting point
                transformOrigin: start[0] + "px " + start[1] + "px"
            });
            // Create Raphael canvas
            var localRaphael = Raphael(wrapper, width, height);
            // Calculate path
            var path = "M" + start[0] + " " + start[1] + " L" + end[0] + " " + end[1];
            var visibleShape = localRaphael.path(path);
            visibleShape.graphiePath = [ start, end ];
            return {
                wrapper: wrapper,
                visibleShape: visibleShape
            };
        },
        sinusoid: function sinusoid(a, b, c, d) {
            // Plot a sinusoid of the form: f(x) = a * sin(b * x - c) + d
            return raphael.path(svgSinusoidPath(a, b, c, d));
        },
        grid: function grid(xr, yr) {
            var step = currentStyle.step || [ 1, 1 ];
            var set = raphael.set();
            var x = step[0] * Math.ceil(xr[0] / step[0]);
            for (;x <= xr[1]; x += step[0]) set.push(this.line([ x, yr[0] ], [ x, yr[1] ]));
            var y = step[1] * Math.ceil(yr[0] / step[1]);
            for (;y <= yr[1]; y += step[1]) set.push(this.line([ xr[0], y ], [ xr[1], y ]));
            return set;
        },
        label: function label(point, text, direction, latex) {
            latex = "undefined" === typeof latex || latex;
            var $span = $("<span>").addClass("graphie-label");
            var pad = currentStyle["label-distance"];
            $span.css($.extend({}, {
                position: "absolute",
                padding: (null != pad ? pad : 7) + "px"
            })).data("labelDirection", direction).appendTo(el);
            $span.setPosition = function(point) {
                var scaledPoint = scalePoint(point);
                $span.css({
                    left: scaledPoint[0],
                    top: scaledPoint[1]
                });
            };
            $span.setPosition(point);
            var span = $span[0];
            $span.processMath = function(math, force) {
                processMath(span, math, force, function() {
                    var width = span.scrollWidth;
                    var height = span.scrollHeight;
                    setLabelMargins(span, [ width, height ]);
                });
            };
            $span.processText = function(text) {
                $span.html(text);
                var width = span.scrollWidth;
                var height = span.scrollHeight;
                setLabelMargins(span, [ width, height ]);
            };
            latex ? $span.processMath(text, /* force */ false) : $span.processText(text);
            return $span;
        },
        plotParametric: function plotParametric(fn, range, shade, fn2) {
            // Note: fn2 should only be set if 'shade' is true, as it denotes
            // the function between which fn should have its area shaded.
            // In general, plotParametric shouldn't be used to shade the area
            // between two arbitrary parametrics functions over an interval,
            // as the method assumes that fn and fn2 are both of the form
            // fn(t) = (t, fn'(t)) for some initial fn'.
            fn2 = fn2 || function(t) {
                return [ t, 0 ];
            };
            // We truncate to 500,000, since anything bigger causes
            // overflow in the firefox svg renderer.  This is safe
            // since 500,000 is outside the viewport anyway.  We
            // write these functions the way we do to handle undefined.
            var clipper = function clipper(xy) {
                if (Math.abs(xy[1]) > 5e5) return [ xy[0], Math.min(Math.max(xy[1], -5e5), 5e5) ];
                return xy;
            };
            var clippedFn = function clippedFn(x) {
                return clipper(fn(x));
            };
            var clippedFn2 = function clippedFn2(x) {
                return clipper(fn2(x));
            };
            currentStyle.strokeLinejoin || (currentStyle.strokeLinejoin = "round");
            currentStyle.strokeLinecap || (currentStyle.strokeLinecap = "round");
            var min = range[0];
            var max = range[1];
            var step = (max - min) / (currentStyle["plot-points"] || 800);
            0 === step && (step = 1);
            var paths = raphael.set();
            var points = [];
            var lastDiff = GraphUtils.coordDiff(clippedFn(min), clippedFn2(min));
            var lastFlip = min;
            for (var t = min; t <= max; t += step) {
                var top = clippedFn(t);
                var bottom = clippedFn2(t);
                var diff = GraphUtils.coordDiff(top, bottom);
                // Find points where it flips
                // Create path that sketches area between the two functions
                if (// if there is an asymptote here, meaning that the graph
                // switches signs and has a large difference
                diff[1] < 0 !== lastDiff[1] < 0 && Math.abs(diff[1] - lastDiff[1]) > 2 * yScale || // or the function is undefined
                isNaN(diff[1])) {
                    // split the path at this point, and draw it
                    if (shade) {
                        points.push(top);
                        // backtrack to draw paired function
                        for (var u = t - step; u >= lastFlip; u -= step) points.push(clippedFn2(u));
                        lastFlip = t;
                    }
                    paths.push(this.path(points));
                    // restart the path, excluding this point
                    points = [];
                    shade && points.push(top);
                } else // otherwise, just add the point to the path
                points.push(top);
                lastDiff = diff;
            }
            if (shade) // backtrack to draw paired function
            for (var _u = max - step; _u >= lastFlip; _u -= step) points.push(clippedFn2(_u));
            paths.push(this.path(points));
            return paths;
        },
        plotPolar: function plotPolar(fn, range) {
            var min = range[0];
            var max = range[1];
            // There is probably a better heuristic for this
            currentStyle["plot-points"] || (currentStyle["plot-points"] = 2 * (max - min) * xScale);
            return this.plotParametric(function(th) {
                return polar(fn(th), 180 * th / Math.PI);
            }, range);
        },
        plot: function plot(fn, range, swapAxes, shade, fn2) {
            var min = range[0];
            var max = range[1];
            currentStyle["plot-points"] || (currentStyle["plot-points"] = 2 * (max - min) * xScale);
            if (swapAxes) {
                if (fn2) // TODO(charlie): support swapped axis area shading
                throw new Error("Can't shade area between functions with swapped axes.");
                return this.plotParametric(function(y) {
                    return [ fn(y), y ];
                }, range, shade);
            }
            if (fn2) {
                if (shade) return this.plotParametric(function(x) {
                    return [ x, fn(x) ];
                }, range, shade, function(x) {
                    return [ x, fn2(x) ];
                });
                throw new Error("fn2 should only be set when 'shade' is True.");
            }
            return this.plotParametric(function(x) {
                return [ x, fn(x) ];
            }, range, shade);
        },
        /**
         * Given a piecewise function, return a Raphael set of paths that
         * can be used to draw the function, e.g. using style().
         * Calls plotParametric.
         *
         * @param  {[]} fnArray    array of functions which when called
         *                         with a parameter i return the value of
         *                         the function at i
         * @param  {[]} rangeArray array of ranges over which the
         *                         corresponding functions are defined
         * @return {Raphael set}
         */
        plotPiecewise: function plotPiecewise(fnArray, rangeArray) {
            var paths = raphael.set();
            var self = this;
            _.times(fnArray.length, function(i) {
                var fn = fnArray[i];
                var range = rangeArray[i];
                var fnPaths = self.plotParametric(function(x) {
                    return [ x, fn(x) ];
                }, range);
                _.each(fnPaths, function(fnPath) {
                    paths.push(fnPath);
                });
            });
            return paths;
        },
        /**
         * Given an array of coordinates of the form [x, y], create and
         * return a Raphael set of Raphael circle objects at those
         * coordinates
         *
         * @param  {Array of arrays} endpointArray
         * @return {Raphael set}
         */
        plotEndpointCircles: function plotEndpointCircles(endpointArray) {
            var circles = raphael.set();
            var self = this;
            _.each(endpointArray, function(coord, i) {
                circles.push(self.circle(coord, .15));
            });
            return circles;
        },
        plotAsymptotes: function plotAsymptotes(fn, range) {
            var min = range[0];
            var max = range[1];
            var step = (max - min) / (currentStyle["plot-points"] || 800);
            var asymptotes = raphael.set();
            var lastVal = fn(min);
            for (var t = min; t <= max; t += step) {
                var funcVal = fn(t);
                funcVal < 0 !== lastVal < 0 && Math.abs(funcVal - lastVal) > 2 * yScale && asymptotes.push(this.line([ t, yScale ], [ t, -yScale ]));
                lastVal = funcVal;
            }
            return asymptotes;
        }
    };
    var graphie = new Graphie();
    _.extend(graphie, {
        raphael: raphael,
        init: function init(options) {
            var scale = options.scale || [ 40, 40 ];
            scale = "number" === typeof scale ? [ scale, scale ] : scale;
            xScale = scale[0];
            yScale = scale[1];
            if (null == options.range) return Khan.error("range should be specified in graph init");
            xRange = options.range[0];
            yRange = options.range[1];
            var w = (xRange[1] - xRange[0]) * xScale;
            var h = (yRange[1] - yRange[0]) * yScale;
            raphael.setSize(w, h);
            $(el).css({
                width: w,
                height: h
            });
            this.range = options.range;
            this.scale = scale;
            this.dimensions = [ w, h ];
            this.xpixels = w;
            this.ypixels = h;
            this.isMobile = options.isMobile;
            return this;
        },
        style: function style(attrs, fn) {
            var processed = processAttributes(attrs);
            if ("function" === typeof fn) {
                var oldStyle = currentStyle;
                currentStyle = $.extend({}, currentStyle, processed);
                var result = fn.call(graphie);
                currentStyle = oldStyle;
                return result;
            }
            $.extend(currentStyle, processed);
        },
        scalePoint: scalePoint,
        scaleVector: scaleVector,
        unscalePoint: unscalePoint,
        unscaleVector: unscaleVector,
        // Custom SVG path functions that are dependent on graphie range
        // `svgPath`, while independent of range, is exported for consistency
        svgPath: svgPath,
        svgParabolaPath: svgParabolaPath,
        svgSinusoidPath: svgSinusoidPath
    });
    $.each(drawingTools, function(name) {
        graphie[name] = function() {
            var last = arguments[arguments.length - 1];
            var oldStyle = currentStyle;
            var result = void 0;
            // The last argument is probably trying to change the style
            if ("object" !== ("undefined" === typeof last ? "undefined" : _typeof(last)) || _.isArray(last)) {
                currentStyle = $.extend({}, currentStyle);
                result = drawingTools[name].apply(drawingTools, arguments);
            } else {
                currentStyle = _extends({}, currentStyle, processAttributes(last));
                var rest = [].slice.call(arguments, 0, arguments.length - 1);
                result = drawingTools[name].apply(drawingTools, rest);
            }
            // Bad heuristic for recognizing Raphael elements and sets
            var type = result.constructor.prototype;
            if (type === Raphael.el || type === Raphael.st) {
                result.attr(currentStyle);
                currentStyle.arrows && (result = addArrowheads(result));
            } else result instanceof $ && // We assume that if it's not a Raphael element/set, it
            // does not contain SVG.
            result.css(_extends({}, currentStyle, SVG_SPECIFIC_STYLE_MASK));
            currentStyle = oldStyle;
            return result;
        };
    });
    // Initializes graphie settings for a graph and draws the basic graph
    // features (axes, grid, tick marks, and axis labels)
    // Options expected are:
    // - range: [[a, b], [c, d]] or [a, b]
    // - scale: [a, b] or number
    // - gridOpacity: number (0 - 1)
    // - gridStep: [a, b] or number (relative to units)
    // - tickStep: [a, b] or number (relative to grid steps)
    // - tickLen: [a, b] or number (in pixels)
    // - labelStep: [a, b] or number (relative to tick steps)
    // - yLabelFormat: fn to format label string for y-axis
    // - xLabelFormat: fn to format label string for x-axis
    // - smartLabelPositioning: true or false to ignore minus sign
    graphie.graphInit = function(options) {
        options = options || {};
        $.each(options, function(prop, val) {
            // allow options to be specified by a single number for shorthand if
            // the horizontal and vertical components are the same
            prop.match(/.*Opacity$/) || "range" === prop || "number" !== typeof val || (options[prop] = [ val, val ]);
            // allow symmetric ranges to be specified by the absolute values
            "range" !== prop && "gridRange" !== prop || (val.constructor === Array ? // but don't mandate symmetric ranges
            val[0].constructor !== Array && (options[prop] = [ [ -val[0], val[0] ], [ -val[1], val[1] ] ]) : "number" === typeof val && (options[prop] = [ [ -val, val ], [ -val, val ] ]));
        });
        var range = options.range || [ [ -10, 10 ], [ -10, 10 ] ];
        var gridRange = options.gridRange || options.range;
        var scale = options.scale || [ 20, 20 ];
        var grid = null == options.grid || options.grid;
        var gridOpacity = options.gridOpacity || .1;
        var gridStep = options.gridStep || [ 1, 1 ];
        var axes = null == options.axes || options.axes;
        var axisArrows = options.axisArrows || "";
        var axisOpacity = options.axisOpacity || 1;
        var axisCenter = options.axisCenter || [ Math.min(Math.max(range[0][0], 0), range[0][1]), Math.min(Math.max(range[1][0], 0), range[1][1]) ];
        var axisLabels = null != options.axisLabels && options.axisLabels;
        var ticks = null == options.ticks || options.ticks;
        var tickStep = options.tickStep || [ 2, 2 ];
        var tickLen = options.tickLen || [ 5, 5 ];
        var tickOpacity = options.tickOpacity || 1;
        var labels = options.labels || options.labelStep || false;
        var labelStep = options.labelStep || [ 1, 1 ];
        var labelOpacity = options.labelOpacity || 1;
        var unityLabels = options.unityLabels || false;
        var labelFormat = options.labelFormat || function(a) {
            return a;
        };
        var xLabelFormat = options.xLabelFormat || labelFormat;
        var yLabelFormat = options.yLabelFormat || labelFormat;
        var smartLabelPositioning = null == options.smartLabelPositioning || options.smartLabelPositioning;
        var realRange = [ [ range[0][0] - (range[0][0] > 0 ? 1 : 0), range[0][1] + (range[0][1] < 0 ? 1 : 0) ], [ range[1][0] - (range[1][0] > 0 ? 1 : 0), range[1][1] + (range[1][1] < 0 ? 1 : 0) ] ];
        _.isArray(unityLabels) || (unityLabels = [ unityLabels, unityLabels ]);
        if (smartLabelPositioning) {
            var minusIgnorer = function minusIgnorer(lf) {
                return function(a) {
                    return (lf(a) + "").replace(/-(\d)/g, "\\llap{-}$1");
                };
            };
            xLabelFormat = minusIgnorer(xLabelFormat);
            yLabelFormat = minusIgnorer(yLabelFormat);
        }
        this.init({
            range: realRange,
            scale: scale,
            isMobile: options.isMobile
        });
        // draw grid
        grid && this.grid(gridRange[0], gridRange[1], {
            stroke: options.isMobile ? KhanColors.GRAY_C : "#000000",
            opacity: options.isMobile ? 1 : gridOpacity,
            step: gridStep,
            strokeWidth: options.isMobile ? 1 : 2
        });
        // draw axes
        if (axes) {
            // this is a slight hack until <-> arrowheads work
            "<->" === axisArrows || true === axisArrows ? this.style({
                stroke: options.isMobile ? KhanColors.GRAY_G : "#000000",
                opacity: options.isMobile ? 1 : axisOpacity,
                strokeWidth: options.isMobile ? 1 : 2,
                arrows: "->"
            }, function() {
                if (range[1][0] < 0 && range[1][1] > 0) {
                    this.path([ axisCenter, [ gridRange[0][0], axisCenter[1] ] ]);
                    this.path([ axisCenter, [ gridRange[0][1], axisCenter[1] ] ]);
                }
                if (range[0][0] < 0 && range[0][1] > 0) {
                    this.path([ axisCenter, [ axisCenter[0], gridRange[1][0] ] ]);
                    this.path([ axisCenter, [ axisCenter[0], gridRange[1][1] ] ]);
                }
            }) : "->" !== axisArrows && "" !== axisArrows || this.style({
                stroke: "#000000",
                opacity: axisOpacity,
                strokeWidth: 2,
                arrows: axisArrows
            }, function() {
                this.path([ [ gridRange[0][0], axisCenter[1] ], [ gridRange[0][1], axisCenter[1] ] ]);
                this.path([ [ axisCenter[0], gridRange[1][0] ], [ axisCenter[0], gridRange[1][1] ] ]);
            });
            if (axisLabels && 2 === axisLabels.length) {
                this.label([ gridRange[0][1], axisCenter[1] ], axisLabels[0], "right");
                this.label([ axisCenter[0], gridRange[1][1] ], axisLabels[1], "above");
            }
        }
        // draw tick marks
        if (ticks) {
            var halfWidthTicks = options.isMobile;
            this.style({
                stroke: options.isMobile ? KhanColors.GRAY_G : "#000000",
                opacity: options.isMobile ? 1 : tickOpacity,
                strokeWidth: 1
            }, function() {
                // horizontal axis
                var step = gridStep[0] * tickStep[0];
                var len = tickLen[0] / scale[1];
                var start = gridRange[0][0];
                var stop = gridRange[0][1];
                if (range[1][0] < 0 && range[1][1] > 0) {
                    for (var x = step + axisCenter[0]; x <= stop; x += step) (x < stop || !axisArrows) && this.line([ x, -len + axisCenter[1] ], [ x, halfWidthTicks ? 0 : len + axisCenter[1] ]);
                    for (var _x = -step + axisCenter[0]; _x >= start; _x -= step) (_x > start || !axisArrows) && this.line([ _x, -len + axisCenter[1] ], [ _x, halfWidthTicks ? 0 : len + axisCenter[1] ]);
                }
                // vertical axis
                step = gridStep[1] * tickStep[1];
                len = tickLen[1] / scale[0];
                start = gridRange[1][0];
                stop = gridRange[1][1];
                if (range[0][0] < 0 && range[0][1] > 0) {
                    for (var y = step + axisCenter[1]; y <= stop; y += step) (y < stop || !axisArrows) && this.line([ -len + axisCenter[0], y ], [ halfWidthTicks ? 0 : len + axisCenter[0], y ]);
                    for (var _y = -step + axisCenter[1]; _y >= start; _y -= step) (_y > start || !axisArrows) && this.line([ -len + axisCenter[0], _y ], [ halfWidthTicks ? 0 : len + axisCenter[0], _y ]);
                }
            });
        }
        // draw axis labels
        labels && this.style({
            stroke: options.isMobile ? KhanColors.GRAY_G : "#000000",
            opacity: options.isMobile ? 1 : labelOpacity
        }, function() {
            // horizontal axis
            var step = gridStep[0] * tickStep[0] * labelStep[0];
            var start = gridRange[0][0];
            var stop = gridRange[0][1];
            var xAxisPosition = axisCenter[0] < 0 ? "above" : "below";
            var yAxisPosition = axisCenter[0] < 0 ? "right" : "left";
            var xShowZero = 0 === axisCenter[0] && 0 !== axisCenter[1];
            var yShowZero = 0 !== axisCenter[0] && 0 === axisCenter[1];
            var axisOffCenter = 0 !== axisCenter[0] || 0 !== axisCenter[1];
            var showUnityX = unityLabels[0] || axisOffCenter;
            var showUnityY = unityLabels[1] || axisOffCenter;
            // positive x-axis
            for (var x = (xShowZero ? 0 : step) + axisCenter[0]; x <= stop; x += step) (x < stop || !axisArrows) && this.label([ x, axisCenter[1] ], xLabelFormat(x), xAxisPosition);
            // negative x-axis
            for (var _x2 = -step * (showUnityX ? 1 : 2) + axisCenter[0]; _x2 >= start; _x2 -= step) (_x2 > start || !axisArrows) && this.label([ _x2, axisCenter[1] ], xLabelFormat(_x2), xAxisPosition);
            step = gridStep[1] * tickStep[1] * labelStep[1];
            start = gridRange[1][0];
            stop = gridRange[1][1];
            // positive y-axis
            for (var y = (yShowZero ? 0 : step) + axisCenter[1]; y <= stop; y += step) (y < stop || !axisArrows) && this.label([ axisCenter[0], y ], yLabelFormat(y), yAxisPosition);
            // negative y-axis
            for (var _y2 = -step * (showUnityY ? 1 : 2) + axisCenter[1]; _y2 >= start; _y2 -= step) (_y2 > start || !axisArrows) && this.label([ axisCenter[0], _y2 ], yLabelFormat(_y2), yAxisPosition);
        });
    };
    return graphie;
};

module.exports = GraphUtils;