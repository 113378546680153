"use strict";

/**
 * An article renderer. Articles are long-form pieces of content,
 * composed of multiple (Renderer) sections concatenated together.
 */
var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var React = require("react");

var ReactDOM = require("react-dom");

var classNames = require("classnames");

var Util = require("./util.js");

var ApiOptions = require("./perseus-api.jsx").Options;

var ApiClassNames = require("./perseus-api.jsx").ClassNames;

var Renderer = require("./renderer.jsx");

var ProvideKeypad = require("./mixins/provide-keypad.jsx");

var Gorgon = require("./gorgon/gorgon.js");

var _require = require("./gorgon/proptypes.js"), linterContextProps = _require.linterContextProps, linterContextDefault = _require.linterContextDefault;

var rendererProps = React.PropTypes.shape({
    content: React.PropTypes.string,
    widgets: React.PropTypes.object,
    images: React.PropTypes.object
});

var ArticleRenderer = React.createClass({
    displayName: "ArticleRenderer",
    propTypes: _extends({}, ProvideKeypad.propTypes, {
        apiOptions: React.PropTypes.shape({
            onFocusChange: React.PropTypes.func,
            isMobile: React.PropTypes.bool
        }),
        json: React.PropTypes.oneOfType([ rendererProps, React.PropTypes.arrayOf(rendererProps) ]).isRequired,
        // Whether to use the new Bibliotron styles for articles
        useNewStyles: React.PropTypes.bool,
        linterContext: linterContextProps,
        legacyPerseusLint: React.PropTypes.arrayOf(React.PropTypes.string)
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            apiOptions: {},
            useNewStyles: false,
            linterContext: linterContextDefault
        };
    },
    getInitialState: function getInitialState() {
        return ProvideKeypad.getInitialState();
    },
    componentDidMount: function componentDidMount() {
        ProvideKeypad.componentDidMount.call(this);
        this._currentFocus = null;
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== this.props || nextState !== this.state;
    },
    componentWillUnmount: function componentWillUnmount() {
        ProvideKeypad.componentWillUnmount.call(this);
    },
    keypadElement: function keypadElement() {
        return ProvideKeypad.keypadElement.call(this);
    },
    _handleFocusChange: function _handleFocusChange(newFocusPath, oldFocusPath) {
        // TODO(charlie): DRY this up--some of this logic is repeated in
        // ItemRenderer.
        newFocusPath ? this._setCurrentFocus(newFocusPath) : this._onRendererBlur(oldFocusPath);
    },
    _setCurrentFocus: function _setCurrentFocus(newFocusPath) {
        var keypadElement = this.keypadElement();
        var prevFocusPath = this._currentFocus;
        this._currentFocus = newFocusPath;
        // Use the section prefix to extract the relevant Renderer's input
        // paths, so as to check whether the focused path represents an
        // input.
        var didFocusInput = false;
        if (this._currentFocus) {
            var _currentFocus = this._currentFocus, sectionRef = _currentFocus[0], focusPath = _currentFocus.slice(1);
            didFocusInput = this.refs[sectionRef].getInputPaths().some(function(inputPath) {
                return Util.inputPathsEqual(inputPath, focusPath);
            });
        }
        null != this.props.apiOptions.onFocusChange && this.props.apiOptions.onFocusChange(this._currentFocus, prevFocusPath, didFocusInput && keypadElement && ReactDOM.findDOMNode(keypadElement));
        keypadElement && (didFocusInput ? keypadElement.activate() : keypadElement.dismiss());
    },
    _onRendererBlur: function _onRendererBlur(blurPath) {
        var _this = this;
        var blurringFocusPath = this._currentFocus;
        // Failsafe: abort if ID is different, because focus probably happened
        // before blur.
        if (!Util.inputPathsEqual(blurPath, blurringFocusPath)) return;
        // Wait until after any new focus events fire this tick before declaring
        // that nothing is focused, since if there were a focus change across
        // sections, we could receive the blur before the focus.
        setTimeout(function() {
            Util.inputPathsEqual(_this._currentFocus, blurringFocusPath) && _this._setCurrentFocus(null);
        });
    },
    blur: function blur() {
        if (this._currentFocus) {
            var _currentFocus2 = this._currentFocus, sectionRef = _currentFocus2[0], inputPath = _currentFocus2.slice(1);
            this.refs[sectionRef].blurPath(inputPath);
        }
    },
    _sections: function _sections() {
        return Array.isArray(this.props.json) ? this.props.json : [ this.props.json ];
    },
    render: function render() {
        var _classNames, _this2 = this;
        var apiOptions = _extends({}, ApiOptions.defaults, this.props.apiOptions, {
            isArticle: true
        });
        var classes = classNames((_classNames = {
            "framework-perseus": true,
            "perseus-article": true,
            "bibliotron-article": this.props.useNewStyles
        }, _classNames[ApiClassNames.MOBILE] = apiOptions.isMobile, _classNames));
        // TODO(alex): Add mobile api functions and pass them down here
        var sections = this._sections().map(function(section, i) {
            var refForSection = "section-" + i;
            return React.createElement("div", {
                key: i,
                className: "clearfix"
            }, React.createElement(Renderer, _extends({}, section, {
                ref: refForSection,
                key: i,
                key_: i,
                keypadElement: _this2.keypadElement(),
                apiOptions: _extends({}, apiOptions, {
                    onFocusChange: function onFocusChange(newFocusPath, oldFocusPath) {
                        // Prefix the paths with the relevant section,
                        // so as to allow us to distinguish between
                        // equivalently-named inputs across Renderers.
                        _this2._handleFocusChange(newFocusPath && [ refForSection ].concat(newFocusPath), oldFocusPath && [ refForSection ].concat(oldFocusPath));
                    }
                }),
                linterContext: Gorgon.pushContextStack(_this2.props.linterContext, "article"),
                legacyPerseusLint: _this2.props.legacyPerseusLint
            })));
        });
        return React.createElement("div", {
            className: classes
        }, sections);
    }
});

module.exports = ArticleRenderer;