var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var _KeyConfigs;

/**
 * This file contains configuration settings for the buttons in the keypad.
 */
/* globals i18n */
var Keys = require("../data/keys");

var _require = require("../consts"), DecimalSeparators = _require.DecimalSeparators, IconTypes = _require.IconTypes, KeyTypes = _require.KeyTypes;

var _require2 = require("../utils"), decimalSeparator = _require2.decimalSeparator;

var KeyConfigs = (_KeyConfigs = {}, _KeyConfigs[Keys.PLUS] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a plus sign.
    ariaLabel: i18n._("Plus")
}, _KeyConfigs[Keys.MINUS] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a minus sign.
    ariaLabel: i18n._("Minus")
}, _KeyConfigs[Keys.NEGATIVE] = {
    type: KeyTypes.VALUE,
    // I18N: A label for a minus sign.
    ariaLabel: i18n._("Negative")
}, _KeyConfigs[Keys.TIMES] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a multiplication sign (represented with an 'x').
    ariaLabel: i18n._("Multiply")
}, _KeyConfigs[Keys.DIVIDE] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a division sign.
    ariaLabel: i18n._("Divide")
}, _KeyConfigs[Keys.DECIMAL] = {
    type: KeyTypes.VALUE,
    // I18N: A label for a decimal symbol.
    ariaLabel: i18n._("Decimal"),
    icon: decimalSeparator === DecimalSeparators.COMMA ? {
        // TODO(charlie): Get an SVG icon for the comma, or verify with
        // design that the text-rendered version is acceptable.
        type: IconTypes.TEXT,
        data: ","
    } : {
        type: IconTypes.SVG,
        data: Keys.PERIOD
    }
}, _KeyConfigs[Keys.PERCENT] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a percent sign.
    ariaLabel: i18n._("Percent")
}, _KeyConfigs[Keys.CDOT] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a multiplication sign (represented as a dot).
    ariaLabel: i18n._("Multiply")
}, _KeyConfigs[Keys.EQUAL] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Equals sign")
}, _KeyConfigs[Keys.NEQ] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Not-equals sign")
}, _KeyConfigs[Keys.GT] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a 'greater than' sign (represented as '>').
    ariaLabel: i18n._("Greater than sign")
}, _KeyConfigs[Keys.LT] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a 'less than' sign (represented as '<').
    ariaLabel: i18n._("Less than sign")
}, _KeyConfigs[Keys.GEQ] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Greater than or equal to sign")
}, _KeyConfigs[Keys.LEQ] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Less than or equal to sign")
}, _KeyConfigs[Keys.FRAC_INCLUSIVE] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a button that creates a new fraction and puts the
    // current expression in the numerator of that fraction.
    ariaLabel: i18n._("Fraction, with current expression in numerator")
}, _KeyConfigs[Keys.FRAC_EXCLUSIVE] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a button that creates a new fraction next to the
    // cursor.
    ariaLabel: i18n._("Fraction, excluding the current expression")
}, _KeyConfigs[Keys.EXP] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a button that will allow the user to input a custom
    // exponent.
    ariaLabel: i18n._("Custom exponent")
}, _KeyConfigs[Keys.EXP_2] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a button that will square (take to the second
    // power) some math.
    ariaLabel: i18n._("Square")
}, _KeyConfigs[Keys.EXP_3] = {
    type: KeyTypes.OPERATOR,
    // I18N: A label for a button that will cube (take to the third power)
    // some math.
    ariaLabel: i18n._("Cube")
}, _KeyConfigs[Keys.SQRT] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Square root")
}, _KeyConfigs[Keys.CUBE_ROOT] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Cube root")
}, _KeyConfigs[Keys.RADICAL] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Radical with custom root")
}, _KeyConfigs[Keys.LEFT_PAREN] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Left parenthesis")
}, _KeyConfigs[Keys.RIGHT_PAREN] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Right parenthesis")
}, _KeyConfigs[Keys.LN] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Natural logarithm")
}, _KeyConfigs[Keys.LOG] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Logarithm with base 10")
}, _KeyConfigs[Keys.LOG_N] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Logarithm with custom base")
}, _KeyConfigs[Keys.SIN] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Sine")
}, _KeyConfigs[Keys.COS] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Cosine")
}, _KeyConfigs[Keys.TAN] = {
    type: KeyTypes.OPERATOR,
    ariaLabel: i18n._("Tangent")
}, _KeyConfigs[Keys.PI] = {
    type: KeyTypes.VALUE,
    ariaLabel: i18n._("Pi"),
    icon: {
        type: IconTypes.MATH,
        data: "\\pi"
    }
}, _KeyConfigs[Keys.THETA] = {
    type: KeyTypes.VALUE,
    ariaLabel: i18n._("Theta"),
    icon: {
        type: IconTypes.MATH,
        data: "\\theta"
    }
}, _KeyConfigs[Keys.NOOP] = {
    type: KeyTypes.EMPTY
}, _KeyConfigs[Keys.UP] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Up arrow")
}, _KeyConfigs[Keys.RIGHT] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Right arrow")
}, _KeyConfigs[Keys.DOWN] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Down arrow")
}, _KeyConfigs[Keys.LEFT] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Left arrow")
}, _KeyConfigs[Keys.JUMP_OUT_PARENTHESES] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right out of a set of parentheses")
}, _KeyConfigs[Keys.JUMP_OUT_EXPONENT] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right out of an exponent")
}, _KeyConfigs[Keys.JUMP_OUT_BASE] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right out of a base")
}, _KeyConfigs[Keys.JUMP_INTO_NUMERATOR] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right into the numerator of a fraction")
}, _KeyConfigs[Keys.JUMP_OUT_NUMERATOR] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right out of the numerator and into the denominator")
}, _KeyConfigs[Keys.JUMP_OUT_DENOMINATOR] = {
    type: KeyTypes.INPUT_NAVIGATION,
    ariaLabel: i18n._("Navigate right out of the denominator of a fraction")
}, _KeyConfigs[Keys.BACKSPACE] = {
    type: KeyTypes.INPUT_NAVIGATION,
    // I18N: A label for a button that will delete some input.
    ariaLabel: i18n._("Delete")
}, _KeyConfigs[Keys.DISMISS] = {
    type: KeyTypes.KEYPAD_NAVIGATION,
    // I18N: A label for a button that will dismiss/hide a keypad.
    ariaLabel: i18n._("Dismiss")
}, _KeyConfigs);

// Add in any multi-function buttons. By default, these keys will mix in any
// configuration settings from their default child key (i.e., the first key in
// the `childKeyIds` array).
// TODO(charlie): Make the multi-function button's long-press interaction
// accessible.
KeyConfigs[Keys.FRAC_MULTI] = {
    childKeyIds: [ Keys.FRAC_INCLUSIVE, Keys.FRAC_EXCLUSIVE ]
};

// TODO(charlie): Use the numeral color for the 'Many' key.
KeyConfigs[Keys.MANY] = {
    type: KeyTypes.MANY
};

// Add in every numeral.
var NUMBERS = [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 ];

for (var _iterator = NUMBERS, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator](); ;) {
    var _ref;
    if (_isArray) {
        if (_i >= _iterator.length) break;
        _ref = _iterator[_i++];
    } else {
        _i = _iterator.next();
        if (_i.done) break;
        _ref = _i.value;
    }
    var num = _ref;
    // TODO(charlie): Consider removing the SVG icons that we have for the
    // numeral keys. They can be rendered just as easily with text (though that
    // would mean that we'd be using text beyond the variable key).
    var textRepresentation = "" + num;
    KeyConfigs["NUM_" + num] = {
        type: KeyTypes.VALUE,
        ariaLabel: textRepresentation,
        icon: {
            type: IconTypes.TEXT,
            data: textRepresentation
        }
    };
}

// Add in every variable.
var LETTERS = [ "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z" ];

for (var _iterator2 = LETTERS, _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator](); ;) {
    var _ref2;
    if (_isArray2) {
        if (_i2 >= _iterator2.length) break;
        _ref2 = _iterator2[_i2++];
    } else {
        _i2 = _iterator2.next();
        if (_i2.done) break;
        _ref2 = _i2.value;
    }
    var letter = _ref2;
    var lowerCaseVariable = letter.toLowerCase();
    var upperCaseVariable = letter.toUpperCase();
    var _arr = [ lowerCaseVariable, upperCaseVariable ];
    for (var _i4 = 0; _i4 < _arr.length; _i4++) {
        var _textRepresentation = _arr[_i4];
        KeyConfigs[_textRepresentation] = {
            type: KeyTypes.VALUE,
            ariaLabel: _textRepresentation,
            icon: {
                type: IconTypes.MATH,
                data: _textRepresentation
            }
        };
    }
}

for (var _iterator3 = Object.keys(KeyConfigs), _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator](); ;) {
    var _ref3;
    if (_isArray3) {
        if (_i3 >= _iterator3.length) break;
        _ref3 = _iterator3[_i3++];
    } else {
        _i3 = _iterator3.next();
        if (_i3.done) break;
        _ref3 = _i3.value;
    }
    var key = _ref3;
    KeyConfigs[key] = _extends({
        id: key,
        // Default to an SVG icon indexed by the key name.
        icon: {
            type: IconTypes.SVG,
            data: key
        }
    }, KeyConfigs[key]);
}

module.exports = KeyConfigs;