var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

/* eslint-disable react/sort-comp */
var React = require("react");

var ReactDOM = require("react-dom");

var TextInput = React.createClass({
    displayName: "TextInput",
    propTypes: {
        value: React.PropTypes.string,
        onChange: React.PropTypes.func.isRequired,
        className: React.PropTypes.string,
        labelText: React.PropTypes.string,
        onFocus: React.PropTypes.func,
        onBlur: React.PropTypes.func,
        disabled: React.PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: "",
            disabled: false
        };
    },
    render: function render() {
        var _this = this;
        var _props = this.props, labelText = _props.labelText, props = _objectWithoutProperties(_props, [ "labelText" ]);
        return React.createElement("input", _extends({}, props, {
            type: "text",
            "aria-label": labelText,
            onChange: function onChange(e) {
                return _this.props.onChange(e.target.value);
            }
        }));
    },
    focus: function focus() {
        ReactDOM.findDOMNode(this).focus();
    },
    blur: function blur() {
        ReactDOM.findDOMNode(this).blur();
    },
    getValue: function getValue() {
        return ReactDOM.findDOMNode(this).value;
    },
    getStringValue: function getStringValue() {
        return ReactDOM.findDOMNode(this).value.toString();
    },
    setSelectionRange: function setSelectionRange(selectionStart, selectionEnd) {
        ReactDOM.findDOMNode(this).setSelectionRange(selectionStart, selectionEnd);
    },
    getSelectionStart: function getSelectionStart() {
        return ReactDOM.findDOMNode(this).selectionStart;
    },
    getSelectionEnd: function getSelectionEnd() {
        return ReactDOM.findDOMNode(this).selectionEnd;
    }
});

module.exports = TextInput;