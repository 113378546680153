var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "blockquoted-widget",
    severity: _rule2.default.Severity.WARNING,
    selector: "blockQuote widget",
    message: "Blockquoted widget:\nwidgets should not be indented."
});