var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var Movable = require("./interactive2/movable.js");

var MovablePoint = require("./interactive2/movable-point.jsx");

var MovableLine = require("./interactive2/movable-line.js");

var MovablePolygon = require("./interactive2/movable-polygon.js");

var KhanColors = require("./util/colors.js");

var Interactive2 = {
    MovablePoint: MovablePoint,
    addMovablePoint: function addMovablePoint(graphie, options) {
        var movable = new Movable(graphie, {});
        return new MovablePoint(graphie, movable, options);
    },
    MovableLine: MovableLine,
    addMovableLine: function addMovableLine(graphie, options) {
        var movable = new Movable(graphie, {});
        return new MovableLine(graphie, movable, options);
    },
    MovablePolygon: MovablePolygon,
    addMovablePolygon: function addMovablePolygon(graphie, options) {
        var movable = new Movable(graphie, {});
        return new MovablePolygon(graphie, movable, options);
    },
    addMaybeMobileMovablePoint: function addMaybeMobileMovablePoint(widget, extraProps) {
        var isMobile = widget.props.apiOptions.isMobile;
        var commonStyle = isMobile ? {
            stroke: "#ffffff",
            "stroke-width": 3,
            fill: KhanColors.INTERACTIVE
        } : {
            stroke: KhanColors.INTERACTIVE,
            fill: KhanColors.INTERACTIVE
        };
        var normalStyle = Object.assign(commonStyle, extraProps.normalStyle);
        var highlightStyle = Object.assign(isMobile ? _extends({}, commonStyle, {
            "stroke-width": 0,
            scale: .75
        }) : {}, extraProps.highlightStyle);
        var props = Object.assign({
            normalStyle: normalStyle,
            highlightStyle: highlightStyle,
            shadow: isMobile,
            tooltip: isMobile && widget.props.showTooltips,
            showHairlines: widget.showHairlines,
            hideHairlines: widget.hideHairlines
        }, isMobile ? {
            pointSize: 7
        } : {});
        return Interactive2.addMovablePoint(widget.graphie, Object.assign(extraProps, props));
    }
};

module.exports = Interactive2;