var _typeof = "function" === typeof Symbol && "symbol" === typeof Symbol.iterator ? function(obj) {
    return typeof obj;
} : function(obj) {
    return obj && "function" === typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

var babelPluginFlowReactPropTypes_proptype_ArrayShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ArrayShape || require("react").PropTypes.any;

/**
 * Utility functions for manipulating multi-item-shaped trees.
 *
 * Multi-items are trees! But we also often have other trees that are shaped
 * like multi-items - for example, if we map a multi-item tree into a tree of
 * renderer info and state, and then map that again into a tree of just the
 * renderer nodes, like we do in MultiRenderer. See tree-types.js for further
 * discussion.
 *
 * These functions enable us to manipulate generic multi-item-shaped trees,
 * regardless of what type of data they contain at their leaves. You can use
 * the mapper functions to transform a tree into another tree of the same
 * shape, or to discover all the nodes of a particular type.
 *
 * We expose two simple mapper functions (mapContentNodes and mapHintNodes),
 * and also a more complex interface for creating a mapping over all of a
 * tree's node types simultaneously:
 *
 * `buildMapper()` returns a TreeMapper object that allows you to build your
 * mapper object one node type at a time. Then, you can execute your mapping by
 * calling the `mapTree` method.
 *
 * For example:
 *     const renderers = buildMapper()
 *         .setContentMapper(this.renderContentNode)
 *         .setHintMapper(this.renderHintNode)
 *         .setTagsMapper(this.renderTagsNode)
 *         .setArrayMapper(this.hideSkippedQuestions)
 *         .mapTree(tree, shape);
 *
 * This will copy the given tree, apply the given transformations to the
 * content, hint, and array nodes respectively, and return the resulting tree.
 *
 * For node types whose mappers aren't specified, we default to the identity
 * function. (This builder interface enables us to implement that default
 * behavior in a provably type-safe way, while not requiring the call site to
 * be aware of all the node types. Hooray!)
 *
 * The call to `setArrayMapper` must come last, because the array mapper's
 * argument types depend on the other mappers' types. See ArrayMapper for more
 * details.
 *
 * WARNING: These functions trust that the provided tree conforms to the
 * provided shape. If not, behavior is undefined and may not respect the type
 * signatures specified here.
 */
var babelPluginFlowReactPropTypes_proptype_TagsShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_TagsShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_HintShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_HintShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ContentShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ContentShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Shape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_Shape || require("react").PropTypes.any;

/**
 * The sequence of edges that lead to a particular node in a Tree.
 * Elements can be `string` to correspond to an ObjectNode key, or `number` to
 * correspond to an ArrayNode index.
 */
var babelPluginFlowReactPropTypes_proptype_ObjectNode = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_ObjectNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ArrayNode = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_ArrayNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Tree = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_Tree || require("react").PropTypes.any;

/**
 * These are function interfaces for mapping over various types of tree nodes.
 *
 * ArrayMapper is a bit more complicated than the leaf node mappers. It's
 * executed in the context of a `mapTree` call, after we've finished mapping
 * its child nodes, so the function has access to both the resulting array
 * (with mapped elements) and the original array (with the original untouched
 * elements).
 *
 * The ArrayMapper then has the opportunity to apply a final transformation to
 * the resulting array, like filtering certain elements or (in the hacky
 * MultiRenderer case) attaching a `renderHints` method to arrays of hint
 * renderers :)
 *
 * This is why `TreeMapper#setArrayMapper` must be called last: ArrayMapper's
 * types depend on the ContentMapper and HintMapper's types. And, since you can
 * only specify one mapper at a time in this builder interface (which is
 * necessary to provide default mappers in a type-safe way), you need your
 * dependencies to already be in place by the time you call `setArrayMapper`.
 * Otherwise, we'd have to set the ArrayMapper and *hope* that you *eventually*
 * provide a compatible ContentMapper and HintMapper, which is difficult to
 * prove at compile time.
 *
 * There's no ObjectMapper here, but not for any particular reason. We just
 * don't have a use case for it yet, so we haven't built it yet.
 */
/**
 * A TreeMapper is a collection of node mappers, which, together, compose the
 * behavior for mapping over an entire tree.
 *
 * This serves as the interface for the two TreeMapper classes, including both
 * the internal mapper properties that we care about, and the `mapTree`
 * function that the call site will use.
 */
/**
 * This is a TreeMapper that only has mappers specified for its leaf nodes; its
 * array mapper is the identity function.
 *
 * This is the TreeMapper initially returned by `buildMapper`. It allows you to
 * change the types of your ContentMapper and HintMapper, which is safe because
 * none of the other mappers that depend on those types (aka ArrayMapper) have
 * been specified yet. (Or, more specifically, the ArrayMapper is currently
 * `identity`, which can trivially vary with the ContentMapper and HintMapper's
 * types.)
 *
 * Once you call `setArrayMapper`, however, we move to the other class:
 * TreeMapperForLeavesAndCollections.
 */
var TreeMapperJustForLeaves = function() {
    function TreeMapperJustForLeaves(content, hint, tags) {
        _classCallCheck(this, TreeMapperJustForLeaves);
        this.content = content;
        this.hint = hint;
        this.tags = tags;
        this.array = identity;
    }
    TreeMapperJustForLeaves.prototype.setContentMapper = function setContentMapper(newContentMapper) {
        return new TreeMapperJustForLeaves(newContentMapper, this.hint, this.tags);
    };
    TreeMapperJustForLeaves.prototype.setHintMapper = function setHintMapper(newHintMapper) {
        return new TreeMapperJustForLeaves(this.content, newHintMapper, this.tags);
    };
    TreeMapperJustForLeaves.prototype.setTagsMapper = function setTagsMapper(newTagsMapper) {
        return new TreeMapperJustForLeaves(this.content, this.hint, newTagsMapper);
    };
    TreeMapperJustForLeaves.prototype.setArrayMapper = function setArrayMapper(newArrayMapper) {
        return new TreeMapperForLeavesAndCollections(this.content, this.hint, this.tags, newArrayMapper);
    };
    TreeMapperJustForLeaves.prototype.mapTree = function mapTree(tree, shape) {
        return _mapTree(tree, shape, [], this);
    };
    return TreeMapperJustForLeaves;
}();

/**
 * This is a TreeMapper that already has an ArrayMapper specified, so its
 * ContentMapper and HintMapper are now locked in.
 */
var TreeMapperForLeavesAndCollections = function() {
    function TreeMapperForLeavesAndCollections(content, hint, tags, array) {
        _classCallCheck(this, TreeMapperForLeavesAndCollections);
        this.content = content;
        this.hint = hint;
        this.tags = tags;
        this.array = array;
    }
    TreeMapperForLeavesAndCollections.prototype.setArrayMapper = function setArrayMapper(newArrayMapper) {
        return new TreeMapperForLeavesAndCollections(this.content, this.hint, this.tags, newArrayMapper);
    };
    TreeMapperForLeavesAndCollections.prototype.mapTree = function mapTree(tree, shape) {
        return _mapTree(tree, shape, [], this);
    };
    return TreeMapperForLeavesAndCollections;
}();

function identity(x) {
    return x;
}

/**
 * Return a new TreeMapper that will perform a no-op transformation on an input
 * tree. To make it useful, chain any combination of `setContentMapper`,
 * `setHintMapper`, `setTagMapper`, and `setArrayMapper` to specify
 * transformations for the individual node types.
 */
function buildMapper() {
    return new TreeMapperJustForLeaves(identity, identity, identity);
}

/**
 * Copy the given tree, apply the corresponding transformation specified in the
 * TreeMapper to each node, and return the resulting tree.
 */
function _mapTree(tree, shape, path, mappers) {
    // We trust the shape of the multi-item to match the shape provided at
    // runtime. Therefore, in each shape branch, we cast the node to `any` and
    // reinterpret it as the expected node type.
    if ("content" === shape.type) {
        var _content = tree;
        return mappers.content(_content, shape, path);
    }
    if ("hint" === shape.type) {
        var _hint = tree;
        return mappers.hint(_hint, shape, path);
    }
    if ("tags" === shape.type) {
        var _tags = tree;
        return mappers.tags(_tags, shape, path);
    }
    if ("array" === shape.type) {
        var _array = tree;
        if (!Array.isArray(_array)) throw new Error('Invalid object of type "' + ("undefined" === typeof _array ? "undefined" : _typeof(_array)) + '" found at path ' + [ "<root>" ].concat(path).join(".") + ". Expected array.");
        var elementShape = shape.elementShape;
        var mappedElements = _array.map(function(inner, i) {
            return _mapTree(inner, elementShape, path.concat(i), mappers);
        });
        return mappers.array(mappedElements, _array, shape, path);
    }
    if ("object" === shape.type) {
        var object = tree;
        if (object && "object" !== ("undefined" === typeof object ? "undefined" : _typeof(object))) throw new Error('Invalid object of type "' + ("undefined" === typeof object ? "undefined" : _typeof(object)) + '" found at path ' + [ "<root>" ].concat(path).join(".") + '. Expected "object" type.');
        var valueShapes = shape.shape;
        if (!valueShapes) throw new Error("Unexpected shape " + JSON.stringify(shape) + " at path " + [ "<root>" ].concat(path).join(".") + ".");
        var newObject = {};
        Object.keys(valueShapes).forEach(function(key) {
            if (!(key in object)) throw new Error('Key "' + key + '" is missing from shape at path ' + [ "<root>" ].concat(path).join(".") + ".");
            newObject[key] = _mapTree(object[key], valueShapes[key], path.concat(key), mappers);
        });
        return newObject;
    }
    throw new Error("unexpected shape type " + shape.type);
}

module.exports = {
    buildMapper: buildMapper
};