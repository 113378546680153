function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A component that renders a single SVG icon.
 */
var React = require("react");

var Iconography = require("./iconography");

var SvgIcon = function(_React$Component) {
    _inherits(SvgIcon, _React$Component);
    function SvgIcon() {
        _classCallCheck(this, SvgIcon);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    SvgIcon.prototype.render = function render() {
        var _props = this.props, color = _props.color, name = _props.name;
        var SvgForName = Iconography[name];
        return React.createElement(SvgForName, {
            color: color
        });
    };
    return SvgIcon;
}(React.Component);

SvgIcon.propTypes = {
    color: React.PropTypes.string.isRequired,
    name: React.PropTypes.string.isRequired
};

module.exports = SvgIcon;