var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

var babelPluginFlowReactPropTypes_proptype_TagsNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_TagsNode || require("react").PropTypes.any;

/**
 * Main entry point to the MultiRenderer render portion.
 *
 * This file exposes the `MultiRenderer` component which performs
 * multi-rendering. To multi-render a question, pass in the content of the item
 * to the `MultiRenderer` component as a props. Then, pass in a function which
 * takes an object of renderers (in the same structure as the content), and
 * return a render tree. The `MultiRenderer` component will allow you to
 * combine scores, serialized state, etc. without having to manually call on
 * each of the functions. It also handles inter-widgets requests between the
 * different renderers.
 *
 * Example:
 *
 *   item = {_multi: {
 *       left: <content data>,
 *       right: [<content data>, <content data>],
 *   }}
 *   shape = shapes.shape({
 *       left: shapes.content,
 *       right: shapes.arrayOf(shapes.content),
 *   })
 *
 *   <MultiRenderer item={item} shape={shape}>
 *       {({renderers}) =>
 *           <div>
 *               <div id="left">{renderers.left}</div>
 *               <ul id="right">
 *                   {renderers.right.map(r => <li>{r}</li>)}
 *               </ul>
 *           </div>
 *       }
 *   </MultiRenderer>
 */
var babelPluginFlowReactPropTypes_proptype_HintNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_HintNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ContentNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_ContentNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Item = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_Item || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ArrayShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ArrayShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Shape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_Shape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Tree = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_Tree || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Path = require("./trees.js").babelPluginFlowReactPropTypes_proptype_Path || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_HintMapper = require("./trees.js").babelPluginFlowReactPropTypes_proptype_HintMapper || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ContentMapper = require("./trees.js").babelPluginFlowReactPropTypes_proptype_ContentMapper || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_TreeMapper = require("./trees.js").babelPluginFlowReactPropTypes_proptype_TreeMapper || require("react").PropTypes.any;

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var lens = require("../../hubble/index.js");

var React = require("react");

var _require2 = require("./items.js"), itemToTree = _require2.itemToTree;

var HintsRenderer = require("../hints-renderer.jsx");

var Renderer = require("../renderer.jsx");

var _require3 = require("./trees.js"), buildMapper = _require3.buildMapper;

var Util = require("../util.js");

// TODO(mdr)
// TODO(mdr)
// TODO(mdr)
// TODO(mdr)
// TODO(mdr)
// TODO(mdr)
var MultiRenderer = function(_React$Component) {
    _inherits(MultiRenderer, _React$Component);
    /* eslint-disable react/sort-comp */
    // TODO(mdr): Update the linter to allow property type declarations here.
    function MultiRenderer(props) {
        _classCallCheck(this, MultiRenderer);
        var _this = _possibleConstructorReturn(this, _React$Component.call(this, props));
        _this._handleSerializedStateUpdated = function(path, newState) {
            var onSerializedStateUpdated = _this.props.onSerializedStateUpdated;
            if (onSerializedStateUpdated) {
                var oldState = _this._getSerializedState(_this.props.serializedState);
                onSerializedStateUpdated(lens(oldState).set(path, newState).freeze());
            }
        };
        _this.rendererDataTreeMapper = buildMapper().setContentMapper(function(c, _, p) {
            return _this._makeContentRendererData(c, p);
        }).setHintMapper(function(h) {
            return _this._makeHintRendererData(h);
        }).setTagsMapper(function(t) {
            return null;
        });
        _this.getRenderersMapper = buildMapper().setContentMapper(function(c) {
            return c.makeRenderer();
        }).setHintMapper(function(h) {
            return h.makeRenderer();
        }).setArrayMapper(_this._annotateRendererArray.bind(_this));
        // Keep state in sync with props.
        _this.state = _this._tryMakeRendererState(_this.props);
        return _this;
    }
    /* eslint-enable react/sort-comp */
    MultiRenderer.prototype.componentWillReceiveProps = function componentWillReceiveProps(nextProps) {
        // Keep state in sync with props.
        nextProps.item !== this.props.item && this.setState(this._tryMakeRendererState(nextProps));
    };
    /**
     * Attempt to build a State that includes a renderer tree corresponding to
     * the item provided in props. On error, return a state with `renderError`
     * set instead.
     */
    MultiRenderer.prototype._tryMakeRendererState = function _tryMakeRendererState(props) {
        try {
            return {
                rendererDataTree: this._makeRendererDataTree(props.item, props.shape),
                renderError: null
            };
        } catch (e) {
            // NOTE(mdr): It's appropriate to log an error traceback in a
            //     caught error condition, and console.error is supported in
            //     all target browsers. Just do it, linter.
            // eslint-disable-next-line no-console
            console.error(e);
            return {
                rendererDataTree: null,
                renderError: e
            };
        }
    };
    /**
     * Props that aren't directly used by the MultiRenderer are delegated to
     * the underlying Renderers.
     */
    MultiRenderer.prototype._getRendererProps = function _getRendererProps() {
        /* eslint-disable no-unused-vars */
        // eslint is complaining that `item` and `children` are unused. I'm
        // explicitly pulling them out of `this.props` so I don't pass them to
        // `<Renderer>`. I'm not sure how else to do this.
        var _props = this.props, item = _props.item, children = _props.children, shape = _props.shape;
        /* eslint-enable no-unused-vars */
        return _objectWithoutProperties(_props, [ "item", "children", "shape" ]);
    };
    /**
     * Construct a Renderer and a ref placeholder for the given ContentNode.
     */
    MultiRenderer.prototype._makeContentRendererData = function _makeContentRendererData(content, path) {
        var _this2 = this;
        // NOTE(emily): The `findExternalWidgets` function here is computed
        //     inline and thus changes each time we run this function. If it
        //     were to change every render, it would cause the Renderer to
        //     re-render a lot more than is necessary. Don't re-compute this
        //     element unless it is necessary!
        // HACK(mdr): Flow can't prove that this is a ContentRendererData,
        //     because of how we awkwardly construct it in order to obtain a
        var data = {
            ref: null,
            makeRenderer: null
        };
        var refFunc = function refFunc(e) {
            return data.ref = e;
        };
        var findExternalWidgets = function findExternalWidgets(criterion) {
            return _this2._findWidgets(data, criterion);
        };
        var handleSerializedState = function handleSerializedState(state) {
            return _this2._handleSerializedStateUpdated(path, state);
        };
        data.makeRenderer = function() {
            return React.createElement(Renderer, _extends({}, _this2._getRendererProps(), content, {
                ref: refFunc,
                findExternalWidgets: findExternalWidgets,
                serializedState: _this2.props.serializedState ? lens(_this2.props.serializedState).get(path) : null,
                onSerializedStateUpdated: handleSerializedState
            }));
        };
        return data;
    };
    /**
     * Construct a Renderer for the given HintNode, and keep track of the hint
     * itself for future use, too.
     */
    MultiRenderer.prototype._makeHintRendererData = function _makeHintRendererData(hint) {
        var _this3 = this;
        // TODO(mdr): Once HintsRenderer supports inter-widget communication,
        //     give it a ref. Until then, leave the ref null forever, to avoid
        //     confusing the findWidgets functions.
        //
        // NOTE(davidflanagan): As a partial step toward inter-widget
        // communication we're going to pass a findExternalWidgets function
        // (using a dummy data object). This allows passage-ref widgets in
        // hints to use findWidget() to find the passage widgets they reference.
        // Note that this is one-way only, however. It does not allow
        // widgets in the question to find widgets in the hints, for example.
        var findExternalWidgets = function findExternalWidgets(criterion) {
            return _this3._findWidgets({}, criterion);
        };
        return {
            hint: hint,
            findExternalWidgets: findExternalWidgets,
            // _annotateRendererArray() needs this
            ref: null,
            makeRenderer: function makeRenderer() {
                return React.createElement(HintsRenderer, _extends({}, _this3._getRendererProps(), {
                    findExternalWidgets: findExternalWidgets,
                    hints: [ hint ]
                }));
            }
        };
    };
    /**
     * Construct a tree of interconnected RendererDatas, corresponding to the
     * given item. Called in `_tryMakeRendererState`, in order to store this
     * tree in the component state.
     */
    MultiRenderer.prototype._makeRendererDataTree = function _makeRendererDataTree(item, shape) {
        var itemTree = itemToTree(item);
        return this.rendererDataTreeMapper.mapTree(itemTree, shape);
    };
    /**
     * Return all widgets that meet the given criterion, from all Renderers
     * except the Renderer that triggered this call.
     *
     * This function is provided to each Renderer's `findExternalWidgets` prop,
     * which enables widgets in different Renderers to discover each other and
     * communicate.
     */
    MultiRenderer.prototype._findWidgets = function _findWidgets(callingData, filterCriterion) {
        var results = [];
        this._mapRenderers(function(data) {
            callingData !== data && data.ref && results.push.apply(results, data.ref.findInternalWidgets(filterCriterion));
        });
        return results;
    };
    /**
     * Copy the renderer tree, apply the given transformation to the leaf nodes
     * and the optional given transformation to the array nodes, and return the
     * result.
     *
     * Used to provide structured data to the call site (the Renderer tree on
     * `render`, the Score tree on `getScores`, etc.), and to traverse the
     * renderer tree even when we disregard the output (like in
     * `_findWidgets`).
     */
    MultiRenderer.prototype._mapRenderers = function _mapRenderers(leafMapper) {
        var rendererDataTree = this.state.rendererDataTree;
        if (!rendererDataTree) return null;
        return buildMapper().setContentMapper(leafMapper).setHintMapper(leafMapper).mapTree(rendererDataTree, this.props.shape);
    };
    MultiRenderer.prototype._scoreFromRef = function _scoreFromRef(ref) {
        if (!ref) return null;
        var _ref$guessAndScore = ref.guessAndScore(), guess = _ref$guessAndScore[0], score = _ref$guessAndScore[1];
        var state = void 0;
        ref.getSerializedState && (state = ref.getSerializedState());
        return Util.keScoreFromPerseusScore(score, guess, state);
    };
    /**
     * Return a tree in the shape of the multi-item, with scores at each of
     * the content nodes and `null` at the other leaf nodes.
     */
    MultiRenderer.prototype.getScores = function getScores() {
        var _this4 = this;
        return this._mapRenderers(function(data) {
            return _this4._scoreFromRef(data.ref);
        });
    };
    /**
     * Return a single composite score for all rendered content nodes.
     * The `guess` is a tree in the shape of the multi-item, with an individual
     * guess at each content node and `null` at the other leaf nodes.
     */
    MultiRenderer.prototype.score = function score() {
        var scores = [];
        var state = [];
        var guess = this._mapRenderers(function(data) {
            if (!data.ref) return null;
            data.ref.getSerializedState && state.push(data.ref.getSerializedState());
            scores.push(data.ref.score());
            return data.ref.getUserInput();
        });
        var combinedScore = scores.reduce(Util.combineScores);
        return Util.keScoreFromPerseusScore(combinedScore, guess, state);
    };
    /**
     * Return a tree in the shape of the multi-item, with serialized state at
     * each of the content nodes and `null` at the other leaf nodes.
     *
     * If the lastSerializedState argument is supplied, this function will fill
     * in the state of not-currently-rendered content and hint nodes with the
     * values from the previous serialized state. If no lastSerializedState is
     * supplied, `null` will be returned for not-currently-rendered content and
     * hint nodes.
     */
    MultiRenderer.prototype._getSerializedState = function _getSerializedState(lastSerializedState) {
        return this._mapRenderers(function(data, _, path) {
            return data.ref ? data.ref.getSerializedState() : lastSerializedState ? lens(lastSerializedState).get(path) : null;
        });
    };
    /**
     * Given a tree in the shape of the multi-item, with serialized state at
     * each of the content nodes, restore each state to the corresponding
     * renderer if currently mounted.
     */
    MultiRenderer.prototype.restoreSerializedState = function restoreSerializedState(serializedState, callback) {
        // We want to call our async callback only once all of the childrens'
        // callbacks have run. We add one to this counter before we call out to
        // each renderer and decrement it when it runs our callback.
        var numCallbacks = 0;
        var countCallback = function countCallback() {
            numCallbacks--;
            callback && 0 === numCallbacks && callback();
        };
        this._mapRenderers(function(data, _, path) {
            if (!data.ref) return;
            var state = lens(serializedState).get(path);
            if (!state) return;
            numCallbacks++;
            data.ref.restoreSerializedState(state, countCallback);
        });
    };
    /**
     * Given an array of renderers, if it happens to be an array of *hint*
     * renderers, then attach a `firstN` method to the array, which allows the
     * layout to render the hints together in one HintsRenderer.
     */
    MultiRenderer.prototype._annotateRendererArray = function _annotateRendererArray(renderers, rendererDatas, shape) {
        var _this5 = this;
        if ("hint" === shape.elementShape.type) {
            // The shape says that these are HintRendererDatas, even though
            var hintRendererDatas = rendererDatas;
            renderers = [].concat(renderers);
            renderers.firstN = function(n) {
                return React.createElement(HintsRenderer, _extends({}, _this5._getRendererProps(), {
                    findExternalWidgets: hintRendererDatas[0] ? hintRendererDatas[0].findExternalWidgets : void 0,
                    hints: hintRendererDatas.map(function(d) {
                        return d.hint;
                    }),
                    hintsVisible: n
                }));
            };
        }
        return renderers;
    };
    /**
     * Return a tree in the shape of the multi-item, with a Renderer at each
     * content node and a HintRenderer at each hint node.
     *
     * This is generated by running each of the `makeRenderer` functions at the
     * leaf nodes.
     */
    MultiRenderer.prototype._getRenderers = function _getRenderers() {
        return this.getRenderersMapper.mapTree(this.state.rendererDataTree, this.props.shape);
    };
    MultiRenderer.prototype.render = function render() {
        if (this.state.renderError) return React.createElement("div", {
            className: css(styles.error)
        }, "Error rendering: ", String(this.state.renderError));
        // Pass the renderer tree to the `children` function, which will
        // determine the actual content of this component.
        return this.props.children({
            renderers: this._getRenderers()
        });
    };
    return MultiRenderer;
}(React.Component);

MultiRenderer.propTypes = {
    item: babelPluginFlowReactPropTypes_proptype_Item,
    shape: babelPluginFlowReactPropTypes_proptype_Shape,
    children: require("react").PropTypes.func.isRequired,
    serializedState: babelPluginFlowReactPropTypes_proptype_Tree,
    onSerializedStateUpdated: require("react").PropTypes.func
};

var styles = StyleSheet.create({
    error: {
        color: "red"
    }
});

module.exports = MultiRenderer;