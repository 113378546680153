var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/**
 * A copy of the ItemRenderer which renders its question renderer and hints
 * renderer normally instead of ReactDOM.render()ing them into elements in the
 * DOM.
 *
 * This allows this component to be used in server-rendering of a perseus
 * exercise.
 */
var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var ApiOptions = require("./perseus-api.jsx").Options;

var HintsRenderer = require("./hints-renderer.jsx");

var ProvideKeypad = require("./mixins/provide-keypad.jsx");

var Renderer = require("./renderer.jsx");

var Util = require("./util.js");

var _require2 = require("./interactive2/objective_.js"), mapObject = _require2.mapObject;

var RP = React.PropTypes;

var ItemRenderer = React.createClass({
    displayName: "ItemRenderer",
    propTypes: _extends({}, ProvideKeypad.propTypes, {
        apiOptions: RP.any,
        hintsVisible: RP.number,
        item: RP.shape({
            answerArea: RP.shape({
                calculator: RP.bool,
                chi2Table: RP.bool,
                periodicTable: RP.bool,
                tTable: RP.bool,
                zTable: RP.bool
            }),
            hints: RP.arrayOf(RP.object),
            question: RP.object
        }).isRequired,
        problemNum: RP.number,
        reviewMode: RP.bool
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            apiOptions: {}
        };
    },
    getInitialState: function getInitialState() {
        return _extends({}, ProvideKeypad.getInitialState(), {
            questionCompleted: false,
            questionHighlightedWidgets: []
        });
    },
    componentDidMount: function componentDidMount() {
        ProvideKeypad.componentDidMount.call(this);
        this._currentFocus = null;
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        this.setState({
            questionHighlightedWidgets: []
        });
    },
    componentDidUpdate: function componentDidUpdate() {
        if (this.props.apiOptions.answerableCallback) {
            var isAnswerable = 0 === this.questionRenderer.emptyWidgets().length;
            this.props.apiOptions.answerableCallback(isAnswerable);
        }
    },
    componentWillUnmount: function componentWillUnmount() {
        ProvideKeypad.componentWillUnmount.call(this);
    },
    keypadElement: function keypadElement() {
        return ProvideKeypad.keypadElement.call(this);
    },
    _handleFocusChange: function _handleFocusChange(newFocus, oldFocus) {
        null != newFocus ? this._setCurrentFocus(newFocus) : this._onRendererBlur(oldFocus);
    },
    // Sets the current focus path and element and
    // send an onChangeFocus event back to our parent.
    _setCurrentFocus: function _setCurrentFocus(newFocus) {
        var _this = this;
        var keypadElement = this.keypadElement();
        // By the time this happens, newFocus cannot be a prefix of
        // prevFocused, since we must have either been called from
        // an onFocusChange within a renderer, which is only called when
        // this is not a prefix, or between the question and answer areas,
        // which can never prefix each other.
        var prevFocus = this._currentFocus;
        this._currentFocus = newFocus;
        // Determine whether the newly focused path represents an input.
        var inputPaths = this.getInputPaths();
        var didFocusInput = this._currentFocus && inputPaths.some(function(inputPath) {
            return Util.inputPathsEqual(inputPath, _this._currentFocus);
        });
        null != this.props.apiOptions.onFocusChange && this.props.apiOptions.onFocusChange(this._currentFocus, prevFocus, didFocusInput && keypadElement && ReactDOM.findDOMNode(keypadElement));
        keypadElement && (didFocusInput ? keypadElement.activate() : keypadElement.dismiss());
    },
    _onRendererBlur: function _onRendererBlur(blurPath) {
        var _this2 = this;
        var blurringFocusPath = this._currentFocus;
        // Failsafe: abort if ID is different, because focus probably happened
        // before blur
        if (!_.isEqual(blurPath, blurringFocusPath)) return;
        // Wait until after any new focus events fire this tick before
        // declaring that nothing is focused.
        // If a different widget was focused, we'll see an onBlur event
        // now, but then an onFocus event on a different element before
        // this callback is executed
        _.defer(function() {
            _.isEqual(_this2._currentFocus, blurringFocusPath) && _this2._setCurrentFocus(null);
        });
    },
    /**
     * Accepts a question area widgetId, or an answer area widgetId of
     * the form "answer-input-number 1", or the string "answer-area"
     * for the whole answer area (if the answer area is a single widget).
     */
    _setWidgetProps: function _setWidgetProps(widgetId, newProps, callback) {
        this.questionRenderer._setWidgetProps(widgetId, newProps, callback);
    },
    _handleAPICall: function _handleAPICall(functionName, path) {
        // Get arguments to pass to function, including `path`
        var functionArgs = _.rest(arguments);
        var caller = this.questionRenderer;
        return caller[functionName].apply(caller, functionArgs);
    },
    setInputValue: function setInputValue(path, newValue, focus) {
        return this._handleAPICall("setInputValue", path, newValue, focus);
    },
    focusPath: function focusPath(path) {
        return this._handleAPICall("focusPath", path);
    },
    blurPath: function blurPath(path) {
        return this._handleAPICall("blurPath", path);
    },
    getDOMNodeForPath: function getDOMNodeForPath(path) {
        return this._handleAPICall("getDOMNodeForPath", path);
    },
    getGrammarTypeForPath: function getGrammarTypeForPath(path) {
        return this._handleAPICall("getGrammarTypeForPath", path);
    },
    getInputPaths: function getInputPaths() {
        return this.questionRenderer.getInputPaths();
    },
    handleInteractWithWidget: function handleInteractWithWidget(widgetId) {
        var withRemoved = _.difference(this.state.questionHighlightedWidgets, [ widgetId ]);
        this.setState({
            questionCompleted: false,
            questionHighlightedWidgets: withRemoved
        });
        this.props.apiOptions.interactionCallback && this.props.apiOptions.interactionCallback();
    },
    focus: function focus() {
        return this.questionRenderer.focus();
    },
    blur: function blur() {
        this._currentFocus && this.blurPath(this._currentFocus);
    },
    getNumHints: function getNumHints() {
        return this.props.item.hints.length;
    },
    /**
     * Grades the item.
     *
     * Returns a KE-style score of {
     *     empty: bool,
     *     correct: bool,
     *     message: string|null,
     *     guess: Array
     * }
     */
    scoreInput: function scoreInput() {
        var guessAndScore = this.questionRenderer.guessAndScore();
        var guess = guessAndScore[0];
        var score = guessAndScore[1];
        // Continue to include an empty guess for the now defunct answer area.
        // TODO(alex): Check whether we rely on the format here for
        //             analyzing ProblemLogs. If not, remove this layer.
        var maxCompatGuess = [ guess, [] ];
        var keScore = Util.keScoreFromPerseusScore(score, maxCompatGuess, this.questionRenderer.getSerializedState());
        var emptyQuestionAreaWidgets = this.questionRenderer.emptyWidgets();
        this.setState({
            questionCompleted: keScore.correct,
            questionHighlightedWidgets: emptyQuestionAreaWidgets
        });
        return keScore;
    },
    /**
     * Returns an array of all widget IDs in the order they occur in
     * the question content.
     */
    getWidgetIds: function getWidgetIds() {
        return this.questionRenderer.getWidgetIds();
    },
    /**
     * Returns an object mapping from widget ID to KE-style score.
     * The keys of this object are the values of the array returned
     * from `getWidgetIds`.
     */
    scoreWidgets: function scoreWidgets() {
        var qScore = this.questionRenderer.scoreWidgets();
        var qGuess = this.questionRenderer.getUserInputForWidgets();
        var state = this.questionRenderer.getSerializedState();
        return mapObject(qScore, function(score, id) {
            return Util.keScoreFromPerseusScore(score, qGuess[id], state);
        });
    },
    /**
     * Get a representation of the current state of the item.
     */
    getSerializedState: function getSerializedState() {
        return {
            question: this.questionRenderer.getSerializedState(),
            hints: this.hintsRenderer.getSerializedState()
        };
    },
    restoreSerializedState: function restoreSerializedState(state, callback) {
        // We need to wait for both the question renderer and the hints
        // renderer to finish restoring their states.
        var numCallbacks = 2;
        var fireCallback = function fireCallback() {
            --numCallbacks;
            callback && 0 === numCallbacks && callback();
        };
        this.questionRenderer.restoreSerializedState(state.question, fireCallback);
        this.hintsRenderer.restoreSerializedState(state.hints, fireCallback);
    },
    showRationalesForCurrentlySelectedChoices: function showRationalesForCurrentlySelectedChoices() {
        this.questionRenderer.showRationalesForCurrentlySelectedChoices();
    },
    deselectIncorrectSelectedChoices: function deselectIncorrectSelectedChoices() {
        this.questionRenderer.deselectIncorrectSelectedChoices();
    },
    render: function render() {
        var _this3 = this;
        var apiOptions = _extends({}, ApiOptions.defaults, this.props.apiOptions, {
            onFocusChange: this._handleFocusChange
        });
        var questionRenderer = React.createElement(Renderer, _extends({
            keypadElement: this.keypadElement(),
            problemNum: this.props.problemNum,
            onInteractWithWidget: this.handleInteractWithWidget,
            highlightedWidgets: this.state.questionHighlightedWidgets,
            apiOptions: apiOptions,
            questionCompleted: this.state.questionCompleted,
            reviewMode: this.props.reviewMode,
            ref: function ref(elem) {
                return _this3.questionRenderer = elem;
            }
        }, this.props.item.question));
        var hintsRenderer = React.createElement(HintsRenderer, {
            hints: this.props.item.hints,
            hintsVisible: this.props.hintsVisible,
            apiOptions: apiOptions,
            ref: function ref(elem) {
                return _this3.hintsRenderer = elem;
            }
        });
        return React.createElement("div", null, React.createElement("div", null, questionRenderer), React.createElement("div", {
            className: // Avoid adding any horizontal padding when applying the
            // mobile hint styles, which are flush to the left.
            // NOTE(charlie): We may still want to apply this
            // padding for desktop exercises.
            !apiOptions.isMobile && css(styles.hintsContainer)
        }, hintsRenderer));
    }
});

var styles = StyleSheet.create({
    hintsContainer: {
        marginLeft: 50
    }
});

module.exports = ItemRenderer;