/**
 * Main entry point
 */
var version = require("./version.json");

var Widgets = require("./widgets.js");

var basicWidgets = require("./basic-widgets.js");

Widgets.registerMany(basicWidgets);

module.exports = {
    apiVersion: version.apiVersion,
    itemDataVersion: version.itemDataVersion,
    init: require("./init.js"),
    ArticleRenderer: require("./article-renderer.jsx"),
    ItemRenderer: require("./item-renderer.jsx"),
    ServerItemRenderer: require("./server-item-renderer.jsx"),
    HintsRenderer: require("./hints-renderer.jsx"),
    Renderer: require("./renderer.jsx"),
    MultiItems: require("./multi-items.js")
};