/**
 * In review mode (currently only visible in the sat-mission), NumericInput and
 * InputNumber use this component to display the set of correct answers.
 */
var React = require("react");

var _ = require("underscore");

var PossibleAnswers = React.createClass({
    displayName: "PossibleAnswers",
    propTypes: {
        answers: React.PropTypes.arrayOf(React.PropTypes.string)
    },
    render: function render() {
        // It's redundant to show duplicate answers.
        // So, remove duplicates from the given list of answer strings.
        var answers = _.uniq(this.props.answers);
        var answerComponents = _.map(answers, function(answer) {
            // Plus, now that our answers are distinct, we can safely use the
            // answer string as a key.
            return React.createElement("dd", {
                key: answer
            }, answer);
        });
        return React.createElement("dl", {
            className: "perseus-possible-answers"
        }, React.createElement("dt", null, "Correct Answer"), answerComponents);
    }
});

module.exports = PossibleAnswers;