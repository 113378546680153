var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A keypad button containing no symbols and triggering no actions on click.
 */
var React = require("react");

var _require = require("react-redux"), connect = _require.connect;

var GestureManager = require("./gesture-manager");

var KeyConfigs = require("../data/key-configs");

var KeypadButton = require("./keypad-button");

var EmptyKeypadButton = function(_React$Component) {
    _inherits(EmptyKeypadButton, _React$Component);
    function EmptyKeypadButton() {
        _classCallCheck(this, EmptyKeypadButton);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    EmptyKeypadButton.prototype.render = function render() {
        var _props = this.props, gestureManager = _props.gestureManager, rest = _objectWithoutProperties(_props, [ "gestureManager" ]);
        // Register touch events on the button, but don't register its DOM node
        // or compute focus state or anything like that. We want the gesture
        // manager to know about touch events that start on empty buttons, but
        // we don't need it to know about their DOM nodes, as it doesn't need
        // to focus them or trigger presses.
        return React.createElement(KeypadButton, _extends({
            onTouchStart: function onTouchStart(evt) {
                return gestureManager.onTouchStart(evt);
            },
            onTouchEnd: function onTouchEnd(evt) {
                return gestureManager.onTouchEnd(evt);
            },
            onTouchMove: function onTouchMove(evt) {
                return gestureManager.onTouchMove(evt);
            },
            onTouchCancel: function onTouchCancel(evt) {
                return gestureManager.onTouchCancel(evt);
            }
        }, KeyConfigs.NOOP, rest));
    };
    return EmptyKeypadButton;
}(React.Component);

EmptyKeypadButton.propTypes = {
    gestureManager: React.PropTypes.instanceOf(GestureManager)
};

var mapStateToProps = function mapStateToProps(state) {
    return {
        gestureManager: state.gestures.gestureManager
    };
};

module.exports = connect(mapStateToProps)(EmptyKeypadButton);