function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * Renders text indicating whether the choice was correct or
 * not and whether the choice was selected or not.
 * This information is redundant with that provided in the
 * ChoiceIcon visualizations but is meant to make the distinctions
 * between the states more immediately clear to users.
 */
/* globals i18n */
var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var styleConstants = require("../../styles/constants.js");

var OptionStatus = function(_React$Component) {
    _inherits(OptionStatus, _React$Component);
    function OptionStatus() {
        _classCallCheck(this, OptionStatus);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    OptionStatus.prototype.render = function render() {
        var _props = this.props, checked = _props.checked, correct = _props.correct;
        var correctness = correct ? i18n._("correct") : i18n._("incorrect");
        var selectedness = checked ? i18n._("(selected)") : "";
        var text = correctness + " " + selectedness;
        var textStyle = correct ? styles.correct : styles.incorrect;
        return React.createElement("div", {
            className: css(styles.text, textStyle)
        }, text);
    };
    return OptionStatus;
}(React.Component);

OptionStatus.propTypes = {
    correct: require("react").PropTypes.bool.isRequired,
    checked: require("react").PropTypes.bool.isRequired
};

var styles = StyleSheet.create({
    text: {
        alignItems: "center",
        display: "flex",
        fontSize: 12,
        height: 32,
        textTransform: "uppercase"
    },
    correct: {
        color: styleConstants.kaGreen
    },
    incorrect: {
        color: styleConstants.warning1
    }
});

module.exports = OptionStatus;