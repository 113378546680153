// Generated by running:
// `node less-to-js.js 'stylesheets/exercise-content-package/variables.less'`
module.exports = {
    // @baseFontFamily:        "Proxima Nova", sans-serif;
    baseFontFamily: "'Proxima Nova',sans-serif",
    // @boldFontFamily:        "Proxima Nova Semibold", sans-serif;
    boldFontFamily: "'Proxima Nova Semibold',sans-serif",
    // @green:                 #76a005;
    green: "#76A005",
    // @kaGreen:               #71b307;
    kaGreen: "#71B307",
    // @blue:                  #1c758a;
    blue: "#1C758A",
    // @gray:                  #aaa;
    gray: "#AAAAAA",
    // @red:                   #ffbaba;
    red: "#FFBABA",
    // @questionWidth:         480px;
    questionWidth: "480px",
    // @grayLight:             #aaa;
    grayLight: "#AAAAAA",
    // @grayLighter:           #ddd;
    grayLighter: "#DDDDDD",
    // @learnstormBlue:        #4898fc;
    learnstormBlue: "#4898FC",
    white: "#FFFFFF",
    gray98: "#FAFAFA",
    gray97: "#F6F7F7",
    gray95: "#F0F1F2",
    gray90: "#E3E5E6",
    gray85: "#D6D8DA",
    gray76: "#BABEC2",
    gray68: "#888D93",
    gray41: "#626569",
    gray25: "#3B3e40",
    gray17: "#21242c",
    black: "#000000",
    warning1: "#F86700",
    warning3: "#C75300",
    // @pure-sm-min: 568px;
    pureSmMin: "568px",
    // @pure-md-min: 768px;
    pureMdMin: "768px",
    // @pure-lg-min: 1024px;
    pureLgMin: "1024px",
    // @pure-xl-min: 1280px;
    pureXlMin: "1280px",
    // @pure-xs-max: (@pure-sm-min - 1);
    pureXsMax: "567px",
    // @pure-sm-max: (@pure-md-min - 1);
    pureSmMax: "767px",
    // @pure-md-max: (@pure-lg-min - 1);
    pureMdMax: "1023px",
    // @pure-lg-max: (@pure-xl-min - 1);
    pureLgMax: "1279px",
    // @tableBackgroundAccent: #f9f9f9; // for striping
    tableBackgroundAccent: "#F9F9F9",
    gtpBlue: "#4c00ff",
    gtpIncorrectColor: "#babec2",
    gtpCorrectColor: "#ffbe26",
    // @satBlue:               #0084ce;
    satBlue: "#0084CE",
    // @satSelectedBackgroundColor: #e4f3f9;
    satSelectedBackgroundColor: "#E4F3F9",
    // @satActiveBackgroundColor: #d0edf4;
    satActiveBackgroundColor: "#D0EDF4",
    // @satCorrectColor:       #009900;
    satCorrectColor: "#009900",
    // @satCorrectBorderColor: #00cc00;
    satCorrectBorderColor: "#00CC00",
    // @satCorrectBackgroundColor: #e4f7e4;
    satCorrectBackgroundColor: "#E4F7E4",
    // @satIncorrectColor:     #990000;
    satIncorrectColor: "#990000",
    // @satIncorrectBorderColor: #cc5252;
    satIncorrectBorderColor: "#CC5252",
    // @satIncorrectBackgroundColor: #f2ebeb;
    satIncorrectBackgroundColor: "#F2EBEB",
    // @zIndexScratchPad: 1;
    zIndexScratchPad: "1",
    // @zIndexAboveScratchpad: @zIndexScratchPad + 1;
    zIndexAboveScratchpad: "2",
    // @zIndexInteractiveComponent: @zIndexAboveScratchpad + 1;
    zIndexInteractiveComponent: "3",
    // @zIndexCurrentlyDragging: @zIndexInteractiveComponent + 1;
    zIndexCurrentlyDragging: "4",
    // @zIndexCalculator: @zIndexCurrentlyDragging + 1;
    zIndexCalculator: "5",
    // @phoneMargin: 16px;
    phoneMargin: 16,
    negativePhoneMargin: -16,
    hintBorderWidth: 4,
    // The 'base unit' -- our new typography and layout styles are defined in
    // terms of multiples of the 'base unit'.
    baseUnitPx: 16,
    interactiveSizes: {
        defaultBoxSize: 400,
        defaultBoxSizeSmall: 288
    },
    circleSize: 24,
    radioMarginWidth: 2,
    warningColor: "#f86700",
    warningColorHover: "#df5c00",
    warningColorActive: "#c75300",
    publishBlockingErrorColor: "#be2612"
};

module.exports.radioBorderColor = module.exports.gray76;

module.exports.checkedColor = module.exports.kaGreen;