var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

// Because no selector is specified, this rule only applies to text nodes.
// Math and code hold their content directly and do not have text nodes
// beneath them (unlike the HTML DOM) so this rule automatically does not
// apply inside $$ or ``.
module.exports = _rule2.default.makeRule({
    name: "math-without-dollars",
    severity: _rule2.default.Severity.GUIDELINE,
    pattern: /\\\w+{[^}]*}|{|}/,
    message: "This looks like LaTeX:\ndid you mean to put it inside dollar signs?"
});