/* eslint-disable react/prop-types, react/sort-comp */
var React = require("react");

var _ = require("underscore");

var TeX = require("react-components/tex.jsx");

var prettyBig = {
    fontSize: "150%"
};

var slightlyBig = {
    fontSize: "120%"
};

var symbStyle = {
    fontSize: "130%"
};

// These are functions because we want to generate a new component for each use
// on the page rather than reusing an instance (which will cause an error).
// Also, it's useful for things which might look different depending on the
// props.
var basic = [ function() {
    return [ React.createElement("span", {
        key: "plus",
        style: slightlyBig
    }, "+"), "+" ];
}, function() {
    return [ React.createElement("span", {
        key: "minus",
        style: prettyBig
    }, "-"), "-" ];
}, // TODO(joel) - display as \cdot when appropriate
function(props) {
    return props.convertDotToTimes ? [ React.createElement(TeX, {
        key: "times",
        style: prettyBig
    }, "\\times"), "\\times" ] : [ React.createElement(TeX, {
        key: "times",
        style: prettyBig
    }, "\\cdot"), "\\cdot" ];
}, function() {
    return [ React.createElement(TeX, {
        key: "frac",
        style: prettyBig
    }, "\\frac{□}{□}"), // If there's something in the input that can become part of a
    // fraction, typing "/" puts it in the numerator. If not, typing
    // "/" does nothing. In that case, enter a \frac.
    function(input) {
        var contents = input.latex();
        input.typedText("/");
        input.latex() === contents && input.cmd("\\frac");
    } ];
} ];

var buttonSets = {
    basic: basic,
    "basic+div": basic.concat([ function() {
        return [ React.createElement(TeX, {
            key: "div"
        }, "\\div"), "\\div" ];
    } ]),
    trig: [ function() {
        return [ React.createElement(TeX, {
            key: "sin"
        }, "\\sin"), "\\sin" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "cos"
        }, "\\cos"), "\\cos" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "tan"
        }, "\\tan"), "\\tan" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "theta",
            style: symbStyle
        }, "\\theta"), "\\theta" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "pi",
            style: symbStyle
        }, "\\phi"), "\\phi" ];
    } ],
    prealgebra: [ function() {
        return [ React.createElement(TeX, {
            key: "sqrt"
        }, "\\sqrt{x}"), "\\sqrt" ];
    }, // TODO(joel) - how does desmos do this?
    function() {
        return [ React.createElement(TeX, {
            key: "nthroot"
        }, "\\sqrt[3]{x}"), function(input) {
            input.typedText("nthroot3");
            input.keystroke("Right");
        } ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "pow",
            style: slightlyBig
        }, "□^a"), function(input) {
            var contents = input.latex();
            input.typedText("^");
            // If the input hasn't changed (for example, if we're
            // attempting to add an exponent on an empty input or an empty
            // denominator), insert our own "a^b"
            input.latex() === contents && input.typedText("a^b");
        } ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "pi",
            style: slightlyBig
        }, "\\pi"), "\\pi" ];
    } ],
    logarithms: [ function() {
        return [ React.createElement(TeX, {
            key: "log"
        }, "\\log"), "\\log" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "ln"
        }, "\\ln"), "\\ln" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "log_b"
        }, "\\log_b"), function(input) {
            input.typedText("log_");
            input.keystroke("Right");
            input.typedText("(");
            input.keystroke("Left");
            input.keystroke("Left");
        } ];
    } ],
    "basic relations": [ function() {
        return [ React.createElement(TeX, {
            key: "eq"
        }, "="), "=" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "lt"
        }, "\\lt"), "\\lt" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "gt"
        }, "\\gt"), "\\gt" ];
    } ],
    "advanced relations": [ function() {
        return [ React.createElement(TeX, {
            key: "neq"
        }, "\\neq"), "\\neq" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "leq"
        }, "\\leq"), "\\leq" ];
    }, function() {
        return [ React.createElement(TeX, {
            key: "geq"
        }, "\\geq"), "\\geq" ];
    } ]
};

var buttonSetsType = React.PropTypes.arrayOf(React.PropTypes.oneOf(_(buttonSets).keys()));

var TexButtons = React.createClass({
    displayName: "TexButtons",
    propTypes: {
        sets: buttonSetsType.isRequired,
        onInsert: React.PropTypes.func.isRequired
    },
    render: function render() {
        var _this = this;
        // Always show buttonSets in the same order. Note: Technically it's ok
        // for _.keys() to return the keys in an arbitrary order, but in
        // practice, they will be ordered as listed above.
        var sortedButtonSets = _.sortBy(this.props.sets, function(setName) {
            return _.keys(buttonSets).indexOf(setName);
        });
        var buttons = _(sortedButtonSets).map(function(setName) {
            return buttonSets[setName];
        });
        var buttonRows = _(buttons).map(function(row) {
            return row.map(function(symbGen) {
                // create a (component, thing we should send to mathquill) pair
                var symbol = symbGen(_this.props);
                return React.createElement("button", {
                    onClick: function onClick() {
                        return _this.props.onInsert(symbol[1]);
                    },
                    className: "tex-button",
                    key: symbol[0].key,
                    tabIndex: -1,
                    type: "button"
                }, symbol[0]);
            });
        });
        var buttonPopup = _(buttonRows).map(function(row, i) {
            return React.createElement("div", {
                className: "clearfix tex-button-row",
                key: _this.props.sets[i]
            }, row);
        });
        return React.createElement("div", {
            className: this.props.className + " preview-measure"
        }, buttonPopup);
    },
    statics: {
        buttonSets: buttonSets,
        buttonSetsType: buttonSetsType
    }
});

module.exports = TexButtons;