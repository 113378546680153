/**
 * Default methods for a wrapped movable.
 */
var _ = require("underscore");

var InteractiveUtil = require("./interactive-util.js");

var objective_ = require("./objective_.js");

var kvector = require("kmath").vector;

/*
 * These functions, when called on the wrapped object, simply pass the
 * arguments to the underlying Raphael object.
 */
var PASS_TO_RAPHAEL = [ "attr", "animate" ];

var WrappedDefaults = _.extend({
    transform: function transform(transformation) {
        var prefixedTransform = InteractiveUtil.getPrefixedTransform();
        this.wrapper.style[prefixedTransform] = transformation;
    },
    toFront: function toFront() {
        var parentNode = this.wrapper.parentNode;
        // TODO(emily): Sometimes, we call `.remove()` but then hold a
        // reference to this object, and sometimes call `.toFront` on it.
        // Notably, this happens in the reflection transformation in the
        // Transformer widget. This is a hacky fix. Make this less bad.
        parentNode && parentNode.appendChild(this.wrapper);
    },
    toBack: function toBack() {
        var parentNode = this.wrapper.parentNode;
        parentNode.firstChild !== this.wrapper && parentNode.insertBefore(this.wrapper, parentNode.firstChild);
    },
    remove: function remove() {
        this.visibleShape.remove();
        $(this.wrapper).remove();
    },
    getMouseTarget: function getMouseTarget() {
        return this.visibleShape[0];
    },
    moveTo: function moveTo(point) {
        var delta = kvector.subtract(this.graphie.scalePoint(point), this.graphie.scalePoint(this.initialPoint));
        var do3dTransform = InteractiveUtil.getCanUse3dTransform();
        var transformation = "translateX(" + delta[0] + "px) translateY(" + delta[1] + "px)" + (do3dTransform ? " translateZ(0)" : "");
        this.transform(transformation);
    },
    hide: function hide() {
        this.visibleShape.hide();
    },
    show: function show() {
        this.visibleShape.show();
    }
}, objective_.mapObjectFromArray(PASS_TO_RAPHAEL, function(attribute) {
    return function() {
        var _visibleShape;
        (_visibleShape = this.visibleShape)[attribute].apply(_visibleShape, arguments);
    };
}));

module.exports = WrappedDefaults;