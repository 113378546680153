var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A component that renders a keypad button.
 */
var React = require("react");

var _require = require("react-redux"), connect = _require.connect;

var _require2 = require("aphrodite"), StyleSheet = _require2.StyleSheet, css = _require2.css;

var _require3 = require("../fake-react-native-web"), View = _require3.View;

var Icon = require("./icon");

var MultiSymbolGrid = require("./multi-symbol-grid");

var CornerDecal = require("./corner-decal");

var _require4 = require("../consts"), KeyTypes = _require4.KeyTypes, BorderDirections = _require4.BorderDirections, BorderStyles = _require4.BorderStyles;

var _require5 = require("./common-style"), brightGreen = _require5.brightGreen, innerBorderColor = _require5.innerBorderColor, innerBorderStyle = _require5.innerBorderStyle, innerBorderWidthPx = _require5.innerBorderWidthPx, valueGrey = _require5.valueGrey, operatorGrey = _require5.operatorGrey, controlGrey = _require5.controlGrey, emptyGrey = _require5.emptyGrey;

var _require6 = require("./prop-types"), bordersPropType = _require6.bordersPropType, iconPropType = _require6.iconPropType, keyConfigPropType = _require6.keyConfigPropType;

var KeypadButton = function(_React$PureComponent) {
    _inherits(KeypadButton, _React$PureComponent);
    function KeypadButton() {
        var _temp, _this, _ret;
        _classCallCheck(this, KeypadButton);
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$PureComponent.call.apply(_React$PureComponent, [ this ].concat(args))), 
        _this), _this._preInjectStyles = function() {
            // HACK(charlie): Pre-inject all of the possible styles for the button.
            // This avoids a flickering effect in the echo animation whereby the
            // echoes vary in size as they animate. Note that we need to account for
            // the "initial" styles that `View` will include, as these styles are
            // applied to `View` components and Aphrodite will consolidate the style
            // object. This method must be called whenever a property that
            // influences the possible outcomes of `this._getFocusStyle` and
            // `this._getButtonStyle` changes (such as `this.buttonSizeStyle`).
            for (var _iterator = Object.keys(KeyTypes), _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator](); ;) {
                var _ref;
                if (_isArray) {
                    if (_i >= _iterator.length) break;
                    _ref = _iterator[_i++];
                } else {
                    _i = _iterator.next();
                    if (_i.done) break;
                    _ref = _i.value;
                }
                var type = _ref;
                css.apply(void 0, [ View.styles.initial ].concat(_this._getFocusStyle(type)));
                for (var _iterator2 = Object.values(BorderStyles), _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator](); ;) {
                    var _ref2;
                    if (_isArray2) {
                        if (_i2 >= _iterator2.length) break;
                        _ref2 = _iterator2[_i2++];
                    } else {
                        _i2 = _iterator2.next();
                        if (_i2.done) break;
                        _ref2 = _i2.value;
                    }
                    var borders = _ref2;
                    css.apply(void 0, [ View.styles.initial ].concat(_this._getButtonStyle(type, borders)));
                }
            }
        }, _this._getFocusStyle = function(type) {
            var focusBackgroundStyle = void 0;
            focusBackgroundStyle = type === KeyTypes.INPUT_NAVIGATION || type === KeyTypes.KEYPAD_NAVIGATION ? styles.light : styles.bright;
            return [ styles.focusBox, focusBackgroundStyle ];
        }, _this._getButtonStyle = function(type, borders, style) {
            // Select the appropriate style for the button.
            var backgroundStyle = void 0;
            switch (type) {
              case KeyTypes.EMPTY:
                backgroundStyle = styles.empty;
                break;

              case KeyTypes.MANY:
              case KeyTypes.VALUE:
                backgroundStyle = styles.value;
                break;

              case KeyTypes.OPERATOR:
                backgroundStyle = styles.operator;
                break;

              case KeyTypes.INPUT_NAVIGATION:
              case KeyTypes.KEYPAD_NAVIGATION:
                backgroundStyle = styles.control;
                break;

              case KeyTypes.ECHO:
                backgroundStyle = null;
            }
            var borderStyle = [];
            -1 !== borders.indexOf(BorderDirections.LEFT) && borderStyle.push(styles.leftBorder);
            -1 !== borders.indexOf(BorderDirections.BOTTOM) && borderStyle.push(styles.bottomBorder);
            return [ styles.buttonBase, backgroundStyle ].concat(borderStyle, [ type === KeyTypes.ECHO && styles.echo, _this.buttonSizeStyle ], Array.isArray(style) ? style : [ style ]);
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }
    KeypadButton.prototype.componentWillMount = function componentWillMount() {
        this.buttonSizeStyle = styleForButtonDimensions(this.props.heightPx, this.props.widthPx);
    };
    KeypadButton.prototype.componentDidMount = function componentDidMount() {
        this._preInjectStyles();
    };
    KeypadButton.prototype.componentWillUpdate = function componentWillUpdate(newProps, newState) {
        // Only recompute the Aphrodite StyleSheet when the button height has
        // changed. Though it is safe to recompute the StyleSheet (since
        // they're content-addressable), it saves us a bunch of hashing and
        // other work to cache it here.
        if (newProps.heightPx !== this.props.heightPx || newProps.widthPx !== this.props.widthPx) {
            this.buttonSizeStyle = styleForButtonDimensions(newProps.heightPx, newProps.widthPx);
            this._preInjectStyles();
        }
    };
    KeypadButton.prototype.render = function render() {
        var _props = this.props, ariaLabel = _props.ariaLabel, borders = _props.borders, childKeys = _props.childKeys, disabled = _props.disabled, focused = _props.focused, icon = _props.icon, onTouchCancel = _props.onTouchCancel, onTouchEnd = _props.onTouchEnd, onTouchMove = _props.onTouchMove, onTouchStart = _props.onTouchStart, popoverEnabled = _props.popoverEnabled, style = _props.style, type = _props.type;
        // We render in the focus state if the key is focused, or if it's an
        // echo.
        var renderFocused = !disabled && focused || popoverEnabled || type === KeyTypes.ECHO;
        var buttonStyle = this._getButtonStyle(type, borders, style);
        var focusStyle = this._getFocusStyle(type);
        var iconWrapperStyle = [ styles.iconWrapper, disabled && styles.disabled ];
        var eventHandlers = {
            onTouchCancel: onTouchCancel,
            onTouchEnd: onTouchEnd,
            onTouchMove: onTouchMove,
            onTouchStart: onTouchStart
        };
        var maybeFocusBox = renderFocused && React.createElement(View, {
            style: focusStyle
        });
        var maybeCornerDecal = !renderFocused && !disabled && childKeys && childKeys.length > 0 && React.createElement(CornerDecal, {
            style: styles.decalInset
        });
        if (type === KeyTypes.EMPTY) return React.createElement(View, _extends({
            style: buttonStyle
        }, eventHandlers));
        if (type === KeyTypes.MANY) {
            // TODO(charlie): Make the long-press interaction accessible. See
            // the TODO in key-configs.js for more.
            var manyButtonA11yMarkup = {
                role: "button",
                ariaLabel: childKeys[0].ariaLabel
            };
            var icons = childKeys.map(function(keyConfig) {
                return keyConfig.icon;
            });
            return React.createElement(View, _extends({
                style: buttonStyle
            }, eventHandlers, manyButtonA11yMarkup), maybeFocusBox, React.createElement(View, {
                style: iconWrapperStyle
            }, React.createElement(MultiSymbolGrid, {
                icons: icons,
                focused: renderFocused
            })), maybeCornerDecal);
        }
        var a11yMarkup = {
            role: "button",
            ariaLabel: ariaLabel
        };
        return React.createElement(View, _extends({
            style: buttonStyle
        }, eventHandlers, a11yMarkup), maybeFocusBox, React.createElement(View, {
            style: iconWrapperStyle
        }, React.createElement(Icon, {
            icon: icon,
            focused: renderFocused
        })), maybeCornerDecal);
    };
    return KeypadButton;
}(React.PureComponent);

KeypadButton.propTypes = {
    ariaLabel: React.PropTypes.string,
    // The borders to display on the button. Typically, this should be set
    // using one of the preset `BorderStyles` options.
    borders: bordersPropType,
    // Any additional keys that can be accessed by long-pressing on the
    // button.
    childKeys: React.PropTypes.arrayOf(keyConfigPropType),
    // Whether the button should be rendered in a 'disabled' state, i.e.,
    // without any touch feedback.
    disabled: React.PropTypes.bool,
    focused: React.PropTypes.bool,
    heightPx: React.PropTypes.number.isRequired,
    icon: iconPropType,
    onTouchCancel: React.PropTypes.func,
    onTouchEnd: React.PropTypes.func,
    onTouchMove: React.PropTypes.func,
    onTouchStart: React.PropTypes.func,
    popoverEnabled: React.PropTypes.bool,
    style: React.PropTypes.any,
    type: React.PropTypes.oneOf(Object.keys(KeyTypes)).isRequired,
    // NOTE(charlie): We may want to make this optional for phone layouts
    // (and rely on Flexbox instead), since it might not be pixel perfect
    // with borders and such.
    widthPx: React.PropTypes.number.isRequired
};

KeypadButton.defaultProps = {
    borders: BorderStyles.ALL,
    childKeys: [],
    disabled: false,
    focused: false,
    popoverEnabled: false
};

var focusInsetPx = 4;

var focusBoxZIndex = 0;

var styles = StyleSheet.create({
    buttonBase: {
        // HACK(benkomalo): support old style flex box in Android browsers
        "-webkit-box-flex": "1",
        flex: 1,
        cursor: "pointer",
        // Make the text unselectable
        userSelect: "none",
        justifyContent: "center",
        alignItems: "center",
        // Borders are made selectively visible.
        borderColor: innerBorderColor,
        borderStyle: innerBorderStyle,
        boxSizing: "border-box"
    },
    decalInset: {
        top: focusInsetPx,
        right: focusInsetPx
    },
    // Overrides for the echo state, where we want to render the borders for
    // layout purposes, but we don't want them to be visible.
    echo: {
        borderColor: "transparent"
    },
    // Background colors and other base styles that may vary between key types.
    value: {
        backgroundColor: valueGrey
    },
    operator: {
        backgroundColor: operatorGrey
    },
    control: {
        backgroundColor: controlGrey
    },
    empty: {
        backgroundColor: emptyGrey,
        cursor: "default"
    },
    bright: {
        backgroundColor: brightGreen
    },
    light: {
        backgroundColor: "rgba(33, 36, 44, 0.1)"
    },
    iconWrapper: {
        zIndex: focusBoxZIndex + 1
    },
    focusBox: {
        position: "absolute",
        zIndex: focusBoxZIndex,
        left: focusInsetPx,
        right: focusInsetPx,
        bottom: focusInsetPx,
        top: focusInsetPx,
        borderRadius: 1
    },
    disabled: {
        opacity: .3
    },
    // Styles used to render the appropriate borders. Buttons are only allowed
    // to render left and bottom borders, to simplify layout.
    leftBorder: {
        borderLeftWidth: innerBorderWidthPx
    },
    bottomBorder: {
        borderBottomWidth: innerBorderWidthPx
    }
});

var styleForButtonDimensions = function styleForButtonDimensions(heightPx, widthPx) {
    return StyleSheet.create({
        buttonSize: {
            height: heightPx,
            width: widthPx,
            maxWidth: widthPx
        }
    }).buttonSize;
};

var mapStateToProps = function mapStateToProps(state) {
    return state.layout.buttonDimensions;
};

module.exports = connect(mapStateToProps)(KeypadButton);