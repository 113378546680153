/**
 * Utility functions for writing Interactive2 movablethings
 */
var _ = require("underscore");

var MovableHelperMethods = require("./movable-helper-methods.js");

/**
 * Compute the correct vendor-prefixed `transform`.
 */
var prefixedTransform = null;

function computePrefixedTransform() {
    // Temporary element for testing prefix validity
    var el = document.createElement("div");
    var prefixes = [ "transform", "msTransform", "MozTransform", "WebkitTransform", "OTransform" ];
    var correctPrefix = null;
    _.each(prefixes, function(prefix) {
        "undefined" !== typeof el.style[prefix] && (correctPrefix = prefix);
    });
    return correctPrefix;
}

/**
 * Compute whether the browser can use 3d transforms by trying to use the
 * translateZ transformation.
 */
var canUse3dTransform = null;

function computeCanUse3dTransform() {
    var el = document.createElement("div");
    var prefix = InteractiveUtil.getPrefixedTransform();
    el.style[prefix] = "translateZ(0px)";
    return !!el.style[prefix];
}

var InteractiveUtil = {
    assert: function assert(isTrue, message) {
        if (!isTrue) throw new Error("Assertion Error" + (message ? ": " + message : ""));
    },
    /**
     * Create getters for this.state, based on the default state, `defaults`
     */
    createGettersFor: function createGettersFor(Class, defaults) {
        _.each(_.keys(defaults), function(key) {
            void 0 === Class.prototype[key] && (Class.prototype[key] = function() {
                return this.state[key];
            });
        });
    },
    /**
     * Add MovableHelperMethods methods to a MovableThing class
     */
    addMovableHelperMethodsTo: function addMovableHelperMethodsTo(Class) {
        _.each(MovableHelperMethods, function(methodFunc, methodName) {
            void 0 === Class.prototype[methodName] && (Class.prototype[methodName] = methodFunc);
        });
    },
    /**
     * Turn a function or an array of functions into an array of functions
     */
    arrayify: function arrayify(funcOrArray) {
        return null == funcOrArray ? [] : _.isArray(funcOrArray) ? _.filter(_.flatten(funcOrArray), _.identity) : [ funcOrArray ];
    },
    /**
     * Convert all function-or-array arguments to arrays of functions
     */
    normalizeOptions: function normalizeOptions(arrayOptionNames, options) {
        // TODO(jack): Having to clone here is annoying; this
        // function should really just modify this.state in place
        // (and maybe be a function on MovableHelperMethods to get access
        // to this.state), which would also be nicer because we could
        // normalizeOptions once in this.modify
        var result = _.clone(options);
        _.each(arrayOptionNames, function(eventName) {
            var funcOrArray = options[eventName];
            // Only propagate values which were set; not present values
            // shouldn't be added to options because we'd like them to
            // fall through to defaults
            if (void 0 !== funcOrArray) {
                var funcArray = InteractiveUtil.arrayify(funcOrArray);
                result[eventName] = funcArray;
            }
        });
        return result;
    },
    /**
     * Get the correct vendor-prefixed `transform`.
     */
    getPrefixedTransform: function getPrefixedTransform() {
        // Cache result to avoid re-computation
        prefixedTransform = prefixedTransform || computePrefixedTransform();
        return prefixedTransform;
    },
    /**
     * Get whether the browser can use 3d transforms.
     */
    getCanUse3dTransform: function getCanUse3dTransform() {
        null == canUse3dTransform && (canUse3dTransform = computeCanUse3dTransform());
        return canUse3dTransform;
    }
};

module.exports = InteractiveUtil;