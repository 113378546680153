/**
 * These tools allow you to construct arbirtary shapes, by combining simple
 * leaf shapes like `content` and `hint` into composite shapes like
 * `arrayOf(shape({question: content, hints: arrayOf(hint)}))`.
 */
var babelPluginFlowReactPropTypes_proptype_ObjectShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ObjectShape || require("react").PropTypes.any;

/**
 * Utility functions for constructing and inferring multi-item shapes.
 *
 * A shape is an object that serves as a runtime type declaration: it specifies
 * a tree structure for a particular class of multi-item. See shape-types.js
 * for further discussion.
 *
 * This module allows you to construct arbitrary Shape trees, by combining
 * leaf node shapes like `content` and `hint` into composite shapes like
 * `arrayOf(shape({foo: content, bar: hint}))`.
 */
var babelPluginFlowReactPropTypes_proptype_ArrayShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ArrayShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_TagsShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_TagsShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_HintShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_HintShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ContentShape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_ContentShape || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Shape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_Shape || require("react").PropTypes.any;

var contentShape = {
    type: "content"
};

var hintShape = {
    type: "hint"
};

var tagsShape = {
    type: "tags"
};

var buildArrayShape = function buildArrayShape(elementShape) {
    return {
        type: "array",
        elementShape: elementShape
    };
};

var buildObjectShape = function buildObjectShape(shape) {
    return {
        type: "object",
        shape: shape
    };
};

var hintsShape = buildArrayShape(hintShape);

module.exports = {
    content: contentShape,
    hint: hintShape,
    hints: hintsShape,
    tags: tagsShape,
    arrayOf: buildArrayShape,
    shape: buildObjectShape
};