var _mobileHintStylesHint, _mobileHintStylesGetA, _mobileHintStylesPlus;

var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var React = require("react");

var ReactDOM = require("react-dom");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var classnames = require("classnames");

var _ = require("underscore");

var HintRenderer = require("./hint-renderer.jsx");

var SvgImage = require("./components/svg-image.jsx");

var ApiOptionsProps = require("./mixins/api-options-props.js");

var mediaQueries = require("./styles/media-queries.js");

var sharedStyles = require("./styles/shared.js");

var _require2 = require("./styles/constants.js"), baseUnitPx = _require2.baseUnitPx, hintBorderWidth = _require2.hintBorderWidth, kaGreen = _require2.kaGreen, gray85 = _require2.gray85, gray17 = _require2.gray17;

var Gorgon = require("./gorgon/gorgon.js");

var _require3 = require("./gorgon/proptypes.js"), linterContextProps = _require3.linterContextProps, linterContextDefault = _require3.linterContextDefault;

var HintsRenderer = React.createClass({
    displayName: "HintsRenderer",
    propTypes: _extends({}, ApiOptionsProps.propTypes, {
        className: React.PropTypes.string,
        hints: React.PropTypes.arrayOf(React.PropTypes.any),
        hintsVisible: React.PropTypes.number,
        findExternalWidgets: React.PropTypes.func,
        linterContext: linterContextProps
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            linterContext: linterContextDefault
        };
    },
    componentDidMount: function componentDidMount() {
        this._cacheHintImages();
    },
    componentDidUpdate: function componentDidUpdate(prevProps, prevState) {
        _.isEqual(prevProps.hints, this.props.hints) && prevProps.hintsVisible === this.props.hintsVisible || this._cacheHintImages();
        // When a new hint is displayed we immediately focus it
        if (prevProps.hintsVisible < this.props.hintsVisible) {
            var pos = this.props.hintsVisible - 1;
            ReactDOM.findDOMNode(this.refs["hintRenderer" + pos]).focus();
        }
    },
    _hintsVisible: function _hintsVisible() {
        return null == this.props.hintsVisible || -1 === this.props.hintsVisible ? this.props.hints.length : this.props.hintsVisible;
    },
    _cacheImagesInHint: function _cacheImagesInHint(hint) {
        _.each(hint.images, function(data, src) {
            new Image().src = SvgImage.getRealImageUrl(src);
        });
    },
    _cacheHintImages: function _cacheHintImages() {
        // Only cache images in the first hint at the start. When hints are
        // taken, cache images in the rest of the hints
        this._hintsVisible() > 0 ? _.each(this.props.hints, this._cacheImagesInHint) : this.props.hints.length > 0 && this._cacheImagesInHint(this.props.hints[0]);
    },
    getApiOptions: function getApiOptions() {
        return ApiOptionsProps.getApiOptions.call(this);
    },
    getSerializedState: function getSerializedState() {
        var _this = this;
        return _.times(this._hintsVisible(), function(i) {
            return _this.refs["hintRenderer" + i].getSerializedState();
        });
    },
    restoreSerializedState: function restoreSerializedState(state, callback) {
        var _this2 = this;
        // We need to wait until all the renderers are finished restoring their
        // state before we fire our callback.
        var numCallbacks = 1;
        var fireCallback = function fireCallback() {
            --numCallbacks;
            callback && 0 === numCallbacks && callback();
        };
        _.each(state, function(hintState, i) {
            var hintRenderer = _this2.refs["hintRenderer" + i];
            // This is not ideal in that it doesn't restore state
            // if the hint isn't visible, but we can't exactly restore
            // the state to an unmounted renderer, so...
            // If you want to restore state to hints, make sure to
            // have the appropriate number of hints visible already.
            if (hintRenderer) {
                ++numCallbacks;
                hintRenderer.restoreSerializedState(hintState, fireCallback);
            }
        });
        // This makes sure that the callback is fired if there aren't any
        // mounted renderers.
        fireCallback();
    },
    render: function render() {
        var _this3 = this;
        var apiOptions = this.getApiOptions();
        var hintsVisible = this._hintsVisible();
        var hints = [];
        this.props.hints.slice(0, hintsVisible).forEach(function(hint, i) {
            var lastHint = i === _this3.props.hints.length - 1 && !/\*\*/.test(hint.content);
            var lastRendered = i === hintsVisible - 1;
            var renderer = React.createElement(HintRenderer, {
                lastHint: lastHint,
                lastRendered: lastRendered,
                hint: hint,
                pos: i,
                totalHints: _this3.props.hints.length,
                ref: "hintRenderer" + i,
                key: "hintRenderer" + i,
                apiOptions: apiOptions,
                findExternalWidgets: _this3.props.findExternalWidgets,
                linterContext: Gorgon.pushContextStack(_this3.props.linterContext, "hints[" + i + "]")
            });
            hint.replace && hints.length > 0 ? hints[hints.length - 1] = renderer : hints.push(renderer);
        });
        var showGetAnotherHint = apiOptions.getAnotherHint && hintsVisible > 0 && hintsVisible < this.props.hints.length;
        var hintRatioCopy = "(" + hintsVisible + "/" + this.props.hints.length + ")";
        var classNames = classnames(this.props.className, apiOptions.isMobile && hintsVisible > 0 && css(styles.mobileHintStylesHintsRenderer));
        return React.createElement("div", {
            className: classNames
        }, apiOptions.isMobile && hintsVisible > 0 && React.createElement("div", {
            className: css(styles.mobileHintStylesHintTitle, sharedStyles.responsiveLabel)
        }, i18n._("Hints")), hints, showGetAnotherHint && React.createElement("button", {
            rel: "button",
            className: css(styles.linkButton, styles.getAnotherHintButton, apiOptions.isMobile && styles.mobileHintStylesGetAnotherHintButton),
            onClick: function onClick(evt) {
                evt.preventDefault();
                evt.stopPropagation();
                apiOptions.getAnotherHint();
            }
        }, React.createElement("span", {
            className: css(styles.plusText, apiOptions.isMobile && styles.mobileHintStylesPlusText)
        }, "+"), React.createElement("span", {
            className: css(styles.getAnotherHintText)
        }, i18n._("Get another hint"), " ", hintRatioCopy)));
    }
});

var hintIndentation = baseUnitPx + hintBorderWidth;

var styles = StyleSheet.create({
    rendererMargins: {
        marginTop: baseUnitPx
    },
    linkButton: {
        cursor: "pointer",
        border: "none",
        backgroundColor: "transparent",
        fontSize: "100%",
        fontFamily: "inherit",
        fontWeight: "bold",
        color: kaGreen,
        padding: 0,
        position: "relative"
    },
    plusText: {
        fontSize: 20,
        position: "absolute",
        top: -3,
        left: 0
    },
    getAnotherHintText: {
        marginLeft: 16
    },
    mobileHintStylesHintsRenderer: {
        marginTop: 4 * baseUnitPx,
        border: "solid " + gray85,
        borderWidth: "1px 0 0 0",
        position: "relative",
        ":before": {
            content: '""',
            display: "table",
            clear: "both"
        },
        ":after": {
            content: '""',
            display: "table",
            clear: "both"
        }
    },
    mobileHintStylesHintTitle: (_mobileHintStylesHint = {
        fontFamily: "inherit",
        fontStyle: "normal",
        fontWeight: "bold",
        color: gray17,
        paddingTop: baseUnitPx,
        paddingBottom: 1.5 * baseUnitPx
    }, _mobileHintStylesHint[mediaQueries.lgOrSmaller] = {
        paddingLeft: 0
    }, _mobileHintStylesHint[mediaQueries.smOrSmaller] = {
        // On phones, ensure that the button is aligned with the hint body
        // content, which is inset at the standard `baseUnitPx`, plus an
        // additional `hintBorderWidth`.
        paddingLeft: hintIndentation
    }, _mobileHintStylesHint),
    getAnotherHintButton: {
        marginTop: 1.5 * baseUnitPx
    },
    mobileHintStylesGetAnotherHintButton: (_mobileHintStylesGetA = {}, _mobileHintStylesGetA[mediaQueries.lgOrSmaller] = {
        paddingLeft: 0
    }, _mobileHintStylesGetA[mediaQueries.smOrSmaller] = {
        // As with the title, on phones, ensure that the button is aligned
        // with the hint body content.
        paddingLeft: hintIndentation
    }, _mobileHintStylesGetA),
    mobileHintStylesPlusText: (_mobileHintStylesPlus = {}, _mobileHintStylesPlus[mediaQueries.lgOrSmaller] = {
        left: 0
    }, _mobileHintStylesPlus[mediaQueries.smOrSmaller] = {
        left: hintIndentation
    }, _mobileHintStylesPlus)
});

module.exports = HintsRenderer;