var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "heading-level-1",
    severity: _rule2.default.Severity.WARNING,
    selector: "heading",
    lint: function lint(state, content, nodes, match) {
        if (1 === nodes[0].level) return "Don't use level-1 headings:\nBegin headings with two or more # characters.";
    }
});