/**
 * An autogenerated component that renders the JUMP_OUT_EXPONENT iconograpy in SVG.
 *
 * Generated with: https://gist.github.com/crm416/3c7abc88e520eaed72347af240b32590.
 */
var React = require("react");

var JumpOutExponent = function JumpOutExponent() {
    return React.createElement("svg", {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48"
    }, React.createElement("g", {
        fill: "none",
        fillRule: "evenodd"
    }, React.createElement("path", {
        fill: "none",
        d: "M0 0h48v48H0z"
    }), React.createElement("path", {
        fill: "none",
        d: "M12 12h24v24H12z"
    }), React.createElement("path", {
        d: "M35 19v16M23 19l8 8M31 23v4h-4",
        stroke: "#78C008",
        strokeWidth: "2",
        strokeLinecap: "round",
        strokeLinejoin: "round"
    }), React.createElement("path", {
        d: "M12 12.997c0-.55.453-.997.997-.997h6.006c.55 0 .997.453.997.997v6.006c0 .55-.453.997-.997.997h-6.006c-.55 0-.997-.453-.997-.997v-6.006zM14 14h4v4h-4v-4z",
        fill: "#888D93"
    })));
};

module.exports = JumpOutExponent;