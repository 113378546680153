var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "long-paragraph",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "paragraph",
    pattern: /^.{501,}/,
    lint: function lint(state, content, nodes, match) {
        return "Paragraph too long:\nThis paragraph is " + content.length + " characters long.\nShorten it to 500 characters or fewer.";
    }
});