var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "image-spaces-around-urls",
    severity: _rule2.default.Severity.ERROR,
    selector: "image",
    lint: function lint(state, content, nodes, match, context) {
        var image = nodes[0];
        var url = image.target;
        // The markdown parser strips leading and trailing spaces for us,
        // but they're still a problem for our translation process, so
        // we need to go check for them in the unparsed source string
        // if we have it.
        if (context && context.content) {
            // Find the url in the original content and make sure that the
            // character before is '(' and the character after is ')'
            var index = context.content.indexOf(url);
            if (-1 === index) // It is not an error if we didn't find it.
            return;
            if ("(" !== context.content[index - 1] || ")" !== context.content[index + url.length]) return "Whitespace before or after image url:\nFor images, don't include any space or newlines after '(' or before ')'.\nWhitespace in image URLs causes translation difficulties.";
        }
    }
});