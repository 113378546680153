var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "math-starts-with-space",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "math, blockMath",
    pattern: /^\s*(~|\\qquad|\\quad|\\,|\\;|\\:|\\ |\\!|\\enspace|\\phantom)/,
    message: "Math starts with space:\nmath should not be indented. Do not begin math expressions with\nLaTeX space commands like ~, \\;, \\quad, or \\phantom"
});