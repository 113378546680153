var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/**
 * Zooms child to fit with tap-to-zoom behavior.
 */
var React = require("react");

var ReactDOM = require("react-dom");

var Deferred = require("../deferred.js");

var Zoomable = React.createClass({
    displayName: "Zoomable",
    propTypes: {
        animateHeight: React.PropTypes.bool,
        children: React.PropTypes.element.isRequired,
        /**
         * Optional function that allows customizations in zooming.
         *
         * Defaults to just using the bounding client rect of the first DOM
         * element of this component.
         *
         * @return {Object} bounds object with `width` and `height` properties
         */
        computeChildBounds: React.PropTypes.func,
        // If this prop is specified, we wait until the deferred is resolved
        // before measuring the child element.  This is necessary in cases
        // where the child size depends on whether or not resources, such as
        // fonts, have been loaded.
        readyToMeasureDeferred: React.PropTypes.shape({
            then: React.PropTypes.func.isRequired,
            reject: React.PropTypes.func.isRequired
        }).isRequired
    },
    getDefaultProps: function getDefaultProps() {
        var deferred = new Deferred();
        deferred.resolve();
        return {
            animateHeight: false,
            readyToMeasureDeferred: deferred,
            computeChildBounds: function computeChildBounds(parentNode) {
                var firstChild = parentNode.firstElementChild;
                return {
                    // The +1 is a fudge factor to make sure any border on the
                    // content isn't clipped by the the container it's in.
                    width: firstChild.offsetWidth + 1,
                    height: firstChild.offsetHeight + 1
                };
            }
        };
    },
    getInitialState: function getInitialState() {
        return {
            visible: false,
            marginBottomPx: 0,
            zoomed: true
        };
    },
    componentDidMount: function componentDidMount() {
        var _this = this;
        this._node = ReactDOM.findDOMNode(this);
        this.props.readyToMeasureDeferred.then(function() {
            if (_this.isMounted()) {
                _this.scaleChildToFit(false);
                if (window.MutationObserver) {
                    _this._observer = new MutationObserver(function(mutations) {
                        if (_this.isMounted()) for (var _iterator = mutations, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator](); ;) {
                            var _ref;
                            if (_isArray) {
                                if (_i >= _iterator.length) break;
                                _ref = _iterator[_i++];
                            } else {
                                _i = _iterator.next();
                                if (_i.done) break;
                                _ref = _i.value;
                            }
                            var mutation = _ref;
                            if (mutation.target !== _this._node) {
                                // Only act on mutations of children
                                _this.scaleChildToFit(_this.state.zoomed);
                                break;
                            }
                        }
                    });
                    _this._observer.observe(_this._node, {
                        childList: true,
                        subtree: true,
                        attributes: true
                    });
                }
                window.addEventListener("resize", _this.reset);
            }
        });
    },
    componentWillUnmount: function componentWillUnmount() {
        window.removeEventListener("resize", this.reset);
        this._observer && this._observer.disconnect();
    },
    reset: function reset() {
        var _this2 = this;
        if (!this.isMounted()) return;
        if (!this.state.visible) return;
        this._originalWidth = null;
        this.setState({
            visible: false,
            compactHeight: null,
            expandedHeight: null,
            zoomed: true
        }, function() {
            _this2.scaleChildToFit(false);
        });
    },
    stopPropagationIfZoomed: function stopPropagationIfZoomed(e) {
        this.state.zoomed || // We only allow touch events (which trigger interactive elements)
        // to be propagated to children if we are already zoomed.
        e.stopPropagation();
    },
    // TODO(benkomalo): call this on viewport width changes?
    // https://github.com/Khan/math-input/blob/master/src/components/math-keypad.js#L43
    scaleChildToFit: function scaleChildToFit(zoomed) {
        var _this3 = this;
        var parentBounds = {
            width: this._node.offsetWidth,
            height: this._node.offsetHeight
        };
        var childBounds = this.props.computeChildBounds(this._node, parentBounds);
        var childWidth = childBounds.width;
        var childHeight = childBounds.height;
        if (childWidth > parentBounds.width) {
            var scale = parentBounds.width / childWidth;
            this.setState({
                scale: scale,
                zoomed: zoomed,
                compactHeight: Math.ceil(scale * childHeight),
                expandedHeight: childHeight
            });
            // TODO(charlie): Do this as a callback to `setState`. Something is
            // going wrong with that approach in initial testing.
            setTimeout(function() {
                // Only show it after the next paint, to allow for CSS
                // transitions to fade it in.
                _this3.isMounted() && _this3.setState({
                    visible: true
                });
            });
        } else this.setState({
            visible: true
        });
    },
    handleClickIfZoomed: function handleClickIfZoomed(e) {
        if (!this.state.zoomed) {
            e.stopPropagation();
            this.handleClick();
        }
    },
    handleClick: function handleClick() {
        this.setState({
            zoomed: !this.state.zoomed
        });
    },
    render: function render() {
        var _state = this.state, visible = _state.visible, scale = _state.scale, compactHeight = _state.compactHeight, expandedHeight = _state.expandedHeight, zoomed = _state.zoomed;
        var animateHeight = this.props.animateHeight;
        var property = animateHeight ? "opacity transform height" : "opacity transform";
        // Since we're not using aphrodite, we have to prefix ourselves.
        /* eslint-disable indent */
        var transitionStyle = visible ? {
            transitionProperty: property,
            WebkitTransitionProperty: property,
            msTransitionProperty: property,
            transitionDuration: "0.3s",
            WebkitTransitionDuration: "0.3s",
            msTransitionDuration: "0.3s",
            transitionTimingFunction: "ease-out",
            WebkitTransitionTimingfunction: "ease-out",
            msTransitionTmingFunction: "ease-out"
        } : {};
        /* eslint-enable indent */
        // Do a fancy little slide as we fade the contents in the first time.
        var translateOffset = visible ? "" : " translate(0, 8px)";
        var transform = zoomed ? "scale(1, 1) " + translateOffset : "scale(" + scale + ", " + scale + ") " + translateOffset;
        var style = _extends({
            display: "block",
            width: "100%",
            height: zoomed ? expandedHeight : compactHeight,
            transform: transform,
            WebkitTransform: transform,
            msTransform: transform,
            transformOrigin: "0 0",
            WebkitTransformOrigin: "0 0",
            msTransformOrigin: "0 0",
            opacity: visible ? 1 : 0,
            WebkitTapHighlightColor: "transparent"
        }, transitionStyle);
        return React.createElement("span", {
            onClick: this.handleClick,
            onClickCapture: this.handleClickIfZoomed,
            onTouchCancelCapture: this.stopPropagationIfZoomed,
            onTouchEndCapture: this.stopPropagationIfZoomed,
            onTouchStartCapture: this.stopPropagationIfZoomed,
            style: style
        }, this.props.children);
    }
});

module.exports = Zoomable;