Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_ContentShape", require("react").PropTypes.shape({
    type: require("react").PropTypes.oneOf([ "content" ]).isRequired
}));

/**
 * Type definitions for multi-item shapes.
 *
 * A shape is an object that serves as a runtime type declaration: it specifies
 * a tree structure for a particular class of multi-item.
 *
 * We use shapes instead static compile-time typing because the CMS needs to
 * understand the shape of our content library's multi-items at runtime, and
 * it's not always possible to infer the full shape from an example multi-item.
 *
 * Shapes also enable us to traverse a multi-item-shaped tree with confidence,
 * even when we can't infer the shape from the tree alone.
 *
 * We *could* go all-in on a more general library to make certain Flow types
 * runtime-inspectable, in order to DRY some things up, but that's probably a
 * big ol' infrastructural magic mess, and the narrower scope of Shapes makes
 * it easier to be confident that we've covered all cases rather than having to
 * deal with all possible Javascript types.
 */
Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_HintShape", require("react").PropTypes.shape({
    type: require("react").PropTypes.oneOf([ "hint" ]).isRequired
}));

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_TagsShape", require("react").PropTypes.shape({
    type: require("react").PropTypes.oneOf([ "tags" ]).isRequired
}));

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_ArrayShape", require("react").PropTypes.shape({
    type: require("react").PropTypes.oneOf([ "array" ]).isRequired,
    elementShape: require("react").PropTypes.any.isRequired
}));

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_ObjectShape", require("react").PropTypes.shape({
    type: require("react").PropTypes.oneOf([ "object" ]).isRequired,
    shape: require("react").PropTypes.shape({}).isRequired
}));