var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A keypad component that acts as a container for rows or columns of buttons,
 * and manages the rendering of echo animations on top of those buttons.
 */
var React = require("react");

var ReactDOM = require("react-dom");

var _require = require("react-redux"), connect = _require.connect;

var _require2 = require("../actions"), removeEcho = _require2.removeEcho;

var _require3 = require("../fake-react-native-web"), View = _require3.View;

var EchoManager = require("./echo-manager");

var PopoverManager = require("./popover-manager");

var _require4 = require("./prop-types"), echoPropType = _require4.echoPropType, popoverPropType = _require4.popoverPropType;

var Keypad = function(_React$Component) {
    _inherits(Keypad, _React$Component);
    function Keypad() {
        var _temp, _this, _ret;
        _classCallCheck(this, Keypad);
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return _ret = (_temp = (_this = _possibleConstructorReturn(this, _React$Component.call.apply(_React$Component, [ this ].concat(args))), 
        _this), _this._computeContainer = function() {
            var domNode = ReactDOM.findDOMNode(_this);
            _this._container = domNode.getBoundingClientRect();
        }, _this._updateSizeAndPosition = function() {
            // Mark the container for recalculation next time the keypad is
            // opened.
            // TODO(charlie): Since we're not recalculating the container
            // immediately, if you were to resize the page while a popover were
            // active, you'd likely get unexpected behavior. This seems very
            // difficult to do and, as such, incredibly unlikely, but we may
            // want to reconsider the caching here.
            _this._container = null;
        }, _this._onResize = function() {
            // Whenever the page resizes, we need to recompute the container's
            // bounding box. This is the only time that the bounding box can change.
            // Throttle resize events -- taken from:
            //    https://developer.mozilla.org/en-US/docs/Web/Events/resize
            null == _this._resizeTimeout && (_this._resizeTimeout = setTimeout(function() {
                _this._resizeTimeout = null;
                _this._isMounted && _this._updateSizeAndPosition();
            }, 66));
        }, _temp), _possibleConstructorReturn(_this, _ret);
    }
    Keypad.prototype.componentDidMount = function componentDidMount() {
        this._isMounted = true;
        window.addEventListener("resize", this._onResize);
        this._updateSizeAndPosition();
    };
    Keypad.prototype.componentWillReceiveProps = function componentWillReceiveProps(newProps) {
        this._container || !newProps.popover && !newProps.echoes.length || this._computeContainer();
    };
    Keypad.prototype.componentWillUnmount = function componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("resize", this._onResize);
    };
    Keypad.prototype.render = function render() {
        var _this2 = this;
        var _props = this.props, children = _props.children, echoes = _props.echoes, removeEcho = _props.removeEcho, popover = _props.popover, style = _props.style;
        // Translate the echo boxes, as they'll be positioned absolutely to
        // this relative container.
        var relativeEchoes = echoes.map(function(echo) {
            var initialBounds = echo.initialBounds, rest = _objectWithoutProperties(echo, [ "initialBounds" ]);
            return _extends({}, rest, {
                initialBounds: {
                    top: initialBounds.top - _this2._container.top,
                    right: initialBounds.right - _this2._container.left,
                    bottom: initialBounds.bottom - _this2._container.top,
                    left: initialBounds.left - _this2._container.left,
                    width: initialBounds.width,
                    height: initialBounds.height
                }
            });
        });
        // Translate the popover bounds from page-absolute to keypad-relative.
        // Note that we only need three bounds, since popovers are anchored to
        // the bottom left corners of the keys over which they appear.
        var relativePopover = popover && _extends({}, popover, {
            bounds: {
                bottom: this._container.height - (popover.bounds.bottom - this._container.top),
                left: popover.bounds.left - this._container.left,
                width: popover.bounds.width
            }
        });
        return React.createElement(View, {
            style: style
        }, children, React.createElement(EchoManager, {
            echoes: relativeEchoes,
            onAnimationFinish: removeEcho
        }), React.createElement(PopoverManager, {
            popover: relativePopover
        }));
    };
    return Keypad;
}(React.Component);

Keypad.propTypes = {
    // Whether the keypad is active, i.e., whether it should be rendered as
    // visible or invisible.
    active: React.PropTypes.bool,
    children: React.PropTypes.oneOfType([ React.PropTypes.arrayOf(React.PropTypes.node), React.PropTypes.node ]),
    echoes: React.PropTypes.arrayOf(echoPropType).isRequired,
    popover: popoverPropType,
    removeEcho: React.PropTypes.func.isRequired,
    style: React.PropTypes.any
};

var mapStateToProps = function mapStateToProps(state) {
    return _extends({}, state.echoes, {
        active: state.keypad.active,
        popover: state.gestures.popover
    });
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
        removeEcho: function(_removeEcho) {
            function removeEcho(_x) {
                return _removeEcho.apply(this, arguments);
            }
            removeEcho.toString = function() {
                return _removeEcho.toString();
            };
            return removeEcho;
        }(function(animationId) {
            dispatch(removeEcho(animationId));
        })
    };
};

module.exports = connect(mapStateToProps, mapDispatchToProps)(Keypad);