var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

var _lintUtils = require("./lint-utils.js");

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "absolute-url",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "link, image",
    lint: function lint(state, content, nodes, match) {
        var url = nodes[0].target;
        var hostname = (0, _lintUtils.getHostname)(url);
        if ("khanacademy.org" === hostname || hostname.endsWith(".khanacademy.org")) return "Don't use absolute URLs:\nWhen linking to KA content or images, omit the\nhttps://www.khanacademy.org URL prefix.\nUse a relative URL beginning with / instead.";
    }
});