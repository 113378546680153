/**
 * Paragraph parsing/splitting for article jipt i18n
 */
var SimpleMarkdown = require("simple-markdown");

var arrayRules = {
    fence: {
        match: SimpleMarkdown.defaultRules.fence.match,
        order: 1,
        parse: function parse(capture, state, _parse) {
            return capture[3];
        }
    },
    paragraph: {
        match: SimpleMarkdown.defaultRules.paragraph.match,
        order: 2,
        parse: function parse(capture, state, _parse2) {
            return capture[1];
        }
    }
};

var builtArrayParser = SimpleMarkdown.parserFor(arrayRules);

// This should just return an array of strings! magick!
var parseToArray = function parseToArray(source) {
    // Remove any leading newlines to avoid splitting weirdness
    // (simple-markdown has the `newline` rule for this, and i have
    // no idea how this will handle leading newlines without that rule),
    // and add \n\n to let it parse at a block/paragraph level
    var paragraphedSource = source.replace(/^\n\s*\n/, "") + "\n\n";
    return builtArrayParser(paragraphedSource, {
        inline: false
    });
};

var joinFromArray = function joinFromArray(paragraphs) {
    return paragraphs.join("\n\n");
};

module.exports = {
    parseToArray: parseToArray,
    joinFromArray: joinFromArray
};