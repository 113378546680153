var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "nested-lists",
    severity: _rule2.default.Severity.WARNING,
    selector: "list list",
    message: "Nested lists:\nnested lists are hard to read on mobile devices;\ndo not use additional indentation."
});