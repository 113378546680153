/* eslint-disable brace-style, comma-dangle, indent, max-len, no-var, one-var, prefer-spread */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var _ = require("underscore");

var KhanAnswerTypes = require("./util/answer-types.js");

var nestedMap = function nestedMap(children, func, context) {
    return _.isArray(children) ? _.map(children, function(child) {
        return nestedMap(child, func);
    }) : func.call(context, children);
};

var Util = {
    /**
     * Used to compare equality of two input paths, which are represented as
     * arrays of strings.
     */
    inputPathsEqual: function inputPathsEqual(a, b) {
        if (null == a || null == b) return null == a === (null == b);
        return a.length === b.length && a.every(function(item, index) {
            return b[index] === item;
        });
    },
    nestedMap: nestedMap,
    rWidgetParts: /^\[\[\u2603 (([a-z-]+) ([0-9]+))\]\]$/,
    rWidgetRule: /^\[\[\u2603 (([a-z-]+) ([0-9]+))\]\]/,
    rTypeFromWidgetId: /^([a-z-]+) ([0-9]+)$/,
    snowman: "☃",
    noScore: {
        type: "points",
        earned: 0,
        total: 0,
        message: null
    },
    seededRNG: function seededRNG(seed) {
        var randomSeed = seed;
        return function() {
            // Robert Jenkins' 32 bit integer hash function.
            var seed = randomSeed;
            seed = seed + 2127912214 + (seed << 12) & 4294967295;
            seed = 4294967295 & (3345072700 ^ seed ^ seed >>> 19);
            seed = seed + 374761393 + (seed << 5) & 4294967295;
            seed = 4294967295 & (seed + 3550635116 ^ seed << 9);
            seed = seed + 4251993797 + (seed << 3) & 4294967295;
            seed = 4294967295 & (3042594569 ^ seed ^ seed >>> 16);
            return (randomSeed = 268435455 & seed) / 268435456;
        };
    },
    // Shuffle an array using a given random seed or function.
    // If `ensurePermuted` is true, the input and ouput are guaranteed to be
    // distinct permutations.
    shuffle: function shuffle(array, randomSeed, ensurePermuted) {
        // Always return a copy of the input array
        var shuffled = _.clone(array);
        // Handle edge cases (input array is empty or uniform)
        if (!shuffled.length || _.all(shuffled, function(value) {
            return _.isEqual(value, shuffled[0]);
        })) return shuffled;
        var random;
        random = _.isFunction(randomSeed) ? randomSeed : Util.seededRNG(randomSeed);
        do {
            // Fischer-Yates shuffle
            for (var top = shuffled.length; top > 0; top--) {
                var newEnd = Math.floor(random() * top), temp = shuffled[newEnd];
                shuffled[newEnd] = shuffled[top - 1];
                shuffled[top - 1] = temp;
            }
        } while (ensurePermuted && _.isEqual(array, shuffled));
        return shuffled;
    },
    // In IE8, split doesn't work right. Implement it ourselves.
    split: "x".split(/(.)/g).length ? function(str, r) {
        return str.split(r);
    } : function(str, r) {
        // Based on Steven Levithan's MIT-licensed split, available at
        // http://blog.stevenlevithan.com/archives/cross-browser-split
        var output = [];
        var lastIndex = r.lastIndex = 0;
        var match;
        while (match = r.exec(str)) {
            output.push(str.slice(lastIndex, match.index));
            output.push.apply(output, match.slice(1));
            lastIndex = match.index + match[0].length;
        }
        output.push(str.slice(lastIndex));
        return output;
    },
    /**
     * Given two score objects for two different widgets, combine them so that
     * if one is wrong, the total score is wrong, etc.
     */
    combineScores: function combineScores(scoreA, scoreB) {
        var message;
        if ("points" === scoreA.type && "points" === scoreB.type) {
            // TODO(alpert): Figure out how to combine messages usefully
            message = scoreA.message && scoreB.message && scoreA.message !== scoreB.message ? null : scoreA.message || scoreB.message;
            return {
                type: "points",
                earned: scoreA.earned + scoreB.earned,
                total: scoreA.total + scoreB.total,
                message: message
            };
        }
        if ("points" === scoreA.type && "invalid" === scoreB.type) return scoreB;
        if ("invalid" === scoreA.type && "points" === scoreB.type) return scoreA;
        if ("invalid" === scoreA.type && "invalid" === scoreB.type) {
            // TODO(alpert): Figure out how to combine messages usefully
            message = scoreA.message && scoreB.message && scoreA.message !== scoreB.message ? null : scoreA.message || scoreB.message;
            return {
                type: "invalid",
                message: message
            };
        }
    },
    keScoreFromPerseusScore: function keScoreFromPerseusScore(score, guess, state) {
        if ("points" === score.type) return {
            empty: false,
            correct: score.earned >= score.total,
            message: score.message,
            guess: guess,
            state: state
        };
        if ("invalid" === score.type) return {
            empty: true,
            correct: false,
            message: score.message,
            guess: guess,
            state: state
        };
        throw new Error("Invalid score type: " + score.type);
    },
    /**
     * Return the first valid interpretation of 'text' as a number, in the form
     * {value: 2.3, exact: true}.
     */
    firstNumericalParse: function firstNumericalParse(text) {
        // TODO(alpert): This is sort of hacky...
        var first;
        KhanAnswerTypes.predicate.createValidatorFunctional(function(ans) {
            first = ans;
            return true;
        }, {
            simplify: "optional",
            inexact: true,
            forms: "integer, proper, improper, pi, log, mixed, decimal"
        })(text);
        return first;
    },
    stringArrayOfSize: function stringArrayOfSize(size) {
        return _(size).times(function() {
            return "";
        });
    },
    /**
     * For a graph's x or y dimension, given the tick step,
     * the ranges extent (e.g. [-10, 10]), the pixel dimension constraint,
     * and the grid step, return a bunch of configurations for that dimension.
     *
     * Example:
     *      gridDimensionConfig(10, [-50, 50], 400, 5)
     *
     * Returns: {
     *      scale: 4,
     *      snap: 2.5,
     *      tickStep: 2,
     *      unityLabel: true
     * };
     */
    gridDimensionConfig: function gridDimensionConfig(absTickStep, extent, dimensionConstraint, gridStep) {
        var scale = Util.scaleFromExtent(extent, dimensionConstraint);
        return {
            scale: scale,
            tickStep: absTickStep / gridStep,
            unityLabel: absTickStep * scale > 30
        };
    },
    /**
     * Given the range, step, and boxSize, calculate the reasonable gridStep.
     * Used for when one was not given explicitly.
     *
     * Example:
     *      getGridStep([[-10, 10], [-10, 10]], [1, 1], 340)
     *
     * Returns: [1, 1]
     */
    getGridStep: function getGridStep(range, step, boxSize) {
        return _(2).times(function(i) {
            var scale = Util.scaleFromExtent(range[i], boxSize);
            return Util.gridStepFromTickStep(step[i], scale);
        });
    },
    snapStepFromGridStep: function snapStepFromGridStep(gridStep) {
        return _.map(gridStep, function(step) {
            return step / 2;
        });
    },
    /**
     * Given the range and a dimension, come up with the appropriate
     * scale.
     * Example:
     *      scaleFromExtent([-25, 25], 500) // returns 10
     */
    scaleFromExtent: function scaleFromExtent(extent, dimensionConstraint) {
        return dimensionConstraint / (extent[1] - extent[0]);
    },
    /**
     * Return a reasonable tick step given extent and dimension.
     * (extent is [begin, end] of the domain.)
     * Example:
     *      tickStepFromExtent([-10, 10], 300) // returns 2
     */
    tickStepFromExtent: function tickStepFromExtent(extent, dimensionConstraint) {
        var span = extent[1] - extent[0];
        var tickFactor;
        // If single number digits
        tickFactor = 15 < span && span <= 20 ? 23 : span > 100 || span < 5 ? 10 : 16;
        var constraintFactor = dimensionConstraint / 500;
        var desiredNumTicks = tickFactor * constraintFactor;
        return Util.tickStepFromNumTicks(span, desiredNumTicks);
    },
    /**
     * Given the tickStep and the graph's scale, find a
     * grid step.
     * Example:
     *      gridStepFromTickStep(200, 0.2) // returns 100
     */
    gridStepFromTickStep: function gridStepFromTickStep(tickStep, scale) {
        var tickWidth = tickStep * scale;
        var x = tickStep;
        var y = Math.pow(10, Math.floor(Math.log(x) / Math.LN10));
        var leadingDigit = Math.floor(x / y);
        if (tickWidth < 25) return tickStep;
        if (tickWidth < 50) return 5 === leadingDigit ? tickStep : tickStep / 2;
        if (1 === leadingDigit) return tickStep / 2;
        if (2 === leadingDigit) return tickStep / 4;
        if (5 === leadingDigit) return tickStep / 5;
    },
    /**
     * Find a good tick step for the desired number of ticks in the range
     * Modified from d3.scale.linear: d3_scale_linearTickRange.
     * Thanks, mbostock!
     * Example:
     *      tickStepFromNumTicks(50, 6) // returns 10
     */
    tickStepFromNumTicks: function tickStepFromNumTicks(span, numTicks) {
        var step = Math.pow(10, Math.floor(Math.log(span / numTicks) / Math.LN10));
        var err = numTicks / span * step;
        // Filter ticks to get closer to the desired count.
        err <= .15 ? step *= 10 : err <= .35 ? step *= 5 : err <= .75 && (step *= 2);
        // Round start and stop values to step interval.
        return step;
    },
    /**
     * Constrain tick steps intended for desktop size graphs
     * to something more suitable for mobile size graphs.
     * Specifically, we aim for 10 or fewer ticks per graph axis.
     */
    constrainedTickStepsFromTickSteps: function constrainedTickStepsFromTickSteps(tickSteps, ranges) {
        var steps = [];
        for (var i = 0; i < 2; i++) {
            var span = ranges[i][1] - ranges[i][0];
            var numTicks = span / tickSteps[i];
            // Will displays fine on mobile
            steps[i] = numTicks <= 10 ? tickSteps[i] : numTicks <= 20 ? 2 * tickSteps[i] : Util.tickStepFromNumTicks(span, 10);
        }
        return steps;
    },
    /**
     * Transparently update deprecated props so that the code to deal
     * with them only lives in one place: (Widget).deprecatedProps
     *
     * For example, if a boolean `foo` was deprecated in favor of a
     * number 'bar':
     *      deprecatedProps: {
     *          foo: function(props) {
     *              return {bar: props.foo ? 1 : 0};
     *          }
     *      }
     */
    DeprecationMixin: {
        // This lifecycle stage is only called before first render
        componentWillMount: function componentWillMount() {
            var newProps = {};
            _.each(this.deprecatedProps, function(func, prop) {
                _.has(this.props, prop) && _.extend(newProps, func(this.props));
            }, this);
            if (!_.isEmpty(newProps)) {
                // Set new props directly so that widget renders correctly
                // when it first mounts, even though these will be overwritten
                // almost immediately afterwards...
                _.extend(this.props, newProps);
                // ...when we propagate the new props upwards and they come
                // back down again.
                setTimeout(this.props.onChange, 0, newProps);
            }
        }
    },
    /**
     * Approximate equality on numbers and primitives.
     */
    eq: function eq(x, y) {
        return _.isNumber(x) && _.isNumber(y) ? Math.abs(x - y) < 1e-9 : x === y;
    },
    /**
     * Deep approximate equality on primitives, numbers, arrays, and objects.
     */
    deepEq: function deepEq(x, y) {
        if (_.isArray(x) && _.isArray(y)) {
            if (x.length !== y.length) return false;
            for (var i = 0; i < x.length; i++) if (!Util.deepEq(x[i], y[i])) return false;
            return true;
        }
        return !_.isArray(x) && !_.isArray(y) && (_.isFunction(x) && _.isFunction(y) ? Util.eq(x, y) : !_.isFunction(x) && !_.isFunction(y) && (_.isObject(x) && _.isObject(y) ? x === y || _.all(x, function(v, k) {
            return Util.deepEq(y[k], v);
        }) && _.all(y, function(v, k) {
            return Util.deepEq(x[k], v);
        }) : !_.isObject(x) && !_.isObject(y) && Util.eq(x, y)));
    },
    /**
     * Query String Parser
     *
     * Original from:
     * http://stackoverflow.com/questions/901115/get-querystring-values-in-javascript/2880929#2880929
     */
    parseQueryString: function parseQueryString(query) {
        query = query || window.location.search.substring(1);
        var urlParams = {}, e, a = /\+/g, // Regex for replacing addition symbol with a space
        r = /([^&=]+)=?([^&]*)/g, d = function d(s) {
            return decodeURIComponent(s.replace(a, " "));
        };
        while (e = r.exec(query)) urlParams[d(e[1])] = d(e[2]);
        return urlParams;
    },
    /**
     * Query string adder
     * Works for URLs without #.
     * Original from:
     * http://stackoverflow.com/questions/5999118/add-or-update-query-string-parameter
     */
    updateQueryString: function updateQueryString(uri, key, value) {
        value = encodeURIComponent(value);
        var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
        var separator = -1 !== uri.indexOf("?") ? "&" : "?";
        return uri.match(re) ? uri.replace(re, "$1" + key + "=" + value + "$2") : uri + separator + key + "=" + value;
    },
    /**
     * A more strict encodeURIComponent that escapes `()'!`s
     * Especially useful for creating URLs that are embeddable in markdown
     *
     * Adapted from
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
     * This function and the above original available under the
     * CC-BY-SA 2.5 license.
     */
    strongEncodeURIComponent: function strongEncodeURIComponent(str) {
        return encodeURIComponent(str).replace(/['()!]/g, window.escape).replace(/\*/g, "%2A");
    },
    // There are certain widgets where we don't want to provide the "answered"
    // highlight indicator.
    // The issue with just using the `graded` flag on questions is that showing
    // that a certain widget is ungraded can sometimes reveal the answer to a
    // question ("is this transformation possible? if so, do it")
    // This is kind of a hack to get around this.
    widgetShouldHighlight: function widgetShouldHighlight(widget) {
        if (!widget) return false;
        var HIGHLIGHT_BAR_BLACKLIST = [ "measurer", "protractor" ];
        return !_.contains(HIGHLIGHT_BAR_BLACKLIST, widget.type);
    },
    /**
     * If a widget says that it is empty once it is graded.
     * Trying to encapsulate references to the score format.
     */
    scoreIsEmpty: function scoreIsEmpty(score) {
        // HACK(benkomalo): ugh. this isn't great; the Perseus score objects
        // overload the type "invalid" for what should probably be three
        // distinct cases:
        //  - truly empty or not fully filled out
        //  - invalid or malformed inputs
        //  - "almost correct" like inputs where the widget wants to give
        //  feedback (e.g. a fraction needs to be reduced, or `pi` should
        //  be used instead of 3.14)
        //
        //  Unfortunately the coercion happens all over the place, as these
        //  Perseus style score objects are created *everywhere* (basically
        //  in every widget), so it's hard to change now. We assume that
        //  anything with a "message" is not truly empty, and one of the
        //  latter two cases for now.
        return "invalid" === score.type && (!score.message || 0 === score.message.length);
    },
    /**
     * Extracts the location of a touch or mouse event, allowing you to pass
     * in a "mouseup", "mousedown", or "mousemove" event and receive the
     * correct coordinates. Shouldn't be used with "vmouse" events.
     *
     * The Util.touchHandlers are used to track the current state of the touch
     * event, such as whether or not the user is currently pressed down (either
     * through touch or mouse) on the screen.
     */
    touchHandlers: {
        pointerDown: false,
        currentTouchIdentifier: null
    },
    resetTouchHandlers: function resetTouchHandlers() {
        _.extend(Util.touchHandlers, {
            pointerDown: false,
            currentTouchIdentifier: null
        });
    },
    extractPointerLocation: function extractPointerLocation(event) {
        var touchOrEvent;
        if (Util.touchHandlers.pointerDown) {
            // Look for the touch matching the one we're tracking; ignore others
            if (null != Util.touchHandlers.currentTouchIdentifier) {
                var len = event.changedTouches ? event.changedTouches.length : 0;
                for (var i = 0; i < len; i++) event.changedTouches[i].identifier === Util.touchHandlers.currentTouchIdentifier && (touchOrEvent = event.changedTouches[i]);
            } else touchOrEvent = event;
            var isEndish = "touchend" === event.type || "touchcancel" === event.type;
            if (touchOrEvent && isEndish) {
                Util.touchHandlers.pointerDown = false;
                Util.touchHandlers.currentTouchIdentifier = null;
            }
        } else {
            // touchstart or mousedown
            Util.touchHandlers.pointerDown = true;
            if (event.changedTouches) {
                touchOrEvent = event.changedTouches[0];
                Util.touchHandlers.currentTouchIdentifier = touchOrEvent.identifier;
            } else touchOrEvent = event;
        }
        if (touchOrEvent) return {
            left: touchOrEvent.pageX,
            top: touchOrEvent.pageY
        };
    },
    /**
     * Pass this function as the touchstart for an element to
     * avoid sending the touch to the mobile scratchpad
     */
    captureScratchpadTouchStart: function captureScratchpadTouchStart(e) {
        e.stopPropagation();
    },
    getImageSize: function getImageSize(url, callback) {
        var img = new Image();
        img.onload = function() {
            // IE 11 seems to have problems calculating the heights of svgs
            // if they're not in the DOM. To solve this, we add the element to
            // the dom, wait for a rerender, and use `.clientWidth` and
            // `.clientHeight`. I think we could also solve the problem by
            // adding the image to the document before setting the src, but then
            // the experience would be worse for other browsers.
            if (0 === img.width && 0 === img.height) {
                document.body.appendChild(img);
                _.defer(function() {
                    callback(img.clientWidth, img.clientHeight);
                    document.body.removeChild(img);
                });
            } else callback(img.width, img.height);
        };
        // Require here to prevent recursive imports
        var SvgImage = require("./components/svg-image.jsx");
        img.src = SvgImage.getRealImageUrl(url);
    },
    textarea: {
        /**
         * Gets the word right before where the textarea cursor is
         *
         * @param {Element} textarea - The textarea DOM element
         * @return {JSON} - An object with the word and its starting and ending positions in the textarea
         */
        getWordBeforeCursor: function getWordBeforeCursor(textarea) {
            var text = textarea.value;
            var endPos = textarea.selectionStart - 1;
            var startPos = Math.max(text.lastIndexOf("\n", endPos), text.lastIndexOf(" ", endPos)) + 1;
            return {
                string: text.substring(startPos, endPos + 1),
                pos: {
                    start: startPos,
                    end: endPos
                }
            };
        },
        /**
         * Moves the textarea cursor at the specified position
         *
         * @param {Element} textarea - The textarea DOM element
         * @param {int} pos - The position where the cursor will be moved
         */
        moveCursor: function moveCursor(textarea, pos) {
            textarea.selectionStart = pos;
            textarea.selectionEnd = pos;
        }
    }
};

Util.random = Util.seededRNG(4294967295 & new Date().getTime());

module.exports = Util;