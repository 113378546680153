/* eslint-disable react/forbid-prop-types, react/sort-comp */
var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var textWidthCache = {};

function getTextWidth(text) {
    if (!textWidthCache[text]) {
        // Hacky way to guess the width of an input box
        var $test = $("<span>").text(text).appendTo("body");
        textWidthCache[text] = $test.width() + 5;
        $test.remove();
    }
    return textWidthCache[text];
}

var TextListEditor = React.createClass({
    displayName: "TextListEditor",
    propTypes: {
        options: React.PropTypes.array,
        layout: React.PropTypes.string,
        onChange: React.PropTypes.func.isRequired
    },
    getDefaultProps: function getDefaultProps() {
        return {
            options: [],
            layout: "horizontal"
        };
    },
    getInitialState: function getInitialState() {
        return {
            items: this.props.options.concat("")
        };
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        this.setState({
            items: nextProps.options.concat("")
        });
    },
    render: function render() {
        var className = [ "perseus-text-list-editor", "perseus-clearfix", "layout-" + this.props.layout ].join(" ");
        var inputs = _.map(this.state.items, function(item, i) {
            return React.createElement("li", {
                key: i
            }, React.createElement("input", {
                ref: "input_" + i,
                type: "text",
                value: item,
                onChange: this.onChange.bind(this, i),
                onKeyDown: this.onKeyDown.bind(this, i),
                style: {
                    width: getTextWidth(item)
                }
            }));
        }, this);
        return React.createElement("ul", {
            className: className
        }, inputs);
    },
    onChange: function onChange(index, event) {
        var items = _.clone(this.state.items);
        items[index] = event.target.value;
        index === items.length - 1 && (items = items.concat(""));
        this.setState({
            items: items
        });
        this.props.onChange(_.compact(items));
    },
    onKeyDown: function onKeyDown(index, event) {
        var which = event.nativeEvent.keyCode;
        // Backspace deletes an empty input...
        if (8 === which && "" === this.state.items[index]) {
            event.preventDefault();
            var items = _.clone(this.state.items);
            var focusIndex = 0 === index ? 0 : index - 1;
            if (index !== items.length - 1 || 0 !== index && "" === items[focusIndex]) {
                items.splice(index, 1);
                this.setState({
                    items: items
                }, function() {
                    ReactDOM.findDOMNode(this.refs["input_" + focusIndex]).focus();
                });
            } else // ...except for the last one, iff it is the only empty
            // input at the end.
            ReactDOM.findDOMNode(this.refs["input_" + focusIndex]).focus();
        } else if (8 === which && 1 === this.state.items[index].length && index === this.state.items.length - 2) {
            event.preventDefault();
            var _items = _.clone(this.state.items);
            _items.splice(index, 1);
            this.setState({
                items: _items
            });
            this.props.onChange(_.compact(_items));
        } else if (13 === which) {
            event.preventDefault();
            var _items2 = _.clone(this.state.items);
            var _focusIndex = index + 1;
            if (index === _items2.length - 2) // ...unless the empty input is just below.
            ReactDOM.findDOMNode(this.refs["input_" + _focusIndex]).focus(); else {
                _items2.splice(_focusIndex, 0, "");
                this.setState({
                    items: _items2
                }, function() {
                    ReactDOM.findDOMNode(this.refs["input_" + _focusIndex]).focus();
                });
            }
        }
    }
});

module.exports = TextListEditor;