/* eslint-disable react/sort-comp */
var React = require("react");

var Tooltip = require("react-components/tooltip.jsx");

var _ = require("underscore");

var ApiClassNames = require("../perseus-api.jsx").ClassNames;

var MathInput = require("./math-input.jsx");

var Renderer = require("../renderer.jsx");

var TextInput = require("./text-input.jsx");

var MathOutput = require("../components/math-output.jsx");

var Gorgon = require("../gorgon/gorgon.js");

var _require = require("../gorgon/proptypes.js"), linterContextProps = _require.linterContextProps, linterContextDefault = _require.linterContextDefault;

var captureScratchpadTouchStart = require("../util.js").captureScratchpadTouchStart;

var MATH = "math";

var TEXT = "text";

var TEX = "tex";

var InputWithExamples = React.createClass({
    displayName: "InputWithExamples",
    propTypes: {
        type: React.PropTypes.oneOf([ MATH, TEXT, TEX ]),
        value: React.PropTypes.string,
        onChange: React.PropTypes.func.isRequired,
        className: React.PropTypes.string,
        examples: React.PropTypes.arrayOf(React.PropTypes.string).isRequired,
        shouldShowExamples: React.PropTypes.bool,
        convertDotToTimes: React.PropTypes.bool,
        buttonSet: React.PropTypes.string,
        buttonsVisible: React.PropTypes.oneOf([ "always", "never", "focused" ]),
        labelText: React.PropTypes.string,
        onFocus: React.PropTypes.func,
        onBlur: React.PropTypes.func,
        disabled: React.PropTypes.bool,
        // A unique string identifying this InputWithExamples
        id: React.PropTypes.string.isRequired,
        linterContext: linterContextProps
    },
    getDefaultProps: function getDefaultProps() {
        return {
            type: TEXT,
            shouldShowExamples: true,
            onFocus: function onFocus() {},
            onBlur: function onBlur() {},
            disabled: false,
            linterContext: linterContextDefault
        };
    },
    getInitialState: function getInitialState() {
        return {
            focused: false,
            showExamples: false
        };
    },
    _getUniqueId: function _getUniqueId() {
        return "input-with-examples-" + btoa(this.props.id).replace(/=/g, "");
    },
    _getInputClassName: function _getInputClassName() {
        // <MathOutput> is a special component that manages its own class and
        // state, as it's a <span> that wants to act like an <input>.
        if (this.props.type === TEX) return this.props.className;
        // Otherwise, we need to add these INPUT and FOCUSED tags here.
        var className = ApiClassNames.INPUT + " " + ApiClassNames.INTERACTIVE;
        this.state.focused && (className += " " + ApiClassNames.FOCUSED);
        this.props.className && (className += " " + this.props.className);
        return className;
    },
    _getPropsForInputType: function _getPropsForInputType() {
        // Minimal set of props, used by each input type
        var inputProps = {
            "aria-describedby": this._getUniqueId(),
            ref: "input",
            className: this._getInputClassName(),
            labelText: this.props.labelText,
            value: this.props.value,
            onFocus: this._handleFocus,
            onBlur: this._handleBlur,
            disabled: this.props.disabled
        };
        if (this.props.type === TEX) return inputProps;
        // Add useful props required for MATH and TEXT modes
        _.extend(inputProps, {
            onChange: this.props.onChange,
            onTouchStart: captureScratchpadTouchStart
        });
        // And add final props that are MATH- and TEXT-specific
        if (this.props.type === MATH) return _.extend({
            buttonSet: this.props.buttonSet,
            buttonsVisible: this.props.buttonsVisible,
            convertDotToTimes: this.props.convertDotToTimes
        }, inputProps);
        if (this.props.type === TEXT) return _.extend({
            autoCapitalize: "off",
            autoComplete: "off",
            autoCorrect: "off",
            spellCheck: "false"
        }, inputProps);
    },
    _getComponentForInputType: function _getComponentForInputType() {
        switch (this.props.type) {
          case TEX:
            return MathOutput;

          case MATH:
            return MathInput;

          case TEXT:
            return TextInput;

          default:
            return null;
        }
    },
    _renderInput: function _renderInput() {
        var inputProps = this._getPropsForInputType();
        var InputComponent = this._getComponentForInputType();
        return React.createElement(InputComponent, inputProps);
    },
    render: function render() {
        var input = this._renderInput();
        // Static rendering, which doesn't include the 'tooltip' logic that the
        // other types require, and is hence handled separately.
        if (this.props.type === TEX) return input;
        // Else, we need to be able to show examples
        var examplesContent = _.map(this.props.examples, function(example) {
            return "- " + example;
        }).join("\n");
        var showExamples = this.props.shouldShowExamples && this.state.showExamples;
        return React.createElement(Tooltip, {
            ref: "tooltip",
            className: "perseus-formats-tooltip preview-measure",
            horizontalPosition: "left",
            horizontalAlign: "left",
            verticalPosition: "bottom",
            arrowSize: 10,
            borderColor: "#ccc",
            show: showExamples
        }, input, React.createElement("div", {
            id: this._getUniqueId()
        }, React.createElement(Renderer, {
            content: examplesContent,
            linterContext: Gorgon.pushContextStack(this.props.linterContext, "input-with-examples")
        })));
    },
    _handleFocus: function _handleFocus() {
        this.props.onFocus();
        this.setState({
            focused: true,
            showExamples: true
        });
    },
    show: function show() {
        this.setState({
            showExamples: true
        });
    },
    hide: function hide() {
        this.setState({
            showExamples: false
        });
    },
    _handleBlur: function _handleBlur() {
        this.props.onBlur();
        this.setState({
            focused: false,
            showExamples: false
        });
    },
    focus: function focus() {
        this.refs.input.focus();
    },
    blur: function blur() {
        this.refs.input.blur();
    },
    handleChange: function handleChange(e) {
        this.props.onChange(e.target.value);
    }
});

module.exports = InputWithExamples;