var _nonSatRationale, _intermediateResponsi;

var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
/* global i18n */
var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var _ = require("underscore");

var React = require("react");

var classNames = require("classnames");

var _require2 = require("../../perseus-api.jsx"), ClassNames = _require2.ClassNames;

var sharedStyles = require("../../styles/shared.js");

var styleConstants = require("../../styles/constants.js");

var mediaQueries = require("../../styles/media-queries.js");

var ToggleableRadioButton = require("./toggleable-radio-button.jsx");

var ChoiceIcon = require("./choice-icon.jsx");

var OptionStatus = require("./option-status.jsx");

var focusedStyleMixin = {
    backgroundColor: styleConstants.satSelectedBackgroundColor,
    outline: "2px solid " + styleConstants.satBlue,
    // Render the outline higher than the next element's border
    zIndex: 1
};

var intermediateCheckboxPadding = "16px 16px";

var intermediateCheckboxPaddingPhone = "12px 16px";

var Choice = React.createClass({
    displayName: "Choice",
    propTypes: {
        // TODO(kevinb) use Options.propTypes from perseus-api.jsx
        // This change will also require make sure that item-renderer.jsx and
        // server-item-renderer.jsx have appropriate defaults for apiOptions
        // because many of the properties on Options.propTypes are required.
        apiOptions: React.PropTypes.shape({
            satStyling: React.PropTypes.bool,
            isMobile: React.PropTypes.bool,
            styling: React.PropTypes.shape({
                radioStyleVersion: React.PropTypes.oneOf([ "intermediate", "final" ]),
                primaryProductColor: React.PropTypes.string
            }),
            readOnly: React.PropTypes.bool
        }),
        checked: React.PropTypes.bool,
        className: React.PropTypes.string,
        rationale: React.PropTypes.node,
        content: React.PropTypes.node,
        correct: React.PropTypes.bool,
        deselectEnabled: React.PropTypes.bool,
        disabled: React.PropTypes.bool,
        editMode: React.PropTypes.bool,
        groupName: React.PropTypes.string,
        isLastChoice: React.PropTypes.bool,
        // Needed for border styling
        // This indicates the position of the choice relative to others
        // (so that we can display a nice little (A), (B), etc. next to it)
        // Also used to generate an id for each input.
        pos: React.PropTypes.number,
        reviewMode: React.PropTypes.bool,
        showRationale: React.PropTypes.bool,
        showCorrectness: React.PropTypes.bool,
        type: React.PropTypes.string,
        // Indicates whether the user has "crossed out" this choice, meaning
        // that they don't think it's correct. This value does not affect
        // scoring or other behavior; it's just a note for the user's
        // reference.
        crossedOut: React.PropTypes.bool,
        // A callback indicating that this choice has changed. Its argument is
        // an object with two keys: `checked` and `crossedOut`. Each contains a
        // boolean value specifying the new checked and crossed-out value of
        // this choice.
        onChange: React.PropTypes.func
    },
    statics: {
        styles: StyleSheet.create({
            pos: {
                display: "none"
            },
            description: {
                display: "inline-block",
                width: "100%"
            },
            satDescription: {
                display: "block",
                position: "relative",
                boxSizing: "border-box",
                cursor: "pointer",
                marginLeft: 0,
                padding: "17px 14px"
            },
            satDescriptionInputFocused: _extends({}, focusedStyleMixin),
            satDescriptionInputActive: _extends({}, focusedStyleMixin, {
                backgroundColor: styleConstants.satActiveBackgroundColor
            }),
            satDescriptionCorrect: {
                color: styleConstants.satCorrectColor
            },
            satDescriptionCorrectChecked: {
                backgroundColor: styleConstants.satCorrectBackgroundColor
            },
            satDescriptionIncorrectChecked: {
                color: styleConstants.satIncorrectColor,
                backgroundColor: styleConstants.satIncorrectBackgroundColor
            },
            input: {
                display: "inline-block",
                width: 20,
                margin: 3,
                marginLeft: -20,
                marginRight: 0,
                float: "none"
            },
            satReviewInput: {
                pointerEvents: "none"
            },
            satRadioOptionContent: {
                userSelect: "text",
                display: "block",
                marginLeft: 45,
                // Overriding here, not sure why typically set
                // to "cursor: default" in js
                cursor: "inherit"
            },
            satReviewRadioOptionContent: {
                fontWeight: "bold"
            },
            satCheckboxOptionContent: {
                position: "absolute",
                display: "block",
                top: "50%",
                margin: "-16px 0 0 0",
                width: "auto"
            },
            choiceIconWrapper: {
                display: "flex",
                marginRight: 12,
                // NOTE(mdr): Without this style, the bubbles shrink on iOS
                //     when answer text gets long.
                flexShrink: 0
            },
            optionStatusContainer: {
                display: "block"
            },
            rationale: {
                display: "block"
            },
            nonSatRationale: (_nonSatRationale = {
                padding: intermediateCheckboxPadding,
                paddingTop: 0,
                marginLeft: 44
            }, _nonSatRationale[mediaQueries.smOrSmaller] = {
                padding: intermediateCheckboxPaddingPhone,
                paddingTop: 0
            }, _nonSatRationale),
            satReviewRationale: {
                marginTop: 13,
                marginLeft: 45
            },
            label: {
                display: "block"
            },
            responsiveLabel: {
                WebkitTapHighlightColor: "transparent",
                display: "flex"
            },
            satLabel: {
                cursor: "pointer"
            },
            intermediateResponsiveCheckbox: (_intermediateResponsi = {
                display: "flex",
                alignItems: "center",
                padding: intermediateCheckboxPadding
            }, _intermediateResponsi[mediaQueries.smOrSmaller] = {
                padding: intermediateCheckboxPaddingPhone
            }, _intermediateResponsi),
            intermediateResponsiveCheckboxReview: {
                alignItems: "flex-start"
            },
            crossOutLink: {
                textAlign: "right",
                alignSelf: "center",
                width: 100
            }
        })
    },
    getDefaultProps: function getDefaultProps() {
        return {
            checked: false,
            classSet: {},
            disabled: false,
            editMode: false,
            showRationale: false,
            type: "radio",
            pos: 0
        };
    },
    getInitialState: function getInitialState() {
        return {
            isInputFocused: false,
            isInputActive: false
        };
    },
    componentWillUpdate: function componentWillUpdate(nextProps) {
        this.state.isInputFocused && nextProps.disabled && this.setState({
            isInputFocused: false
        });
    },
    onInputFocus: function onInputFocus() {
        this.setState({
            isInputFocused: true
        });
    },
    onInputBlur: function onInputBlur() {
        this.setState({
            isInputFocused: false
        });
    },
    onInputMouseDown: function onInputMouseDown(e) {
        if ("mousedown" === e.type && this.justFinishedTouch) return;
        this.setState({
            isInputActive: true
        });
        // Simulate Chrome's radio button behavior in all browsers: when the
        // mouse goes down or up, the radio button should become focused.
        // That way, the newly-selected answer becomes highlighted after click.
        this.props.apiOptions.satStyling && this._input && this._input.focus();
    },
    onInputMouseUp: function onInputMouseUp(e) {
        var _this = this;
        if ("mouseup" === e.type && this.justFinishedTouch) return;
        // NOTE(emily): We do some special handling here of touch events to
        // make the "active" effect look better. In particular, when you click
        // using touch events, we get a series of events going
        // touchstart -> (delay) -> touchend -> mousedown -> mouseup -> click
        // In order to make sure that we don't turn the active state of and on
        // and off again during the touchend -> mousedown -> mouseup series, we
        // set a flag (this.justFinishedTouch) after the touchend, and ignore
        // the mousedown and mouseup events. Then, a little while later, we
        // turn the flag off. Instead of turning the active state off right at
        // the beginning, we wait for a little bit to sync it up better with
        // the click event.
        if ("touchend" === e.type) {
            this.justFinishedTouch = true;
            setTimeout(function() {
                _this.setState({
                    isInputActive: false
                });
                _this.justFinishedTouch = false;
            }, 10);
            return;
        }
        this.setState({
            isInputActive: false
        });
        // Simulate Chrome's radio button behavior in all browsers: when the
        // mouse goes down or up, the radio button should become focused.
        // That way, the newly-selected answer becomes highlighted after click.
        this.props.apiOptions.satStyling && this._input && this._input.focus();
    },
    onInputMouseOut: function onInputMouseOut() {
        this.setState({
            isInputActive: false
        });
    },
    inputRef: function inputRef(ref) {
        this._input = ref;
    },
    renderOptionStatus: function renderOptionStatus() {
        var _props = this.props, correct = _props.correct, checked = _props.checked;
        // Option status is shown only in review mode, and excluded for SAT
        if (!_props.reviewMode || this.props.apiOptions.satStyling) return;
        return React.createElement(OptionStatus, {
            checked: checked,
            correct: correct
        });
    },
    renderChoiceIcon: function renderChoiceIcon() {
        var _props$apiOptions$sty = this.props.apiOptions.styling, radioStyleVersion = _props$apiOptions$sty.radioStyleVersion, primaryProductColor = _props$apiOptions$sty.primaryProductColor;
        if (!("undefined" !== typeof radioStyleVersion && "final" === radioStyleVersion) && !this.props.apiOptions.satStyling) return null;
        return React.createElement(ChoiceIcon, {
            pos: this.props.pos,
            correct: this.props.correct,
            pressed: this.state.isInputActive,
            focused: this.state.isInputFocused,
            checked: this.props.checked,
            showCorrectness: this.props.showCorrectness,
            reviewMode: this.props.reviewMode,
            product: this.props.apiOptions.satStyling ? "sat" : "library",
            primaryProductColor: primaryProductColor
        });
    },
    // NOTE(mdr): This method expects to be auto-bound. If this component is
    //     converted to an ES6 class, take care to auto-bind this method!
    _toggleCrossOut: function _toggleCrossOut() {
        this.props.crossedOut ? this._sendChange({
            crossedOut: false
        }) : // If we're crossing out a checked option, let's also uncheck it.
        this._sendChange({
            checked: false,
            crossedOut: true
        });
    },
    // Call `this.props.onChange` with the given values. Any keys that are not
    // specified will be filled in with the current value. (For example, if
    // `checked` is specified but `crossedOut` is not, then `crossedOut` will
    // be filled in with `this.props.crossedOut`.)
    //
    // This enables us to use shorthand inside this component, while
    // maintaining a consistent API for the parent.
    _sendChange: function _sendChange(newValues) {
        var checked = null != newValues.checked ? newValues.checked : this.props.checked;
        var crossedOut = null != newValues.crossedOut ? newValues.crossedOut : this.props.crossedOut;
        this.props.onChange({
            checked: checked,
            crossedOut: crossedOut
        });
    },
    render: function render() {
        var _this2 = this;
        var styles = Choice.styles;
        var sat = this.props.apiOptions.satStyling;
        var isMobile = this.props.apiOptions.isMobile;
        var radioStyleVersion = this.props.apiOptions.styling.radioStyleVersion;
        var finalStyles = "undefined" !== typeof radioStyleVersion && "final" === radioStyleVersion;
        var className = classNames(this.props.className, "checkbox-label", css(styles.label, isMobile && sharedStyles.disableTextSelection, !sat && styles.responsiveLabel, sat && styles.satLabel));
        // There's two different input components we could use (the builtin
        // input component, or the ToggleableRadioButton component). These are
        // the props that we will pass to either.
        var commonInputProps = {
            type: this.props.type,
            name: this.props.groupName,
            id: this.props.groupName + "-choice-" + this.props.pos,
            checked: this.props.checked,
            disabled: this.props.disabled,
            onFocus: this.onInputFocus,
            onBlur: this.onInputBlur,
            className: css(// intermediate styles are not different for radio and
            // checkbox, and have a separate active state.
            !finalStyles && sharedStyles.perseusInteractive, !finalStyles && styles.input, !finalStyles && sharedStyles.responsiveInput, !finalStyles && !sat && sharedStyles.responsiveRadioInput, !finalStyles && !sat && this.state.isInputActive && sharedStyles.responsiveRadioInputActive, finalStyles && sharedStyles.perseusSrOnly, sat && sharedStyles.perseusSrOnly, sat && this.props.reviewMode && styles.satReviewInput)
        };
        var input = null;
        // This is a special radio button that allows a user to deselect
        // it by merely clicking/selecting it again.
        input = "radio" === this.props.type ? React.createElement(ToggleableRadioButton, _extends({
            onChecked: function onChecked(willBeChecked) {
                _this2._sendChange({
                    checked: willBeChecked
                });
            },
            inputRef: this.inputRef
        }, commonInputProps)) : React.createElement("input", _extends({
            onChange: function onChange(event) {
                _this2._sendChange({
                    checked: event.target.checked
                });
            },
            ref: this.inputRef
        }, commonInputProps));
        var _props2 = this.props, reviewMode = _props2.reviewMode, correct = _props2.correct, checked = _props2.checked, isLastChoice = _props2.isLastChoice;
        // HACK: while most of the styling for rendering SAT items is handled
        // via aphrodite, we also need to assign normal CSS classnames here to
        // special-case the coloring of MathJax formulas (see .MathJax .math in
        // stylesheets/task-package/tasks.less)
        var satCorrectChoice = sat && reviewMode && correct;
        var satIncorrectChecked = sat && reviewMode && !correct && checked;
        var descriptionClassName = classNames("description", satCorrectChoice && "sat-correct", satIncorrectChecked && "sat-incorrect", css(!sat && styles.description, sat && this.state.isInputFocused && styles.satDescriptionInputFocused, sat && this.state.isInputActive && styles.satDescriptionInputActive, sat && styles.satDescription, satCorrectChoice && styles.satDescriptionCorrect, satCorrectChoice && checked && styles.satDescriptionCorrectChecked, satIncorrectChecked && styles.satDescriptionIncorrectChecked, sat && isLastChoice && styles.satDescriptionLastChoice));
        var checkboxContentClassName = classNames("checkbox", css(sharedStyles.perseusInteractive, !sat && styles.choiceIconWrapper, sat && styles.satCheckboxOptionContent));
        var checkboxAndOptionClassName = classNames("checkbox-and-option", css(!sat && styles.intermediateResponsiveCheckbox, !sat && reviewMode && styles.intermediateResponsiveCheckboxReview));
        var rationaleClassName = classNames("perseus-radio-rationale-content", css(styles.rationale, !sat && styles.nonSatRationale, sat && styles.satReviewRationale));
        // In edit mode, we must allow selection of the contentEditable
        // element inside, therefore we cannot use a label, which makes
        // selection of anything inside automatically select the input
        // element instead
        var LabelOrDiv = this.props.editMode ? "div" : "label";
        var showCrossOut = !this.props.showCorrectness && !sat && // HACK(mdr): This should go behind a real flag; this is just a
        //     quick hack so I can ship this code for review (and maybe
        //     land before the Nov 2017 TSM) before overcommitting to arch.
        //     https://app.asana.com/0/329800276300868/467694510751339
        document.location.href.indexOf("PERSEUS-HACK-SHOW-CROSS-OUT") >= 0;
        // We want to show the choices as dimmed out when the choices are
        // disabled. However, we don't want to do this in the SAT product and
        // we also don't want to do this when we're in review mode in the
        // content library.
        var showDimmed = !sat && !reviewMode && this.props.apiOptions.readOnly || // HACK(mdr): This is a temporary way of showing the crossedOut
        //     state. Eventually, we'll have custom visuals for this state,
        //     at which point we'll remove this condition.
        //     https://app.asana.com/0/329800276300868/467694510751336
        showCrossOut && this.props.crossedOut;
        return React.createElement(LabelOrDiv, {
            htmlFor: !this.props.editMode && commonInputProps.id,
            className: className,
            style: {
                opacity: showDimmed ? .5 : 1
            }
        }, React.createElement("div", {
            className: descriptionClassName,
            onMouseDown: this.onInputMouseDown,
            onMouseUp: this.onInputMouseUp,
            onMouseOut: this.onInputMouseOut,
            onTouchStart: this.onInputMouseDown,
            onTouchEnd: this.onInputMouseUp
        }, React.createElement("div", {
            className: checkboxAndOptionClassName
        }, React.createElement("span", {
            className: checkboxContentClassName
        }, input, this.renderChoiceIcon()), React.createElement("span", {
            className: classNames(ClassNames.RADIO.OPTION_CONTENT, ClassNames.INTERACTIVE, css(sat && styles.satRadioOptionContent, sat && reviewMode && styles.satReviewRadioOptionContent)),
            style: {
                cursor: "default"
            }
        }, React.createElement("div", {
            className: css(styles.optionStatusContainer)
        }, this.renderOptionStatus()), React.createElement("div", null, this.props.content))), this.props.showRationale && React.createElement("div", {
            className: rationaleClassName
        }, this.props.rationale)), showCrossOut && React.createElement("a", {
            href: "javascript:void 0",
            onClick: this._toggleCrossOut,
            className: css(styles.crossOutLink)
        }, this.props.crossedOut ? i18n.i18nDoNotTranslate("Uncross out") : i18n.i18nDoNotTranslate("Cross out")));
    }
});

module.exports = Choice;