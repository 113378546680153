/**
 * A directory of autogenerated icon components.
 */
module.exports = {
    COS: require("./cos"),
    LOG: require("./log"),
    EQUAL: require("./equal"),
    BACKSPACE: require("./backspace"),
    SQRT: require("./sqrt"),
    EXP: require("./exp"),
    NEQ: require("./neq"),
    GEQ: require("./geq"),
    LN: require("./ln"),
    DISMISS: require("./dismiss"),
    SIN: require("./sin"),
    LT: require("./lt"),
    CUBE_ROOT: require("./cube-root"),
    PLUS: require("./plus"),
    TAN: require("./tan"),
    LEFT: require("./left"),
    UP: require("./up"),
    DOWN: require("./down"),
    LEFT_PAREN: require("./left-paren"),
    RIGHT_PAREN: require("./right-paren"),
    GT: require("./gt"),
    DIVIDE: require("./divide"),
    PERIOD: require("./period"),
    PERCENT: require("./percent"),
    TIMES: require("./times"),
    EXP_3: require("./exp-3"),
    EXP_2: require("./exp-2"),
    RIGHT: require("./right"),
    CDOT: require("./cdot"),
    LOG_N: require("./log-n"),
    LEQ: require("./leq"),
    MINUS: require("./minus"),
    NEGATIVE: require("./minus"),
    RADICAL: require("./radical"),
    FRAC_INCLUSIVE: require("./frac-inclusive"),
    FRAC_EXCLUSIVE: require("./frac-exclusive"),
    JUMP_OUT_PARENTHESES: require("./jump-out-parentheses"),
    JUMP_OUT_EXPONENT: require("./jump-out-exponent"),
    JUMP_OUT_BASE: require("./jump-out-base"),
    JUMP_INTO_NUMERATOR: require("./jump-into-numerator"),
    JUMP_OUT_NUMERATOR: require("./jump-out-numerator"),
    JUMP_OUT_DENOMINATOR: require("./jump-out-denominator")
};