/* eslint-disable react/sort-comp */
var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var TeX = require("react-components/tex.jsx");

var ApiClassNames = require("../perseus-api.jsx").ClassNames;

var ModifyTex = require("../tex-wrangler.js").modifyTex;

var MathOutput = React.createClass({
    displayName: "MathOutput",
    propTypes: {
        value: React.PropTypes.oneOfType([ React.PropTypes.string, React.PropTypes.number ]),
        className: React.PropTypes.string,
        labelText: React.PropTypes.string,
        onFocus: React.PropTypes.func,
        onBlur: React.PropTypes.func
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: "",
            onFocus: function onFocus() {},
            onBlur: function onBlur() {}
        };
    },
    getInitialState: function getInitialState() {
        return {
            focused: false,
            selectorNamespace: _.uniqueId("math-output")
        };
    },
    _getInputClassName: function _getInputClassName() {
        var className = "math-output " + ApiClassNames.INPUT + " " + ApiClassNames.INTERACTIVE;
        this.state.focused && (className += " " + ApiClassNames.FOCUSED);
        this.props.className && (className += " " + this.props.className);
        return className;
    },
    _getDisplayValue: function _getDisplayValue(value) {
        // Cast from (potentially a) number to string
        var displayText = void 0;
        displayText = null != value ? "" + value : "";
        return ModifyTex(displayText);
    },
    render: function render() {
        var divStyle = {
            textAlign: "center"
        };
        return React.createElement("span", {
            ref: "input",
            className: this._getInputClassName(),
            "aria-label": this.props.labelText,
            onMouseDown: this.focus,
            onTouchStart: this.focus
        }, React.createElement("div", {
            style: divStyle
        }, React.createElement(TeX, null, this._getDisplayValue(this.props.value))));
    },
    getValue: function getValue() {
        return this.props.value;
    },
    focus: function focus() {
        if (!this.state.focused) {
            this.props.onFocus();
            this._bindBlurHandler();
            this.setState({
                focused: true
            });
        }
    },
    blur: function blur() {
        if (this.state.focused) {
            this.props.onBlur();
            this._unbindBlurHandler();
            this.setState({
                focused: false
            });
        }
    },
    _bindBlurHandler: function _bindBlurHandler() {
        var _this = this;
        $(document).bind("vclick." + this.state.selectorNamespace, function(e) {
            $(e.target).closest(ReactDOM.findDOMNode(_this)).length || _this.blur();
        });
    },
    _unbindBlurHandler: function _unbindBlurHandler() {
        $(document).unbind("." + this.state.selectorNamespace);
    },
    componentWillUnmount: function componentWillUnmount() {
        this._unbindBlurHandler();
    }
});

module.exports = MathOutput;