var _ = require("underscore");

var knumber = require("kmath").number;

var KhanMath = {
    // Simplify formulas before display
    cleanMath: function cleanMath(expr) {
        return "string" === typeof expr ? expr.replace(/\+\s*-/g, "- ").replace(/-\s*-/g, "+ ").replace(/\^1/g, "") : expr;
    },
    // Bound a number by 1e-6 and 1e20 to avoid exponents after toString
    bound: function bound(num) {
        return 0 === num ? num : num < 0 ? -KhanMath.bound(-num) : Math.max(1e-6, Math.min(num, 1e20));
    },
    factorial: function factorial(x) {
        return x <= 1 ? x : x * KhanMath.factorial(x - 1);
    },
    getGCD: function getGCD(a, b) {
        if (arguments.length > 2) {
            var rest = [].slice.call(arguments, 1);
            return KhanMath.getGCD(a, KhanMath.getGCD.apply(KhanMath, rest));
        }
        var mod = void 0;
        a = Math.abs(a);
        b = Math.abs(b);
        while (b) {
            mod = a % b;
            a = b;
            b = mod;
        }
        return a;
    },
    getLCM: function getLCM(a, b) {
        if (arguments.length > 2) {
            var rest = [].slice.call(arguments, 1);
            return KhanMath.getLCM(a, KhanMath.getLCM.apply(KhanMath, rest));
        }
        return Math.abs(a * b) / KhanMath.getGCD(a, b);
    },
    primes: [ 2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79, 83, 89, 97 ],
    isPrime: function isPrime(n) {
        if (n <= 1) return false;
        if (n < 101) return !!$.grep(KhanMath.primes, function(p, i) {
            return Math.abs(p - n) <= .5;
        }).length;
        if (n <= 1 || n > 2 && n % 2 === 0) return false;
        for (var i = 3, sqrt = Math.sqrt(n); i <= sqrt; i += 2) if (n % i === 0) return false;
        return true;
    },
    getPrimeFactorization: function getPrimeFactorization(number) {
        if (1 === number) return [];
        if (KhanMath.isPrime(number)) return [ number ];
        var maxf = Math.sqrt(number);
        for (var f = 2; f <= maxf; f++) if (number % f === 0) return $.merge(KhanMath.getPrimeFactorization(f), KhanMath.getPrimeFactorization(number / f));
    },
    // Round a number to the nearest increment
    // E.g., if increment = 30 and num = 40, return 30. if increment = 30 and
    //     num = 45, return 60.
    roundToNearest: function roundToNearest(increment, num) {
        return Math.round(num / increment) * increment;
    },
    // Round a number to a certain number of decimal places
    roundTo: function roundTo(precision, num) {
        var factor = Math.pow(10, precision).toFixed(5);
        return Math.round((num * factor).toFixed(5)) / factor;
    },
    /**
     * Return a string of num rounded to a fixed precision decimal places,
     * with an approx symbol if num had to be rounded, and trailing 0s
     */
    toFixedApprox: function toFixedApprox(num, precision) {
        // TODO(aria): Make this locale-dependent like KhanUtil.localeToFixed
        var fixedStr = num.toFixed(precision);
        return knumber.equal(+fixedStr, num) ? fixedStr : "\\approx " + fixedStr;
    },
    /**
     * Return a string of num rounded to precision decimal places, with an
     * approx symbol if num had to be rounded, but no trailing 0s if it was
     * not rounded.
     */
    roundToApprox: function roundToApprox(num, precision) {
        var fixed = KhanMath.roundTo(precision, num);
        return knumber.equal(fixed, num) ? String(fixed) : KhanMath.toFixedApprox(num, precision);
    },
    // toFraction(4/8) => [1, 2]
    // toFraction(0.666) => [333, 500]
    // toFraction(0.666, 0.001) => [2, 3]
    //
    // tolerance can't be bigger than 1, sorry
    toFraction: function toFraction(decimal, tolerance) {
        null == tolerance && (tolerance = Math.pow(2, -46));
        if (decimal < 0 || decimal > 1) {
            var fract = decimal % 1;
            fract += fract < 0 ? 1 : 0;
            var nd = KhanMath.toFraction(fract, tolerance);
            nd[0] += Math.round(decimal - fract) * nd[1];
            return nd;
        }
        if (Math.abs(Math.round(Number(decimal)) - decimal) <= tolerance) return [ Math.round(decimal), 1 ];
        var loN = 0;
        var loD = 1;
        var hiN = 1;
        var hiD = 1;
        var midN = 1;
        var midD = 2;
        while (true) {
            // eslint-disable-line no-constant-condition
            if (Math.abs(Number(midN / midD) - decimal) <= tolerance) return [ midN, midD ];
            if (midN / midD < decimal) {
                loN = midN;
                loD = midD;
            } else {
                hiN = midN;
                hiD = midD;
            }
            midN = loN + hiN;
            midD = loD + hiD;
        }
    },
    // Returns the format (string) of a given numeric string
    // Note: purposively more inclusive than answer-types' predicate.forms
    // That is, it is not necessarily true that interpreted input are numeric
    getNumericFormat: function getNumericFormat(text) {
        text = $.trim(text);
        text = text.replace(/\u2212/, "-").replace(/([+-])\s+/g, "$1");
        if (text.match(/^[+-]?\d+$/)) return "integer";
        if (text.match(/^[+-]?\d+\s+\d+\s*\/\s*\d+$/)) return "mixed";
        var fraction = text.match(/^[+-]?(\d+)\s*\/\s*(\d+)$/);
        return fraction ? parseFloat(fraction[1]) > parseFloat(fraction[2]) ? "improper" : "proper" : text.replace(/[,. ]/g, "").match(/^\d+$/) ? "decimal" : text.match(/(pi?|\u03c0|t(?:au)?|\u03c4|pau)/) ? "pi" : null;
    },
    // Returns a string of the number in a specified format
    toNumericString: function toNumericString(number, format) {
        if (null == number) return "";
        if (0 === number) return "0";
        if ("percent" === format) return 100 * number + "%";
        if ("pi" === format) {
            var fraction = knumber.toFraction(number / Math.PI);
            var numerator = Math.abs(fraction[0]);
            var denominator = fraction[1];
            if (knumber.isInteger(numerator)) {
                return (number < 0 ? "-" : "") + (1 === numerator ? "" : numerator) + "π" + (1 === denominator ? "" : "/" + denominator);
            }
        }
        if (_([ "proper", "improper", "mixed", "fraction" ]).contains(format)) {
            var _fraction = knumber.toFraction(number);
            var _numerator = Math.abs(_fraction[0]);
            var _denominator = _fraction[1];
            var _sign = number < 0 ? "-" : "";
            if (1 === _denominator) return _sign + _numerator;
            if ("mixed" === format) {
                var modulus = _numerator % _denominator;
                var integer = (_numerator - modulus) / _denominator;
                return _sign + (integer ? integer + " " : "") + modulus + "/" + _denominator;
            }
            // otherwise proper, improper, or fraction
            return _sign + _numerator + "/" + _denominator;
        }
        // otherwise (decimal, float, long long)
        return String(number);
    }
};

module.exports = KhanMath;