var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "widget-in-table",
    severity: _rule2.default.Severity.BULK_WARNING,
    selector: "table widget",
    message: "Widget in table:\ndo not put widgets inside of tables."
});