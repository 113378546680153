function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

function _possibleConstructorReturn(self, call) {
    if (!self) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    return !call || "object" !== typeof call && "function" !== typeof call ? self : call;
}

function _inherits(subClass, superClass) {
    if ("function" !== typeof superClass && null !== superClass) throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    subClass.prototype = Object.create(superClass && superClass.prototype, {
        constructor: {
            value: subClass,
            enumerable: false,
            writable: true,
            configurable: true
        }
    });
    superClass && (Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass);
}

/**
 * A component that renders a view pager indicator, with a circular icon for
 * each page.
 */
var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet;

var _require2 = require("../fake-react-native-web"), View = _require2.View;

var _require3 = require("./common-style"), pageIndicatorHeightPx = _require3.pageIndicatorHeightPx, gray68 = _require3.gray68, gray85 = _require3.gray85;

var PagerIcon = function(_React$Component) {
    _inherits(PagerIcon, _React$Component);
    function PagerIcon() {
        _classCallCheck(this, PagerIcon);
        return _possibleConstructorReturn(this, _React$Component.apply(this, arguments));
    }
    PagerIcon.prototype.render = function render() {
        var _props = this.props, active = _props.active, radiusPx = _props.radiusPx;
        var fillColor = active ? gray68 : gray85;
        return React.createElement("svg", {
            width: 2 * radiusPx,
            height: 2 * radiusPx
        }, React.createElement("circle", {
            cx: radiusPx,
            cy: radiusPx,
            r: radiusPx,
            fill: fillColor
        }));
    };
    return PagerIcon;
}(React.Component);

PagerIcon.propTypes = {
    active: React.PropTypes.bool,
    radiusPx: React.PropTypes.number
};

PagerIcon.defaultProps = {
    active: false,
    radiusPx: 4
};

var PagerIndicator = function(_React$Component2) {
    _inherits(PagerIndicator, _React$Component2);
    function PagerIndicator() {
        _classCallCheck(this, PagerIndicator);
        return _possibleConstructorReturn(this, _React$Component2.apply(this, arguments));
    }
    PagerIndicator.prototype.render = function render() {
        var _props2 = this.props, currentPage = _props2.currentPage, numPages = _props2.numPages;
        var pagerIconRadiusPx = 4;
        // Collect the various indicator circles.
        var indicators = [];
        for (var i = 0; i < numPages; i++) indicators.push(React.createElement(PagerIcon, {
            key: i,
            active: i === currentPage,
            radiusPx: 4
        }));
        // Size the box that contains the icons to accommodate for proper
        // spacing, and let Flexbox take care of the details.
        var totalIconWidthPx = 8 * numPages;
        var totalSpacingWidthPx = 8 * (numPages - 1);
        var iconStripSize = {
            width: totalIconWidthPx + totalSpacingWidthPx
        };
        return React.createElement(View, {
            style: styles.indicatorStrip
        }, React.createElement(View, {
            style: styles.iconStrip,
            dynamicStyle: iconStripSize
        }, indicators));
    };
    return PagerIndicator;
}(React.Component);

PagerIndicator.propTypes = {
    currentPage: React.PropTypes.number.isRequired,
    numPages: React.PropTypes.number.isRequired
};

var styles = StyleSheet.create({
    indicatorStrip: {
        backgroundColor: "#F0F1F2",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: pageIndicatorHeightPx
    },
    iconStrip: {
        flexDirection: "row",
        justifyContent: "space-between"
    }
});

module.exports = PagerIndicator;