/**
 * Stub Tag Editor.
 *
 * This is stupidly used by Perseus Zero because I didn't implement
 * the <TagEditor> for Perseus Zero (since everyone wants me to
 * delete it anyways).
 *
 * This is a small wrapper for a TextListEditor that allows us to
 * edit raw Tag ID strings in perseus zero (please don't use this).
 *
 * It also gives a nicer interface for the group metadata editor
 * in local demo mode.
 */
var React = require("react");

var TextListEditor = require("./text-list-editor.jsx");

var EMPTY_ARRAY = [];

var StubTagEditor = React.createClass({
    displayName: "StubTagEditor",
    propTypes: {
        value: React.PropTypes.arrayOf(React.PropTypes.string),
        onChange: React.PropTypes.func.isRequired,
        showTitle: React.PropTypes.bool.isRequired
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: EMPTY_ARRAY,
            showTitle: true
        };
    },
    render: function render() {
        return React.createElement("div", null, this.props.showTitle && React.createElement("div", {
            style: {
                fontSize: 14
            }
        }, "Tags:"), React.createElement(TextListEditor, {
            options: this.props.value || EMPTY_ARRAY,
            layout: "vertical",
            onChange: this.props.onChange
        }));
    }
});

module.exports = StubTagEditor;