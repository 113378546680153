var _newHint;

var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

var React = require("react");

var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var classnames = require("classnames");

var Renderer = require("./renderer.jsx");

var ApiOptions = require("./perseus-api.jsx").Options;

var mediaQueries = require("./styles/media-queries.js");

var _require2 = require("./styles/constants.js"), baseUnitPx = _require2.baseUnitPx, hintBorderWidth = _require2.hintBorderWidth, kaGreen = _require2.kaGreen, gray97 = _require2.gray97;

var Gorgon = require("./gorgon/gorgon.js");

var _require3 = require("./gorgon/proptypes.js"), linterContextProps = _require3.linterContextProps, linterContextDefault = _require3.linterContextDefault;

/* Renders just a hint preview */
var HintRenderer = React.createClass({
    displayName: "HintRenderer",
    propTypes: {
        apiOptions: ApiOptions.propTypes,
        className: React.PropTypes.string,
        hint: React.PropTypes.any,
        lastHint: React.PropTypes.bool,
        lastRendered: React.PropTypes.bool,
        pos: React.PropTypes.number,
        totalHints: React.PropTypes.number,
        findExternalWidgets: React.PropTypes.func,
        linterContext: linterContextProps
    },
    getDefaultProps: function getDefaultProps() {
        return {
            linterContext: linterContextDefault
        };
    },
    getSerializedState: function getSerializedState() {
        return this.refs.renderer.getSerializedState();
    },
    restoreSerializedState: function restoreSerializedState(state, callback) {
        this.refs.renderer.restoreSerializedState(state, callback);
    },
    render: function render() {
        var _props = this.props, apiOptions = _props.apiOptions, className = _props.className, hint = _props.hint, lastHint = _props.lastHint, lastRendered = _props.lastRendered, pos = _props.pos, totalHints = _props.totalHints;
        var isMobile = apiOptions.isMobile;
        var classNames = classnames(!isMobile && "perseus-hint-renderer", isMobile && css(styles.newHint), isMobile && lastRendered && css(styles.lastRenderedNewHint), lastHint && "last-hint", lastRendered && "last-rendered", className);
        // TODO(charlie): Allowing `staticRender` here would require that we
        // extend `HintsRenderer` and `HintRenderer` to implement the full
        // "input' API, so that clients could access the static inputs. Allowing
        // `customKeypad` would require that we extend `ItemRenderer` to support
        // nested inputs in the `HintsRenderer`. For now, we disable these
        // options. Instead, clients will get standard <input/> elements, which
        // aren't nice to use on mobile, but are at least usable.
        var rendererApiOptions = _extends({}, apiOptions, {
            customKeypad: false,
            staticRender: false
        });
        return React.createElement("div", {
            className: classNames,
            tabIndex: "-1"
        }, !apiOptions.isMobile && React.createElement("span", {
            className: "perseus-sr-only"
        }, i18n._("Hint #{ pos }", {
            pos: pos + 1
        })), !apiOptions.isMobile && !apiOptions.satStyling && totalHints && null != pos && React.createElement("span", {
            className: "perseus-hint-label",
            style: {
                display: "block",
                color: apiOptions.hintProgressColor
            }
        }, pos + 1 + " / " + totalHints), React.createElement(Renderer, {
            ref: "renderer",
            widgets: hint.widgets,
            content: hint.content || "",
            images: hint.images,
            apiOptions: rendererApiOptions,
            findExternalWidgets: this.props.findExternalWidgets,
            linterContext: Gorgon.pushContextStack(this.props.linterContext, "hint")
        }));
    }
});

var styles = StyleSheet.create({
    newHint: (_newHint = {
        marginBottom: 1.5 * baseUnitPx,
        borderLeftColor: gray97,
        borderLeftStyle: "solid",
        borderLeftWidth: hintBorderWidth
    }, _newHint[mediaQueries.lgOrSmaller] = {
        paddingLeft: baseUnitPx
    }, _newHint[mediaQueries.smOrSmaller] = {
        paddingLeft: 0
    }, _newHint[":focus"] = {
        outline: "none"
    }, _newHint),
    lastRenderedNewHint: {
        marginBottom: 0,
        borderLeftColor: kaGreen
    }
});

module.exports = HintRenderer;