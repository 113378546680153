var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "heading-sentence-case",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "heading",
    pattern: /^\W*[a-z]/,
    // first letter is lowercase
    message: "First letter is lowercase:\nthe first letter of a heading should be capitalized."
});