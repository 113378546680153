var _typeof = "function" === typeof Symbol && "symbol" === typeof Symbol.iterator ? function(obj) {
    return typeof obj;
} : function(obj) {
    return obj && "function" === typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

var babelPluginFlowReactPropTypes_proptype_ItemObjectNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_ItemObjectNode || require("react").PropTypes.any;

/**
 * Utility functions for constructing and manipulating multi-items.
 *
 * These functions apply *specifically* to Items and ItemTrees - things that
 * actually semantically *are* multi-items. For more general functions for
 * traversing and manipulating *anything* shaped like a multi-item (like a
 * renderer tree or a score tree or, well, a multi-item), see trees.js.
 */
var babelPluginFlowReactPropTypes_proptype_ItemArrayNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_ItemArrayNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_TagsNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_TagsNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_HintNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_HintNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ContentNode = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_ContentNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_ItemTree = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_ItemTree || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Item = require("./item-types.js").babelPluginFlowReactPropTypes_proptype_Item || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Shape = require("./shape-types.js").babelPluginFlowReactPropTypes_proptype_Shape || require("react").PropTypes.any;

var _require = require("./trees.js"), buildMapper = _require.buildMapper;

var shapes = require("./shapes.js");

/**
 * Return a semantically empty ItemTree that conforms to the given shape.
 *
 * - An empty content node has an empty content string and no widgets/images.
 * - An empty hint node has an empty content string and no widgets/images.
 * - An empty array node has no elements.
 * - An empty object node has a semantically empty node for each of its keys.
 *   (That is, we recursively call buildEmptyItemTreeForShape for each key.)
 */
function buildEmptyItemTreeForShape(shape) {
    if ("content" === shape.type) return {
        __type: "content",
        content: "",
        images: {},
        widgets: {}
    };
    if ("hint" === shape.type) return {
        __type: "hint",
        replace: false,
        content: "",
        images: {},
        widgets: {}
    };
    if ("tags" === shape.type) return [];
    if ("array" === shape.type) return [];
    if ("object" === shape.type) {
        var valueShapes = shape.shape;
        var object = {};
        Object.keys(valueShapes).forEach(function(key) {
            object[key] = buildEmptyItemTreeForShape(valueShapes[key]);
        });
        return object;
    }
    throw new Error("unexpected shape type " + shape.type);
}

/**
 * Return a semantically empty Item that conforms to the given shape.
 *
 * - An empty content node has an empty content string and no widgets/images.
 * - An empty hint node has an empty content string and no widgets/images.
 * - An empty array node has no elements.
 * - An empty object node has a semantically empty node for each of its keys.
 *   (That is, we recursively call buildEmptyItemTreeForShape for each key.)
 */
function buildEmptyItemForShape(shape) {
    return treeToItem(buildEmptyItemTreeForShape(shape));
}

/**
 * Given an Item and its Shape, yield all of its content nodes to the callback.
 */
function findContentNodesInItem(item, shape, callback) {
    var itemTree = itemToTree(item);
    buildMapper().setContentMapper(callback).mapTree(itemTree, shape);
}

/**
 * Given an Item and its Shape, yield all of its hint nodes to the callback.
 */
function findHintNodesInItem(item, shape, callback) {
    var itemTree = itemToTree(item);
    buildMapper().setHintMapper(callback).mapTree(itemTree, shape);
}

/**
 * Given an ItemTree, return a Shape that it conforms to.
 *
 * The Shape might not be complete or correct Shape that this Item was designed
 * for. If you have access to the intended Shape, use that instead.
 */
function inferItemShape(item) {
    return inferItemTreeShape(itemToTree(item));
}

function inferItemTreeShape(node) {
    if (Array.isArray(node)) return node.length ? "string" === typeof node[0] ? shapes.tags : shapes.arrayOf(inferItemTreeShape(node[0])) : shapes.arrayOf(shapes.content);
    if (// TODO(mdr): Remove #LegacyContentNode support.
    "object" !== ("undefined" === typeof node ? "undefined" : _typeof(node)) || "content" !== node.__type && "item" !== node.__type) {
        if ("object" === ("undefined" === typeof node ? "undefined" : _typeof(node)) && "hint" === node.__type) return shapes.hint;
        if ("object" === ("undefined" === typeof node ? "undefined" : _typeof(node))) {
            var valueShapes = {};
            Object.keys(node).forEach(function(key) {
                // $FlowFixMe: Not sure why this property deref is an error.
                valueShapes[key] = inferItemTreeShape(node[key]);
            });
            return shapes.shape(valueShapes);
        }
        throw new Error("unexpected multi-item node " + JSON.stringify(node));
    }
    return shapes.content;
}

/**
 * Convert the given ItemTree to an Item, by wrapping it in the `_multi` key.
 */
function itemToTree(item) {
    return item._multi;
}

/**
 * Convert the given Item to an ItemTree, by unwrapping the `_multi` key.
 */
function treeToItem(node) {
    return {
        _multi: node
    };
}

module.exports = {
    buildEmptyItemTreeForShape: buildEmptyItemTreeForShape,
    buildEmptyItemForShape: buildEmptyItemForShape,
    findContentNodesInItem: findContentNodesInItem,
    findHintNodesInItem: findHintNodesInItem,
    inferItemShape: inferItemShape,
    itemToTree: itemToTree,
    treeToItem: treeToItem
};