var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable comma-dangle, indent, no-redeclare, no-undef, no-unused-vars, no-var, object-curly-spacing, react/jsx-closing-bracket-location, react/jsx-indent-props, react/sort-comp */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var classNames = require("classnames");

var React = require("react");

var ReactDOM = require("react-dom");

var Tooltip = require("react-components/tooltip.jsx");

var _ = require("underscore");

var ApiOptions = require("../perseus-api.jsx").Options;

var Changeable = require("../mixins/changeable.jsx");

var ApiOptions = require("../perseus-api.jsx").Options;

var ApiClassNames = require("../perseus-api.jsx").ClassNames;

var KhanAnswerTypes = require("../util/answer-types.js");

var InlineIcon = require("../components/inline-icon.jsx");

var InputWithExamples = require("../components/input-with-examples.jsx");

var MathInput = require("../components/math-input.jsx");

var TexButtons = require("../components/tex-buttons.jsx");

var KeypadInput = require("../../math-input").components.KeypadInput;

var _require$propTypes = require("../../math-input").propTypes, keypadConfigurationPropType = _require$propTypes.keypadConfigurationPropType, keypadElementPropType = _require$propTypes.keypadElementPropType;

var KeypadTypes = require("../../math-input").consts.KeypadTypes;

var _require = require("../gorgon/proptypes.js"), linterContextProps = _require.linterContextProps, linterContextDefault = _require.linterContextDefault;

var _require2 = require("../icon-paths.js"), iconExclamationSign = _require2.iconExclamationSign;

var lens = require("../../hubble/index.js");

var ERROR_MESSAGE = i18n._("Sorry, I don't understand that!");

// TODON'T(emily): Don't delete these.
var NO_ANSWERS_WARNING = [ "An expression without an answer", "is no expression to me.", "Who can learn from an input", "like the one that I see?", "Put something in there", "won't you please?", "A few digits will do -", "might I suggest some threes?" ].join("\n");

var NO_CORRECT_ANSWERS_WARNING = "This question is probably going to be too hard because the expression has no correct answer.";

var SIMPLIFY_WARNING = function SIMPLIFY_WARNING(str) {
    return '"' + str + '" is required to be simplified but is not considered simplified by our fancy computer algebra system. This will be graded as incorrect.';
};

var PARSE_WARNING = function PARSE_WARNING(str) {
    return '"' + str + "\" <- you sure that's math?";
};

var NOT_SPECIFIED_WARNING = function NOT_SPECIFIED_WARNING(ix) {
    return "mind filling in answer " + ix + "? (the blank one)";
};

var insertBraces = function insertBraces(value) {
    // HACK(alex): Make sure that all LaTeX super/subscripts are wrapped
    // in curly braces to avoid the mismatch between KAS and LaTeX sup/sub
    // parsing.
    //
    // What exactly is this mismatch? Due to its heritage of parsing plain
    // text math from <OldExpression />, KAS parses "x^12" as x^(12).
    // This is both generally what the user expects to happen, and is
    // consistent with other computer algebra systems. It is NOT
    // consistent with LaTeX however, where x^12 is equivalent to x^{1}2.
    //
    // Since the only LaTeX we parse comes from MathQuill, this wouldn't
    // be a problem if MathQuill just always gave us the latter version
    // (with explicit braces). However, instead it always gives the former.
    // This behavior is baked in pretty deep; my naive attempts at changing
    // it triggered all sorts of confusing errors. So instead we just make
    // sure to add in any missing braces before grading MathQuill input.
    //
    // TODO(alex): Properly hack MathQuill to always use explicit braces.
    return value.replace(/([_^])([^{])/g, "$1{$2}");
};

// The new, MathQuill input expression widget
var Expression = React.createClass({
    displayName: "Expression",
    propTypes: _extends({}, Changeable.propTypes, {
        apiOptions: ApiOptions.propTypes,
        buttonSets: TexButtons.buttonSetsType,
        buttonsVisible: React.PropTypes.oneOf([ "always", "never", "focused" ]),
        functions: React.PropTypes.arrayOf(React.PropTypes.string),
        keypadConfiguration: keypadConfigurationPropType,
        keypadElement: keypadElementPropType,
        times: React.PropTypes.bool,
        trackInteraction: React.PropTypes.func.isRequired,
        value: React.PropTypes.string,
        widgetId: React.PropTypes.string.isRequired,
        linterContext: linterContextProps
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            value: "",
            times: false,
            functions: [],
            buttonSets: [ "basic", "trig", "prealgebra", "logarithms" ],
            onFocus: function onFocus() {},
            onBlur: function onBlur() {},
            apiOptions: ApiOptions.defaults,
            linterContext: linterContextDefault
        };
    },
    getInitialState: function getInitialState() {
        return {
            showErrorTooltip: false,
            showErrorText: false
        };
    },
    change: function change() {
        for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) args[_key] = arguments[_key];
        return Changeable.change.apply(this, args);
    },
    parse: function parse(value, props) {
        // TODO(jack): Disable icu for content creators here, or
        // make it so that solution answers with ','s or '.'s work
        var options = _.pick(props || this.props, "functions");
        window.icu && window.icu.getDecimalFormatSymbols && _.extend(options, window.icu.getDecimalFormatSymbols());
        return KAS.parse(insertBraces(value), options);
    },
    render: function render() {
        var _this = this;
        if (this.props.apiOptions.customKeypad) return React.createElement(KeypadInput, {
            ref: "input",
            value: this.props.value,
            keypadElement: this.props.keypadElement,
            onChange: this.changeAndTrack,
            onFocus: function onFocus() {
                _this.props.keypadElement.configure(_this.props.keypadConfiguration, function() {
                    _this.isMounted() && _this._handleFocus();
                });
            },
            onBlur: this._handleBlur
        });
        if (this.props.apiOptions.staticRender) // To make things slightly easier, we just use an InputWithExamples
        // component to handle the static rendering, which is the same
        // component used by InputNumber and NumericInput
        return React.createElement(InputWithExamples, {
            ref: "input",
            value: this.props.value,
            type: "tex",
            examples: [],
            shouldShowExamples: false,
            onChange: this.changeAndTrack,
            onFocus: this._handleFocus,
            onBlur: this._handleBlur,
            id: this.props.widgetId,
            linterContext: this.props.linterContext
        });
        // TODO(alex): Style this tooltip to be more consistent with other
        // tooltips on the site; align to left middle (once possible)
        var errorTooltip = React.createElement("span", {
            className: "error-tooltip"
        }, React.createElement(Tooltip, {
            className: "error-text-container",
            horizontalPosition: "right",
            horizontalAlign: "left",
            verticalPosition: "top",
            arrowSize: 10,
            borderColor: "#fcc335",
            show: this.state.showErrorText
        }, React.createElement("span", {
            className: "error-icon",
            onMouseEnter: function onMouseEnter() {
                _this.setState({
                    showErrorText: true
                });
            },
            onMouseLeave: function onMouseLeave() {
                _this.setState({
                    showErrorText: false
                });
            },
            onClick: function onClick() {
                // TODO(alex): Better error feedback for mobile
                _this.setState({
                    showErrorText: !_this.state.showErrorText
                });
            }
        }, React.createElement(InlineIcon, iconExclamationSign)), React.createElement("div", {
            className: "error-text"
        }, ERROR_MESSAGE)));
        var className = classNames({
            "perseus-widget-expression": true,
            "show-error-tooltip": this.state.showErrorTooltip
        });
        return React.createElement("span", {
            className: className
        }, React.createElement(MathInput, {
            ref: "input",
            className: ApiClassNames.INTERACTIVE,
            value: this.props.value,
            onChange: this.changeAndTrack,
            convertDotToTimes: this.props.times,
            buttonsVisible: this.props.buttonsVisible || "focused",
            buttonSets: this.props.buttonSets,
            onFocus: this._handleFocus,
            onBlur: this._handleBlur
        }), this.state.showErrorTooltip && errorTooltip);
    },
    changeAndTrack: function changeAndTrack(e, cb) {
        this.change("value", e, cb);
        this.props.trackInteraction();
    },
    _handleFocus: function _handleFocus() {
        this.props.onFocus([]);
    },
    _handleBlur: function _handleBlur() {
        this.props.onBlur([]);
    },
    errorTimeout: null,
    // Whenever the input value changes, attempt to parse it.
    //
    // Clear any errors if this parse succeeds, show an error within a second
    // if it fails.
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        var _this2 = this;
        if (!_.isEqual(this.props.value, nextProps.value) || !_.isEqual(this.props.functions, nextProps.functions)) {
            clearTimeout(this.errorTimeout);
            this.parse(nextProps.value, nextProps).parsed ? this.setState({
                showErrorTooltip: false
            }) : // Store timeout ID so that we can clear it above
            this.errorTimeout = setTimeout(function() {
                false !== _this2.props.apiOptions.onInputError(null, // reserved for some widget identifier
                _this2.props.value, ERROR_MESSAGE) && _this2.setState({
                    showErrorTooltip: true
                });
            }, 2e3);
        }
    },
    componentWillUnmount: function componentWillUnmount() {
        clearTimeout(this.errorTimeout);
    },
    focus: function focus() {
        this.props.apiOptions.customKeypad && this.refs.input.focus();
        return true;
    },
    focusInputPath: function focusInputPath(inputPath) {
        this.refs.input.focus();
    },
    blurInputPath: function blurInputPath(inputPath) {
        this.refs.input.blur();
    },
    // HACK(joel)
    insert: function insert(text) {
        this.props.apiOptions.staticRender || this.refs.input.insert(text);
    },
    getInputPaths: function getInputPaths() {
        // The widget itself is an input, so we return a single empty list to
        // indicate this.
        return [ [] ];
    },
    getGrammarTypeForPath: function getGrammarTypeForPath(inputPath) {
        return "expression";
    },
    setInputValue: function setInputValue(path, newValue, cb) {
        this.props.onChange({
            value: newValue
        }, cb);
    },
    getAcceptableFormatsForInputPath: function getAcceptableFormatsForInputPath() {
        // TODO(charlie): What format does the mobile team want this in?
        return null;
    },
    getUserInput: function getUserInput() {
        return insertBraces(this.props.value);
    },
    simpleValidate: function simpleValidate(rubric, onInputError) {
        onInputError = onInputError || function() {};
        return Expression.validate(this.getUserInput(), rubric, onInputError);
    }
});

/* Content creators input a list of answers which are matched from top to
 * bottom. The intent is that they can include spcific solutions which should
 * be graded as correct or incorrect (or ungraded!) first, then get more
 * general.
 *
 * We iterate through each answer, trying to match it with the user's input
 * using the following angorithm:
 * - Try to parse the user's input. If it doesn't parse then return "not
 *   graded".
 * - For each answer:
 *   ~ Try to validate the user's input against the answer. The answer is
 *     expected to parse.
 *   ~ If the user's input validates (the validator judges it "correct"), we've
 *     matched and can stop considering answers.
 * - If there were no matches or the matching answer is considered "ungraded",
 *   show the user an error. TODO(joel) - what error?
 * - Otherwise, pass through the resulting points and message.
 */
_.extend(Expression, {
    validate: function validate(state, rubric, onInputError) {
        var options = _.clone(rubric);
        window.icu && window.icu.getDecimalFormatSymbols && _.extend(options, window.icu.getDecimalFormatSymbols());
        var createValidator = function createValidator(answer) {
            // We don't give options to KAS.parse here because that is
            // parsing the solution answer, not the student answer, and we
            // don't want a solution to work if the student is using a
            // different language but not in english.
            return KhanAnswerTypes.expression.createValidatorFunctional(KAS.parse(answer.value, rubric).expr, _({}).extend(options, {
                simplify: answer.simplify,
                form: answer.form
            }));
        };
        // find the first result to match the user's input
        var result;
        var matchingAnswer;
        var allEmpty = true;
        var foundMatch = !!_(rubric.answerForms).find(function(answer) {
            var validate = createValidator(answer);
            // save these because they'll be needed if this answer matches
            result = validate(state);
            matchingAnswer = answer;
            allEmpty = allEmpty && result.empty;
            // short-circuit as soon as an answer matches
            return result.correct;
        });
        var message = result && result.message;
        // now check to see whether it's considered correct, incorrect, or
        // ungraded
        if (foundMatch) {
            if ("ungraded" === matchingAnswer.considered) {
                return {
                    type: "invalid",
                    message: false === onInputError(null, // reserved for some widget identifier
                    state, message) ? null : message
                };
            }
            // TODO(eater): Seems silly to translate result to this
            // invalid/points thing and immediately translate it back in
            // ItemRenderer.scoreInput()
            return {
                type: "points",
                earned: "correct" === matchingAnswer.considered ? 1 : 0,
                total: 1,
                message: message
            };
        }
        return allEmpty ? {
            type: "invalid",
            message: null
        } : {
            type: "points",
            earned: 0,
            total: 1
        };
    }
});

/**
 * Determine the keypad configuration parameters for the input, based on the
 * provided properties.
 *
 * There are two configuration parameters to be passed to the keypad:
 *   (1) The keypad type. For the Expression widget, we always use the
 *       Expression keypad.
 *   (2) The extra keys; namely, any variables or constants (like Pi) that need
 *       to be included as keys on the keypad. These are scraped from the answer
 *       forms.
 */
var keypadConfigurationForProps = function keypadConfigurationForProps(props) {
    // Always use the Expression keypad, regardless of the button sets that have
    // been enabled.
    var keypadType = KeypadTypes.EXPRESSION;
    // Extract any and all variables and constants from the answer forms.
    var uniqueExtraVariables = {};
    var uniqueExtraConstants = {};
    for (var _iterator = props.answerForms, _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator](); ;) {
        var _ref;
        if (_isArray) {
            if (_i >= _iterator.length) break;
            _ref = _iterator[_i++];
        } else {
            _i = _iterator.next();
            if (_i.done) break;
            _ref = _i.value;
        }
        var answerForm = _ref;
        var maybeExpr = KAS.parse(answerForm.value, props);
        maybeExpr.parsed && !function() {
            var expr = maybeExpr.expr;
            // The keypad expects Greek letters to be capitalized (e.g., it
            // requires `PI` instead of `pi`). Right now, it only supports Pi
            // and Theta, so we special-case.
            var isGreek = function isGreek(symbol) {
                return "pi" === symbol || "theta" === symbol;
            };
            var toKey = function toKey(symbol) {
                return isGreek(symbol) ? symbol.toUpperCase() : symbol;
            };
            for (var _iterator2 = expr.getVars(), _isArray2 = Array.isArray(_iterator2), _i2 = 0, _iterator2 = _isArray2 ? _iterator2 : _iterator2[Symbol.iterator](); ;) {
                var _ref2;
                if (_isArray2) {
                    if (_i2 >= _iterator2.length) break;
                    _ref2 = _iterator2[_i2++];
                } else {
                    _i2 = _iterator2.next();
                    if (_i2.done) break;
                    _ref2 = _i2.value;
                }
                uniqueExtraVariables[toKey(_ref2)] = true;
            }
            for (var _iterator3 = expr.getConsts(), _isArray3 = Array.isArray(_iterator3), _i3 = 0, _iterator3 = _isArray3 ? _iterator3 : _iterator3[Symbol.iterator](); ;) {
                var _ref3;
                if (_isArray3) {
                    if (_i3 >= _iterator3.length) break;
                    _ref3 = _iterator3[_i3++];
                } else {
                    _i3 = _iterator3.next();
                    if (_i3.done) break;
                    _ref3 = _i3.value;
                }
                uniqueExtraConstants[toKey(_ref3)] = true;
            }
        }();
    }
    // TODO(charlie): Alert the keypad as to which of these symbols should be
    // treated as functions.
    var extraVariables = Object.keys(uniqueExtraVariables);
    extraVariables.sort();
    var extraConstants = Object.keys(uniqueExtraConstants);
    extraConstants.sort();
    var extraKeys = [].concat(extraVariables, extraConstants);
    extraKeys.length || // If there are no extra symbols available, we include Pi anyway, so
    // that the "extra symbols" button doesn't appear empty.
    extraKeys.push("PI");
    return {
        keypadType: keypadType,
        extraKeys: extraKeys
    };
};

/*
 * v0 props follow this schema:
 *
 *     times: bool
 *     buttonSets: [string]
 *     functions: [string]
 *     buttonsVisible: "always" | "focused" | "never"
 *
 *     value: string
 *     form: bool
 *     simplify: bool
 *
 * v1 props follow this schema:
 *
 *     times: bool
 *     buttonSets: [string]
 *     functions: [string]
 *     buttonsVisible: "always" | "focused" | "never"
 *
 *     answerForms: [{
 *         considered: "correct" | "ungraded" | "incorrect"
 *         form: bool
 *         simplify: bool
 *         value: string
 *     }]
 */
var propUpgrades = {
    1: function _(v0props) {
        return {
            times: v0props.times,
            buttonSets: v0props.buttonSets,
            functions: v0props.functions,
            buttonsVisible: v0props.buttonsVisible,
            answerForms: [ {
                considered: "correct",
                form: v0props.form,
                simplify: v0props.simplify,
                value: v0props.value,
                key: 0
            } ]
        };
    }
};

module.exports = {
    name: "expression",
    displayName: "Expression / Equation",
    defaultAlignment: "inline-block",
    widget: Expression,
    transform: function transform(editorProps) {
        var times = editorProps.times, functions = editorProps.functions, buttonSets = editorProps.buttonSets, buttonsVisible = editorProps.buttonsVisible;
        return {
            keypadConfiguration: keypadConfigurationForProps(editorProps),
            times: times,
            functions: functions,
            buttonSets: buttonSets,
            buttonsVisible: buttonsVisible
        };
    },
    version: {
        major: 1,
        minor: 0
    },
    propUpgrades: propUpgrades,
    // For use by the editor
    Expression: Expression,
    isLintable: true
};