var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable no-var, prefer-spread */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var ApiOptions = require("./perseus-api.jsx").Options;

var HintsRenderer = require("./hints-renderer.jsx");

var Renderer = require("./renderer.jsx");

var ProvideKeypad = require("./mixins/provide-keypad.jsx");

var Util = require("./util.js");

var _require = require("./interactive2/objective_.js"), mapObject = _require.mapObject;

var Gorgon = require("./gorgon/gorgon.js");

var _require2 = require("./gorgon/proptypes.js"), linterContextProps = _require2.linterContextProps, linterContextDefault = _require2.linterContextDefault;

var RP = React.PropTypes;

var ItemRenderer = React.createClass({
    displayName: "ItemRenderer",
    propTypes: _extends({}, ProvideKeypad.propTypes, {
        // defaults are set in `this.update()` so as to adhere to
        // `ApiOptions.PropTypes`, though the API options that are passed in
        // can be in any degree of completeness
        apiOptions: RP.shape({
            interactionCallback: RP.func,
            onFocusChange: RP.func,
            setDrawingAreaAvailable: RP.func
        }),
        // Whether this component should control hiding/showing peripheral
        // item-related components (for list, see item.answerArea below).
        // TODO(alex): Generalize this to an 'expectsToBeInTemplate' prop
        controlPeripherals: RP.bool,
        hintsAreaSelector: RP.string,
        initialHintsVisible: RP.number,
        item: RP.shape({
            answerArea: RP.shape({
                calculator: RP.bool,
                chi2Table: RP.bool,
                periodicTable: RP.bool,
                tTable: RP.bool,
                zTable: RP.bool
            }),
            hints: RP.arrayOf(RP.object),
            question: RP.object
        }).isRequired,
        onShowCalculator: RP.func,
        onShowChi2Table: RP.func,
        onShowPeriodicTable: RP.func,
        onShowTTable: RP.func,
        onShowZTable: RP.func,
        problemNum: RP.number,
        reviewMode: React.PropTypes.bool,
        savedState: RP.any,
        workAreaSelector: RP.string,
        linterContext: linterContextProps,
        legacyPerseusLint: React.PropTypes.arrayOf(React.PropTypes.string)
    }),
    getDefaultProps: function getDefaultProps() {
        return {
            apiOptions: {},
            // defaults are set in `this.update()`
            controlPeripherals: true,
            hintsAreaSelector: "#hintsarea",
            initialHintsVisible: 0,
            workAreaSelector: "#workarea",
            reviewMode: false,
            linterContext: linterContextDefault
        };
    },
    getInitialState: function getInitialState() {
        return _extends({}, ProvideKeypad.getInitialState(), {
            hintsVisible: this.props.initialHintsVisible,
            questionCompleted: false,
            questionHighlightedWidgets: []
        });
    },
    componentDidMount: function componentDidMount() {
        ProvideKeypad.componentDidMount.call(this);
        this.props.controlPeripherals && this.props.apiOptions.setDrawingAreaAvailable && this.props.apiOptions.setDrawingAreaAvailable(true);
        this._currentFocus = null;
        this.update();
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        this.setState({
            questionHighlightedWidgets: []
        });
    },
    componentDidUpdate: function componentDidUpdate() {
        this.update();
    },
    componentWillUnmount: function componentWillUnmount() {
        ProvideKeypad.componentWillUnmount.call(this);
        ReactDOM.unmountComponentAtNode(document.querySelector(this.props.workAreaSelector));
        ReactDOM.unmountComponentAtNode(document.querySelector(this.props.hintsAreaSelector));
        if (this.props.controlPeripherals) {
            var answerArea = this.props.item.answerArea || {};
            answerArea.calculator && $("#calculator").hide();
            answerArea.periodicTable && $(".periodic-table-info-box").hide();
            answerArea.zTable && $(".z-table-info-box").hide();
            answerArea.tTable && $(".t-table-info-box").hide();
            answerArea.chi2Table && $(".chi2-table-info-box").hide();
        }
    },
    keypadElement: function keypadElement() {
        return ProvideKeypad.keypadElement.call(this);
    },
    update: function update() {
        var apiOptions = _extends({}, ApiOptions.defaults, this.props.apiOptions, {
            onFocusChange: this._handleFocusChange
        });
        // Since the item renderer works by rendering things into three divs
        // that have completely different places in the DOM, we have to do this
        // strangeness instead of relying on React's normal render() method.
        // TODO(alpert): Figure out how to clean this up somehow
        this.questionRenderer = ReactDOM.render(React.createElement(Renderer, _extends({
            keypadElement: this.keypadElement(),
            problemNum: this.props.problemNum,
            onInteractWithWidget: this.handleInteractWithWidget,
            highlightedWidgets: this.state.questionHighlightedWidgets,
            apiOptions: apiOptions,
            questionCompleted: this.state.questionCompleted,
            reviewMode: this.props.reviewMode,
            savedState: this.props.savedState,
            linterContext: Gorgon.pushContextStack(this.props.linterContext, "question")
        }, this.props.item.question, {
            legacyPerseusLint: this.props.legacyPerseusLint
        })), document.querySelector(this.props.workAreaSelector));
        this.hintsRenderer = ReactDOM.render(React.createElement(HintsRenderer, {
            hints: this.props.item.hints,
            hintsVisible: this.state.hintsVisible,
            apiOptions: apiOptions,
            linterContext: Gorgon.pushContextStack(this.props.linterContext, "hints")
        }), document.querySelector(this.props.hintsAreaSelector));
        var answerArea = this.props.item.answerArea || {};
        if (this.props.controlPeripherals) {
            $("#calculator").toggle(answerArea.calculator || false);
            $(".periodic-table-info-box").toggle(answerArea.periodicTable || false);
            $(".z-table-info-box").toggle(answerArea.zTable || false);
            $(".t-table-info-box").toggle(answerArea.tTable || false);
            $(".chi2-table-info-box").toggle(answerArea.chi2Table || false);
        } else {
            answerArea.calculator && this.props.onShowCalculator && this.props.onShowCalculator();
            answerArea.periodicTable && this.props.onShowPeriodicTable && this.props.onShowPeriodicTable();
            answerArea.zTable && this.props.onShowZTable && this.props.onShowZTable();
            answerArea.tTable && this.props.onShowTTable && this.props.onShowTTable();
            answerArea.chi2Table && this.props.onShowChi2Table && this.props.onShowChi2Table();
        }
        if (apiOptions.answerableCallback) {
            var isAnswerable = 0 === this.questionRenderer.emptyWidgets().length;
            apiOptions.answerableCallback(isAnswerable);
        }
    },
    _handleFocusChange: function _handleFocusChange(newFocus, oldFocus) {
        null != newFocus ? this._setCurrentFocus(newFocus) : this._onRendererBlur(oldFocus);
    },
    // Sets the current focus path and element and send an onChangeFocus event
    // back to our parent.
    _setCurrentFocus: function _setCurrentFocus(newFocus) {
        var _this = this;
        var keypadElement = this.keypadElement();
        // By the time this happens, newFocus cannot be a prefix of
        // prevFocused, since we must have either been called from
        // an onFocusChange within a renderer, which is only called when
        // this is not a prefix, or between the question and answer areas,
        // which can never prefix each other.
        var prevFocus = this._currentFocus;
        this._currentFocus = newFocus;
        // Determine whether the newly focused path represents an input.
        var inputPaths = this.getInputPaths();
        var didFocusInput = this._currentFocus && inputPaths.some(function(inputPath) {
            return Util.inputPathsEqual(inputPath, _this._currentFocus);
        });
        null != this.props.apiOptions.onFocusChange && this.props.apiOptions.onFocusChange(this._currentFocus, prevFocus, didFocusInput && keypadElement && ReactDOM.findDOMNode(keypadElement));
        keypadElement && (didFocusInput ? keypadElement.activate() : keypadElement.dismiss());
    },
    _onRendererBlur: function _onRendererBlur(blurPath) {
        var _this2 = this;
        var blurringFocusPath = this._currentFocus;
        // Failsafe: abort if ID is different, because focus probably happened
        // before blur.
        if (!Util.inputPathsEqual(blurPath, blurringFocusPath)) return;
        // Wait until after any new focus events fire this tick before
        // declaring that nothing is focused, since if there were a focus change
        // across Renderers (e.g., from the HintsRenderer to the
        // QuestionRenderer), we could receive the blur before the focus.
        setTimeout(function() {
            Util.inputPathsEqual(_this2._currentFocus, blurringFocusPath) && _this2._setCurrentFocus(null);
        });
    },
    /**
     * Accepts a question area widgetId, or an answer area widgetId of
     * the form "answer-input-number 1", or the string "answer-area"
     * for the whole answer area (if the answer area is a single widget).
     */
    _setWidgetProps: function _setWidgetProps(widgetId, newProps, callback) {
        this.questionRenderer._setWidgetProps(widgetId, newProps, callback);
    },
    _handleAPICall: function _handleAPICall(functionName, path) {
        // Get arguments to pass to function, including `path`.
        var functionArgs = _.rest(arguments);
        // TODO(charlie): Extend this API to support inputs in the
        // HintsRenderer as well.
        var caller = this.questionRenderer;
        return caller[functionName].apply(caller, functionArgs);
    },
    setInputValue: function setInputValue(path, newValue, focus) {
        return this._handleAPICall("setInputValue", path, newValue, focus);
    },
    focusPath: function focusPath(path) {
        return this._handleAPICall("focusPath", path);
    },
    blurPath: function blurPath(path) {
        return this._handleAPICall("blurPath", path);
    },
    getDOMNodeForPath: function getDOMNodeForPath(path) {
        return this._handleAPICall("getDOMNodeForPath", path);
    },
    getGrammarTypeForPath: function getGrammarTypeForPath(path) {
        return this._handleAPICall("getGrammarTypeForPath", path);
    },
    getInputPaths: function getInputPaths() {
        return this.questionRenderer.getInputPaths();
    },
    handleInteractWithWidget: function handleInteractWithWidget(widgetId) {
        var withRemoved = _.difference(this.state.questionHighlightedWidgets, [ widgetId ]);
        this.setState({
            questionCompleted: false,
            questionHighlightedWidgets: withRemoved
        });
        this.props.apiOptions.interactionCallback && this.props.apiOptions.interactionCallback();
    },
    focus: function focus() {
        return this.questionRenderer.focus();
    },
    blur: function blur() {
        this._currentFocus && this.blurPath(this._currentFocus);
    },
    showHint: function showHint() {
        this.state.hintsVisible < this.getNumHints() && this.setState({
            hintsVisible: this.state.hintsVisible + 1
        });
    },
    getNumHints: function getNumHints() {
        return this.props.item.hints.length;
    },
    /**
     * Grades the item.
     *
     * Returns a KE-style score of {
     *     empty: bool,
     *     correct: bool,
     *     message: string|null,
     *     guess: Array
     * }
     */
    scoreInput: function scoreInput() {
        var guessAndScore = this.questionRenderer.guessAndScore();
        var guess = guessAndScore[0];
        var score = guessAndScore[1];
        // Continue to include an empty guess for the now defunct answer area.
        // TODO(alex): Check whether we rely on the format here for
        //             analyzing ProblemLogs. If not, remove this layer.
        var maxCompatGuess = [ guess, [] ];
        var keScore = Util.keScoreFromPerseusScore(score, maxCompatGuess, this.questionRenderer.getSerializedState());
        var emptyQuestionAreaWidgets = this.questionRenderer.emptyWidgets();
        this.setState({
            questionCompleted: keScore.correct,
            questionHighlightedWidgets: emptyQuestionAreaWidgets
        });
        return keScore;
    },
    /**
     * Returns an array of all widget IDs in the order they occur in
     * the question content.
     */
    getWidgetIds: function getWidgetIds() {
        return this.questionRenderer.getWidgetIds();
    },
    /**
     * Returns an object mapping from widget ID to KE-style score.
     * The keys of this object are the values of the array returned
     * from `getWidgetIds`.
     */
    scoreWidgets: function scoreWidgets() {
        var qScore = this.questionRenderer.scoreWidgets();
        var qGuess = this.questionRenderer.getUserInputForWidgets();
        var state = this.questionRenderer.getSerializedState();
        return mapObject(qScore, function(score, id) {
            return Util.keScoreFromPerseusScore(score, qGuess[id], state);
        });
    },
    /**
     * Get a representation of the current state of the item.
     */
    getSerializedState: function getSerializedState() {
        return {
            question: this.questionRenderer.getSerializedState(),
            hints: this.hintsRenderer.getSerializedState()
        };
    },
    restoreSerializedState: function restoreSerializedState(state, callback) {
        // We need to wait for both the question renderer and the hints
        // renderer to finish restoring their states.
        var numCallbacks = 2;
        var fireCallback = function fireCallback() {
            --numCallbacks;
            callback && 0 === numCallbacks && callback();
        };
        this.questionRenderer.restoreSerializedState(state.question, fireCallback);
        this.hintsRenderer.restoreSerializedState(state.hints, fireCallback);
    },
    showRationalesForCurrentlySelectedChoices: function showRationalesForCurrentlySelectedChoices() {
        this.questionRenderer.showRationalesForCurrentlySelectedChoices();
    },
    deselectIncorrectSelectedChoices: function deselectIncorrectSelectedChoices() {
        this.questionRenderer.deselectIncorrectSelectedChoices();
    },
    render: function render() {
        return React.createElement("div", null);
    }
});

module.exports = ItemRenderer;