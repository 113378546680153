/**
 * An autogenerated component that renders the JUMP_OUT_PARENTHESES iconograpy in SVG.
 *
 * Generated with: https://gist.github.com/crm416/3c7abc88e520eaed72347af240b32590.
 */
var React = require("react");

var JumpOutParentheses = function JumpOutParentheses() {
    return React.createElement("svg", {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48"
    }, React.createElement("g", {
        fill: "none",
        fillRule: "evenodd"
    }, React.createElement("path", {
        fill: "none",
        d: "M0 0h48v48H0z"
    }), React.createElement("path", {
        fill: "none",
        d: "M12 12h24v24H12z"
    }), React.createElement("path", {
        d: "M24 18c3 3 3 9 0 12M18 18c-3 3-3 9 0 12",
        stroke: "#888D93",
        strokeWidth: "2",
        strokeLinecap: "round",
        strokeLinejoin: "round"
    }), React.createElement("path", {
        stroke: "#78C008",
        strokeWidth: "2",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        d: "M32 27l3-3-3-3M22 24h12"
    })));
};

module.exports = JumpOutParentheses;