/* eslint-disable react/prop-types, react/sort-comp */
var classNames = require("classnames");

var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var TexButtons = require("./tex-buttons.jsx");

// TODO(alex): Package MathQuill
var PT = React.PropTypes;

// A WYSIWYG math input that calls `onChange(LaTeX-string)`
var MathInput = React.createClass({
    displayName: "MathInput",
    propTypes: {
        value: PT.string,
        onChange: PT.func.isRequired,
        convertDotToTimes: PT.bool,
        buttonsVisible: PT.oneOf([ "always", "never", "focused" ]),
        buttonSets: TexButtons.buttonSetsType.isRequired,
        labelText: React.PropTypes.string,
        onFocus: PT.func,
        onBlur: PT.func
    },
    render: function render() {
        var className = classNames({
            "perseus-math-input": true,
            // mathquill usually adds these itself but react removes them when
            // updating the component.
            "mq-editable-field": true,
            "mq-math-mode": true
        });
        this.props.className && (className = className + " " + this.props.className);
        var buttons = null;
        this._shouldShowButtons() && (buttons = React.createElement(TexButtons, {
            sets: this.props.buttonSets,
            className: "math-input-buttons absolute",
            convertDotToTimes: this.props.convertDotToTimes,
            onInsert: this.insert
        }));
        return React.createElement("div", {
            style: {
                display: "inline-block"
            }
        }, React.createElement("div", {
            style: {
                display: "inline-block"
            }
        }, React.createElement("span", {
            className: className,
            ref: "mathinput",
            "aria-label": this.props.labelText,
            onFocus: this.handleFocus,
            onBlur: this.handleBlur
        })), React.createElement("div", {
            style: {
                position: "relative"
            }
        }, buttons));
    },
    // handlers:
    // keep track of two related bits of state:
    // * this.state.focused - whether the buttons are currently shown
    // * this.mouseDown - whether a mouse click is active that started in the
    //   buttons div
    handleFocus: function handleFocus() {
        this.setState({
            focused: true
        });
    },
    handleMouseDown: function handleMouseDown(event) {
        var focused = ReactDOM.findDOMNode(this).contains(event.target);
        this.mouseDown = focused;
        focused || this.setState({
            focused: false
        });
    },
    handleMouseUp: function handleMouseUp() {
        // this mouse click started in the buttons div so we should focus the
        // input
        this.mouseDown && this.focus();
        this.mouseDown = false;
    },
    handleBlur: function handleBlur() {
        this.mouseDown || this.setState({
            focused: false
        });
    },
    _shouldShowButtons: function _shouldShowButtons() {
        return "always" === this.props.buttonsVisible || "never" !== this.props.buttonsVisible && this.state.focused;
    },
    getDefaultProps: function getDefaultProps() {
        return {
            value: "",
            convertDotToTimes: false,
            buttonsVisible: "focused"
        };
    },
    getInitialState: function getInitialState() {
        return {
            focused: false
        };
    },
    insert: function insert(value) {
        var input = this.mathField();
        _(value).isFunction() ? value(input) : "\\" === value[0] ? input.cmd(value).focus() : input.write(value).focus();
        input.focus();
    },
    mathField: function mathField(options) {
        // MathQuill.MathField takes a DOM node, MathQuill-ifies it if it's
        // seeing that node for the first time, then returns the associated
        // MathQuill object for that node. It is stable - will always return
        // the same object when called on the same DOM node.
        return MathQuill.getInterface(2).MathField(ReactDOM.findDOMNode(this.refs.mathinput), options);
    },
    componentWillUnmount: function componentWillUnmount() {
        window.removeEventListener("mousedown", this.handleMouseDown);
        window.removeEventListener("mouseup", this.handleMouseUp);
    },
    componentDidMount: function componentDidMount() {
        var _this = this;
        window.addEventListener("mousedown", this.handleMouseDown);
        window.addEventListener("mouseup", this.handleMouseUp);
        var initialized = false;
        // Initialize MathQuill.MathField instance
        this.mathField({
            // LaTeX commands that, when typed, are immediately replaced by the
            // appropriate symbol. This does not include ln, log, or any of the
            // trig functions; those are always interpreted as commands.
            autoCommands: "pi theta phi sqrt nthroot",
            // Pop the cursor out of super/subscripts on arithmetic operators
            // or (in)equalities.
            charsThatBreakOutOfSupSub: "+-*/=<>≠≤≥",
            // Prevent excessive super/subscripts or fractions from being
            // created without operands, e.g. when somebody holds down a key
            supSubsRequireOperand: true,
            // The name of this option is somewhat misleading, as tabbing in
            // MathQuill breaks you out of a nested context (fraction/script)
            // if you're in one, but moves focus to the next input if you're
            // not. Spaces (with this option enabled) are just ignored in the
            // latter case.
            //
            // TODO(alex): In order to allow inputting mixed numbers, we will
            // have to accept spaces in certain cases. The desired behavior is
            // still to escape nested contexts if currently in one, but to
            // insert a space if not (we don't expect mixed numbers in nested
            // contexts). We should also limit to one consecutive space.
            spaceBehavesLikeTab: true,
            handlers: {
                edited: function edited(mathField) {
                    // This handler is guaranteed to be called on change, but
                    // unlike React it sometimes generates false positives.
                    // One of these is on initialization (with an empty string
                    // value), so we have to guard against that below.
                    var value = mathField.latex();
                    // Provide a MathQuill-compatible way to generate the
                    // not-equals sign without pasting unicode or typing TeX
                    value = value.replace(/<>/g, "\\ne");
                    // Use the specified symbol to represent multiplication
                    // TODO(alex): Add an option to disallow variables, in
                    // which case 'x' should get converted to '\\times'
                    if (_this.props.convertDotToTimes) {
                        value = value.replace(/\\cdot/g, "\\times");
                        // Preserve cursor position in the common case:
                        // typing '*' to insert a multiplication sign.
                        // We do this by modifying internal MathQuill state
                        // directly, instead of waiting for `.latex()` to be
                        // called in `componentDidUpdate()`.
                        var left = mathField.__controller.cursor[MathQuill.L];
                        if (left && "\\cdot " === left.ctrlSeq) {
                            mathField.__controller.backspace();
                            mathField.cmd("\\times");
                        }
                    } else value = value.replace(/\\times/g, "\\cdot");
                    initialized && _this.props.value !== value && _this.props.onChange(value);
                },
                enter: function enter() {
                    // This handler is called when the user presses the enter
                    // key. Since this isn't an actual <input> element, we have
                    // to manually trigger the usually automatic form submit.
                    $(ReactDOM.findDOMNode(_this.refs.mathinput)).submit();
                },
                upOutOf: function upOutOf(mathField) {
                    // This handler is called when the user presses the up
                    // arrow key, but there is nowhere in the expression to go
                    // up to (no numerator or exponent). For ease of use,
                    // interpret this as an attempt to create an exponent.
                    mathField.typedText("^");
                }
            }
        });
        // Ideally, we would be able to pass an initial value directly into
        // the constructor above
        this.mathField().latex(this.props.value);
        initialized = true;
    },
    componentDidUpdate: function componentDidUpdate() {
        _.isEqual(this.mathField().latex(), this.props.value) || this.mathField().latex(this.props.value);
    },
    focus: function focus() {
        this.mathField().focus();
        this.setState({
            focused: true
        });
    },
    blur: function blur() {
        this.mathField().blur();
        this.setState({
            focused: false
        });
    }
});

module.exports = MathInput;