var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "math-frac",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "math, blockMath",
    pattern: /\\frac[ {]/,
    message: "Use \\dfrac instead of \\frac in your math expressions."
});