var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "table-missing-cells",
    severity: _rule2.default.Severity.WARNING,
    selector: "table",
    lint: function lint(state, content, nodes, match) {
        var table = nodes[0];
        var headerLength = table.header.length;
        var rowLengths = table.cells.map(function(r) {
            return r.length;
        });
        for (var r = 0; r < rowLengths.length; r++) if (rowLengths[r] !== headerLength) return "Table rows don't match header:\nThe table header has " + headerLength + " cells, but\nRow " + (r + 1) + " has " + rowLengths[r] + " cells.";
    }
});