var babelPluginFlowReactPropTypes_proptype_ObjectNode = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_ObjectNode || require("react").PropTypes.any;

/**
 * Type definitions for multi-item types, including:
 *
 * - Item: A multi-item tree wrapped in a `_multi` key, to help us recognize it
 *         as a multi-item in other contexts and avoid misinterpreting its
 *         other properties.
 * - ItemTree: A multi-item without the `_multi` key. Conforms to the Tree
 *             interface, so it's compatible with our tree traversal functions.
 * - And the various types of nodes that compose a tree.
 */
var babelPluginFlowReactPropTypes_proptype_ArrayNode = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_ArrayNode || require("react").PropTypes.any;

var babelPluginFlowReactPropTypes_proptype_Tree = require("./tree-types.js").babelPluginFlowReactPropTypes_proptype_Tree || require("react").PropTypes.any;

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_ContentNode", require("react").PropTypes.shape({
    __type: require("react").PropTypes.oneOf([ "content", "item" ]).isRequired,
    content: require("react").PropTypes.string,
    images: require("react").PropTypes.shape({}),
    widgets: require("react").PropTypes.shape({})
}));

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_HintNode", require("react").PropTypes.shape({
    __type: require("react").PropTypes.oneOf([ "hint" ]).isRequired,
    content: require("react").PropTypes.string,
    images: require("react").PropTypes.shape({}),
    widgets: require("react").PropTypes.shape({}),
    replace: require("react").PropTypes.bool
}));

Object.defineProperty(module.exports, "babelPluginFlowReactPropTypes_proptype_Item", require("react").PropTypes.shape({
    _multi: require("react").PropTypes.any.isRequired
}));