var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

/* eslint-disable comma-dangle, react/forbid-prop-types, react/jsx-closing-bracket-location, react/jsx-indent-props, react/sort-comp */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
var classNames = require("classnames");

var React = require("react");

var ReactDOM = require("react-dom");

var _require = require("./styles/constants.js"), zIndexInteractiveComponent = _require.zIndexInteractiveComponent;

var Widgets = require("./widgets.js");

var _require2 = require("./util/sizing-utils.js"), containerSizeClass = _require2.containerSizeClass, getClassFromWidth = _require2.getClassFromWidth;

var _require3 = require("./gorgon/proptypes.js"), linterContextProps = _require3.linterContextProps, linterContextDefault = _require3.linterContextDefault;

var WidgetContainer = React.createClass({
    displayName: "WidgetContainer",
    propTypes: {
        shouldHighlight: React.PropTypes.bool.isRequired,
        type: React.PropTypes.string,
        initialProps: React.PropTypes.object.isRequired,
        linterContext: linterContextProps
    },
    getDefaultProps: function getDefaultProps() {
        return {
            linterContext: linterContextDefault
        };
    },
    getInitialState: function getInitialState() {
        return {
            // TODO(benkomalo): before we're mounted, we don't know how big
            // we're going to be, so just default to MEDIUM for now. :/ In the
            // future we can sniff with user-agents or something to get a
            // better approximation, to avoid flickers
            sizeClass: containerSizeClass.MEDIUM,
            widgetProps: this.props.initialProps
        };
    },
    componentDidMount: function componentDidMount() {
        // Only relay size class changes for mobile right now.  We may want to
        // this for desktop as well at some point in the future.
        if (this.state.widgetProps.apiOptions.isMobile) {
            var containerWidth = ReactDOM.findDOMNode(this).offsetWidth;
            // NOTE(benkomalo): in the common case, this won't change anything.
            // Unfortunately, it will cause a flash and re-layout on mobile,
            // but until we have better SSR or a more drastic way change to our
            // APIs that hints at the available size, we do have to measure DOM
            // unfortunately.
            /* eslint-disable react/no-did-mount-set-state */
            this.setState({
                sizeClass: getClassFromWidth(containerWidth)
            });
        }
    },
    render: function render() {
        var className = classNames({
            "perseus-widget-container": true,
            "widget-highlight": this.props.shouldHighlight,
            "widget-nohighlight": !this.props.shouldHighlight
        });
        var type = this.props.type;
        var WidgetType = Widgets.getWidget(type);
        if (null == WidgetType) // Just give up on invalid widget types
        return React.createElement("div", {
            className: className
        });
        var alignment = this.state.widgetProps.alignment;
        "default" === alignment && (alignment = Widgets.getDefaultAlignment(type));
        className += " widget-" + alignment;
        var apiOptions = this.state.widgetProps.apiOptions;
        // Hack to prevent interaction with static widgets: we overlay a big
        // div on top of the widget and overflow: hidden the container.
        // Ideally widgets themselves should know how to prevent interaction.
        var isStatic = this.state.widgetProps.static || apiOptions.readOnly;
        var staticContainerStyles = {
            position: "relative",
            overflow: "visible"
        };
        var staticOverlayStyles = {
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: zIndexInteractiveComponent
        };
        // Some widgets may include strings of markdown that we may
        // want to run the linter on. So if the widget is lintable,
        // and we've been asked to highlight lint, pass that property
        // on to the widget, and if the content is not lintable, make sure
        // to default to false.
        var linterContext = this.props.linterContext;
        Widgets.isLintable(type) || (linterContext.highlightLint = false);
        // Note: if you add more props here, please consider whether or not
        // it should be auto-serialized (e.g. used in scoreInput()). See
        // widget-jsonify-deprecated.jsx and widget-prop-blacklist.jsx
        // We default to an empty object for style instead of null
        // because of a strange bug where the static styles aren't applied
        // after toggling static mode.
        return React.createElement("div", {
            className: className,
            style: isStatic ? staticContainerStyles : {}
        }, React.createElement(WidgetType, _extends({}, this.state.widgetProps, {
            linterContext: linterContext,
            containerSizeClass: this.state.sizeClass,
            ref: "widget"
        })), isStatic && React.createElement("div", {
            style: staticOverlayStyles
        }));
    },
    componentWillReceiveProps: function componentWillReceiveProps(nextProps) {
        if (this.props.type !== nextProps.type) throw new Error("WidgetContainer can't change widget type; set a different key instead to recreate the container.");
    },
    shouldComponentUpdate: function shouldComponentUpdate(nextProps, nextState) {
        return this.props.shouldHighlight !== nextProps.shouldHighlight || this.props.type !== nextProps.type || this.state.widgetProps !== nextState.widgetProps || this.state.sizeClass !== nextState.sizeClass;
    },
    getWidget: function getWidget() {
        return this.refs.widget;
    },
    replaceWidgetProps: function replaceWidgetProps(newWidgetProps) {
        this.setState({
            widgetProps: newWidgetProps
        });
    }
});

module.exports = WidgetContainer;