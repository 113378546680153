var _require = require("./consts"), DecimalSeparators = _require.DecimalSeparators;

// We expect `window.icu` to be exposed by the parent. When in doubt, we fall
// back to a period. We can only depend on a subset of what localeplanet
// provides, however -- the things in `icu-slim.js` (there's a copy in ../lib/
// for reference).
var decimalSeparator = void 0;

decimalSeparator = "undefined" !== typeof window && window.icu && "," === window.icu.getDecimalFormatSymbols().decimal_separator ? DecimalSeparators.COMMA : DecimalSeparators.PERIOD;

module.exports = {
    decimalSeparator: decimalSeparator
};