/**
 * An autogenerated component that renders the BACKSPACE iconograpy in SVG.
 *
 * Generated with: https://gist.github.com/crm416/3c7abc88e520eaed72347af240b32590.
 */
var React = require("react");

var Backspace = function Backspace() {
    return React.createElement("svg", {
        width: "48",
        height: "48",
        viewBox: "0 0 48 48"
    }, React.createElement("g", {
        fill: "none",
        fillRule: "evenodd"
    }, React.createElement("path", {
        fill: "none",
        d: "M0 0h48v48H0z"
    }), React.createElement("path", {
        d: "M13 24l6 6h14V18H19l-6 6zm-1.414-1.414l6-6A2 2 0 0 1 19 16h14a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H19a2 2 0 0 1-1.414-.586l-6-6a2 2 0 0 1 0-2.828z",
        fill: "#888D93"
    }), React.createElement("path", {
        d: "M23 21l6 6M29 21l-6 6",
        stroke: "#888D93",
        strokeWidth: "2",
        strokeLinecap: "round",
        strokeLinejoin: "round"
    })));
};

module.exports = Backspace;