Object.defineProperty(exports, "__esModule", {
    value: true
});

// Define the shape of the linter context object that is passed through the
// tree with additional information about what we are checking.
var React = require("react");

var linterContextProps = exports.linterContextProps = React.PropTypes.shape({
    contentType: React.PropTypes.string,
    highlightLint: React.PropTypes.bool,
    paths: React.PropTypes.arrayOf(React.PropTypes.string),
    stack: React.PropTypes.arrayOf(React.PropTypes.string)
});

var linterContextDefault = exports.linterContextDefault = {
    contentType: "",
    highlightLint: false,
    paths: [],
    stack: []
};