Object.defineProperty(exports, "__esModule", {
    value: true
});

var _rule = require("./gorgon/rule.js");

var _rule2 = _interopRequireDefault(_rule);

var _underscore = require("underscore");

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

/**
 * NotGorgon asynchronously calls Khan Academy's poentry linter.
 *
 * The poentry linter checks for issues that prevent content from being
 * translated. This linter is KA-specific and so NotGorgon does not do anything
 * in non-KA environments.
 *
 * NotGorgon is named as such because it is not Gorgon, Perseus' built-in
 * linter.
 */
// msec to wait before we actually call the linter after receiving the
// last call. The timer is reset if the lint is called during the wait.
// A LintCB is called once the linter has been run.
var DEBOUNCE_TIMEOUT = 1e3;

var NotGorgon = function() {
    function NotGorgon() {
        var _this = this;
        _classCallCheck(this, NotGorgon);
        this.previousContent = null;
        this.runLinter = (0, _underscore.debounce)(function(perseusStr, onLintErrorsGenerated) {
            // $FlowFixMe -- TODO(joshuan): use an api flag instead?
            if ("undefined" === typeof KA) return;
            if (perseusStr === _this.previousContent) return;
            _this.previousContent = perseusStr;
            if ("" === perseusStr) {
                onLintErrorsGenerated([]);
                return;
            }
            fetch("/api/internal/translate/lint_poentry?preview=1&lang=en", {
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    // The poentry linter verifies that the translation from
                    // the original text to the translated text is sane. We
                    // want to ensure that the translation from English to
                    // English is sane, so msgid === mstr.
                    msgid: perseusStr,
                    // msgid is the original text
                    msgstr: perseusStr,
                    // msgstr is the translated text
                    format: "perseus_text",
                    filename: ""
                }),
                method: "POST"
            }).then(function(response) {
                if (response.status >= 400) return {
                    status: "error",
                    message: "Could not run i18n linter."
                };
                return response.json();
            }, function(rejection) {
                return {
                    status: "error",
                    message: "Could not run i18n linter."
                };
            }).then(function(json) {
                onLintErrorsGenerated("error" === json.status ? [ "Some part of this text makes it untranslatable. The specific message from the i18n linter was: " + json.message.replace(/\n/g, " ") ] : []);
            });
        }, DEBOUNCE_TIMEOUT);
    }
    // To avoid linting when nothing has changed.
    /**
   * Runs the poentry linter on the given perseus string asynchronously.
   *
   * It doesn't do anything unless we're in a KA environment.
   *
   * onLintErrorsGenerated is called even if there are no errors. It is not
   * called if perseusStr is unchanged from the last call.
   *
   * Linting is relatively expensive -- so we debounce linting each instance
   * for some amount of time.
   */
    /**
   * Applies an array of errors generated by linters without position
   * information (NotGorgon and the legacy getSaveWarnings()) to the top
   * of a Perseus tree.
   */
    NotGorgon.prototype.applyLintErrors = function applyLintErrors(parsedMarkdown, notGorgonLintErrors) {
        // These lint errors do not have position data associated with
        // them, so we just plop them at the top.
        if (notGorgonLintErrors.length) {
            var errorText = notGorgonLintErrors.join("\n\n");
            parsedMarkdown.unshift({
                content: {
                    type: "text",
                    content: ""
                },
                insideTable: false,
                message: errorText,
                ruleName: "legacy-error",
                severity: _rule2.default.Severity.ERROR,
                type: "lint"
            });
        }
    };
    NotGorgon.prototype.destroy = function destroy() {
        this.runLinter = null;
        this.previousContent = null;
    };
    return NotGorgon;
}();

exports.default = NotGorgon;