var _typeof = "function" === typeof Symbol && "symbol" === typeof Symbol.iterator ? function(obj) {
    return typeof obj;
} : function(obj) {
    return obj && "function" === typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

/* eslint-disable max-lines */
/**
 * Interactive graphie utilities.
 *
 * This file exposes a couple functions, but mostly it adds functions to the
 * `Graphie` prototype for dealing with interactive graphie elements.
 */
// TODO(emily): This file breaks our line length limits like nobody's business.
// Figure out how to fix that.
var _ = require("underscore");

require("../../lib/jquery.mobile.vmouse.js");

/* global Raphael:false */
var GraphUtils = require("./graphie.js");

var kvector = require("kmath").vector;

var kpoint = require("kmath").point;

var kline = require("kmath").line;

var WrappedEllipse = require("../interactive2/wrapped-ellipse.js");

var WrappedLine = require("../interactive2/wrapped-line.js");

var WrappedPath = require("../interactive2/wrapped-path.js");

var KhanMath = require("./math.js");

var KhanColors = require("./colors.js");

var _require = require("../interactive2/interactive-util.js"), getCanUse3dTransform = _require.getCanUse3dTransform;

function sum(array) {
    return _.reduce(array, function(memo, arg) {
        return memo + arg;
    }, 0);
}

function clockwise(points) {
    var segments = _.zip(points, points.slice(1).concat(points.slice(0, 1)));
    return sum(_.map(segments, function(segment) {
        var p1 = segment[0];
        var p2 = segment[1];
        return (p2[0] - p1[0]) * (p2[1] + p1[1]);
    })) > 0;
}

/* vector-add multiple [x, y] coords/vectors */
function addPoints() {
    var points = _.toArray(arguments);
    var zipped = _.zip.apply(_, points);
    return _.map(zipped, sum);
}

function reverseVector(vector) {
    return _.map(vector, function(coord) {
        return -1 * coord;
    });
}

function scaledDistanceFromAngle(angle) {
    var a = 70.2941120352484;
    var b = 11.374597405497571;
    return (a - b) * Math.exp(-.037587715462826674 * angle) + b;
}

function scaledPolarRad(radius, radians) {
    return [ radius * Math.cos(radians), radius * Math.sin(radians) * -1 ];
}

function scaledPolarDeg(radius, degrees) {
    return scaledPolarRad(radius, degrees * Math.PI / 180);
}

// Global dragging state
var dragging = false;

var InteractiveUtils = {
    // Useful for shapes that are only sometimes drawn. If a shape isn't
    // needed, it can be replaced with bogusShape which just has stub methods
    // that successfully do nothing.
    // The alternative would be 'if..typeof' checks all over the place.
    bogusShape: {
        animate: function animate() {},
        attr: function attr() {},
        remove: function remove() {}
    }
};

_.extend(GraphUtils.Graphie.prototype, {
    // graphie puts text spans on top of the SVG, which looks good, but gets
    // in the way of mouse events. This adds another SVG element on top
    // of everything else where we can add invisible shapes with mouse
    // handlers wherever we want.
    addMouseLayer: function addMouseLayer(options) {
        var graph = this;
        options = _.extend({
            allowScratchpad: false,
            setDrawingAreaAvailable: function setDrawingAreaAvailable() {}
        }, options);
        var mouselayerZIndex = 2;
        graph.mouselayer = Raphael(graph.raphael.canvas.parentNode, graph.xpixels, graph.ypixels);
        $(graph.mouselayer.canvas).css("z-index", 2);
        if (options.onClick || options.onMouseDown || options.onMouseMove || options.onMouseOver || options.onMouseOut) {
            var canvasClickTarget = graph.mouselayer.rect(0, 0, graph.xpixels, graph.ypixels).attr({
                fill: "#000",
                opacity: 0
            });
            var isClickingCanvas = false;
            $(graph.mouselayer.canvas).on("vmousedown", function(e) {
                if (e.target === canvasClickTarget[0]) {
                    options.onMouseDown && options.onMouseDown(graph.getMouseCoord(e));
                    isClickingCanvas = true;
                    options.onMouseMove && $(document).bind("vmousemove.mouseLayer", function(e) {
                        if (isClickingCanvas) {
                            e.preventDefault();
                            options.onMouseMove(graph.getMouseCoord(e));
                        }
                    });
                    $(document).bind("vmouseup.mouseLayer", function(e) {
                        $(document).unbind(".mouseLayer");
                        // Only register clicks that started on the canvas,
                        // and not on another mouseLayer target
                        isClickingCanvas && options.onClick && options.onClick(graph.getMouseCoord(e));
                        isClickingCanvas = false;
                    });
                }
            });
            options.onMouseOver && $(graph.mouselayer.canvas).on("vmouseover", function(e) {
                options.onMouseOver(graph.getMouseCoord(e));
            });
            options.onMouseOut && $(graph.mouselayer.canvas).on("vmouseout", function(e) {
                options.onMouseOut(graph.getMouseCoord(e));
            });
        }
        options.allowScratchpad || options.setDrawingAreaAvailable(false);
        // Add mouse and visible wrapper layers for DOM-node-wrapped movables
        graph._mouselayerWrapper = document.createElement("div");
        $(graph._mouselayerWrapper).css({
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2
        });
        graph._visiblelayerWrapper = document.createElement("div");
        $(graph._visiblelayerWrapper).css({
            position: "absolute",
            left: 0,
            top: 0
        });
        var el = graph.raphael.canvas.parentNode;
        el.appendChild(graph._visiblelayerWrapper);
        el.appendChild(graph._mouselayerWrapper);
        // Add functions for adding to wrappers
        graph.addToMouseLayerWrapper = function(el) {
            this._mouselayerWrapper.appendChild(el);
        };
        graph.addToVisibleLayerWrapper = function(el) {
            this._visiblelayerWrapper.appendChild(el);
        };
    },
    /**
     * Get mouse coordinates in pixels
     */
    getMousePx: function getMousePx(event) {
        var graphie = this;
        return [ event.pageX - $(graphie.raphael.canvas.parentNode).offset().left, event.pageY - $(graphie.raphael.canvas.parentNode).offset().top ];
    },
    /**
     * Get mouse coordinates in graph coordinates
     */
    getMouseCoord: function getMouseCoord(event) {
        return this.unscalePoint(this.getMousePx(event));
    },
    /**
     * Unlike all other Graphie-related code, the following three functions use
     * a lot of scaled coordinates (so that labels appear the same size
     * regardless of current shape/figure scale). These are prefixed with 's'.
     */
    labelAngle: function labelAngle(options) {
        var graphie = this;
        _.defaults(options, {
            point1: [ 0, 0 ],
            vertex: [ 0, 0 ],
            point3: [ 0, 0 ],
            label: null,
            numArcs: 1,
            showRightAngleMarker: true,
            pushOut: 0,
            clockwise: false,
            style: {}
        });
        var text = void 0 === options.text ? "" : options.text;
        var vertex = options.vertex;
        var sVertex = graphie.scalePoint(vertex);
        var p1 = void 0;
        var p3 = void 0;
        if (options.clockwise) {
            p1 = options.point1;
            p3 = options.point3;
        } else {
            p1 = options.point3;
            p3 = options.point1;
        }
        var startAngle = GraphUtils.findAngle(p1, vertex);
        var endAngle = GraphUtils.findAngle(p3, vertex);
        var angle = (endAngle + 360 - startAngle) % 360;
        var halfAngle = (startAngle + angle / 2) % 360;
        var sPadding = 5 * options.pushOut;
        var sRadius = sPadding + scaledDistanceFromAngle(angle);
        var temp = [];
        if (Math.abs(angle - 90) < 1e-9 && options.showRightAngleMarker) {
            var v1 = addPoints(sVertex, scaledPolarDeg(sRadius, startAngle));
            var v2 = addPoints(sVertex, scaledPolarDeg(sRadius, endAngle));
            sRadius *= Math.SQRT2;
            var v3 = addPoints(sVertex, scaledPolarDeg(sRadius, halfAngle));
            _.each([ v1, v2 ], function(v) {
                temp.push(graphie.scaledPath([ v, v3 ], options.style));
            });
        } else // Draw arcs
        _.times(options.numArcs, function(i) {
            temp.push(graphie.arc(vertex, graphie.unscaleVector(sRadius), startAngle, endAngle, options.style));
            sRadius += 3;
        });
        if (text) {
            var match = text.match(/\$deg(\d)?/);
            if (match) {
                var precision = match[1] || 1;
                text = text.replace(match[0], KhanMath.toFixedApprox(angle, precision) + "^{\\circ}");
            }
            var sOffset = scaledPolarDeg(sRadius + 15, halfAngle);
            var sPosition = addPoints(sVertex, sOffset);
            var position = graphie.unscalePoint(sPosition);
            // Reuse label if possible
            if (options.label) {
                options.label.setPosition(position);
                options.label.processMath(text, /* force */ true);
            } else graphie.label(position, text, "center", options.style);
        }
        return temp;
    },
    labelSide: function labelSide(options) {
        var graphie = this;
        _.defaults(options, {
            point1: [ 0, 0 ],
            point2: [ 0, 0 ],
            label: null,
            text: "",
            numTicks: 0,
            numArrows: 0,
            clockwise: false,
            style: {}
        });
        var p1 = void 0;
        var p2 = void 0;
        if (options.clockwise) {
            p1 = options.point1;
            p2 = options.point2;
        } else {
            p1 = options.point2;
            p2 = options.point1;
        }
        var midpoint = [ (p1[0] + p2[0]) / 2, (p1[1] + p2[1]) / 2 ];
        var sMidpoint = graphie.scalePoint(midpoint);
        var parallelAngle = Math.atan2(p2[1] - p1[1], p2[0] - p1[0]);
        var perpendicularAngle = parallelAngle + Math.PI / 2;
        var temp = [];
        var sCumulativeOffset = 0;
        if (options.numTicks) {
            var n = options.numTicks;
            var sSpacing = 5;
            var sHeight = 5;
            var style = _.extend({}, options.style, {
                strokeWidth: 2
            });
            _.times(n, function(i) {
                var sOffset = 5 * (i - (n - 1) / 2);
                var sOffsetVector = scaledPolarRad(sOffset, parallelAngle);
                var sHeightVector = scaledPolarRad(5, perpendicularAngle);
                var sPath = [ addPoints(sMidpoint, sOffsetVector, sHeightVector), addPoints(sMidpoint, sOffsetVector, reverseVector(sHeightVector)) ];
                temp.push(graphie.scaledPath(sPath, style));
            });
            sCumulativeOffset += 5 * (n - 1) + 15;
        }
        if (options.numArrows) {
            var _n = options.numArrows;
            var start = [ p1, p2 ].sort(function(a, b) {
                return a[1] === b[1] ? a[0] - b[0] : a[1] - b[1];
            })[0];
            var sStart = graphie.scalePoint(start);
            var _style = _.extend({}, options.style, {
                arrows: "->",
                strokeWidth: 2
            });
            var _sSpacing = 5;
            _.times(_n, function(i) {
                var sOffset = sCumulativeOffset + 5 * i;
                var sOffsetVector = scaledPolarRad(sOffset, parallelAngle);
                start !== p1 && (sOffsetVector = reverseVector(sOffsetVector));
                var sEnd = addPoints(sMidpoint, sOffsetVector);
                temp.push(graphie.scaledPath([ sStart, sEnd ], _style));
            });
        }
        var text = options.text;
        if (text) {
            var match = text.match(/\$len(\d)?/);
            if (match) {
                var distance = GraphUtils.getDistance(p1, p2);
                var precision = match[1] || 1;
                text = text.replace(match[0], KhanMath.toFixedApprox(distance, precision));
            }
            var sOffset = 20;
            var sOffsetVector = scaledPolarRad(20, perpendicularAngle);
            var sPosition = addPoints(sMidpoint, sOffsetVector);
            var position = graphie.unscalePoint(sPosition);
            // Reuse label if possible
            if (options.label) {
                options.label.setPosition(position);
                options.label.processMath(text, /* force */ true);
            } else graphie.label(position, text, "center", options.style);
        }
        return temp;
    },
    /* Can also be used to label points that aren't vertices */
    labelVertex: function labelVertex(options) {
        var graphie = this;
        _.defaults(options, {
            point1: null,
            vertex: [ 0, 0 ],
            point3: null,
            label: null,
            text: "",
            clockwise: false,
            style: {}
        });
        if (!options.text) return;
        var vertex = options.vertex;
        var sVertex = graphie.scalePoint(vertex);
        var p1 = void 0;
        var p3 = void 0;
        if (options.clockwise) {
            p1 = options.point1;
            p3 = options.point3;
        } else {
            p1 = options.point3;
            p3 = options.point1;
        }
        var angle = 135;
        var halfAngle = void 0;
        if (p1 && p3) {
            var startAngle = GraphUtils.findAngle(p1, vertex);
            angle = (GraphUtils.findAngle(p3, vertex) + 360 - startAngle) % 360;
            halfAngle = (startAngle + angle / 2 + 180) % 360;
        } else if (p1) {
            var parallelAngle = GraphUtils.findAngle(vertex, p1);
            halfAngle = parallelAngle + 90;
        } else if (p3) {
            var _parallelAngle = GraphUtils.findAngle(p3, vertex);
            halfAngle = _parallelAngle + 90;
        } else // Standalone point
        halfAngle = 135;
        var sRadius = 10 + scaledDistanceFromAngle(360 - angle);
        var sOffsetVector = scaledPolarDeg(sRadius, halfAngle);
        var sPosition = addPoints(sVertex, sOffsetVector);
        var position = graphie.unscalePoint(sPosition);
        // Reuse label if possible
        if (options.label) {
            options.label.setPosition(position);
            options.label.processMath(options.text, /* force */ true);
        } else graphie.label(position, options.text, "center", options.style);
    },
    // Add a point to the graph that can be dragged around.
    // It allows automatic constraints on its movement as well as automatically
    // managing line segments that terminate at the point.
    //
    // Options can be set to control how the point behaves:
    //   coord[]:
    //     The initial position of the point
    //   snapX, snapY:
    //     The minimum increment the point can be moved
    //
    // The return value is an object that can be used to manipulate the point:
    //   The coordX and coordY properties tell you the current position
    //
    //   By adding an onMove() method to the returned object, you can install an
    //   event handler that gets called every time the user moves the point.
    //
    //   The returned object also provides a moveTo(x,y) method that will move
    //   the point to a specific coordinate
    //
    // Constraints can be set on the on the returned object:
    //
    //  - Set point to be immovable:
    //        movablePoint.constraints.fixed = true
    //
    //  - Constrain point to a fixed distance from another point. The resulting
    //    point will move in a circle:
    //        movablePoint.fixedDistance = {
    //           dist: 2,
    //           point: point1
    //        }
    //
    //  - Constrain point to a line defined by a fixed angle between it and
    //    two other points:
    //        movablePoint.fixedAngle = {
    //           angle: 45,
    //           vertex: point1,
    //           ref: point2
    //        }
    //
    //  - Confined the point to traveling in a vertical or horizontal line,
    //    respectively
    //        movablePoint.constrainX = true;
    //        movablePoint.constrainY = true;
    //
    //  - Connect a movableLineSegment to a movablePoint. The point is attached
    //    to a specific end of the line segment by adding the segment either to
    //    the list of lines that start at the point or the list of lines that
    //    end at the point (movableLineSegment can do this for you):
    //        movablePoint.lineStarts.push(movableLineSegment);
    //          - or -
    //        movablePoint.lineEnds.push(movableLineSegment);
    //
    //  - Connect a movablePolygon to a movablePoint in exacty the same way:
    //        movablePoint.polygonVertices.push(movablePolygon);
    //
    addMovablePoint: function addMovablePoint(options) {
        var movablePoint = $.extend(true, {
            graph: this,
            coord: [ 0, 0 ],
            snapX: 0,
            snapY: 0,
            pointSize: 4,
            highlight: false,
            dragging: false,
            visible: true,
            bounded: true,
            constraints: {
                fixed: false,
                constrainX: false,
                constrainY: false,
                fixedAngle: {},
                fixedDistance: {}
            },
            lineStarts: [],
            lineEnds: [],
            polygonVertices: [],
            normalStyle: {},
            highlightStyle: {
                fill: KhanColors.INTERACTING,
                stroke: KhanColors.INTERACTING
            },
            labelStyle: {
                color: KhanColors.INTERACTIVE
            },
            vertexLabel: "",
            mouseTarget: null
        }, options);
        var normalColor = movablePoint.constraints.fixed ? KhanColors.DYNAMIC : KhanColors.INTERACTIVE;
        movablePoint.normalStyle = _.extend({}, {
            fill: normalColor,
            stroke: normalColor
        }, options.normalStyle);
        // deprecated: don't use coordX/coordY; use coord[]
        void 0 !== options.coordX && (movablePoint.coord[0] = options.coordX);
        void 0 !== options.coordY && (movablePoint.coord[1] = options.coordY);
        var graph = movablePoint.graph;
        var applySnapAndConstraints = function applySnapAndConstraints(coord) {
            // coord should be the scaled coordinate
            // move point away from edge of graph unless it's invisible or fixed
            movablePoint.visible && movablePoint.bounded && !movablePoint.constraints.fixed && (// can't go beyond 10 pixels from the edge
            coord = graph.constrainToBounds(coord, 10));
            var coordX = coord[0];
            var coordY = coord[1];
            // snap coordinates to grid
            0 !== movablePoint.snapX && (coordX = Math.round(coordX / movablePoint.snapX) * movablePoint.snapX);
            0 !== movablePoint.snapY && (coordY = Math.round(coordY / movablePoint.snapY) * movablePoint.snapY);
            // snap to points around circle
            if (movablePoint.constraints.fixedDistance.snapPoints) {
                var mouse = graph.scalePoint(coord);
                var mouseX = mouse[0];
                var mouseY = mouse[1];
                var snapRadians = 2 * Math.PI / movablePoint.constraints.fixedDistance.snapPoints;
                var radius = movablePoint.constraints.fixedDistance.dist;
                var centerCoord = movablePoint.constraints.fixedDistance.point;
                var centerX = (centerCoord[0] - graph.range[0][0]) * graph.scale[0];
                var centerY = (-centerCoord[1] + graph.range[1][1]) * graph.scale[1];
                var mouseXrel = mouseX - centerX;
                var mouseYrel = -mouseY + centerY;
                var radians = Math.atan(mouseYrel / mouseXrel);
                // adjust so that angles increase from 0 to 2 pi as you go
                // around the circle
                mouseXrel < 0 && (radians += Math.PI);
                // perform the snap
                radians = Math.round(radians / snapRadians) * snapRadians;
                // convert from radians back to pixels
                mouseXrel = radius * Math.cos(radians);
                mouseYrel = radius * Math.sin(radians);
                // convert back to coordinates relative to graphie canvas
                mouseX = mouseXrel + centerX;
                mouseY = -mouseYrel + centerY;
                coordX = KhanMath.roundTo(5, mouseX / graph.scale[0] + graph.range[0][0]);
                coordY = KhanMath.roundTo(5, graph.range[1][1] - mouseY / graph.scale[1]);
            }
            return movablePoint.applyConstraint([ coordX, coordY ]);
        };
        // Using the passed coordinates, apply any constraints and return
        // the closest coordinates that match the constraints.
        movablePoint.applyConstraint = function(coord, extraConstraints, override) {
            var newCoord = coord.slice();
            var constraints = {};
            override ? $.extend(constraints, {
                fixed: false,
                constrainX: false,
                constrainY: false,
                fixedAngle: {},
                fixedDistance: {}
            }, extraConstraints) : $.extend(constraints, this.constraints, extraConstraints);
            // constrain to vertical movement
            if (constraints.constrainX) newCoord = [ this.coord[0], coord[1] ]; else if (constraints.constrainY) newCoord = [ coord[0], this.coord[1] ]; else if ("number" === typeof constraints.fixedAngle.angle && "number" === typeof constraints.fixedDistance.dist) {
                var vertex = constraints.fixedAngle.vertex.coord || constraints.fixedAngle.vertex;
                var ref = constraints.fixedAngle.ref.coord || constraints.fixedAngle.ref;
                var distPoint = constraints.fixedDistance.point.coord || constraints.fixedDistance.point;
                var constrainedAngle = (constraints.fixedAngle.angle + GraphUtils.findAngle(ref, vertex)) * Math.PI / 180;
                var length = constraints.fixedDistance.dist;
                newCoord[0] = length * Math.cos(constrainedAngle) + distPoint[0];
                newCoord[1] = length * Math.sin(constrainedAngle) + distPoint[1];
            } else if ("number" === typeof constraints.fixedAngle.angle) {
                var _vertex = constraints.fixedAngle.vertex.coord || constraints.fixedAngle.vertex;
                var _ref = constraints.fixedAngle.ref.coord || constraints.fixedAngle.ref;
                var _constrainedAngle = (constraints.fixedAngle.angle + GraphUtils.findAngle(_ref, _vertex)) * Math.PI / 180;
                var angle = GraphUtils.findAngle(coord, _vertex) * Math.PI / 180;
                var distance = GraphUtils.getDistance(coord, _vertex);
                var _length = distance * Math.cos(_constrainedAngle - angle);
                _length = _length < 1 ? 1 : _length;
                newCoord[0] = _length * Math.cos(_constrainedAngle) + _vertex[0];
                newCoord[1] = _length * Math.sin(_constrainedAngle) + _vertex[1];
            } else if ("number" === typeof constraints.fixedDistance.dist) {
                var _distPoint = constraints.fixedDistance.point.coord || constraints.fixedDistance.point;
                var _angle = GraphUtils.findAngle(coord, _distPoint);
                var _length2 = constraints.fixedDistance.dist;
                _angle = _angle * Math.PI / 180;
                newCoord[0] = _length2 * Math.cos(_angle) + _distPoint[0];
                newCoord[1] = _length2 * Math.sin(_angle) + _distPoint[1];
            } else constraints.fixed && (newCoord = movablePoint.coord);
            return newCoord;
        };
        movablePoint.coord = applySnapAndConstraints(movablePoint.coord);
        var highlightScale = 2;
        movablePoint.visible && graph.style(movablePoint.normalStyle, function() {
            var radii = [ movablePoint.pointSize / graph.scale[0], movablePoint.pointSize / graph.scale[1] ];
            var options = {
                maxScale: 2,
                // Add in 2px of padding to avoid clipping at the edges.
                padding: 2
            };
            movablePoint.visibleShape = new WrappedEllipse(graph, movablePoint.coord, radii, options);
            movablePoint.visibleShape.attr(_.omit(movablePoint.normalStyle, "scale"));
            movablePoint.visibleShape.toFront();
        });
        movablePoint.normalStyle.scale = 1;
        movablePoint.highlightStyle.scale = 2;
        movablePoint.vertexLabel && (movablePoint.labeledVertex = this.label([ 0, 0 ], "", "center", movablePoint.labelStyle));
        movablePoint.drawLabel = function() {
            movablePoint.vertexLabel && movablePoint.graph.labelVertex({
                vertex: movablePoint.coord,
                label: movablePoint.labeledVertex,
                text: movablePoint.vertexLabel,
                style: movablePoint.labelStyle
            });
        };
        movablePoint.drawLabel();
        movablePoint.grab = function(offset) {
            // The offset for the gesture. When provided, the movable point will
            // track the mouse's position, plus this offset. This is typically
            // used to lock the distance between a user's finger and the movable
            // point, when dragging.
            offset = offset || [ 0, 0 ];
            $(document).bind("vmousemove.point vmouseup.point", function(event) {
                event.preventDefault();
                movablePoint.dragging = true;
                dragging = true;
                // Adjust the target coordinate by accounting for the gesture's
                // offset.
                var coord = kvector.add(graph.getMouseCoord(event), offset);
                coord = applySnapAndConstraints(coord);
                var coordX = coord[0];
                var coordY = coord[1];
                var mouseX = void 0;
                var mouseY = void 0;
                if ("vmousemove" === event.type) {
                    var doMove = true;
                    // The caller has the option of adding an onMove() method
                    // to the movablePoint object we return as a sort of event
                    // handler. By returning false from onMove(), the move
                    // can be vetoed, providing custom constraints on where
                    // the point can be moved. By returning array [x, y], the
                    // move can be overridden
                    if (_.isFunction(movablePoint.onMove)) {
                        var result = movablePoint.onMove(coordX, coordY);
                        false === result && (doMove = false);
                        if (_.isArray(result)) {
                            coordX = result[0];
                            coordY = result[1];
                        }
                    }
                    // coord{X|Y} may have been modified by constraints or
                    // onMove handler; adjust mouse{X|Y} to match
                    mouseX = (coordX - graph.range[0][0]) * graph.scale[0];
                    mouseY = (-coordY + graph.range[1][1]) * graph.scale[1];
                    if (doMove) {
                        var point = graph.unscalePoint([ mouseX, mouseY ]);
                        movablePoint.visibleShape.moveTo(point);
                        movablePoint.mouseTarget.moveTo(point);
                        movablePoint.coord = [ coordX, coordY ];
                        movablePoint.updateLineEnds();
                        $(movablePoint).trigger("move");
                    }
                    movablePoint.drawLabel();
                } else if ("vmouseup" === event.type) {
                    $(document).unbind(".point");
                    movablePoint.dragging = false;
                    dragging = false;
                    if (_.isFunction(movablePoint.onMoveEnd)) {
                        var _result = movablePoint.onMoveEnd(coordX, coordY);
                        if (_.isArray(_result)) {
                            coordX = _result[0];
                            coordY = _result[1];
                            mouseX = (coordX - graph.range[0][0]) * graph.scale[0];
                            mouseY = (-coordY + graph.range[1][1]) * graph.scale[1];
                            var _point = graph.unscalePoint([ mouseX, mouseY ]);
                            movablePoint.visibleShape.moveTo(_point);
                            movablePoint.mouseTarget.moveTo(_point);
                            movablePoint.coord = [ coordX, coordY ];
                        }
                    }
                    if (!movablePoint.highlight) {
                        movablePoint.visibleShape.animate(movablePoint.normalStyle, 50);
                        movablePoint.onUnhighlight && movablePoint.onUnhighlight();
                    }
                }
            });
        };
        if (movablePoint.visible && !movablePoint.constraints.fixed) {
            // the invisible shape in front of the point that gets mouse events
            if (!movablePoint.mouseTarget) {
                var radii = graph.unscaleVector(16);
                var _options = {
                    mouselayer: true,
                    padding: 0,
                    disableMouseEventsOnWrapper: true
                };
                movablePoint.mouseTarget = new WrappedEllipse(graph, movablePoint.coord, radii, _options);
                movablePoint.mouseTarget.attr({
                    fill: "#000",
                    opacity: 0
                });
            }
            var $mouseTarget = $(movablePoint.mouseTarget.getMouseTarget());
            $mouseTarget.css("cursor", "move");
            $mouseTarget.bind("vmousedown vmouseover vmouseout", function(event) {
                if ("vmouseover" === event.type) {
                    movablePoint.highlight = true;
                    if (!dragging) {
                        movablePoint.visibleShape.animate(movablePoint.highlightStyle, 50);
                        movablePoint.onHighlight && movablePoint.onHighlight();
                    }
                } else if ("vmouseout" === event.type) {
                    movablePoint.highlight = false;
                    if (!movablePoint.dragging && !dragging) {
                        movablePoint.visibleShape.animate(movablePoint.normalStyle, 50);
                        movablePoint.onUnhighlight && movablePoint.onUnhighlight();
                    }
                } else if ("vmousedown" === event.type && (1 === event.which || 0 === event.which)) {
                    event.preventDefault();
                    // The offset between the cursor or finger and the initial
                    // coordinates of the point. This is tracked so as to avoid
                    // locking the moving point to the user's finger on touch
                    // devices, which would obscure it, no matter how large we
                    // made the touch target. Instead, we respect the offset at
                    // which the point was grabbed for the entirety of the
                    // gesture, if it's a touch-based interaction.
                    var startCoord = movablePoint.coord;
                    var startMouseCoord = graph.getMouseCoord(event);
                    var isMouse = !("ontouchstart" in window);
                    var touchOffset = isMouse ? [ 0, 0 ] : kvector.subtract(startCoord, startMouseCoord);
                    movablePoint.grab(touchOffset);
                }
            });
        }
        // Method to let the caller animate the point to a new position.
        // Useful as part of a hint to show the user the correct place
        // to put the point.
        movablePoint.moveTo = function(coordX, coordY, updateLines) {
            var distance = GraphUtils.getDistance(this.graph.scalePoint([ coordX, coordY ]), this.graph.scalePoint(this.coord));
            var time = 5 * distance;
            var cb = updateLines && function(coord) {
                movablePoint.coord = coord;
                movablePoint.updateLineEnds();
            };
            this.visibleShape.animateTo([ coordX, coordY ], time, cb);
            this.mouseTarget.animateTo([ coordX, coordY ], time, cb);
            this.coord = [ coordX, coordY ];
            _.isFunction(this.onMove) && this.onMove(coordX, coordY);
        };
        // After moving the point, call this to update all line segments
        // terminating at the point
        movablePoint.updateLineEnds = function() {
            $(this.lineStarts).each(function() {
                this.coordA = movablePoint.coord;
                this.transform();
            });
            $(this.lineEnds).each(function() {
                this.coordZ = movablePoint.coord;
                this.transform();
            });
            $(this.polygonVertices).each(function() {
                this.transform();
            });
        };
        // Put the point at a new position without any checks, animation,
        // or callbacks
        movablePoint.setCoord = function(coord) {
            if (this.visible) {
                this.visibleShape.moveTo(coord);
                null != this.mouseTarget && this.mouseTarget.moveTo(coord);
            }
            this.coord = coord.slice();
        };
        // Put the point at the new position, checking that it is
        // within the graph's bounds
        movablePoint.setCoordConstrained = function(coord) {
            this.setCoord(applySnapAndConstraints(coord));
        };
        // Change z-order to back
        movablePoint.toBack = function() {
            if (this.visible) {
                null != this.mouseTarget && this.mouseTarget.toBack();
                this.visibleShape.toBack();
            }
        };
        // Change z-order to front
        movablePoint.toFront = function() {
            if (this.visible) {
                null != this.mouseTarget && this.mouseTarget.toFront();
                this.visibleShape.toFront();
            }
        };
        movablePoint.remove = function() {
            this.visibleShape && this.visibleShape.remove();
            this.mouseTarget && this.mouseTarget.remove();
            this.labeledVertex && this.labeledVertex.remove();
        };
        return movablePoint;
    },
    // MovableLineSegment is a line segment that can be dragged around the
    // screen. By attaching a smartPoint to each (or one) end, the ends can be
    // manipulated individually.
    //
    // To use with smartPoints, add the smartPoints first, then:
    //   addMovableLineSegment({ pointA: smartPoint1, pointZ: smartPoint2 });
    // Or just one end:
    //   addMovableLineSegment({ pointA: smartPoint, coordZ: [0, 0] });
    //
    // Include "fixed: true" in the options if you don't want the entire line
    // to be draggable (you can still use points to make the endpoints
    // draggable)
    //
    // The returned object includes the following properties/methods:
    //
    //   - lineSegment.coordA / lineSegment.coordZ
    //         The coordinates of each end of the line segment
    //
    //   - lineSegment.transform(syncToPoints)
    //         Repositions the line segment. Call after changing coordA
    //         and/or coordZ, or pass syncToPoints = true to use the current
    //         position of the corresponding smartPoints, if the segment was
    //         defined using smartPoints
    //
    addMovableLineSegment: function addMovableLineSegment(options) {
        var lineSegment = $.extend({
            graph: this,
            coordA: [ 0, 0 ],
            coordZ: [ 1, 1 ],
            snapX: 0,
            snapY: 0,
            fixed: false,
            ticks: 0,
            normalStyle: {},
            highlightStyle: {
                stroke: KhanColors.INTERACTING,
                "stroke-width": 6
            },
            labelStyle: {
                stroke: KhanColors.INTERACTIVE,
                color: KhanColors.INTERACTIVE
            },
            highlight: false,
            dragging: false,
            tick: [],
            extendLine: false,
            extendRay: false,
            constraints: {
                fixed: false,
                constrainX: false,
                constrainY: false
            },
            sideLabel: "",
            vertexLabels: [],
            numArrows: 0,
            numTicks: 0,
            movePointsWithLine: false
        }, options);
        var normalColor = lineSegment.fixed ? KhanColors.DYNAMIC : KhanColors.INTERACTIVE;
        lineSegment.normalStyle = _.extend({}, {
            "stroke-width": 2,
            stroke: normalColor
        }, options.normalStyle);
        // arrowStyle should be kept in sync with styling of the line
        lineSegment.arrowStyle = _.extend({}, lineSegment.normalStyle, {
            color: lineSegment.normalStyle.stroke
        });
        // If the line segment is defined by movablePoints, coordA/coordZ are
        // owned by the points, otherwise they're owned by us
        if (void 0 !== options.pointA) {
            lineSegment.coordA = options.pointA.coord;
            lineSegment.pointA.lineStarts.push(lineSegment);
        } else void 0 !== options.coordA && (lineSegment.coordA = options.coordA.slice());
        if (void 0 !== options.pointZ) {
            lineSegment.coordZ = options.pointZ.coord;
            lineSegment.pointZ.lineEnds.push(lineSegment);
        } else void 0 !== options.coordA && (lineSegment.coordA = lineSegment.coordA.slice());
        var graph = lineSegment.graph;
        graph.style(lineSegment.normalStyle);
        for (var i = 0; i < lineSegment.ticks; ++i) lineSegment.tick[i] = InteractiveUtils.bogusShape;
        // TODO(kevinb) figure out why path isn't being used
        /* eslint-disable */
        var path = GraphUtils.unscaledSvgPath([ [ 0, 0 ], [ 1, 0 ] ]);
        for (var _i = 0; _i < lineSegment.ticks; ++_i) {
            var tickoffset = .5 - (lineSegment.ticks - 1 + 2 * _i) / graph.scale[0];
            path += GraphUtils.unscaledSvgPath([ [ tickoffset, -7 ], [ tickoffset, 7 ] ]);
        }
        /* eslint-enable */
        options = {
            thickness: Math.max(lineSegment.normalStyle["stroke-width"], lineSegment.highlightStyle["stroke-width"])
        };
        lineSegment.visibleLine = new WrappedLine(graph, [ 0, 0 ], [ 1, 0 ], options);
        lineSegment.visibleLine.attr(lineSegment.normalStyle);
        // Add mouse target
        if (!lineSegment.fixed) {
            var _options2 = {
                thickness: 30,
                mouselayer: true
            };
            lineSegment.mouseTarget = new WrappedLine(graph, [ 0, 0 ], [ 1, 0 ], _options2);
            lineSegment.mouseTarget.attr({
                fill: "#000",
                opacity: 0
            });
        }
        // Reposition the line segment. Call after changing coordA and/or
        // coordZ, or pass syncToPoints = true to use the current position of
        // the corresponding movablePoints, if the segment was defined using
        // movablePoints
        lineSegment.transform = function(syncToPoints) {
            if (syncToPoints) {
                "object" === _typeof(this.pointA) && (this.coordA = this.pointA.coord);
                "object" === _typeof(this.pointZ) && (this.coordZ = this.pointZ.coord);
            }
            var getScaledAngle = function getScaledAngle(line) {
                var scaledA = line.graph.scalePoint(line.coordA);
                var scaledZ = line.graph.scalePoint(line.coordZ);
                return kvector.polarDegFromCart(kvector.subtract(scaledZ, scaledA))[1];
            };
            var getClipPoint = function getClipPoint(graph, coord, angle) {
                graph = lineSegment.graph;
                var xExtent = graph.range[0][1] - graph.range[0][0];
                var yExtent = graph.range[1][1] - graph.range[1][0];
                var distance = xExtent + yExtent;
                var angleVec = graph.unscaleVector(kvector.cartFromPolarDeg([ 1, angle ]));
                var distVec = kvector.scale(kvector.normalize(angleVec), distance);
                var farCoord = kvector.add(coord, distVec);
                var scaledAngle = kvector.polarDegFromCart(angleVec)[1];
                return graph.constrainToBoundsOnAngle(farCoord, 4, scaledAngle * Math.PI / 180);
            };
            var angle = getScaledAngle(this);
            var start = this.coordA;
            var end = this.coordZ;
            // Extend start, end if necessary (i.e., if not a line segment)
            if (this.extendLine) {
                start = getClipPoint(graph, start, 360 - angle);
                end = getClipPoint(graph, end, (540 - angle) % 360);
            } else this.extendRay && (end = getClipPoint(graph, start, 360 - angle));
            var elements = [ this.visibleLine ];
            this.fixed || elements.push(this.mouseTarget);
            _.each(elements, function(element) {
                element.moveTo(start, end);
            });
            var createArrow = function createArrow(graph, style) {
                var center = [ .75, 0 ];
                var points = [ [ -3, 4 ], [ -2.75, 2.5 ], [ 0, .25 ], center, [ 0, -.25 ], [ -2.75, -2.5 ], [ -3, -4 ] ];
                var scale = 1.4;
                points = _.map(points, function(point) {
                    var pv = kvector.subtract(point, center);
                    var pvScaled = kvector.scale(pv, 1.4);
                    return kvector.add(center, pvScaled);
                });
                var createCubicPath = function createCubicPath(points) {
                    var path = "M" + points[0][0] + " " + points[0][1];
                    for (var _i2 = 1; _i2 < points.length; _i2 += 3) path += "C" + points[_i2][0] + " " + points[_i2][1] + " " + points[_i2 + 1][0] + " " + points[_i2 + 1][1] + " " + points[_i2 + 2][0] + " " + points[_i2 + 2][1];
                    return path;
                };
                var unscaledPoints = _.map(points, graph.unscalePoint);
                var options = {
                    center: graph.unscalePoint(center),
                    createPath: createCubicPath
                };
                var arrowHead = new WrappedPath(graph, unscaledPoints, options);
                arrowHead.attr(_.extend({
                    "stroke-linejoin": "round",
                    "stroke-linecap": "round",
                    "stroke-dasharray": ""
                }, style));
                // Add custom function for transforming arrowheads that
                // accounts for center, scaling, etc.
                arrowHead.toCoordAtAngle = function(coord, angle) {
                    var clipPoint = graph.scalePoint(getClipPoint(graph, coord, angle));
                    var do3dTransform = getCanUse3dTransform();
                    arrowHead.transform("translateX(" + (clipPoint[0] + 1.4 * center[0]) + "px) translateY(" + (clipPoint[1] + 1.4 * center[1]) + "px) " + (do3dTransform ? "translateZ(0) " : "") + "rotate(" + (360 - KhanMath.bound(angle)) + "deg)");
                };
                return arrowHead;
            };
            // Add arrows
            if (null == this._arrows) {
                this._arrows = [];
                if (this.extendLine) {
                    this._arrows.push(createArrow(graph, this.normalStyle));
                    this._arrows.push(createArrow(graph, this.normalStyle));
                } else this.extendRay && this._arrows.push(createArrow(graph, this.normalStyle));
            }
            var coordForArrow = [ this.coordA, this.coordZ ];
            var angleForArrow = [ 360 - angle, (540 - angle) % 360 ];
            _.each(this._arrows, function(arrow, i) {
                arrow.toCoordAtAngle(coordForArrow[i], angleForArrow[i]);
            });
            // Temporary objects: array of SVG nodes that get recreated on drag
            _.invoke(this.temp, "remove");
            this.temp = [];
            var isClockwise = this.coordA[0] < this.coordZ[0] || this.coordA[0] === this.coordZ[0] && this.coordA[1] > this.coordZ[1];
            // Update side label
            this.sideLabel && this.temp.push(this.graph.labelSide({
                point1: this.coordA,
                point2: this.coordZ,
                label: this.labeledSide,
                text: this.sideLabel,
                numArrows: this.numArrows,
                numTicks: this.numTicks,
                clockwise: isClockwise,
                style: this.labelStyle
            }));
            // Update vertex labels
            if (this.vertexLabels.length) {
                this.graph.labelVertex({
                    vertex: this.coordA,
                    point3: this.coordZ,
                    label: this.labeledVertices[0],
                    text: this.vertexLabels[0],
                    clockwise: isClockwise,
                    style: this.labelStyle
                });
                this.graph.labelVertex({
                    point1: this.coordA,
                    vertex: this.coordZ,
                    label: this.labeledVertices[1],
                    text: this.vertexLabels[1],
                    clockwise: isClockwise,
                    style: this.labelStyle
                });
            }
            this.temp = _.flatten(this.temp);
        };
        // Change z-order to back;
        lineSegment.toBack = function() {
            lineSegment.fixed || lineSegment.mouseTarget.toBack();
            lineSegment.visibleLine.toBack();
        };
        // Change z-order to front
        lineSegment.toFront = function() {
            lineSegment.fixed || lineSegment.mouseTarget.toFront();
            lineSegment.visibleLine.toFront();
        };
        lineSegment.remove = function() {
            lineSegment.fixed || lineSegment.mouseTarget.remove();
            lineSegment.visibleLine.remove();
            lineSegment.labeledSide && lineSegment.labeledSide.remove();
            lineSegment.labeledVertices && _.invoke(lineSegment.labeledVertices, "remove");
            lineSegment._arrows && _.invoke(lineSegment._arrows, "remove");
            lineSegment.temp.length && _.invoke(lineSegment.temp, "remove");
        };
        lineSegment.hide = function() {
            lineSegment.visibleLine.hide();
            lineSegment.temp.length && _.invoke(lineSegment.temp, "hide");
            lineSegment._arrows && _.invoke(lineSegment._arrows, "hide");
        };
        lineSegment.show = function() {
            lineSegment.visibleLine.show();
            lineSegment.temp.length && _.invoke(lineSegment.temp, "show");
            lineSegment._arrows && _.invoke(lineSegment._arrows, "show");
        };
        lineSegment.sideLabel && (lineSegment.labeledSide = this.label([ 0, 0 ], "", "center", lineSegment.labelStyle));
        lineSegment.vertexLabels.length && (lineSegment.labeledVertices = _.map(lineSegment.vertexLabels, function(label) {
            return this.label([ 0, 0 ], "", "center", lineSegment.labelStyle);
        }, this));
        if (!lineSegment.fixed && !lineSegment.constraints.fixed) {
            var $mouseTarget = $(lineSegment.mouseTarget.getMouseTarget());
            $mouseTarget.css("cursor", "move");
            $mouseTarget.bind("vmousedown vmouseover vmouseout", function(event) {
                if ("vmouseover" === event.type) {
                    if (!dragging) {
                        lineSegment.highlight = true;
                        lineSegment.visibleLine.animate(lineSegment.highlightStyle, 50);
                        lineSegment.arrowStyle = _.extend({}, lineSegment.arrowStyle, {
                            color: lineSegment.highlightStyle.stroke,
                            stroke: lineSegment.highlightStyle.stroke
                        });
                        lineSegment.transform();
                    }
                } else if ("vmouseout" === event.type) {
                    lineSegment.highlight = false;
                    if (!lineSegment.dragging) {
                        lineSegment.visibleLine.animate(lineSegment.normalStyle, 50);
                        lineSegment.arrowStyle = _.extend({}, lineSegment.arrowStyle, {
                            color: lineSegment.normalStyle.stroke,
                            stroke: lineSegment.normalStyle.stroke
                        });
                        lineSegment.transform();
                    }
                } else if ("vmousedown" === event.type && (1 === event.which || 0 === event.which)) {
                    event.preventDefault();
                    var coordX = (event.pageX - $(graph.raphael.canvas.parentNode).offset().left) / graph.scale[0] + graph.range[0][0];
                    var coordY = graph.range[1][1] - (event.pageY - $(graph.raphael.canvas.parentNode).offset().top) / graph.scale[1];
                    lineSegment.snapX > 0 && (coordX = Math.round(coordX / lineSegment.snapX) * lineSegment.snapX);
                    lineSegment.snapY > 0 && (coordY = Math.round(coordY / lineSegment.snapY) * lineSegment.snapY);
                    var mouseOffsetA = [ lineSegment.coordA[0] - coordX, lineSegment.coordA[1] - coordY ];
                    var mouseOffsetZ = [ lineSegment.coordZ[0] - coordX, lineSegment.coordZ[1] - coordY ];
                    var offsetLeft = -Math.min(graph.scaleVector(mouseOffsetA)[0], graph.scaleVector(mouseOffsetZ)[0]);
                    var offsetRight = Math.max(graph.scaleVector(mouseOffsetA)[0], graph.scaleVector(mouseOffsetZ)[0]);
                    var offsetTop = Math.max(graph.scaleVector(mouseOffsetA)[1], graph.scaleVector(mouseOffsetZ)[1]);
                    var offsetBottom = -Math.min(graph.scaleVector(mouseOffsetA)[1], graph.scaleVector(mouseOffsetZ)[1]);
                    $(document).bind("vmousemove.lineSegment vmouseup.lineSegment", function(event) {
                        event.preventDefault();
                        lineSegment.dragging = true;
                        dragging = true;
                        var mouseX = event.pageX - $(graph.raphael.canvas.parentNode).offset().left;
                        var mouseY = event.pageY - $(graph.raphael.canvas.parentNode).offset().top;
                        // no part of the line segment can go beyond 10
                        // pixels from the edge
                        mouseX = Math.max(offsetLeft + 10, Math.min(graph.xpixels - 10 - offsetRight, mouseX));
                        mouseY = Math.max(offsetTop + 10, Math.min(graph.ypixels - 10 - offsetBottom, mouseY));
                        var coordX = mouseX / graph.scale[0] + graph.range[0][0];
                        var coordY = graph.range[1][1] - mouseY / graph.scale[1];
                        lineSegment.snapX > 0 && (coordX = Math.round(coordX / lineSegment.snapX) * lineSegment.snapX);
                        lineSegment.snapY > 0 && (coordY = Math.round(coordY / lineSegment.snapY) * lineSegment.snapY);
                        if ("vmousemove" === event.type) {
                            lineSegment.constraints.constrainX && (coordX = lineSegment.coordA[0] - mouseOffsetA[0]);
                            lineSegment.constraints.constrainY && (coordY = lineSegment.coordA[1] - mouseOffsetA[1]);
                            var dX = coordX + mouseOffsetA[0] - lineSegment.coordA[0];
                            var dY = coordY + mouseOffsetA[1] - lineSegment.coordA[1];
                            lineSegment.coordA = [ coordX + mouseOffsetA[0], coordY + mouseOffsetA[1] ];
                            lineSegment.coordZ = [ coordX + mouseOffsetZ[0], coordY + mouseOffsetZ[1] ];
                            lineSegment.transform();
                            if (lineSegment.movePointsWithLine) {
                                // If the points are movablePoints, adjust
                                // their coordinates when the line itself is
                                // dragged
                                "object" === _typeof(lineSegment.pointA) && lineSegment.pointA.setCoord([ lineSegment.pointA.coord[0] + dX, lineSegment.pointA.coord[1] + dY ]);
                                "object" === _typeof(lineSegment.pointZ) && lineSegment.pointZ.setCoord([ lineSegment.pointZ.coord[0] + dX, lineSegment.pointZ.coord[1] + dY ]);
                            }
                            _.isFunction(lineSegment.onMove) && lineSegment.onMove(dX, dY);
                        } else if ("vmouseup" === event.type) {
                            $(document).unbind(".lineSegment");
                            lineSegment.dragging = false;
                            dragging = false;
                            if (!lineSegment.highlight) {
                                lineSegment.visibleLine.animate(lineSegment.normalStyle, 50);
                                lineSegment.arrowStyle = _.extend({}, lineSegment.arrowStyle, {
                                    color: lineSegment.normalStyle.stroke,
                                    stroke: lineSegment.normalStyle.stroke
                                });
                                lineSegment.transform();
                            }
                            _.isFunction(lineSegment.onMoveEnd) && lineSegment.onMoveEnd();
                        }
                        $(lineSegment).trigger("move");
                    });
                }
            });
        }
        void 0 !== lineSegment.pointA && lineSegment.pointA.toFront();
        void 0 !== lineSegment.pointZ && lineSegment.pointZ.toFront();
        lineSegment.transform();
        return lineSegment;
    },
    // MovablePolygon is a polygon that can be dragged around the screen.
    // By attaching a smartPoint to each vertex, the points can be
    // manipulated individually.
    //
    // To use with smartPoints, add the smartPoints first, then:
    //   addMovablePolygon({points: [...]});
    //
    // Include "fixed: true" in the options if you don't want the entire
    // polygon to be draggable (you can still use points to make the
    // vertices draggable)
    //
    // The returned object includes the following properties/methods:
    //
    //   - polygon.points
    //         The polygon's dynamic smartPoints and static coordinates, mixed.
    //
    //   - polygon.coords
    //         The polygon's current coordinates (generated, don't edit).
    //
    //   - polygon.transform()
    //         Repositions the polygon. Call after changing any points.
    //
    addMovablePolygon: function addMovablePolygon(options) {
        var graphie = this;
        var polygon = $.extend({
            snapX: 0,
            snapY: 0,
            fixed: false,
            constrainToGraph: true,
            normalStyle: {},
            highlightStyle: {
                stroke: KhanColors.INTERACTING,
                "stroke-width": 2,
                fill: KhanColors.INTERACTING,
                "fill-opacity": .05
            },
            pointHighlightStyle: {
                fill: KhanColors.INTERACTING,
                stroke: KhanColors.INTERACTING
            },
            labelStyle: {
                stroke: KhanColors.DYNAMIC,
                "stroke-width": 1,
                color: KhanColors.DYNAMIC
            },
            angleLabels: [],
            showRightAngleMarkers: [],
            sideLabels: [],
            vertexLabels: [],
            numArcs: [],
            numArrows: [],
            numTicks: [],
            updateOnPointMove: true,
            closed: true
        }, _.omit(options, "points"));
        var normalColor = polygon.fixed ? KhanColors.DYNAMIC : KhanColors.INTERACTIVE;
        polygon.normalStyle = _.extend({
            "stroke-width": 2,
            "fill-opacity": 0,
            fill: normalColor,
            stroke: normalColor
        }, options.normalStyle);
        // don't deep copy the points array with $.extend;
        // we may want to append to it later for click-to-add-points
        polygon.points = options.points;
        var isPoint = function isPoint(coordOrPoint) {
            return !_.isArray(coordOrPoint);
        };
        polygon.update = function() {
            var n = polygon.points.length;
            // Update coords
            polygon.coords = _.map(polygon.points, function(coordOrPoint, i) {
                return isPoint(coordOrPoint) ? coordOrPoint.coord : coordOrPoint;
            });
            // Calculate bounding box
            polygon.left = _.min(_.pluck(polygon.coords, 0));
            polygon.right = _.max(_.pluck(polygon.coords, 0));
            polygon.top = _.max(_.pluck(polygon.coords, 1));
            polygon.bottom = _.min(_.pluck(polygon.coords, 1));
            var scaledCoords = _.map(polygon.coords, function(coord) {
                return graphie.scalePoint(coord);
            });
            // Create path
            polygon.closed ? scaledCoords.push(true) : // For open polygons, concatenate a reverse of the path,
            // to remove the inside area of the path, which would
            // otherwise be clickable (even if the closing line segment
            // wasn't drawn
            scaledCoords = scaledCoords.concat(_.clone(scaledCoords).reverse());
            polygon.path = GraphUtils.unscaledSvgPath(scaledCoords);
            // Temporary objects
            _.invoke(polygon.temp, "remove");
            polygon.temp = [];
            var isClockwise = clockwise(polygon.coords);
            // Update angle labels
            (polygon.angleLabels.length || polygon.showRightAngleMarkers.length) && _.each(polygon.labeledAngles, function(label, i) {
                polygon.temp.push(graphie.labelAngle({
                    point1: polygon.coords[(i - 1 + n) % n],
                    vertex: polygon.coords[i],
                    point3: polygon.coords[(i + 1) % n],
                    label: label,
                    text: polygon.angleLabels[i],
                    showRightAngleMarker: polygon.showRightAngleMarkers[i],
                    numArcs: polygon.numArcs[i],
                    clockwise: isClockwise,
                    style: polygon.labelStyle
                }));
            });
            // Update side labels
            polygon.sideLabels.length && _.each(polygon.labeledSides, function(label, i) {
                polygon.temp.push(graphie.labelSide({
                    point1: polygon.coords[i],
                    point2: polygon.coords[(i + 1) % n],
                    label: label,
                    text: polygon.sideLabels[i],
                    numArrows: polygon.numArrows[i],
                    numTicks: polygon.numTicks[i],
                    clockwise: isClockwise,
                    style: polygon.labelStyle
                }));
            });
            // Update vertex labels
            polygon.vertexLabels.length && _.each(polygon.labeledVertices, function(label, i) {
                graphie.labelVertex({
                    point1: polygon.coords[(i - 1 + n) % n],
                    vertex: polygon.coords[i],
                    point3: polygon.coords[(i + 1) % n],
                    label: label,
                    text: polygon.vertexLabels[i],
                    clockwise: isClockwise,
                    style: polygon.labelStyle
                });
            });
            polygon.temp = _.flatten(polygon.temp);
        };
        polygon.transform = function() {
            polygon.update();
            polygon.visibleShape.attr({
                path: polygon.path
            });
            polygon.fixed || polygon.mouseTarget.attr({
                path: polygon.path
            });
        };
        polygon.remove = function() {
            polygon.visibleShape.remove();
            polygon.fixed || polygon.mouseTarget.remove();
            polygon.labeledAngles && _.invoke(polygon.labeledAngles, "remove");
            polygon.labeledSides && _.invoke(polygon.labeledSides, "remove");
            polygon.labeledVertices && _.invoke(polygon.labeledVertices, "remove");
            polygon.temp.length && _.invoke(polygon.temp, "remove");
        };
        polygon.toBack = function() {
            polygon.fixed || polygon.mouseTarget.toBack();
            polygon.visibleShape.toBack();
        };
        polygon.toFront = function() {
            polygon.fixed || polygon.mouseTarget.toFront();
            polygon.visibleShape.toFront();
        };
        // Setup
        polygon.updateOnPointMove && _.each(_.filter(polygon.points, isPoint), function(coordOrPoint) {
            coordOrPoint.polygonVertices.push(polygon);
        });
        polygon.coords = new Array(polygon.points.length);
        if (polygon.angleLabels.length) {
            var numLabels = Math.max(polygon.angleLabels.length, polygon.showRightAngleMarkers.length);
            polygon.labeledAngles = _.times(numLabels, function() {
                return this.label([ 0, 0 ], "", "center", polygon.labelStyle);
            }, this);
        }
        polygon.sideLabels.length && (polygon.labeledSides = _.map(polygon.sideLabels, function(label) {
            return this.label([ 0, 0 ], "", "center", polygon.labelStyle);
        }, this));
        polygon.vertexLabels.length && (polygon.labeledVertices = _.map(polygon.vertexLabels, function(label) {
            return this.label([ 0, 0 ], "", "center", polygon.labelStyle);
        }, this));
        polygon.update();
        polygon.visibleShape = graphie.raphael.path(polygon.path);
        polygon.visibleShape.attr(polygon.normalStyle);
        if (!polygon.fixed) {
            polygon.mouseTarget = graphie.mouselayer.path(polygon.path);
            polygon.mouseTarget.attr({
                fill: "#000",
                opacity: 0,
                cursor: "move"
            });
            $(polygon.mouseTarget[0]).bind("vmousedown vmouseover vmouseout", function(event) {
                if ("vmouseover" === event.type) {
                    if (!dragging || polygon.dragging) {
                        polygon.highlight = true;
                        polygon.visibleShape.animate(polygon.highlightStyle, 50);
                        _.each(_.filter(polygon.points, isPoint), function(point) {
                            point.visibleShape.animate(polygon.pointHighlightStyle, 50);
                        });
                    }
                } else if ("vmouseout" === event.type) {
                    polygon.highlight = false;
                    if (!polygon.dragging) {
                        polygon.visibleShape.animate(polygon.normalStyle, 50);
                        var points = _.filter(polygon.points, isPoint);
                        _.any(_.pluck(points, "dragging")) || _.each(points, function(point) {
                            point.visibleShape.animate(point.normalStyle, 50);
                        });
                    }
                } else if ("vmousedown" === event.type && (1 === event.which || 0 === event.which)) {
                    event.preventDefault();
                    _.each(_.filter(polygon.points, isPoint), function(point) {
                        point.dragging = true;
                    });
                    var startX = (event.pageX - $(graphie.raphael.canvas.parentNode).offset().left) / graphie.scale[0] + graphie.range[0][0];
                    var startY = graphie.range[1][1] - (event.pageY - $(graphie.raphael.canvas.parentNode).offset().top) / graphie.scale[1];
                    polygon.snapX > 0 && (startX = Math.round(startX / polygon.snapX) * polygon.snapX);
                    polygon.snapY > 0 && (startY = Math.round(startY / polygon.snapY) * polygon.snapY);
                    var lastX = startX;
                    var lastY = startY;
                    var polygonCoords = polygon.coords.slice();
                    var offsetLeft = (startX - polygon.left) * graphie.scale[0];
                    var offsetRight = (polygon.right - startX) * graphie.scale[0];
                    var offsetTop = (polygon.top - startY) * graphie.scale[1];
                    var offsetBottom = (startY - polygon.bottom) * graphie.scale[1];
                    $(document).bind("vmousemove.polygon vmouseup.polygon", function(event) {
                        event.preventDefault();
                        polygon.dragging = true;
                        dragging = true;
                        var mouseX = event.pageX - $(graphie.raphael.canvas.parentNode).offset().left;
                        var mouseY = event.pageY - $(graphie.raphael.canvas.parentNode).offset().top;
                        // no part of the polygon can go beyond 10 pixels from
                        // the edge
                        if (polygon.constrainToGraph) {
                            mouseX = Math.max(offsetLeft + 10, Math.min(graphie.xpixels - 10 - offsetRight, mouseX));
                            mouseY = Math.max(offsetTop + 10, Math.min(graphie.ypixels - 10 - offsetBottom, mouseY));
                        }
                        var currentX = mouseX / graphie.scale[0] + graphie.range[0][0];
                        var currentY = graphie.range[1][1] - mouseY / graphie.scale[1];
                        polygon.snapX > 0 && (currentX = Math.round(currentX / polygon.snapX) * polygon.snapX);
                        polygon.snapY > 0 && (currentY = Math.round(currentY / polygon.snapY) * polygon.snapY);
                        if ("vmousemove" === event.type) {
                            var dX = currentX - startX;
                            var dY = currentY - startY;
                            var doMove = true;
                            if (_.isFunction(polygon.onMove)) {
                                var onMoveResult = polygon.onMove(dX, dY);
                                if (false === onMoveResult) doMove = false; else if (_.isArray(onMoveResult)) {
                                    dX = onMoveResult[0];
                                    dY = onMoveResult[1];
                                    currentX = startX + dX;
                                    currentY = startY + dY;
                                }
                            }
                            var increment = function increment(i) {
                                return [ polygonCoords[i][0] + dX, polygonCoords[i][1] + dY ];
                            };
                            if (doMove) {
                                _.each(polygon.points, function(coordOrPoint, i) {
                                    isPoint(coordOrPoint) ? coordOrPoint.setCoord(increment(i)) : polygon.points[i] = increment(i);
                                });
                                polygon.transform();
                                $(polygon).trigger("move");
                                lastX = currentX;
                                lastY = currentY;
                            }
                        } else if ("vmouseup" === event.type) {
                            $(document).unbind(".polygon");
                            var _points = _.filter(polygon.points, isPoint);
                            _.each(_points, function(point) {
                                point.dragging = false;
                            });
                            polygon.dragging = false;
                            dragging = false;
                            if (!polygon.highlight) {
                                polygon.visibleShape.animate(polygon.normalStyle, 50);
                                _.each(_points, function(point) {
                                    point.visibleShape.animate(point.normalStyle, 50);
                                });
                            }
                            _.isFunction(polygon.onMoveEnd) && polygon.onMoveEnd(lastX - startX, lastY - startY);
                        }
                    });
                }
            });
        }
        // Bring any movable points to the front
        _.invoke(_.filter(polygon.points, isPoint), "toFront");
        return polygon;
    },
    /**
     * Constrain a point to be within the graph (including padding).
     * If outside graph, point's x and y coordinates are clamped within
     * the graph.
     */
    constrainToBounds: function constrainToBounds(point, padding) {
        var lower = this.unscalePoint([ padding, this.ypixels - padding ]);
        var upper = this.unscalePoint([ this.xpixels - padding, padding ]);
        return [ Math.max(lower[0], Math.min(upper[0], point[0])), Math.max(lower[1], Math.min(upper[1], point[1])) ];
    },
    /**
     * Constrain a point to be within the graph (including padding).
     * If outside graph, point is moved along the ray specified by angle
     * until inside graph.
     */
    constrainToBoundsOnAngle: function constrainToBoundsOnAngle(point, padding, angle) {
        var lower = this.unscalePoint([ padding, this.ypixels - padding ]);
        var upper = this.unscalePoint([ this.xpixels - padding, padding ]);
        var result = point.slice();
        result[0] < lower[0] ? result = [ lower[0], result[1] + (lower[0] - result[0]) * Math.tan(angle) ] : result[0] > upper[0] && (result = [ upper[0], result[1] - (result[0] - upper[0]) * Math.tan(angle) ]);
        result[1] < lower[1] ? result = [ result[0] + (lower[1] - result[1]) / Math.tan(angle), lower[1] ] : result[1] > upper[1] && (result = [ result[0] - (result[1] - upper[1]) / Math.tan(angle), upper[1] ]);
        return result;
    },
    // MovableAngle is an angle that can be dragged around the screen.
    // By attaching a smartPoint to the vertex and ray control points, the
    // rays can be manipulated individually.
    //
    // Use only with smartPoints; add the smartPoints first, then:
    //   addMovableAngle({points: [...]});
    //
    // The rays can be controlled to snap on degrees (more useful than snapping
    // on coordinates) by setting snapDegrees to a positive integer.
    //
    // The returned object includes the following properties/methods:
    //
    //   - movableAngle.points
    //         The movableAngle's dynamic smartPoints.
    //
    //   - movableAngle.coords
    //         The movableAngle's current coordinates (generated, don't edit).
    //
    addMovableAngle: function addMovableAngle(options) {
        return new MovableAngle(this, options);
    },
    // center: movable point
    // radius: int
    // circ: graphie circle
    // perim: invisible mouse target for dragging/changing radius
    addCircleGraph: function addCircleGraph(options) {
        var _this = this;
        var graphie = this;
        var circle = $.extend({
            center: [ 0, 0 ],
            radius: 2,
            snapX: .5,
            snapY: .5,
            snapRadius: .5,
            minRadius: 1,
            centerConstraints: {},
            centerNormalStyle: {},
            centerHighlightStyle: {
                stroke: KhanColors.INTERACTING,
                fill: KhanColors.INTERACTING
            },
            circleNormalStyle: {
                stroke: KhanColors.INTERACTIVE,
                "fill-opacity": 0
            },
            circleHighlightStyle: {
                stroke: KhanColors.INTERACTING,
                fill: KhanColors.INTERACTING,
                "fill-opacity": .05
            }
        }, options);
        var normalColor = circle.centerConstraints.fixed ? KhanColors.DYNAMIC : KhanColors.INTERACTIVE;
        var centerNormalStyle = options ? options.centerNormalStyle : null;
        circle.centerNormalStyle = _.extend({}, {
            fill: normalColor,
            stroke: normalColor
        }, centerNormalStyle);
        circle.centerPoint = graphie.addMovablePoint({
            graph: graphie,
            coord: circle.center,
            normalStyle: circle.centerNormalStyle,
            snapX: circle.snapX,
            snapY: circle.snapY,
            constraints: circle.centerConstraints
        });
        circle.circ = graphie.circle(circle.center, circle.radius, circle.circleNormalStyle);
        circle.perim = graphie.mouselayer.circle(graphie.scalePoint(circle.center)[0], graphie.scalePoint(circle.center)[1], graphie.scaleVector(circle.radius)[0]).attr({
            "stroke-width": 20,
            opacity: .002
        });
        // Highlight circle circumference on center point hover
        circle.centerConstraints.fixed || $(circle.centerPoint.mouseTarget.getMouseTarget()).on("vmouseover vmouseout", function(event) {
            circle.centerPoint.highlight || circle.centerPoint.dragging ? circle.circ.animate(circle.circleHighlightStyle, 50) : circle.circ.animate(circle.circleNormalStyle, 50);
        });
        circle.toFront = function() {
            circle.circ.toFront();
            circle.perim.toFront();
            circle.centerPoint.visibleShape.toFront();
            circle.centerConstraints.fixed || circle.centerPoint.mouseTarget.toFront();
        };
        circle.centerPoint.onMove = function(x, y) {
            circle.toFront();
            circle.circ.attr({
                cx: graphie.scalePoint(x)[0],
                cy: graphie.scalePoint(y)[1]
            });
            circle.perim.attr({
                cx: graphie.scalePoint(x)[0],
                cy: graphie.scalePoint(y)[1]
            });
            circle.onMove && circle.onMove(x, y);
        };
        $(circle.centerPoint).on("move", function() {
            circle.center = this.coord;
            $(circle).trigger("move");
        });
        // circle.setCenter(x, y) moves the circle to the specified
        // x, y coordinate as if the user had dragged it there.
        circle.setCenter = function(x, y) {
            circle.centerPoint.setCoord([ x, y ]);
            circle.centerPoint.onMove(x, y);
            circle.center = [ x, y ];
        };
        // circle.setRadius(r) sets the circle's radius to the specified
        // value as if the user had dragged it there.
        circle.setRadius = function(r) {
            circle.radius = r;
            circle.perim.attr({
                r: graphie.scaleVector(r)[0]
            });
            circle.circ.attr({
                rx: graphie.scaleVector(r)[0],
                ry: graphie.scaleVector(r)[1]
            });
        };
        circle.remove = function() {
            circle.centerPoint.remove();
            circle.circ.remove();
            circle.perim.remove();
        };
        // Define a set of axes using polar coordinates to specify
        // which resizing cursor we want to show based on where the
        // mouse position lies in relation to the circle's center.
        // The first two columns in cursorAxes refer to the minimum
        // and maximum angle values bounding a circle sector, and
        // the third column refers to the cursor name that will be
        // applied if the mouse position falls inside the given sector.
        var cursorAxes = [ [ -1 * Math.PI, -.875 * Math.PI, "ew-resize" ], [ -.875 * Math.PI, -.625 * Math.PI, "nesw-resize" ], [ -.625 * Math.PI, -.375 * Math.PI, "ns-resize" ], [ -.375 * Math.PI, -.125 * Math.PI, "nwse-resize" ], [ -.125 * Math.PI, .125 * Math.PI, "ew-resize" ], [ .125 * Math.PI, .375 * Math.PI, "nesw-resize" ], [ .375 * Math.PI, .625 * Math.PI, "ns-resize" ], [ .625 * Math.PI, .875 * Math.PI, "nwse-resize" ], [ .875 * Math.PI, 1 * Math.PI, "ew-resize" ] ];
        // When the mouse moves along the circle's perimeter, we
        // dynamically set a CSS rule to show the correct
        // bidirectional cursor so a student knows they can resize
        // our circle. To do this, we convert the x and y coordinates
        // of the mouse position into polar coordinates and use the
        // defined cursorAxes above to set our rule.
        $(circle.perim.node).on("vmousemove", function(event) {
            var _getMouseCoord = _this.getMouseCoord(event), x = _getMouseCoord[0], y = _getMouseCoord[1];
            x -= circle.center[0];
            y -= circle.center[1];
            var theta = Math.atan2(y, x);
            cursorAxes.forEach(function(axes) {
                var min = axes[0], max = axes[1], cursorName = axes[2];
                theta >= min && theta < max && $(circle.perim.node).css("cursor", cursorName);
            });
        });
        // Set a default resizing-friendly cursor to be safe.
        $(circle.perim.node).css("cursor", "nesw-resize");
        // Prevent the page from scrolling when we grab and drag the circle on
        // a mobile device.
        circle.perim.node.addEventListener("touchstart", function(event) {
            event.preventDefault();
        }, {
            passive: false
        });
        $(circle.perim.node).on("vmouseover vmouseout vmousedown", function(event) {
            if ("vmouseover" === event.type) {
                circle.highlight = true;
                if (!dragging) {
                    // TODO(jack): Figure out why this doesn't work
                    // for circleHighlightStyle's that change
                    // stroke-dasharray
                    circle.circ.animate(circle.circleHighlightStyle, 50);
                    circle.centerPoint.visibleShape.animate(circle.centerHighlightStyle, 50);
                }
            } else if ("vmouseout" === event.type) {
                circle.highlight = false;
                if (!circle.dragging && !circle.centerPoint.dragging) {
                    circle.circ.animate(circle.circleNormalStyle, 50);
                    circle.centerPoint.visibleShape.animate(circle.centerNormalStyle, 50);
                }
            } else if ("vmousedown" === event.type && (1 === event.which || 0 === event.which)) {
                event.preventDefault();
                circle.toFront();
                var startRadius = circle.radius;
                $(document).on("vmousemove vmouseup", function(event) {
                    event.preventDefault();
                    circle.dragging = true;
                    dragging = true;
                    if ("vmousemove" === event.type) {
                        var coord = graphie.constrainToBounds(graphie.getMouseCoord(event), 10);
                        var radius = GraphUtils.getDistance(circle.centerPoint.coord, coord);
                        radius = Math.max(circle.minRadius, Math.round(radius / circle.snapRadius) * circle.snapRadius);
                        var oldRadius = circle.radius;
                        var doResize = true;
                        if (circle.onResize) {
                            var onResizeResult = circle.onResize(radius, oldRadius);
                            _.isNumber(onResizeResult) ? radius = onResizeResult : false === onResizeResult && (doResize = false);
                        }
                        if (doResize) {
                            circle.setRadius(radius);
                            $(circle).trigger("move");
                        }
                    } else if ("vmouseup" === event.type) {
                        $(document).off("vmousemove vmouseup");
                        circle.dragging = false;
                        dragging = false;
                        circle.onResizeEnd && circle.onResizeEnd(circle.radius, startRadius);
                    }
                });
            }
        });
        return circle;
    },
    addRotateHandle: function() {
        var drawRotateHandle = function drawRotateHandle(graphie, center, radius, halfWidth, lengthAngle, angle, interacting) {
            var getRotateHandlePoint = function getRotateHandlePoint(offset, distanceFromArrowMidline) {
                var distFromRotationCenter = radius + distanceFromArrowMidline;
                var vec = kvector.cartFromPolarDeg([ distFromRotationCenter, angle + offset ]);
                var absolute = kvector.add(center, vec);
                var pixels = graphie.scalePoint(absolute);
                return pixels[0] + "," + pixels[1];
            };
            var innerR = graphie.scaleVector(radius - halfWidth);
            var outerR = graphie.scaleVector(radius + halfWidth);
            // Draw the double-headed arrow thing that shows users where to
            // click and drag to rotate
            // upper arrowhead
            // outer arc
            // lower arrowhead
            // inner arc
            return graphie.raphael.path(" M" + getRotateHandlePoint(lengthAngle, -halfWidth) + " L" + getRotateHandlePoint(lengthAngle, -3 * halfWidth) + " L" + getRotateHandlePoint(2 * lengthAngle, 0) + " L" + getRotateHandlePoint(lengthAngle, 3 * halfWidth) + " L" + getRotateHandlePoint(lengthAngle, halfWidth) + " A" + outerR[0] + "," + outerR[1] + ",0,0,1," + getRotateHandlePoint(-lengthAngle, halfWidth) + " L" + getRotateHandlePoint(-lengthAngle, 3 * halfWidth) + " L" + getRotateHandlePoint(-2 * lengthAngle, 0) + " L" + getRotateHandlePoint(-lengthAngle, -3 * halfWidth) + " L" + getRotateHandlePoint(-lengthAngle, -halfWidth) + " A" + innerR[0] + "," + innerR[1] + ",0,0,0," + getRotateHandlePoint(lengthAngle, -halfWidth) + " Z").attr({
                stroke: null,
                fill: interacting ? KhanColors.INTERACTING : KhanColors.INTERACTIVE
            });
        };
        return function(options) {
            var graph = this;
            var rotatePoint = options.center;
            var radius = options.radius;
            var lengthAngle = options.lengthAngle || 30;
            var hideArrow = options.hideArrow || false;
            var mouseTarget = options.mouseTarget;
            var id = _.uniqueId("rotateHandle");
            // Normalize rotatePoint into something that always looks
            // like a movablePoint
            _.isArray(rotatePoint) && (rotatePoint = {
                coord: rotatePoint
            });
            var rotateHandle = graph.addMovablePoint({
                coord: kpoint.addVector(rotatePoint.coord, kvector.cartFromPolarDeg(radius, options.angleDeg || 0)),
                constraints: {
                    fixedDistance: {
                        dist: radius,
                        point: rotatePoint
                    }
                },
                mouseTarget: mouseTarget
            });
            // move the rotatePoint in front of the rotateHandle to avoid
            // confusing clicking/scaling of the rotateHandle when the user
            // intends to click on the rotatePoint
            rotatePoint.toFront();
            var rotatePointPrevCoord = rotatePoint.coord;
            var rotateHandlePrevCoord = rotateHandle.coord;
            var rotateHandleStartCoord = rotateHandlePrevCoord;
            var isRotating = false;
            var isHovering = false;
            var drawnRotateHandle = void 0;
            var redrawRotateHandle = function redrawRotateHandle(handleCoord) {
                if (hideArrow) return;
                var handleVec = kvector.subtract(handleCoord, rotatePoint.coord);
                var handlePolar = kvector.polarDegFromCart(handleVec);
                var angle = handlePolar[1];
                drawnRotateHandle && drawnRotateHandle.remove();
                drawnRotateHandle = drawRotateHandle(graph, rotatePoint.coord, options.radius, isRotating || isHovering ? options.hoverWidth / 2 : options.width / 2, lengthAngle, angle, isRotating || isHovering);
            };
            // when the rotation center moves, we need to move
            // the rotationHandle as well, or it will end up out
            // of sync
            $(rotatePoint).on("move." + id, function() {
                var delta = kvector.subtract(rotatePoint.coord, rotatePointPrevCoord);
                rotateHandle.setCoord(kvector.add(rotateHandle.coord, delta));
                redrawRotateHandle(rotateHandle.coord);
                rotatePointPrevCoord = rotatePoint.coord;
                rotateHandle.constraints.fixedDistance.point = rotatePoint;
                rotateHandlePrevCoord = rotateHandle.coord;
            });
            // Rotate polygon with rotateHandle
            rotateHandle.onMove = function(x, y) {
                if (!isRotating) {
                    rotateHandleStartCoord = rotateHandlePrevCoord;
                    isRotating = true;
                }
                var coord = [ x, y ];
                if (options.onMove) {
                    var oldPolar = kvector.polarDegFromCart(kvector.subtract(rotateHandlePrevCoord, rotatePoint.coord));
                    var newPolar = kvector.polarDegFromCart(kvector.subtract(coord, rotatePoint.coord));
                    var oldAngle = oldPolar[1];
                    var newAngle = newPolar[1];
                    var result = options.onMove(newAngle, oldAngle);
                    if (null != result && true !== result) {
                        false === result && (result = oldAngle);
                        coord = kvector.add(rotatePoint.coord, kvector.cartFromPolarDeg([ oldPolar[0], result ]));
                    }
                }
                redrawRotateHandle(coord);
                rotateHandlePrevCoord = coord;
                return coord;
            };
            rotateHandle.onMoveEnd = function() {
                isRotating = false;
                redrawRotateHandle(rotateHandle.coord);
                if (options.onMoveEnd) {
                    var oldPolar = kvector.polarDegFromCart(kvector.subtract(rotateHandleStartCoord, rotatePoint.coord));
                    var newPolar = kvector.polarDegFromCart(kvector.subtract(rotateHandle.coord, rotatePoint.coord));
                    options.onMoveEnd(newPolar[1], oldPolar[1]);
                }
            };
            // Remove the default dot added by the movablePoint since we have
            // our double-arrow thing
            rotateHandle.visibleShape.remove();
            mouseTarget || // Make the default mouse target bigger to encompass the whole
            // area around the double-arrow thing
            rotateHandle.mouseTarget.attr({
                scale: 2
            });
            var $mouseTarget = $(rotateHandle.mouseTarget.getMouseTarget());
            $mouseTarget.bind("vmouseover", function(e) {
                isHovering = true;
                redrawRotateHandle(rotateHandle.coord);
            });
            $mouseTarget.bind("vmouseout", function(e) {
                isHovering = false;
                redrawRotateHandle(rotateHandle.coord);
            });
            redrawRotateHandle(rotateHandle.coord);
            var oldRemove = rotateHandle.remove;
            rotateHandle.remove = function() {
                oldRemove.call(rotateHandle);
                drawnRotateHandle && drawnRotateHandle.remove();
                $(rotatePoint).off("move." + id);
            };
            rotateHandle.update = function() {
                redrawRotateHandle(rotateHandle.coord);
            };
            return rotateHandle;
        };
    }(),
    addReflectButton: function() {
        var drawButton = function drawButton(graphie, buttonCoord, lineCoords, size, distanceFromCenter, leftStyle, rightStyle) {
            // Avoid invalid lines
            kpoint.equal(lineCoords[0], lineCoords[1]) && (lineCoords = [ lineCoords[0], kpoint.addVector(lineCoords[0], [ 1, 1 ]) ]);
            var lineDirection = kvector.normalize(kvector.subtract(lineCoords[1], lineCoords[0]));
            var lineVec = kvector.scale(lineDirection, size / 2);
            var centerVec = kvector.scale(lineDirection, distanceFromCenter);
            var leftCenterVec = kvector.rotateDeg(centerVec, 90);
            var rightCenterVec = kvector.rotateDeg(centerVec, -90);
            var negLineVec = kvector.negate(lineVec);
            var leftVec = kvector.rotateDeg(lineVec, 90);
            var rightVec = kvector.rotateDeg(lineVec, -90);
            var leftCenter = kpoint.addVectors(buttonCoord, leftCenterVec);
            var rightCenter = kpoint.addVectors(buttonCoord, rightCenterVec);
            var leftCoord1 = kpoint.addVectors(buttonCoord, leftCenterVec, lineVec, leftVec);
            var leftCoord2 = kpoint.addVectors(buttonCoord, leftCenterVec, negLineVec, leftVec);
            var rightCoord1 = kpoint.addVectors(buttonCoord, rightCenterVec, lineVec, rightVec);
            var rightCoord2 = kpoint.addVectors(buttonCoord, rightCenterVec, negLineVec, rightVec);
            var leftButton = graphie.path([ leftCenter, leftCoord1, leftCoord2, true ], leftStyle);
            var rightButton = graphie.path([ rightCenter, rightCoord1, rightCoord2, true ], rightStyle);
            return {
                remove: function remove() {
                    leftButton.remove();
                    rightButton.remove();
                }
            };
        };
        return function(options) {
            var graphie = this;
            var line = options.line;
            var button = graphie.addMovablePoint({
                constraints: options.constraints,
                coord: kline.midpoint([ line.pointA.coord, line.pointZ.coord ]),
                snapX: graphie.snap[0],
                snapY: graphie.snap[1],
                onMove: function onMove(x, y) {
                    // Don't allow the button to actually move. This is a hack
                    // around the inability to both set a point as fixed AND
                    // allow it to be clicked.
                    return false;
                },
                onMoveEnd: function onMoveEnd(x, y) {
                    options.onMoveEnd && options.onMoveEnd.call(this, x, y);
                }
            });
            var isHovering = false;
            var isFlipped = false;
            var currentlyDrawnButton = void 0;
            var isHighlight = function isHighlight() {
                return isHovering;
            };
            var styles = _.map([ 0, 1 ], function(isHighlight) {
                var baseStyle = isHighlight ? options.highlightStyle : options.normalStyle;
                return _.map([ 0, 1 ], function(opacity) {
                    return _.defaults({
                        "fill-opacity": opacity
                    }, baseStyle);
                });
            });
            var getStyle = function getStyle(isRight) {
                isFlipped && (isRight = !isRight);
                return styles[+isHighlight()][+isRight];
            };
            var redraw = function redraw(coord, lineCoords) {
                currentlyDrawnButton && currentlyDrawnButton.remove();
                currentlyDrawnButton = drawButton(graphie, coord, lineCoords, isHighlight() ? 1.5 * options.size : options.size, isHighlight() ? .125 * options.size : .25, getStyle(0), getStyle(1));
            };
            var update = function update(coordA, coordZ) {
                coordA = coordA || line.pointA.coord;
                coordZ = coordZ || line.pointZ.coord;
                var buttonCoord = kline.midpoint([ coordA, coordZ ]);
                button.setCoord(buttonCoord);
                redraw(buttonCoord, [ coordA, coordZ ]);
            };
            $(line).on("move", _.bind(update, button, null, null));
            var $mouseTarget = $(button.mouseTarget.getMouseTarget());
            $mouseTarget.on("vclick", function() {
                if (false !== options.onClick()) {
                    isFlipped = !isFlipped;
                    redraw(button.coord, [ line.pointA.coord, line.pointZ.coord ]);
                }
            });
            // Bring the reflection line handles in front of the button, so
            // that if we drag the reflectPoints really close together, we can
            // still move the handles away from each other, rather than only
            // being able to apply the reflection.
            line.pointA.toFront();
            line.pointZ.toFront();
            // Replace the visual point with the double triangle thing
            button.visibleShape.remove();
            var pointScale = graphie.scaleVector(options.size)[0] / 20;
            button.mouseTarget.attr({
                scale: 1.5 * pointScale
            });
            $mouseTarget.css("cursor", "pointer");
            // Make the arrow-thing grow and shrink with mouseover/out
            $mouseTarget.bind("vmouseover", function(e) {
                isHovering = true;
                redraw(button.coord, [ line.pointA.coord, line.pointZ.coord ]);
            });
            $mouseTarget.bind("vmouseout", function(e) {
                isHovering = false;
                redraw(button.coord, [ line.pointA.coord, line.pointZ.coord ]);
            });
            var oldButtonRemove = button.remove;
            button.remove = function() {
                currentlyDrawnButton.remove();
                oldButtonRemove.call(button);
            };
            button.update = update;
            button.isFlipped = function() {
                return isFlipped;
            };
            update();
            return button;
        };
    }(),
    protractor: function protractor(center) {
        return new Protractor(this, center);
    },
    ruler: function ruler(options) {
        return new Ruler(this, options || {});
    },
    addPoints: addPoints
});

function Protractor(graph, center) {
    this.set = graph.raphael.set();
    this.cx = center[0];
    this.cy = center[1];
    var pro = this;
    var r = graph.unscaleVector(180.5)[0];
    var imgPos = graph.scalePoint([ this.cx - r, this.cy + r - graph.unscaleVector(10.5)[1] ]);
    var image = graph.mouselayer.image("https://ka-perseus-graphie.s3.amazonaws.com/e9d032f2ab8b95979f674fbfa67056442ba1ff6a.png", imgPos[0], imgPos[1], 360, 180);
    this.set.push(image);
    // Prevent the page from scrolling when we grab and drag the image on a
    // mobile device.
    image.node.addEventListener("touchstart", function(event) {
        event.preventDefault();
    }, {
        passive: false
    });
    var arrowHelper = function arrowHelper(angle, pixelsFromEdge) {
        var scaledRadius = graph.scaleVector(r);
        scaledRadius[0] -= 16;
        scaledRadius[1] -= 16;
        var scaledCenter = graph.scalePoint(center);
        return Math.sin((angle + 90) * Math.PI / 180) * (scaledRadius[0] + pixelsFromEdge) + scaledCenter[0] + "," + (Math.cos((angle + 90) * Math.PI / 180) * (scaledRadius[1] + pixelsFromEdge) + scaledCenter[1]);
    };
    var arrow = graph.raphael.path(" M" + arrowHelper(180, 6) + " L" + arrowHelper(180, 2) + " L" + arrowHelper(183, 10) + " L" + arrowHelper(180, 18) + " L" + arrowHelper(180, 14) + " A" + (graph.scaleVector(r)[0] + 10) + "," + (graph.scaleVector(r)[1] + 10) + ",0,0,1," + arrowHelper(170, 14) + " L" + arrowHelper(170, 18) + " L" + arrowHelper(167, 10) + " L" + arrowHelper(170, 2) + " L" + arrowHelper(170, 6) + " A" + (graph.scaleVector(r)[0] + 10) + "," + (graph.scaleVector(r)[1] + 10) + ",0,0,0," + arrowHelper(180, 6) + " Z").attr({
        stroke: null,
        fill: KhanColors.INTERACTIVE
    });
    // add it to the set so it translates with everything else
    this.set.push(arrow);
    this.centerPoint = graph.addMovablePoint({
        coord: center,
        visible: false
    });
    // Use a movablePoint for rotation
    this.rotateHandle = graph.addMovablePoint({
        bounded: false,
        coord: [ Math.sin(275 * Math.PI / 180) * r + this.cx, Math.cos(275 * Math.PI / 180) * r + this.cy ],
        onMove: function onMove(x, y) {
            var angle = 180 * Math.atan2(pro.centerPoint.coord[1] - y, pro.centerPoint.coord[0] - x) / Math.PI;
            pro.rotate(-angle - 5, true);
        }
    });
    // Add a constraint so the point moves in a circle
    this.rotateHandle.constraints.fixedDistance.dist = r;
    this.rotateHandle.constraints.fixedDistance.point = this.centerPoint;
    // Remove the default dot added by the movablePoint since we have our
    // double-arrow thing
    this.rotateHandle.visibleShape.remove();
    // Make the mouse target bigger to encompass the whole area around the
    // double-arrow thing
    this.rotateHandle.mouseTarget.attr({
        scale: 2
    });
    var isDragging = false;
    var isHovering = false;
    var isHighlight = function isHighlight() {
        return isHovering || isDragging;
    };
    var self = this;
    var $mouseTarget = $(self.rotateHandle.mouseTarget.getMouseTarget());
    $mouseTarget.bind("vmousedown", function(event) {
        isDragging = true;
        $mouseTarget.css("cursor", "-webkit-grabbing");
        $mouseTarget.css("cursor", "grabbing");
        arrow.animate({
            scale: 1.5,
            fill: KhanColors.INTERACTING
        }, 50);
        $(document).bind("vmouseup.rotateHandle", function(event) {
            isDragging = false;
            $mouseTarget.css("cursor", "-webkit-grab");
            $mouseTarget.css("cursor", "grab");
            isHighlight() || arrow.animate({
                scale: 1,
                fill: KhanColors.INTERACTIVE
            }, 50);
            $(document).unbind("vmouseup.rotateHandle");
        });
    });
    $mouseTarget.bind("vmouseover", function(event) {
        isHovering = true;
        arrow.animate({
            scale: 1.5,
            fill: KhanColors.INTERACTING
        }, 50);
    });
    $mouseTarget.bind("vmouseout", function(event) {
        isHovering = false;
        isHighlight() || arrow.animate({
            scale: 1,
            fill: KhanColors.INTERACTIVE
        }, 50);
    });
    var setNodes = $.map(this.set, function(el) {
        return el.node;
    });
    this.makeTranslatable = function makeTranslatable() {
        $(setNodes).css("cursor", "move");
        $mouseTarget.css("cursor", "-webkit-grab");
        $mouseTarget.css("cursor", "grab");
        $(setNodes).bind("vmousedown", function(event) {
            event.preventDefault();
            var startx = event.pageX - $(graph.raphael.canvas.parentNode).offset().left;
            var starty = event.pageY - $(graph.raphael.canvas.parentNode).offset().top;
            $(document).bind("vmousemove.protractor", function(event) {
                var mouseX = event.pageX - $(graph.raphael.canvas.parentNode).offset().left;
                var mouseY = event.pageY - $(graph.raphael.canvas.parentNode).offset().top;
                // can't go beyond 10 pixels from the edge
                mouseX = Math.max(10, Math.min(graph.xpixels - 10, mouseX));
                mouseY = Math.max(10, Math.min(graph.ypixels - 10, mouseY));
                var dx = mouseX - startx;
                var dy = mouseY - starty;
                $.each(pro.set.items, function() {
                    this.translate(dx, dy);
                });
                pro.centerPoint.setCoord([ pro.centerPoint.coord[0] + dx / graph.scale[0], pro.centerPoint.coord[1] - dy / graph.scale[1] ]);
                pro.rotateHandle.setCoord([ pro.rotateHandle.coord[0] + dx / graph.scale[0], pro.rotateHandle.coord[1] - dy / graph.scale[1] ]);
                startx = mouseX;
                starty = mouseY;
            });
            $(document).one("vmouseup", function(event) {
                $(document).unbind("vmousemove.protractor");
            });
        });
    };
    this.rotation = 0;
    this.rotate = function(offset, absolute) {
        var center = graph.scalePoint(this.centerPoint.coord);
        absolute && (this.rotation = 0);
        this.set.rotate(this.rotation + offset, center[0], center[1]);
        this.rotation = this.rotation + offset;
        return this;
    };
    this.moveTo = function moveTo(x, y) {
        var start = graph.scalePoint(pro.centerPoint.coord);
        var end = graph.scalePoint([ x, y ]);
        var time = 2 * GraphUtils.getDistance(start, end);
        $({
            x: start[0],
            y: start[1]
        }).animate({
            x: end[0],
            y: end[1]
        }, {
            duration: time,
            step: function step(now, fx) {
                var dx = 0;
                var dy = 0;
                "x" === fx.prop ? dx = now - graph.scalePoint(pro.centerPoint.coord)[0] : "y" === fx.prop && (dy = now - graph.scalePoint(pro.centerPoint.coord)[1]);
                $.each(pro.set.items, function() {
                    this.translate(dx, dy);
                });
                pro.centerPoint.setCoord([ pro.centerPoint.coord[0] + dx / graph.scale[0], pro.centerPoint.coord[1] - dy / graph.scale[1] ]);
                pro.rotateHandle.setCoord([ pro.rotateHandle.coord[0] + dx / graph.scale[0], pro.rotateHandle.coord[1] - dy / graph.scale[1] ]);
            }
        });
    };
    this.rotateTo = function rotateTo(angle) {
        Math.abs(this.rotation - angle) > 180 && (this.rotation += 360);
        var time = 5 * Math.abs(this.rotation - angle);
        $({
            0: this.rotation
        }).animate({
            0: angle
        }, {
            duration: time,
            step: function step(now, fx) {
                pro.rotate(now, true);
                pro.rotateHandle.setCoord([ Math.sin((now + 275) * Math.PI / 180) * r + pro.centerPoint.coord[0], Math.cos((now + 275) * Math.PI / 180) * r + pro.centerPoint.coord[1] ]);
            }
        });
    };
    this.remove = function() {
        this.set.remove();
    };
    this.makeTranslatable();
    return this;
}

function Ruler(graphie, options) {
    _.defaults(options, {
        center: [ 0, 0 ],
        pixelsPerUnit: 40,
        ticksPerUnit: 10,
        // 10 or power of 2
        units: 10,
        // the length the ruler can measure
        label: "",
        // e.g "cm" (the shorter, the better)
        style: {
            fill: null,
            stroke: KhanColors.GRAY
        }
    });
    var light = _.extend({}, options.style, {
        strokeWidth: 1
    });
    var bold = _.extend({}, options.style, {
        strokeWidth: 2
    });
    var width = options.units * options.pixelsPerUnit;
    var height = 50;
    var leftBottom = graphie.unscalePoint(kvector.subtract(graphie.scalePoint(options.center), kvector.scale([ width, -50 ], .5)));
    var graphieUnitsPerUnit = options.pixelsPerUnit / graphie.scale[0];
    var graphieUnitsHeight = 50 / graphie.scale[0];
    var rightTop = kvector.add(leftBottom, [ options.units * graphieUnitsPerUnit, graphieUnitsHeight ]);
    var tickHeight = 1;
    var tickHeightMap = void 0;
    if (10 === options.ticksPerUnit) // decimal, as on a centimeter ruler
    tickHeightMap = {
        10: 1,
        5: .55,
        1: .35
    }; else {
        var sizes = [ 1, .6, .45, .3 ];
        tickHeightMap = {};
        for (var i = options.ticksPerUnit; i >= 1; i /= 2) tickHeightMap[i] = 1 * (sizes.shift() || .2);
    }
    var tickFrequencies = _.keys(tickHeightMap).sort(function(a, b) {
        return b - a;
    });
    function getTickHeight(i) {
        for (var k = 0; k < tickFrequencies.length; k++) {
            var key = tickFrequencies[k];
            if (i % key === 0) return tickHeightMap[key];
        }
    }
    var left = leftBottom[0];
    var bottom = leftBottom[1];
    var right = rightTop[0];
    var top = rightTop[1];
    var numTicks = options.units * options.ticksPerUnit + 1;
    var set = graphie.raphael.set();
    var px = 1 / graphie.scale[0];
    set.push(graphie.line([ left - px, bottom ], [ right + px, bottom ], bold));
    set.push(graphie.line([ left - px, top ], [ right + px, top ], bold));
    _.times(numTicks, function(i) {
        var n = i / options.ticksPerUnit;
        var x = left + n * graphieUnitsPerUnit;
        var height = getTickHeight(i) * graphieUnitsHeight;
        var style = 0 === i || i === numTicks - 1 ? bold : light;
        set.push(graphie.line([ x, bottom ], [ x, bottom + height ], style));
        if (n % 1 === 0) {
            var coord = graphie.scalePoint([ x, top ]);
            var text = void 0;
            var offset = void 0;
            if (0 === n) {
                // Unit label
                text = options.label;
                offset = {
                    mm: 13,
                    cm: 11,
                    m: 8,
                    km: 11,
                    in: 8,
                    ft: 8,
                    yd: 10,
                    mi: 10
                }[text] || 3 * text.toString().length;
            } else {
                // Tick label
                text = n;
                offset = -3 * (n.toString().length + 1);
            }
            var label = graphie.raphael.text(coord[0] + offset, coord[1] + 10, text);
            label.attr({
                "font-family": "KaTeX_Main",
                "font-size": "12px",
                color: "#444"
            });
            set.push(label);
        }
    });
    var mouseTarget = graphie.mouselayer.path(GraphUtils.svgPath([ leftBottom, [ left, top ], rightTop, [ right, bottom ], /* closed */
    true ]));
    mouseTarget.attr({
        fill: "#000",
        opacity: 0,
        stroke: "#000",
        "stroke-width": 2
    });
    set.push(mouseTarget);
    // Prevent the page from scrolling when we grab and drag the ruler on a
    // mobile device.
    mouseTarget.node.addEventListener("touchstart", function(event) {
        event.preventDefault();
    }, {
        passive: false
    });
    var setNodes = $.map(set, function(el) {
        return el.node;
    });
    $(setNodes).css("cursor", "move");
    $(setNodes).bind("vmousedown", function(event) {
        event.preventDefault();
        var startx = event.pageX - $(graphie.raphael.canvas.parentNode).offset().left;
        var starty = event.pageY - $(graphie.raphael.canvas.parentNode).offset().top;
        $(document).bind("vmousemove.ruler", function(event) {
            var mouseX = event.pageX - $(graphie.raphael.canvas.parentNode).offset().left;
            var mouseY = event.pageY - $(graphie.raphael.canvas.parentNode).offset().top;
            // can't go beyond 10 pixels from the edge
            mouseX = Math.max(10, Math.min(graphie.xpixels - 10, mouseX));
            mouseY = Math.max(10, Math.min(graphie.ypixels - 10, mouseY));
            var dx = mouseX - startx;
            var dy = mouseY - starty;
            set.translate(dx, dy);
            leftBottomHandle.setCoord([ leftBottomHandle.coord[0] + dx / graphie.scale[0], leftBottomHandle.coord[1] - dy / graphie.scale[1] ]);
            rightBottomHandle.setCoord([ rightBottomHandle.coord[0] + dx / graphie.scale[0], rightBottomHandle.coord[1] - dy / graphie.scale[1] ]);
            startx = mouseX;
            starty = mouseY;
        });
        $(document).one("vmouseup", function(event) {
            $(document).unbind("vmousemove.ruler");
        });
    });
    var leftBottomHandle = graphie.addMovablePoint({
        coord: leftBottom,
        normalStyle: {
            fill: KhanColors.INTERACTIVE,
            "fill-opacity": 0,
            stroke: KhanColors.INTERACTIVE
        },
        highlightStyle: {
            fill: KhanColors.INTERACTING,
            "fill-opacity": .1,
            stroke: KhanColors.INTERACTING
        },
        pointSize: 6,
        // or 8 maybe?
        onMove: function onMove(x, y) {
            var dy = rightBottomHandle.coord[1] - y;
            var dx = rightBottomHandle.coord[0] - x;
            var angle = 180 * Math.atan2(dy, dx) / Math.PI;
            var center = kvector.scale(kvector.add([ x, y ], rightBottomHandle.coord), .5);
            var scaledCenter = graphie.scalePoint(center);
            var oldCenter = kvector.scale(kvector.add(leftBottomHandle.coord, rightBottomHandle.coord), .5);
            var scaledOldCenter = graphie.scalePoint(oldCenter);
            var diff = kvector.subtract(scaledCenter, scaledOldCenter);
            set.rotate(-angle, scaledOldCenter[0], scaledOldCenter[1]);
            set.translate(diff[0], diff[1]);
        }
    });
    var rightBottomHandle = graphie.addMovablePoint({
        coord: [ right, bottom ],
        normalStyle: {
            fill: KhanColors.INTERACTIVE,
            "fill-opacity": 0,
            stroke: KhanColors.INTERACTIVE
        },
        highlightStyle: {
            fill: KhanColors.INTERACTING,
            "fill-opacity": .1,
            stroke: KhanColors.INTERACTING
        },
        pointSize: 6,
        // or 8 maybe?
        onMove: function onMove(x, y) {
            var dy = y - leftBottomHandle.coord[1];
            var dx = x - leftBottomHandle.coord[0];
            var angle = 180 * Math.atan2(dy, dx) / Math.PI;
            var center = kvector.scale(kvector.add([ x, y ], leftBottomHandle.coord), .5);
            var scaledCenter = graphie.scalePoint(center);
            var oldCenter = kvector.scale(kvector.add(leftBottomHandle.coord, rightBottomHandle.coord), .5);
            var scaledOldCenter = graphie.scalePoint(oldCenter);
            var diff = kvector.subtract(scaledCenter, scaledOldCenter);
            set.rotate(-angle, scaledOldCenter[0], scaledOldCenter[1]);
            set.translate(diff[0], diff[1]);
        }
    });
    // Make each handle rotate the ruler about the other one
    leftBottomHandle.constraints.fixedDistance.dist = width / graphie.scale[0];
    leftBottomHandle.constraints.fixedDistance.point = rightBottomHandle;
    rightBottomHandle.constraints.fixedDistance.dist = width / graphie.scale[0];
    rightBottomHandle.constraints.fixedDistance.point = leftBottomHandle;
    this.remove = function() {
        set.remove();
        leftBottomHandle.remove();
        rightBottomHandle.remove();
    };
    return this;
}

function MovableAngle(graphie, options) {
    this.graphie = graphie;
    // TODO(alex): Move standard colors from math.js to somewhere else
    // so that they are available when this file is first parsed
    _.extend(this, options);
    _.defaults(this, {
        normalStyle: {
            stroke: KhanColors.INTERACTIVE,
            "stroke-width": 2,
            fill: KhanColors.INTERACTIVE
        },
        highlightStyle: {
            stroke: KhanColors.INTERACTING,
            "stroke-width": 2,
            fill: KhanColors.INTERACTING
        },
        labelStyle: {
            stroke: KhanColors.DYNAMIC,
            "stroke-width": 1,
            color: KhanColors.DYNAMIC
        },
        angleStyle: {
            stroke: KhanColors.DYNAMIC,
            "stroke-width": 1,
            color: KhanColors.DYNAMIC
        },
        allowReflex: true
    });
    if (!this.points || 3 !== this.points.length) throw new Error("MovableAngle requires 3 points");
    // Handle coordinates that are not MovablePoints (i.e. [2, 4])
    this.points = _.map(options.points, function(point) {
        return _.isArray(point) ? graphie.addMovablePoint({
            coord: point,
            visible: false,
            constraints: {
                fixed: true
            },
            normalStyle: this.normalStyle
        }) : point;
    }, this);
    this.coords = _.pluck(this.points, "coord");
    null == this.reflex && (this.allowReflex ? this.reflex = this._getClockwiseAngle(this.coords) > 180 : this.reflex = false);
    this.rays = _.map([ 0, 2 ], function(i) {
        return graphie.addMovableLineSegment({
            pointA: this.points[1],
            pointZ: this.points[i],
            fixed: true,
            extendRay: true
        });
    }, this);
    this.temp = [];
    this.labeledAngle = graphie.label([ 0, 0 ], "", "center", this.labelStyle);
    if (!this.fixed) {
        this.addMoveHandlers();
        this.addHighlightHandlers();
    }
    this.update();
}

_.extend(MovableAngle.prototype, {
    points: [],
    snapDegrees: 0,
    snapOffsetDeg: 0,
    angleLabel: "",
    numArcs: 1,
    pushOut: 0,
    fixed: false,
    addMoveHandlers: function addMoveHandlers() {
        var graphie = this.graphie;
        function tooClose(point1, point2) {
            var safeDistance = 30;
            return GraphUtils.getDistance(graphie.scalePoint(point1), graphie.scalePoint(point2)) < 30;
        }
        var points = this.points;
        // Drag the vertex to move the entire angle
        points[1].onMove = function(x, y) {
            var oldVertex = points[1].coord;
            var newVertex = [ x, y ];
            var delta = addPoints(newVertex, reverseVector(oldVertex));
            var valid = true;
            var newPoints = {};
            _.each([ 0, 2 ], function(i) {
                var oldPoint = points[i].coord;
                var newPoint = addPoints(oldPoint, delta);
                var angle = GraphUtils.findAngle(newVertex, newPoint);
                angle *= Math.PI / 180;
                newPoint = graphie.constrainToBoundsOnAngle(newPoint, 10, angle);
                newPoints[i] = newPoint;
                tooClose(newVertex, newPoint) && (valid = false);
            });
            // Only move points if all new positions are valid
            valid && _.each(newPoints, function(newPoint, i) {
                points[i].setCoord(newPoint);
            });
            return valid;
        };
        var snap = this.snapDegrees;
        var snapOffset = this.snapOffsetDeg;
        // Drag ray control points to move each ray individually
        _.each([ 0, 2 ], function(i) {
            points[i].onMove = function(x, y) {
                var newPoint = [ x, y ];
                var vertex = points[1].coord;
                if (tooClose(vertex, newPoint)) return false;
                if (snap) {
                    var angle = GraphUtils.findAngle(newPoint, vertex);
                    angle = Math.round((angle - snapOffset) / snap) * snap + snapOffset;
                    var distance = GraphUtils.getDistance(newPoint, vertex);
                    return addPoints(vertex, graphie.polar(distance, angle));
                }
                return true;
            };
        });
        // Expose only a single move event
        $(points).on("move", function() {
            this.update();
            $(this).trigger("move");
        }.bind(this));
    },
    addHighlightHandlers: function addHighlightHandlers() {
        var vertex = this.points[1];
        vertex.onHighlight = function() {
            _.each(this.points, function(point) {
                point.visibleShape.animate(this.highlightStyle, 50);
            }, this);
            _.each(this.rays, function(ray) {
                ray.visibleLine.animate(this.highlightStyle, 50);
                ray.arrowStyle = _.extend({}, ray.arrowStyle, {
                    color: this.highlightStyle.stroke,
                    stroke: this.highlightStyle.stroke
                });
            }, this);
            this.angleStyle = _.extend({}, this.angleStyle, {
                color: this.highlightStyle.stroke,
                stroke: this.highlightStyle.stroke
            });
            this.update();
        }.bind(this);
        vertex.onUnhighlight = function() {
            _.each(this.points, function(point) {
                point.visibleShape.animate(this.normalStyle, 50);
            }, this);
            _.each(this.rays, function(ray) {
                ray.visibleLine.animate(ray.normalStyle, 50);
                ray.arrowStyle = _.extend({}, ray.arrowStyle, {
                    color: ray.normalStyle.stroke,
                    stroke: ray.normalStyle.stroke
                });
            }, this);
            this.angleStyle = _.extend({}, this.angleStyle, {
                color: KhanColors.DYNAMIC,
                stroke: KhanColors.DYNAMIC
            });
            this.update();
        }.bind(this);
    },
    /**
     * Returns the angle in [0, 360) degrees created by the
     * coords when interpreted in a clockwise direction.
     */
    _getClockwiseAngle: function _getClockwiseAngle(coords) {
        // The order of these is "weird" to match what a clockwise
        // order is in graphie.labelAngle
        // from the second point
        // clockwise to the first point
        return GraphUtils.findAngle(coords[2], coords[0], coords[1]) + 0;
    },
    isReflex: function isReflex() {
        return this.reflex;
    },
    isClockwise: function isClockwise() {
        return this._getClockwiseAngle(this.coords) > 180 === this.reflex;
    },
    getClockwiseCoords: function getClockwiseCoords() {
        return this.isClockwise() ? _.clone(this.coords) : _.clone(this.coords).reverse();
    },
    update: function update(shouldChangeReflexivity) {
        var prevCoords = this.coords;
        this.coords = _.pluck(this.points, "coord");
        // Update lines
        _.invoke(this.points, "updateLineEnds");
        var prevAngle = this._getClockwiseAngle(prevCoords);
        var angle = this._getClockwiseAngle(this.coords);
        var prevClockwiseReflexive = prevAngle > 180;
        var clockwiseReflexive = angle > 180;
        if (this.allowReflex) {
            null == shouldChangeReflexivity && (shouldChangeReflexivity = prevClockwiseReflexive !== clockwiseReflexive && Math.abs(angle - prevAngle) < 180);
            shouldChangeReflexivity && (this.reflex = !this.reflex);
        }
        _.invoke(this.temp, "remove");
        this.temp = this.graphie.labelAngle({
            point1: this.coords[0],
            vertex: this.coords[1],
            point3: this.coords[2],
            label: this.labeledAngle,
            text: this.angleLabel,
            numArcs: this.numArcs,
            pushOut: this.pushOut,
            clockwise: this.reflex === clockwiseReflexive,
            style: this.angleStyle
        });
    },
    remove: function remove() {
        _.invoke(this.rays, "remove");
        _.invoke(this.temp, "remove");
        this.labeledAngle.remove();
    }
});

module.exports = InteractiveUtils;