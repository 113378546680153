/**
 * A library of options to pass to add/draw/remove/constraints
 */
var _ = require("underscore");

var WrappedEllipse = require("./wrapped-ellipse.js");

var kpoint = require("kmath").point;

var add = {
    constrain: function constrain() {
        this.constrain();
    }
};

add.standard = [ add.constrain ];

var modify = {
    draw: function draw() {
        this.draw();
    }
};

modify.standard = [ modify.draw ];

var draw = {
    basic: function basic(state, prevState) {
        var graphie = this.graphie;
        if (!this.state.visibleShape) {
            var radii = [ this.pointSize() / graphie.scale[0], this.pointSize() / graphie.scale[1] ];
            var options = {
                maxScale: Math.max(this.highlightStyle().scale, this.normalStyle().scale),
                // Add in 10px of padding to avoid clipping at the edges.
                padding: 10,
                shadow: state.shadow
            };
            this.state.visibleShape = new WrappedEllipse(graphie, this.coord(), radii, options);
            this.state.visibleShape.attr(_.omit(this.normalStyle(), "scale"));
            this.state.visibleShape.toFront();
            // Keep mouseTarget in front of visible shape
            this.mouseTarget() && this.mouseTarget().toFront();
        }
        state.normalStyle === prevState.normalStyle || _.isEqual(state.normalStyle, prevState.normalStyle) || this.state.visibleShape.attr(this.normalStyle());
        this.state.visibleShape.moveTo(this.coord());
        this.mouseTarget() && this.mouseTarget().moveTo(this.coord());
    },
    highlight: function highlight(state, prevState) {
        state.isHovering && !prevState.isHovering ? state.visibleShape.animate(this.highlightStyle(), 50) : !state.isHovering && prevState.isHovering && state.visibleShape.animate(this.normalStyle(), 50);
    }
};

draw.standard = [ draw.basic, draw.highlight ];

var remove = {
    basic: function basic() {
        if (this.state.visibleShape) {
            this.state.visibleShape.remove();
            this.state.visibleShape = null;
        }
    }
};

remove.standard = remove.basic;

var constraints = {
    fixed: function fixed() {
        return function() {
            return false;
        };
    },
    snap: function snap(_snap) {
        return function(coord) {
            if (null === _snap) return true;
            _snap = _snap || this.graphie.snap;
            return kpoint.roundTo(coord, _snap);
        };
    },
    bound: function bound(range, snap, paddingPx) {
        void 0 === paddingPx && (paddingPx = void 0 === range ? 10 : 0);
        return function(coord, prev, options) {
            var graphie = this.graphie;
            range = range || graphie.range;
            void 0 === snap && (snap = graphie.snap);
            var lower = graphie.unscalePoint([ paddingPx, graphie.ypixels - paddingPx ]);
            var upper = graphie.unscalePoint([ graphie.xpixels - paddingPx, paddingPx ]);
            if (snap) {
                lower = kpoint.ceilTo(lower, snap);
                upper = kpoint.floorTo(upper, snap);
            }
            if (!!options && !!options.onOutOfBounds) {
                if (coord[0] > upper[0] || coord[0] < lower[0] || coord[1] > upper[1] || coord[1] < lower[1]) {
                    options.onSkipRemaining();
                    options.onOutOfBounds();
                }
                return coord;
            }
            return [ Math.max(lower[0], Math.min(upper[0], coord[0])), Math.max(lower[1], Math.min(upper[1], coord[1])) ];
        };
    }
};

constraints.standard = null;

module.exports = {
    add: add,
    modify: modify,
    draw: draw,
    remove: remove,
    onMoveStart: {
        standard: null
    },
    constraints: constraints,
    onMove: {
        standard: null
    },
    onMoveEnd: {
        standard: null
    },
    onClick: {
        standard: null
    }
};