/* eslint-disable object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
/* global i18n:false */
var $ = require("jquery");

var _ = require("underscore");

var KhanMath = require("./math.js");

var MAXERROR_EPSILON = Math.pow(2, -42);

/*
 * Answer types
 *
 * Utility for creating answerable questions displayed in exercises
 *
 * Different answer types produce different kinds of input displays, and do
 * different kinds of checking on the solutions.
 *
 * Each of the objects contain two functions, setup and createValidator.
 *
 * The setup function takes a solutionarea and solution, and performs setup
 * within the solutionarea, and then returns an object which contains:
 *
 * answer: a function which, when called, will retrieve the current answer from
 *         the solutionarea, which can then be validated using the validator
 *         function
 * validator: a function returned from the createValidator function (defined
 *            below)
 * solution: the correct answer to the problem
 * showGuess: a function which, when given a guess, shows the guess within the
 *            provided solutionarea
 * showGuessCustom: a function which displays parts of a guess that are not
 *                  within the solutionarea; currently only used for custom
 *                  answers
 *
 * The createValidator function only takes a solution, and it returns a
 * function which can be used to validate an answer.
 *
 * The resulting validator function returns:
 * - true: if the answer is fully correct
 * - false: if the answer is incorrect
 * - "" (the empty string): if no answer has been provided (e.g. the answer box
 *   is left unfilled)
 * - a string: if there is some slight error
 *
 * In most cases, setup and createValidator don't really need the solution DOM
 * element so we have setupFunctional and createValidatorFunctional for them
 * which take only $solution.text() and $solution.data(). This makes it easier
 * to reuse specific answer types.
 *
 * TODO(alpert): Think of a less-absurd name for createValidatorFunctional.
 *
 */
var KhanAnswerTypes = {
    /*
     * predicate answer type
     *
     * performs simple predicate-based checking of a numeric solution, with
     * different kinds of number formats
     *
     * Uses the data-forms option on the solution to choose which number formats
     * are acceptable. Available data-forms:
     *
     * - integer:  3
     * - proper:   3/5
     * - improper: 5/3
     * - pi:       3 pi
     * - log:      log(5)
     * - percent:  15%
     * - mixed:    1 1/3
     * - decimal:  1.7
     *
     * The solution should be a predicate of the form:
     *
     * function(guess, maxError) {
     *     return abs(guess - 3) < maxError;
     * }
     *
     */
    predicate: {
        defaultForms: "integer, proper, improper, mixed, decimal",
        createValidatorFunctional: function createValidatorFunctional(predicate, options) {
            // Extract the options from the given solution object
            options = _.extend({
                simplify: "required",
                ratio: false,
                forms: KhanAnswerTypes.predicate.defaultForms
            }, options);
            var acceptableForms = void 0;
            // this is maintaining backwards compatibility
            // TODO(merlob) fix all places that depend on this, then delete
            acceptableForms = _.isArray(options.forms) ? options.forms : options.forms.split(/\s*,\s*/);
            // TODO(jack): remove options.inexact in favor of options.maxError
            void 0 === options.inexact && (// If we aren't allowing inexact, ensure that we don't have a
            // large maxError as well.
            options.maxError = 0);
            // Allow a small tolerance on maxError, to avoid numerical
            // representation issues (2.3 should be correct for a solution of
            // 2.45 with maxError=0.15).
            options.maxError = +options.maxError + MAXERROR_EPSILON;
            // If percent is an acceptable form, make sure it's the last one
            // in the list so we don't prematurely complain about not having
            // a percent sign when the user entered the correct answer in a
            // different form (such as a decimal or fraction)
            if (_.contains(acceptableForms, "percent")) {
                acceptableForms = _.without(acceptableForms, "percent");
                acceptableForms.push("percent");
            }
            // Take text looking like a fraction, and turn it into a number
            var fractionTransformer = function fractionTransformer(text) {
                text = text.replace(/\u2212/, "-").replace(/([+-])\s+/g, "$1").replace(/(^\s*)|(\s*$)/gi, "");
                // Extract numerator and denominator
                var match = text.match(/^([+-]?\d+)\s*\/\s*([+-]?\d+)$/);
                var parsedInt = parseInt(text, 10);
                if (match) {
                    var num = parseFloat(match[1]);
                    var denom = parseFloat(match[2]);
                    return [ {
                        value: num / denom,
                        exact: denom > 0 && (options.ratio || "1" !== match[2]) && 1 === KhanMath.getGCD(num, denom)
                    } ];
                }
                if (!isNaN(parsedInt) && "" + parsedInt === text) return [ {
                    value: parsedInt,
                    exact: true
                } ];
                return [];
            };
            /*
             * Different forms of numbers
             *
             * Each function returns a list of objects of the form:
             *
             * {
             *    value: numerical value,
             *    exact: true/false
             * }
             */
            var forms = {
                // integer, which is encompassed by decimal
                integer: function integer(text) {
                    // Compare the decimal form to the decimal form rounded to
                    // an integer. Only accept if the user actually entered an
                    // integer.
                    var decimal = forms.decimal(text);
                    var rounded = forms.decimal(text, 1);
                    if (null != decimal[0].value && decimal[0].value === rounded[0].value || null != decimal[1].value && decimal[1].value === rounded[1].value) return decimal;
                    return [];
                },
                // A proper fraction
                proper: function proper(text) {
                    return $.map(fractionTransformer(text), function(o) {
                        // All fractions that are less than 1
                        // All fractions that are less than 1
                        return Math.abs(o.value) < 1 ? [ o ] : [];
                    });
                },
                // an improper fraction
                improper: function improper(text) {
                    return $.map(fractionTransformer(text), function(o) {
                        // All fractions that are greater than 1
                        // All fractions that are greater than 1
                        return Math.abs(o.value) >= 1 ? [ o ] : [];
                    });
                },
                // pi-like numbers
                pi: function pi(text) {
                    var match = void 0;
                    var possibilities = [];
                    // Replace unicode minus sign with hyphen
                    text = text.replace(/\u2212/, "-");
                    // - pi
                    // (Note: we also support \pi (for TeX), p, tau (and \tau,
                    // and t), pau.)
                    if (match = text.match(/^([+-]?)\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)$/i)) possibilities = [ {
                        value: parseFloat(match[1] + "1"),
                        exact: true
                    } ]; else if (match = text.match(/^([+-]?\s*\d+\s*(?:\/\s*[+-]?\s*\d+)?)\s*\*?\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)$/i)) possibilities = fractionTransformer(match[1]); else if (match = text.match(/^([+-]?)\s*(\d+)\s*([+-]?\d+)\s*\/\s*([+-]?\d+)\s*\*?\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)$/i)) {
                        var sign = parseFloat(match[1] + "1");
                        var integ = parseFloat(match[2]);
                        var num = parseFloat(match[3]);
                        var denom = parseFloat(match[4]);
                        var simplified = num < denom && 1 === KhanMath.getGCD(num, denom);
                        possibilities = [ {
                            value: sign * (integ + num / denom),
                            exact: simplified
                        } ];
                    } else if (match = text.match(/^([+-]?\s*\d+)\s*\*?\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)\s*(?:\/\s*([+-]?\s*\d+))?$/i)) possibilities = fractionTransformer(match[1] + "/" + match[3]); else if (match = text.match(/^([+-]?)\s*\*?\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)\s*(?:\/\s*([+-]?\d+))?$/i)) possibilities = fractionTransformer(match[1] + "1/" + match[3]); else if ("0" === text) possibilities = [ {
                        value: 0,
                        exact: true
                    } ]; else {
                        if (!(match = text.match(/^(.+)\s*\*?\s*(\\?pi|p|\u03c0|\\?tau|t|\u03c4|pau)$/i))) {
                            possibilities = _.reduce(KhanAnswerTypes.predicate.defaultForms.split(/\s*,\s*/), function(memo, form) {
                                return memo.concat(forms[form](text));
                            }, []);
                            // If the answer is a floating point number that's
                            // near a multiple of pi, mark is as being possibly
                            // an approximation of pi.  We actually check if
                            // it's a plausible approximation of pi/12, since
                            // sometimes the correct answer is like pi/3 or pi/4.
                            // We also say it's a pi-approximation if it involves
                            // x/7 (since 22/7 is an approximation of pi.)
                            // Never mark an integer as being an approximation
                            // of pi.
                            var approximatesPi = false;
                            var number = parseFloat(text);
                            if (isNaN(number) || number === parseInt(text)) text.match(/\/\s*7/) && (approximatesPi = true); else {
                                var piMult = Math.PI / 12;
                                var roundedNumber = piMult * Math.round(number / piMult);
                                Math.abs(number - roundedNumber) < .01 && (approximatesPi = true);
                            }
                            approximatesPi && _.each(possibilities, function(possibility) {
                                possibility.piApprox = true;
                            });
                            return possibilities;
                        }
                        possibilities = forms.decimal(match[1]);
                    }
                    var multiplier = Math.PI;
                    text.match(/\\?tau|t|\u03c4/) && (multiplier = 2 * Math.PI);
                    // We're taking an early stand along side xkcd in the
                    // inevitable ti vs. pau debate... http://xkcd.com/1292
                    text.match(/pau/) && (multiplier = 1.5 * Math.PI);
                    $.each(possibilities, function(ix, possibility) {
                        possibility.value *= multiplier;
                    });
                    return possibilities;
                },
                // Converts '' to 1 and '-' to -1 so you can write "[___] x"
                // and accept sane things
                coefficient: function coefficient(text) {
                    var possibilities = [];
                    // Replace unicode minus sign with hyphen
                    text = text.replace(/\u2212/, "-");
                    "" === text ? possibilities = [ {
                        value: 1,
                        exact: true
                    } ] : "-" === text && (possibilities = [ {
                        value: -1,
                        exact: true
                    } ]);
                    return possibilities;
                },
                // simple log(c) form
                log: function log(text) {
                    var match = void 0;
                    var possibilities = [];
                    // Replace unicode minus sign with hyphen
                    text = text.replace(/\u2212/, "-");
                    text = text.replace(/[ \(\)]/g, "");
                    (match = text.match(/^log\s*(\S+)\s*$/i)) ? possibilities = forms.decimal(match[1]) : "0" === text && (possibilities = [ {
                        value: 0,
                        exact: true
                    } ]);
                    return possibilities;
                },
                // Numbers with percent signs
                percent: function percent(text) {
                    text = $.trim(text);
                    // store whether or not there is a percent sign
                    var hasPercentSign = false;
                    if (text.indexOf("%") === text.length - 1) {
                        text = $.trim(text.substring(0, text.length - 1));
                        hasPercentSign = true;
                    }
                    var transformed = forms.decimal(text);
                    $.each(transformed, function(ix, t) {
                        t.exact = hasPercentSign;
                        t.value = t.value / 100;
                    });
                    return transformed;
                },
                // Mixed numbers, like 1 3/4
                mixed: function mixed(text) {
                    var match = text.replace(/\u2212/, "-").replace(/([+-])\s+/g, "$1").match(/^([+-]?)(\d+)\s+(\d+)\s*\/\s*(\d+)$/);
                    if (match) {
                        var sign = parseFloat(match[1] + "1");
                        var integ = parseFloat(match[2]);
                        var num = parseFloat(match[3]);
                        var denom = parseFloat(match[4]);
                        return [ {
                            value: sign * (integ + num / denom),
                            exact: num < denom && 1 === KhanMath.getGCD(num, denom)
                        } ];
                    }
                    return [];
                },
                // Decimal numbers -- compare entered text rounded to
                // 'precision' Reciprical of the precision against the correct
                // answer. We round to 1/1e10 by default, which is healthily
                // less than machine epsilon but should be more than any real
                // decimal answer would use. (The 'integer' answer type uses
                // precision == 1.)
                decimal: function decimal(text, precision) {
                    null == precision && (precision = 1e10);
                    var normal = function normal(text) {
                        text = $.trim(text);
                        var match = text.replace(/\u2212/, "-").replace(/([+-])\s+/g, "$1").match(/^([+-]?(?:\d{1,3}(?:[, ]?\d{3})*\.?|\d{0,3}(?:[, ]?\d{3})*\.(?:\d{3}[, ]?)*\d{1,3}))$/);
                        // You can't start a number with `0,`, to prevent us
                        // interpeting '0.342' as correct for '342'
                        var badLeadingZero = text.match(/^0[0,]*,/);
                        if (match && !badLeadingZero) {
                            var x = parseFloat(match[1].replace(/[, ]/g, ""));
                            void 0 === options.inexact && (x = Math.round(x * precision) / precision);
                            return x;
                        }
                    };
                    var commas = function commas(text) {
                        text = text.replace(/([\.,])/g, function(_, c) {
                            return "." === c ? "," : ".";
                        });
                        return normal(text);
                    };
                    return [ {
                        value: normal(text),
                        exact: true
                    }, {
                        value: commas(text),
                        exact: true
                    } ];
                }
            };
            // validator function
            return function(guess) {
                // The fallback variable is used in place of the answer, if no
                // answer is provided (i.e. the field is left blank)
                var fallback = null != options.fallback ? "" + options.fallback : "";
                guess = $.trim(guess) || fallback;
                var score = {
                    empty: "" === guess,
                    correct: false,
                    message: null,
                    guess: guess
                };
                // iterate over all the acceptable forms, and if one of the
                // answers is correct, return true
                $.each(acceptableForms, function(i, form) {
                    var transformed = forms[form](guess);
                    for (var j = 0, l = transformed.length; j < l; j++) {
                        var val = transformed[j].value;
                        var exact = transformed[j].exact;
                        var piApprox = transformed[j].piApprox;
                        // If a string was returned, and it exactly matches,
                        // return true
                        if (predicate(val, options.maxError)) {
                            // If the exact correct number was returned,
                            // return true
                            if (exact || "optional" === options.simplify) {
                                score.correct = true;
                                score.message = options.message || null;
                                // If the answer is correct, don't say it's
                                // empty. This happens, for example, with the
                                // coefficient type where guess === "" but is
                                // interpreted as "1" which is correct.
                                score.empty = false;
                            } else if ("percent" === form) {
                                // Otherwise, an error was returned
                                score.empty = true;
                                score.message = i18n._("Your answer is almost correct, but it is missing a <code>\\%</code> at the end.");
                            } else {
                                "enforced" !== options.simplify && (score.empty = true);
                                score.message = i18n._("Your answer is almost correct, but it needs to be simplified.");
                            }
                            return false;
                        }
                        if (piApprox && predicate(val, Math.abs(.001 * val))) {
                            score.empty = true;
                            score.message = i18n._("Your answer is close, but you may have approximated pi. Enter your answer as a multiple of pi, like <code>12\\ \\text{pi}</code> or <code>2/3\\ \\text{pi}</code>");
                        }
                    }
                });
                if (false === score.correct) {
                    var interpretedGuess = false;
                    _.each(forms, function(form) {
                        _.any(form(guess), function(t) {
                            return null != t.value && !_.isNaN(t.value);
                        }) && (interpretedGuess = true);
                    });
                    if (!interpretedGuess) {
                        score.empty = true;
                        score.message = i18n._("We could not understand your answer. Please check your answer for extra text or symbols.");
                        return score;
                    }
                }
                return score;
            };
        }
    },
    /*
     * number answer type
     *
     * wraps the predicate answer type to performs simple number-based checking
     * of a solution
     */
    number: {
        convertToPredicate: function convertToPredicate(correct, options) {
            // TODO(alpert): Don't think this $.trim is necessary
            var correctFloat = parseFloat($.trim(correct));
            return [ function(guess, maxError) {
                return Math.abs(guess - correctFloat) < maxError;
            }, $.extend({}, options, {
                type: "predicate"
            }) ];
        },
        createValidatorFunctional: function createValidatorFunctional(correct, options) {
            var _KhanAnswerTypes$pred;
            return (_KhanAnswerTypes$pred = KhanAnswerTypes.predicate).createValidatorFunctional.apply(_KhanAnswerTypes$pred, KhanAnswerTypes.number.convertToPredicate(correct, options));
        }
    },
    /*
     * The expression answer type parses a given expression or equation
     * and semantically compares it to the solution. In addition, instant
     * feedback is provided by rendering the last answer that fully parsed.
     *
     * Parsing options:
     * functions (e.g. data-functions="f g h")
     *     A space or comma separated list of single-letter variables that
     *     should be interpreted as functions. Case sensitive. "e" and "i"
     *     are reserved.
     *
     *     no functions specified: f(x+y) == fx + fy
     *     with "f" as a function: f(x+y) != fx + fy
     *
     * Comparison options:
     * same-form (e.g. data-same-form)
     *     If present, the answer must match the solution's structure in
     *     addition to evaluating the same. Commutativity and excess negation
     *     are ignored, but all other changes will trigger a rejection. Useful
     *     for requiring a particular form of an equation, or if the answer
     *     must be factored.
     *
     *     example question:    Factor x^2 + x - 2
     *     example solution:    (x-1)(x+2)
     *     accepted answers:    (x-1)(x+2), (x+2)(x-1), ---(-x-2)(-1+x), etc.
     *     rejected answers:    x^2+x-2, x*x+x-2, x(x+1)-2, (x-1)(x+2)^1, etc.
     *     rejection message:   Your answer is not in the correct form
     *
     * simplify (e.g. data-simplify)
     *     If present, the answer must be fully expanded and simplified. Use
     *     carefully - simplification is hard and there may be bugs, or you
     *     might not agree on the definition of "simplified" used. You will
     *     get an error if the provided solution is not itself fully expanded
     *     and simplified.
     *
     *     example question:    Simplify ((n*x^5)^5) / (n^(-2)*x^2)^-3
     *     example solution:    x^31 / n
     *     accepted answers:    x^31 / n, x^31 / n^1, x^31 * n^(-1), etc.
     *     rejected answers:    (x^25 * n^5) / (x^(-6) * n^6), etc.
     *     rejection message:   Your answer is not fully expanded and simplified
     *
     * Rendering options:
     * times (e.g. data-times)
     *     If present, explicit multiplication (such as between numbers) will
     *     be rendered with a cross/x symbol (TeX: \times) instead of the usual
     *     center dot (TeX: \cdot).
     *
     *     normal rendering:    2 * 3^x -> 2 \cdot 3^{x}
     *     but with "times":    2 * 3^x -> 2 \times 3^{x}
     */
    expression: {
        parseSolution: function parseSolution(solutionString, options) {
            var solution = KAS.parse(solutionString, options);
            if (!solution.parsed) throw new Error("The provided solution (" + solutionString + ") didn't parse.");
            if (options.simplified && !solution.expr.isSimplified()) throw new Error("The provided solution (" + solutionString + ") isn't fully expanded and simplified.");
            solution = solution.expr;
            return solution;
        },
        createValidatorFunctional: function createValidatorFunctional(solution, options) {
            return function(guess) {
                var score = {
                    empty: false,
                    correct: false,
                    message: null,
                    guess: guess
                };
                // Don't bother parsing an empty input
                if (!guess) {
                    score.empty = true;
                    return score;
                }
                var answer = KAS.parse(guess, options);
                // An unsuccessful parse doesn't count as wrong
                if (!answer.parsed) {
                    score.empty = true;
                    return score;
                }
                // Solution will need to be parsed again if we're creating
                // this from a multiple question type
                "string" === typeof solution && (solution = KhanAnswerTypes.expression.parseSolution(solution, options));
                var result = KAS.compare(answer.expr, solution, options);
                if (result.equal) // Correct answer
                score.correct = true; else if (result.message) // Nearly correct answer
                score.message = result.message; else {
                    // Replace x with * and see if it would have been correct
                    var answerX = KAS.parse(guess.replace(/[xX]/g, "*"), options);
                    if (answerX.parsed) {
                        var resultX = KAS.compare(answerX.expr, solution, options);
                        if (resultX.equal) {
                            score.empty = true;
                            score.message = "I'm a computer. I only understand multiplication if you use an asterisk (*) as the multiplication sign.";
                        } else resultX.message && (score.message = resultX.message + " Also, I'm a computer. I only understand multiplication if you use an asterisk (*) as the multiplication sign.");
                    }
                }
                return score;
            };
        }
    },
    text: {
        createValidatorFunctional: function createValidatorFunctional(solution, options) {
            solution = solution.trim();
            return function(guess) {
                guess = guess.trim();
                var score = {
                    empty: false,
                    correct: false,
                    message: null,
                    guess: guess
                };
                // Don't bother parsing an empty input
                if (!guess) {
                    score.empty = true;
                    return score;
                }
                solution === guess ? score.correct = true : guess.indexOf(solution) > -1 ? score.message = i18n._("You wrote a little too much") : solution.indexOf(guess) > -1 && (score.message = i18n._("You need to write a little more"));
                return score;
            };
        }
    }
};

module.exports = KhanAnswerTypes;