var _instructionsMobile, _responsiveRadioConta;

/* eslint-disable object-curly-spacing */
/* TODO(csilvers): fix these lint errors (http://eslint.org/docs/rules): */
/* To fix, remove an entry above, run ka-lint, and fix errors. */
/* global i18n */
var _require = require("aphrodite"), StyleSheet = _require.StyleSheet, css = _require.css;

var classNames = require("classnames");

var React = require("react");

var ReactDOM = require("react-dom");

var _ = require("underscore");

var ApiClassNames = require("../../perseus-api.jsx").ClassNames;

var Renderer = require("../../renderer.jsx");

var sharedStyles = require("../../styles/shared.js");

var styleConstants = require("../../styles/constants.js");

var mediaQueries = require("../../styles/media-queries.js");

var captureScratchpadTouchStart = require("../../util.js").captureScratchpadTouchStart;

var Choice = require("./choice.jsx");

var ChoiceNoneAbove = React.createClass({
    displayName: "ChoiceNoneAbove",
    propTypes: {
        className: React.PropTypes.string,
        content: React.PropTypes.node,
        showContent: React.PropTypes.bool
    },
    getDefaultProps: function getDefaultProps() {
        return {
            showContent: true
        };
    },
    render: function render() {
        var choiceProps = _.extend({}, this.props, {
            className: classNames(this.props.className, "none-of-above"),
            content: this.props.showContent ? this.props.content : // We use a Renderer here because that is how
            // `this.props.content` is wrapped otherwise.
            // We pass in a key here so that we avoid a semi-spurious
            // react warning when we render this in the same place
            // as the previous choice content renderer.
            // Note this destroys state, but since all we're doing
            // is outputting "None of the above", that is okay.
            React.createElement(Renderer, {
                key: "noneOfTheAboveRenderer",
                content: i18n._("None of the above")
            })
        });
        return React.createElement(Choice, choiceProps);
    }
});

var ChoicesType = React.PropTypes.arrayOf(React.PropTypes.shape({
    // Indicates whether this choice is checked.
    checked: React.PropTypes.bool,
    // Indicates whether the user has "crossed out" this choice, meaning
    // that they don't think it's correct. This value does not affect
    // scoring or other behavior; it's just a note for the user's
    // reference.
    crossedOut: React.PropTypes.bool,
    content: React.PropTypes.node,
    rationale: React.PropTypes.node,
    hasRationale: React.PropTypes.bool,
    showRationale: React.PropTypes.bool,
    showCorrectness: React.PropTypes.bool,
    correct: React.PropTypes.bool,
    originalIndex: React.PropTypes.number,
    isNoneOfTheAbove: React.PropTypes.bool
}));

var radioBorderColor = styleConstants.radioBorderColor;

var BaseRadio = React.createClass({
    displayName: "BaseRadio",
    propTypes: {
        apiOptions: React.PropTypes.shape({
            readOnly: React.PropTypes.bool,
            satStyling: React.PropTypes.bool,
            isMobile: React.PropTypes.bool,
            styling: React.PropTypes.shape({
                radioStyleVersion: React.PropTypes.oneOf([ "intermediate", "final" ])
            })
        }),
        choices: ChoicesType,
        deselectEnabled: React.PropTypes.bool,
        editMode: React.PropTypes.bool,
        labelWrap: React.PropTypes.bool,
        countChoices: React.PropTypes.bool,
        numCorrect: React.PropTypes.number,
        multipleSelect: React.PropTypes.bool,
        reviewModeRubric: React.PropTypes.shape({
            choices: ChoicesType
        }),
        // A callback indicating that this choice has changed. Its argument is
        // an object with two keys: `checked` and `crossedOut`. Each contains
        // an array of boolean values, specifying the new checked and
        // crossed-out value of each choice.
        onChange: React.PropTypes.func
    },
    statics: {
        styles: StyleSheet.create({
            instructions: {
                display: "block",
                color: styleConstants.gray17,
                fontSize: 14,
                lineHeight: 1.25,
                fontStyle: "normal",
                fontWeight: "bold",
                marginBottom: 16
            },
            instructionsMobile: (_instructionsMobile = {
                fontSize: 18
            }, _instructionsMobile[mediaQueries.smOrSmaller] = {
                fontSize: 16
            }, _instructionsMobile[mediaQueries.xl] = {
                fontSize: 20
            }, _instructionsMobile),
            radio: {
                // Avoid centering
                width: "100%"
            },
            responsiveRadioContainer: (_responsiveRadioConta = {
                borderBottom: "1px solid " + radioBorderColor,
                borderTop: "1px solid " + radioBorderColor,
                width: "auto"
            }, _responsiveRadioConta[mediaQueries.smOrSmaller] = {
                marginLeft: styleConstants.negativePhoneMargin,
                marginRight: styleConstants.negativePhoneMargin
            }, _responsiveRadioConta),
            radioContainerFirstHighlighted: {
                borderTop: "1px solid rgba(0, 0, 0, 0)"
            },
            radioContainerLastHighlighted: {
                borderBottom: "1px solid rgba(0, 0, 0, 0)"
            },
            satRadio: {
                background: "none",
                marginLeft: 0,
                userSelect: "none"
            },
            satRadioOption: {
                margin: 0,
                padding: 0,
                borderBottom: "1px solid #ccc",
                ":first-child": {
                    borderTop: "1px solid #ccc"
                }
            },
            satRadioOptionCorrect: {
                borderBottomColor: styleConstants.satCorrectBorderColor,
                ":first-child": {
                    borderTopColor: styleConstants.satCorrectBorderColor
                }
            },
            satRadioOptionIncorrect: {
                borderBottomColor: styleConstants.satIncorrectBorderColor,
                ":first-child": {
                    borderTopColor: styleConstants.satIncorrectBorderColor
                }
            },
            satRadioOptionNextCorrect: {
                borderBottomColor: styleConstants.satCorrectBorderColor
            },
            satRadioOptionNextIncorrect: {
                borderBottomColor: styleConstants.satIncorrectBorderColor
            },
            satReviewRadioOption: {
                pointerEvents: "none"
            },
            item: {
                marginLeft: 20
            },
            inlineItem: {
                display: "inline-block",
                paddingLeft: 20,
                verticalAlign: "middle"
            },
            responsiveItem: {
                marginLeft: 0,
                padding: 0,
                ":not(:last-child)": {
                    borderBottom: "1px solid " + radioBorderColor
                }
            },
            selectedItem: {
                background: "white"
            },
            aboveBackdrop: {
                position: "relative",
                // HACK(emily): We want selected choices to show up above our
                // exercise backdrop, but below the exercise footer and
                // "feedback popover" that shows up. This z-index is carefully
                // coordinated between here and webapp. :(
                zIndex: 1062
            },
            aboveBackdropMobile: {
                boxShadow: "0 0 4px 0 rgba(0, 0, 0, 0.2),0 0 2px 0 rgba(0, 0, 0, 0.1)",
                ":not(:last-child)": {
                    borderBottom: "1px solid rgba(0, 0, 0, 0)"
                }
            },
            nextHighlighted: {
                ":not(:last-child)": {
                    borderBottom: "1px solid rgba(0, 0, 0, 0)"
                }
            },
            responsiveContainer: {
                overflow: "auto",
                marginLeft: styleConstants.negativePhoneMargin,
                marginRight: styleConstants.negativePhoneMargin,
                paddingLeft: styleConstants.phoneMargin
            },
            responsiveFieldset: {
                paddingRight: styleConstants.phoneMargin
            }
        })
    },
    getDefaultProps: function getDefaultProps() {
        return {
            editMode: false
        };
    },
    getInitialState: function getInitialState() {
        return {
            // TODO(mdr): This keeps the ID stable across re-renders on the
            //     same machine, but, at time of writing, the server's state
            //     isn't rehydrated to the client during SSR, so the server and
            //     client will generate different IDs and cause a mismatch
            //     during SSR :(
            radioGroupName: _.uniqueId("perseus_radio_")
        };
    },
    // When a particular choice's `onChange` handler is called, indicating a
    // change in a single choice's values, we need to call our `onChange`
    // handler in order to notify our parent. However, our API with our parent
    // is that we always provide *all* values for *all* choices, even if just
    // one choice's values changed. (This is because sometimes an interaction
    // with one choice can affect many choices, like how checking a new answer
    // will usually cause the old answer to become unchecked.)
    //
    // So, given the new values for a particular choice, compute the new values
    // for all choices, and pass them to `this.props.onChange`.
    //
    // `newValues` is an object with two keys: `checked` and `crossedOut`. Each
    // contains a boolean value specifying the new checked and crossed-out
    // value of this choice.
    updateChoice: function updateChoice(choiceIndex, newValues) {
        // Get the baseline `checked` values. If we're checking a new answer
        // and multiple-select is not on, we should clear all choices to be
        // unchecked. Otherwise, we should copy the old checked values.
        var newCheckedList = void 0;
        newCheckedList = newValues.checked && !this.props.multipleSelect ? this.props.choices.map(function(_) {
            return false;
        }) : this.props.choices.map(function(c) {
            return c.checked;
        });
        // Get the baseline `crossedOut` values.
        var newCrossedOutList = this.props.choices.map(function(c) {
            return c.crossedOut;
        });
        // Update this choice's `checked` and `crossedOut` values.
        newCheckedList[choiceIndex] = newValues.checked;
        newCrossedOutList[choiceIndex] = newValues.crossedOut;
        this.props.onChange({
            checked: newCheckedList,
            crossedOut: newCrossedOutList
        });
    },
    focus: function focus(i) {
        ReactDOM.findDOMNode(this.refs["radio" + (i || 0)]).focus();
        return true;
    },
    getInstructionsText: function getInstructionsText() {
        return this.props.multipleSelect ? this.props.countChoices ? i18n._("Choose { numCorrect } answers:", {
            numCorrect: this.props.numCorrect
        }) : i18n._("Choose all answers that apply:") : i18n._("Choose 1 answer:");
    },
    deselectEnabled: function deselectEnabled() {
        // We want to force enable deselect on mobile.
        return this.props.apiOptions.isMobile || this.props.deselectEnabled;
    },
    render: function render() {
        var inputType = this.props.multipleSelect ? "checkbox" : "radio";
        var rubric = this.props.reviewModeRubric;
        var reviewMode = !!rubric;
        var styles = BaseRadio.styles;
        var sat = this.props.apiOptions.satStyling;
        var isMobile = this.props.apiOptions.isMobile;
        var choices = this.props.choices;
        var firstChoiceHighlighted = choices[0].highlighted;
        var lastChoiceHighlighted = choices[choices.length - 1].highlighted;
        var className = classNames("perseus-widget-radio", !this.props.editMode && "perseus-rendered-radio", css(styles.radio, // SAT doesn't use the "responsive styling" as it conflicts
        // with their custom theming.
        !sat && styles.responsiveRadioContainer, !sat && firstChoiceHighlighted && isMobile && styles.radioContainerFirstHighlighted, !sat && lastChoiceHighlighted && isMobile && styles.radioContainerLastHighlighted, sat && styles.satRadio));
        var instructionsClassName = classNames("instructions", css(styles.instructions, isMobile && styles.instructionsMobile));
        var instructions = this.getInstructionsText();
        var shouldShowInstructions = !sat;
        var responsiveClassName = css(styles.responsiveFieldset);
        var fieldset = React.createElement("fieldset", {
            className: "perseus-widget-radio-fieldset " + responsiveClassName
        }, React.createElement("legend", {
            className: "perseus-sr-only"
        }, instructions), shouldShowInstructions && React.createElement("div", {
            className: instructionsClassName
        }, instructions), React.createElement("ul", {
            className: className
        }, this.props.choices.map(function(choice, i) {
            var _this = this;
            var Element = Choice;
            var elementProps = {
                ref: "radio" + i,
                apiOptions: this.props.apiOptions,
                checked: choice.checked,
                crossedOut: choice.crossedOut,
                reviewMode: reviewMode,
                correct: choice.correct,
                rationale: choice.rationale,
                content: choice.content,
                disabled: this.props.apiOptions.readOnly || choice.disabled,
                editMode: this.props.editMode,
                groupName: this.state.radioGroupName,
                isLastChoice: i === this.props.choices.length - 1,
                showCorrectness: reviewMode || !!choice.showCorrectness,
                showRationale: choice.hasRationale && (reviewMode || choice.showRationale),
                type: inputType,
                pos: i,
                deselectEnabled: this.deselectEnabled(),
                onChange: function onChange(newValues) {
                    _this.updateChoice(i, newValues);
                }
            };
            if (choice.isNoneOfTheAbove) {
                Element = ChoiceNoneAbove;
                _.extend(elementProps, {
                    showContent: choice.revealNoneOfTheAbove
                });
            }
            var nextChoice = this.props.choices[i + 1];
            var nextChoiceHighlighted = !!nextChoice && nextChoice.highlighted;
            var aphroditeClassName = function aphroditeClassName(checked) {
                // Whether or not to show correctness borders
                // for this choice and the next choice.
                var satShowCorrectness = sat && reviewMode && checked;
                var satShowCorrectnessNext = sat && reviewMode && nextChoice && nextChoice.checked;
                return css(sharedStyles.aboveScratchpad, styles.item, !sat && styles.responsiveItem, !sat && checked && styles.selectedItem, !sat && checked && choice.highlighted && styles.aboveBackdrop, !sat && checked && choice.highlighted && _this.props.apiOptions.isMobile && styles.aboveBackdropMobile, !sat && nextChoiceHighlighted && _this.props.apiOptions.isMobile && styles.nextHighlighted, sat && styles.satRadioOption, satShowCorrectness && !choice.correct && styles.satRadioOptionIncorrect, satShowCorrectness && choice.correct && styles.satRadioOptionCorrect, satShowCorrectnessNext && !nextChoice.correct && styles.satRadioOptionNextIncorrect, satShowCorrectnessNext && nextChoice.correct && styles.satRadioOptionNextCorrect, sat && rubric && styles.satReviewRadioOption);
            };
            // HACK(abdulrahman): Preloads the selection-state
            // css because of a bug that causes iOS to lag
            // when selecting the button for the first time.
            aphroditeClassName(true);
            var className = classNames(aphroditeClassName(choice.checked), // TODO(aria): Make test case for these API
            // classNames
            ApiClassNames.RADIO.OPTION, choice.checked && ApiClassNames.RADIO.SELECTED, reviewMode && rubric.choices[i].correct && ApiClassNames.CORRECT, reviewMode && !rubric.choices[i].correct && ApiClassNames.INCORRECT);
            // In edit mode, the Choice renders a Div in order to
            // allow for the contentEditable area to be selected
            // (label forces any clicks inside to select the input
            // element) We have to add some extra behavior to make
            // sure that we can still check the choice.
            var listElem = null;
            var clickHandler = null;
            this.props.editMode && (clickHandler = function clickHandler(e) {
                // Traverse the parent nodes of the clicked
                // element.
                var elem = e.target;
                while (elem && elem !== listElem) {
                    // If the clicked element is inside of the
                    // radio icon, then we want to trigger the
                    // check by flipping the choice of the icon.
                    if (elem.getAttribute("data-is-radio-icon")) {
                        _this.updateChoice(i, {
                            checked: !choice.checked
                        });
                        return;
                    }
                    elem = elem.parentNode;
                }
            });
            // TODO(mattdr): Index isn't a *good* choice of key
            // here; is there a better one? Can we use choice
            // content somehow? Would changing our choice of key
            // somehow break something happening inside a choice's
            // child Renderers, by changing when we mount/unmount?
            return React.createElement("li", {
                key: i,
                ref: function ref(e) {
                    return listElem = e;
                },
                className: className,
                onClick: clickHandler,
                onTouchStart: this.props.labelWrap ? captureScratchpadTouchStart : null
            }, React.createElement(Element, elementProps));
        }, this)));
        // Allow for horizontal scrolling if content is too wide, which may be
        // an issue especially on phones.
        // This is disabled in SAT, since it conflicts with their theming.
        return React.createElement("div", {
            className: css(!sat && styles.responsiveContainer)
        }, fieldset);
    }
});

module.exports = BaseRadio;