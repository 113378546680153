var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "link-click-here",
    severity: _rule2.default.Severity.WARNING,
    selector: "link",
    pattern: /click here/i,
    message: 'Inappropriate link text:\nDo not use the words "click here" in links.'
});