var _extends = Object.assign || function(target) {
    for (var i = 1; i < arguments.length; i++) {
        var source = arguments[i];
        for (var key in source) Object.prototype.hasOwnProperty.call(source, key) && (target[key] = source[key]);
    }
    return target;
};

function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) throw new TypeError("Cannot call a class as a function");
}

/**
 * The state machine that backs our gesture system. In particular, this state
 * machine manages the interplay between focuses, touch ups, and swiping.
 * It is entirely ignorant of the existence of popovers and the positions of
 * DOM nodes, operating solely on IDs. The state machine does accommodate for
 * multi-touch interactions, tracking gesture state on a per-touch basis.
 */
var defaults = {
    longPressWaitTimeMs: 50,
    swipeThresholdPx: 20,
    holdIntervalMs: 250
};

var GestureStateMachine = function() {
    function GestureStateMachine(handlers, options, swipeDisabledNodeIds, multiPressableKeys) {
        _classCallCheck(this, GestureStateMachine);
        this.handlers = handlers;
        this.options = _extends({}, defaults, options);
        this.swipeDisabledNodeIds = swipeDisabledNodeIds || [];
        this.multiPressableKeys = multiPressableKeys || [];
        // TODO(charlie): Flow-type this file. It's not great that we're now
        // passing around these opaque state objects.
        this.touchState = {};
        this.swipeState = null;
    }
    GestureStateMachine.prototype._maybeCancelLongPressForTouch = function _maybeCancelLongPressForTouch(touchId) {
        var longPressTimeoutId = this.touchState[touchId].longPressTimeoutId;
        if (longPressTimeoutId) {
            clearTimeout(longPressTimeoutId);
            this.touchState[touchId] = _extends({}, this.touchState[touchId], {
                longPressTimeoutId: null
            });
        }
    };
    GestureStateMachine.prototype._maybeCancelPressAndHoldForTouch = function _maybeCancelPressAndHoldForTouch(touchId) {
        var pressAndHoldIntervalId = this.touchState[touchId].pressAndHoldIntervalId;
        if (pressAndHoldIntervalId) {
            // If there was an interval set to detect holds, clear it out.
            clearInterval(pressAndHoldIntervalId);
            this.touchState[touchId] = _extends({}, this.touchState[touchId], {
                pressAndHoldIntervalId: null
            });
        }
    };
    GestureStateMachine.prototype._cleanupTouchEvent = function _cleanupTouchEvent(touchId) {
        this._maybeCancelLongPressForTouch(touchId);
        this._maybeCancelPressAndHoldForTouch(touchId);
        delete this.touchState[touchId];
    };
    /**
     * Handle a focus event on the node with the given identifier, which may be
     * `null` to indicate that the user has dragged their finger off of any
     * registered nodes, but is still in the middle of a gesture.
     *
     * @param {string|null} id - the identifier of the newly focused node, or
     *                           `null` if no node is focused
     * @param {number} touchId - a unique identifier associated with the touch
     */
    GestureStateMachine.prototype._onFocus = function _onFocus(id, touchId) {
        var _this = this;
        // If we're in the middle of a long-press, cancel it.
        this._maybeCancelLongPressForTouch(touchId);
        // Reset any existing hold-detecting interval.
        this._maybeCancelPressAndHoldForTouch(touchId);
        // Set the focused node ID and handle the focus event.
        // Note: we can call `onFocus` with `null` IDs. The semantics of an
        // `onFocus` with a `null` ID differs from that of `onBlur`. The former
        // indicates that a gesture that can focus future nodes is still in
        // progress, but that no node is currently focused. The latter
        // indicates that the gesture has ended and nothing will be focused.
        this.touchState[touchId] = _extends({}, this.touchState[touchId], {
            activeNodeId: id
        });
        this.handlers.onFocus(id);
        if (id) // Handle logic for repeating button presses.
        if (this.multiPressableKeys.includes(id)) {
            // Start by triggering a click, iOS style.
            this.handlers.onTrigger(id);
            // Set up a new hold detector for the current button.
            this.touchState[touchId] = _extends({}, this.touchState[touchId], {
                pressAndHoldIntervalId: setInterval(function() {
                    // On every cycle, trigger the click handler.
                    _this.handlers.onTrigger(id);
                }, this.options.holdIntervalMs)
            });
        } else // Set up a new hold detector for the current button.
        this.touchState[touchId] = _extends({}, this.touchState[touchId], {
            longPressTimeoutId: setTimeout(function() {
                _this.handlers.onLongPress(id);
                _this.touchState[touchId] = _extends({}, _this.touchState[touchId], {
                    longPressTimeoutId: null
                });
            }, this.options.longPressWaitTimeMs)
        });
    };
    /**
     * Clear out all active gesture information.
     */
    GestureStateMachine.prototype._onSwipeStart = function _onSwipeStart() {
        for (var _iterator = Object.keys(this.touchState), _isArray = Array.isArray(_iterator), _i = 0, _iterator = _isArray ? _iterator : _iterator[Symbol.iterator](); ;) {
            var _ref;
            if (_isArray) {
                if (_i >= _iterator.length) break;
                _ref = _iterator[_i++];
            } else {
                _i = _iterator.next();
                if (_i.done) break;
                _ref = _i.value;
            }
            var activeTouchId = _ref;
            this._maybeCancelLongPressForTouch(activeTouchId);
            this._maybeCancelPressAndHoldForTouch(activeTouchId);
        }
        this.touchState = {};
        this.handlers.onBlur();
    };
    /**
     * A function that returns the identifier of the node over which the touch
     * event occurred. This is provided as a piece of lazy computation, as
     * computing the DOM node for a given point is expensive, and the state
     * machine won't always need that information. For example, if the user is
     * swiping, then `onTouchMove` needs to be performant and doesn't care about
     * the node over which the touch occurred.
     *
     * @typedef idComputation
     * @returns {DOMNode} - the identifier of the node over which the touch
     *                      occurred
     */
    /**
     * Handle a touch-start event on the node with the given identifer.
     *
     * @param {idComputation} getId - a function that returns identifier of the
     *                                node over which the start event occurred
     * @param {number} touchId - a unique identifier associated with the touch
     */
    GestureStateMachine.prototype.onTouchStart = function onTouchStart(getId, touchId, pageX) {
        // Ignore any touch events that start mid-swipe.
        if (this.swipeState) return;
        if (this.touchState[touchId]) // It turns out we can get multiple touch starts with no
        // intervening move, end, or cancel events in Android WebViews.
        // TODO(benkomalo): it's not entirely clear why this happens, but
        // it seems to happen with the backspace button. It may be related
        // to FastClick (https://github.com/ftlabs/fastclick/issues/71)
        // though I haven't verified, and it's probably good to be robust
        // here anyways.
        return;
        var startingNodeId = getId();
        this.touchState[touchId] = {
            swipeLocked: this.swipeDisabledNodeIds.includes(startingNodeId),
            startX: pageX
        };
        this._onFocus(startingNodeId, touchId);
    };
    /**
     * Handle a touch-move event on the node with the given identifer.
     *
     * @param {idComputation} getId - a function that returns identifier of the
     *                                node over which the move event occurred
     * @param {number} touchId - a unique identifier associated with the touch
     * @param {number} pageX - the x coordinate of the touch
     * @param {boolean} swipeEnabled - whether the system should allow for
     *                                 transitions into a swiping state
     */
    GestureStateMachine.prototype.onTouchMove = function onTouchMove(getId, touchId, pageX, swipeEnabled) {
        if (this.swipeState) // Only respect the finger that started a swipe. Any other lingering
        // gestures are ignored.
        this.swipeState.touchId === touchId && this.handlers.onSwipeChange(pageX - this.swipeState.startX); else if (this.touchState[touchId]) {
            // It could be touch events started outside the keypad and
            // moved into it; ignore them.
            var _touchState$touchId = this.touchState[touchId], activeNodeId = _touchState$touchId.activeNodeId, startX = _touchState$touchId.startX, swipeLocked = _touchState$touchId.swipeLocked;
            var dx = pageX - startX;
            var shouldBeginSwiping = swipeEnabled && !swipeLocked && Math.abs(dx) > this.options.swipeThresholdPx;
            if (shouldBeginSwiping) {
                this._onSwipeStart();
                // Trigger the swipe.
                this.swipeState = {
                    touchId: touchId,
                    startX: startX
                };
                this.handlers.onSwipeChange(pageX - this.swipeState.startX);
            } else {
                var id = getId();
                id !== activeNodeId && this._onFocus(id, touchId);
            }
        }
    };
    /**
     * Handle a touch-end event on the node with the given identifer.
     *
     * @param {idComputation} getId - a function that returns identifier of the
     *                                node over which the end event occurred
     * @param {number} touchId - a unique identifier associated with the touch
     * @param {number} pageX - the x coordinate of the touch
     */
    GestureStateMachine.prototype.onTouchEnd = function onTouchEnd(getId, touchId, pageX) {
        if (this.swipeState) {
            // Only respect the finger that started a swipe. Any other lingering
            // gestures are ignored.
            if (this.swipeState.touchId === touchId) {
                this.handlers.onSwipeEnd(pageX - this.swipeState.startX);
                this.swipeState = null;
            }
        } else if (this.touchState[touchId]) {
            // It could be touch events started outside the keypad and
            // moved into it; ignore them.
            var _touchState$touchId2 = this.touchState[touchId], activeNodeId = _touchState$touchId2.activeNodeId, pressAndHoldIntervalId = _touchState$touchId2.pressAndHoldIntervalId;
            this._cleanupTouchEvent(touchId);
            var didPressAndHold = !!pressAndHoldIntervalId;
            didPressAndHold ? // We don't trigger a touch end if there was a press and hold,
            // because the key has been triggered at least once and calling
            // the onTouchEnd handler would add an extra trigger.
            this.handlers.onBlur() : // Trigger a touch-end. There's no need to notify clients of a
            // blur as clients are responsible for handling any cleanup in
            // their touch-end handlers.
            this.handlers.onTouchEnd(activeNodeId);
        }
    };
    /**
     * Handle a touch-cancel event.
     */
    GestureStateMachine.prototype.onTouchCancel = function onTouchCancel(touchId) {
        // If a touch is cancelled and we're swiping, end the swipe with no
        // displacement.
        if (this.swipeState) {
            if (this.swipeState.touchId === touchId) {
                this.handlers.onSwipeEnd(0);
                this.swipeState = null;
            }
        } else if (this.touchState[touchId]) {
            // Otherwise, trigger a full blur. We don't want to trigger a
            // touch-up, since the cancellation means that the user probably
            // didn't release over a key intentionally.
            this._cleanupTouchEvent(touchId);
            this.handlers.onBlur();
        }
    };
    return GestureStateMachine;
}();

module.exports = GestureStateMachine;