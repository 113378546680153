var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "double-spacing-after-terminal",
    severity: _rule2.default.Severity.BULK_WARNING,
    selector: "paragraph",
    pattern: /[.!\?] {2}/i,
    message: "Use a single space after a sentence-ending period, or\nany other kind of terminal punctuation."
});