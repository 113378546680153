var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

module.exports = _rule2.default.makeRule({
    name: "math-font-size",
    severity: _rule2.default.Severity.GUIDELINE,
    selector: "math, blockMath",
    // eslint-disable-next-line max-len
    pattern: /\\(tiny|Tiny|small|large|Large|LARGE|huge|Huge|scriptsize|normalsize)\s*{/,
    message: "Math font size:\nDon't change the default font size with \\Large{} or similar commands"
});