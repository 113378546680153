var _rule = require("../rule.js");

var _rule2 = _interopRequireDefault(_rule);

function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}

// Normally we have one rule per file. But since our selector class
// can't match specific widget types directly, this rule implements
// a number of image widget related rules in one place. This should
// slightly increase efficiency, but it means that if there is more
// than one problem with an image widget, the user will only see one
// problem at a time.
module.exports = _rule2.default.makeRule({
    name: "image-widget",
    severity: _rule2.default.Severity.BULK_WARNING,
    selector: "widget",
    lint: function lint(state, content, nodes, match, context) {
        // This rule only looks at image widgets
        if ("image" !== state.currentNode().widgetType) return;
        // If it can't find a definition for the widget it does nothing
        var widget = context && context.widgets && context.widgets[state.currentNode().id];
        if (!widget) return;
        // Make sure there is alt text
        var alt = widget.options.alt;
        if (!alt) return 'Images should have alt text:\nfor accessibility, all images should have a text description.\nAdd a description in the "Alt Text" box of the image widget.';
        // Make sure the alt text it is not trivial
        if (alt.trim().length < 8) return "Images should have alt text:\nfor accessibility, all images should have descriptive alt text.\nThis image's alt text is only " + alt.trim().length + " characters long.";
        // Make sure there is no math in the caption
        if (widget.options.caption && widget.options.caption.match(/[^\\]\$/)) return "No math in image captions:\nDon't include math expressions in image captions.";
    }
});